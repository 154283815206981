import { useQuery } from "@apollo/client";
import { InfoOutlined } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import { parse, toSeconds } from "iso8601-duration";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../api/__generated__";
import useSelectTranslation from "../../api/languages/useSelectTranslation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import { getDateFnsLocale } from "../language/languagesUtil";
import CalendarLinks from "../layout/CalendarLinks";
import { OrgProfileDialog } from "../organizations/OrgProfileDialog";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";

const ValueTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.sizes.normal,
  whiteSpace: "pre-line",
}));

const clientConsultationDetailQuery = graphql(`
  query ClientConsultationDetail($uuid: String!) {
    consultation(uuid: $uuid) {
      acceptedByHuman
      acceptedByProfessional
      bloomupFee
      cashTransaction {
        amount
        id
        status
      }
      creditTransactions {
        amount
        id
      }
      duration
      human {
        canPayWithCredits
        creditsLeft
        id
        organization {
          id
          name {
            ...useSelectTranslationStringInput
          }
          ...OrgProfileDialogOrganization
        }
        user {
          email
          firstName
          id
          lastName
          phone
        }
      }
      id
      matchedHuman {
        id
        subtopic {
          id
          titleTranslationKey
        }
        topic {
          id
          titleTranslationKey
        }
        treatment
      }
      message {
        body
        id
      }
      professionalFee
      scheduledFrom
      scheduledTo
      status
      type
      uuid
    }
  }
`);

const ClientConsultationDetail = ({
  consultationUuid,
  showMore,
}: {
  consultationUuid: string;
  showMore: boolean;
}) => {
  const selectTranslation = useSelectTranslation();
  const [orgDialogOpen, setOrgDialogOpen] = useState(false);
  const { data } = useQuery(clientConsultationDetailQuery, {
    variables: {
      uuid: consultationUuid,
    },
  });

  const { i18n, t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Payments,
  ]);

  const dateFnsLocale = getDateFnsLocale(i18n.language);

  const handleCloseOrgDialog = () => {
    setOrgDialogOpen(false);
  };

  if (!data?.consultation) return null;

  return (
    <Box>
      {data.consultation.human ? (
        <Box>
          {data.consultation.human.organization?.id ? (
            <Box
              onClick={() => setOrgDialogOpen(true)}
              sx={{ cursor: "pointer", marginBottom: "1rem" }}
            >
              <Label value={t("common:partner")} />
              <ValueTypography
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {selectTranslation(data.consultation.human.organization.name)}
                <InfoOutlined color="action" sx={{ marginLeft: "4px" }} />
              </ValueTypography>
            </Box>
          ) : null}
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:consultation.with")} />
            <ValueTypography>
              {`${data.consultation.human.user.firstName} ${data.consultation.human.user.lastName} -    `}
              <a href={`mailto:${data.consultation.human.user.email}`}>
                {data.consultation.human.user.email}
              </a>
              {" - "}
              <a href={`tel:${data.consultation.human.user.phone}`}>
                {data.consultation.human.user.phone}
              </a>
            </ValueTypography>
          </Box>
        </Box>
      ) : null}
      <Box sx={{ marginBottom: "1rem" }}>
        <Label value={t("common:consultation_type.label")} />
        <ValueTypography>
          {t(`common:consultation_type.${data.consultation.type}`)}
        </ValueTypography>
      </Box>
      {data.consultation.matchedHuman?.treatment ? (
        <>
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:treatment.label")} />
            <ValueTypography>
              {data.consultation.matchedHuman.treatment === "THERAPY"
                ? t(`common:therapy.label`)
                : data.consultation.matchedHuman.treatment === "COACHING"
                  ? t(`common:coaching.label`)
                  : ""}
            </ValueTypography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:topic.label")} />
            <ValueTypography>
              {t(
                data.consultation.matchedHuman.topic
                  ? data.consultation.matchedHuman.topic.titleTranslationKey
                  : "topics:something_else.title",
              )}
            </ValueTypography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:subtopic.label")} />
            <ValueTypography>
              {t(
                data.consultation.matchedHuman.topic
                  ? data.consultation.matchedHuman.topic.titleTranslationKey
                  : "topics:something_else.title",
              )}
            </ValueTypography>
          </Box>
        </>
      ) : null}
      <Box sx={{ marginBottom: "1rem" }}>
        <Label value={t("common:status.label")} />
        <ValueTypography>
          {t(`common:consultation_status.${data.consultation.status}`, {
            context: "FOR_PROFESSIONAL",
          })}
          {data.consultation.acceptedByHuman === false &&
            data.consultation.acceptedByProfessional === true &&
            data.consultation.status === "REQUESTED" &&
            " (" +
              t(
                "common:professional.consultation.detail.status.waiting.accept",
              ) +
              ")"}
        </ValueTypography>
      </Box>
      {showMore ? (
        <Box sx={{ marginBottom: "1rem" }}>
          {data.consultation.message && data.consultation.message.body ? (
            <Box sx={{ marginBottom: "1rem" }}>
              <Label value={t("common:consultation.message")} />
              <ValueTypography>
                {data.consultation.message.body}
              </ValueTypography>
            </Box>
          ) : null}
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:consultation.date")} />
            <ValueTypography>
              {format(new Date(data.consultation.scheduledFrom), "PPPP", {
                locale: dateFnsLocale,
              })}
            </ValueTypography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Label value={t("common:consultation.time", "Tijd")} />
            <ValueTypography>
              {format(new Date(data.consultation.scheduledFrom), "p", {
                locale: dateFnsLocale,
              })}
              {" → "}
              {format(new Date(data.consultation.scheduledTo), "p", {
                locale: dateFnsLocale,
              })}
              {" ("}
              {t("consultation.time.duration_small", {
                context: "small",
                duration: toSeconds(parse(data.consultation.duration)) / 60,
              })}
              {")"}
            </ValueTypography>
          </Box>
          <Box mt={4}>
            <CalendarLinks
              appUrl={window.location.origin}
              clientName={
                data.consultation.human?.user.firstName +
                " " +
                data.consultation.human?.user.lastName
              }
              path={`consultation/${data.consultation.uuid}`}
              scheduledFrom={data.consultation.scheduledFrom}
              scheduledTo={data.consultation.scheduledTo}
            />
          </Box>
          {data.consultation.human &&
          (data.consultation.cashTransaction ||
            data.consultation.creditTransactions.length > 0) ? (
            <Box sx={{ marginBottom: "1rem" }}>
              <Label value={t("common:price.label")} />
              <SanitizedText
                input={t("professional:consultation.pricing.info", {
                  clientPrice: data.consultation.cashTransaction?.amount,
                  context: !(data.consultation.creditTransactions.length > 0)
                    ? ""
                    : data.consultation.human.creditsLeft !== null
                      ? "credits"
                      : "unlimited_credits",
                  creditCount: data.consultation.creditTransactions[0]
                    ? Math.abs(data.consultation.creditTransactions[0].amount)
                    : 0,
                  professionalNetto: data.consultation.professionalFee ?? 0,
                  serviceCost: data.consultation.bloomupFee ?? 0,
                  totalCredits: data.consultation.human.creditsLeft,
                })}
              />
            </Box>
          ) : null}
          {data.consultation.cashTransaction ? (
            <Box sx={{ marginBottom: "1rem" }}>
              <Label value={t("payments:payment.status.text")} />
              <Typography>
                {t(
                  `payments:payment.status.${data.consultation.cashTransaction.status.toLowerCase()}`,
                  {
                    context: "professional",
                  },
                )}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {data.consultation.human?.organization ? (
        <OrgProfileDialog
          confirmationFooter={false}
          onClose={handleCloseOrgDialog}
          open={orgDialogOpen}
          organization={data.consultation.human.organization}
        />
      ) : null}
    </Box>
  );
};

export default ClientConsultationDetail;
