import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useQueryParams, {
  getParamAsBoolean,
  getParamAsString,
} from "../hooks/useQueryParams";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import { RouteNames } from "../routes/routeNames";

export default function PaymentSuccessPage() {
  const navigate = useNavigate();
  const { consultation, reimbursement } = useQueryParams();
  const reimbursementParam = getParamAsBoolean(reimbursement);
  const consultationParam = getParamAsString(consultation);

  const { t: translate } = useTranslation();

  const redirectPath = reimbursementParam
    ? RouteNames.Payment.Consultation.Pay.Reimbursement.path.replace(
        ":uuid",
        consultationParam,
      )
    : RouteNames.Home.path;

  const nextButtonCopy = reimbursementParam
    ? translate("common:next")
    : translate("ui:back.home");

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={`${translate("ui:thank.you")}!`}
        labelNext={nextButtonCopy}
        onClickNext={() => navigate(redirectPath)}
      >
        <Box>
          <Typography variant="h2">
            {translate("payments:payment.success")}
          </Typography>
          <Box mt={5}>
            <Typography>
              {translate("payments:payment.success.text")}
            </Typography>
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
