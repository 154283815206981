/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useFetchClientsForProfessional from "../../../../api/clients/hooks/useFetchClientsForProfessional";
import Human from "../../../../data-model/types/human/Human";
import LoadingPage from "../../../layout/LoadingPage";
import { RouteNames } from "../../../routes/routeNames";
import ClientsForProfessionalsContext from "./ClientsForProfessionalsContext";
import { IClientsForProfessionalsContext } from "./clientsForProfessionals";

const ClientsForProfessionalsProvider = ({
  children,
}: IClientsForProfessionalsContext) => {
  const [selectedClient, setSelectedClient] = useState<Human | undefined>(
    undefined,
  );

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const {
    data: clients,
    loading,
    refetch: refetchClients,
  } = useFetchClientsForProfessional();

  const toggleModal = () => setModalVisible(!modalVisible);
  const setClient = (id: number) => {
    const foundClient = clients?.find((c) => c.getID() === id);

    setSelectedClient(foundClient);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedClient) {
      navigate(RouteNames.Home.Clients.path + "/" + selectedClient.getID());
    }
  }, [selectedClient]);

  const clearSelectedClient = () => setSelectedClient(undefined);

  if (!clients) return <LoadingPage />;

  return (
    <ClientsForProfessionalsContext.Provider
      value={{
        clearSelectedClient,
        clients: clients,
        loadingClients: loading,
        modalVisible,
        refetchClients,
        selectedClient,
        setClient,
        toggleModal,
      }}
    >
      {children}
    </ClientsForProfessionalsContext.Provider>
  );
};

export default ClientsForProfessionalsProvider;
