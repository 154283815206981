/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import "moment/locale/nl";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationTypes } from "../../../data-model/types/consultation/Consultation";
import TimeSlot from "../../../data-model/types/consultation/TimeSlot";
import Professional from "../../../data-model/types/professional/Professional";
import createFormValidation from "../../../utils/forms/createFormValidation";
import useFormState, { FormStateOptions } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";

moment.locale("nl");

const INITIAL_VALUES: IRequestConsultationFormStateValues = {
  professional: null,
  timeslot: null,
  type: null,
};

export interface IRequestConsultationFormStateValues {
  professional?: ISelectObject<Professional> | null;
  timeslot?: TimeSlot | null;
  type?: ISelectObject<ConsultationTypes> | null;
}

export default function useUpdateConsultationFormState(
  initialValues?: IRequestConsultationFormStateValues,
  options?: FormStateOptions<unknown>,
) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const localInitialValues = useMemo(() => initialValues, []);

  const validation = useMemo(
    () =>
      createFormValidation([
        {
          message: translate(
            "validation:need.to.select.professional",
            "Je moet een professional selecteren.",
          ),
          path: "professional",
          validate: (
            selectedProfessional: ISelectObject<Professional>,
          ): boolean => {
            if (!selectedProfessional) return false;

            const prof = selectedProfessional.value;

            if (Professional.isProfessional(prof)) {
              return prof !== null && prof !== undefined;
            }

            return false;
          },
        },
        {
          message: translate(
            "validation:need.to.select.timeslot",
            "Je moet een tijdsslot selecteren.",
          ),
          path: "timeslot",
          validate: (selectedTimeSlot: TimeSlot): boolean => {
            if (!selectedTimeSlot) return false;

            if (TimeSlot.isTimeSlot(selectedTimeSlot)) {
              return (
                selectedTimeSlot !== null && selectedTimeSlot !== undefined
              );
            }

            return false;
          },
        },
      ]),
    [translate],
  );

  return useFormState<IRequestConsultationFormStateValues>(
    {
      ...INITIAL_VALUES,
      ...localInitialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
