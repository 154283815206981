import { useMutation, useQuery } from "@apollo/client";
import EuroIcon from "@mui/icons-material/Euro";
import {
  Box,
  CircularProgress,
  TextField,
  Stack,
  Typography,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { graphql } from "../../../../api/__generated__";
import ConsultationDurationDataType from "../../../../data-model/types/consultation/ConsultationDuration";
import environment from "../../../../environment/environment";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import RadioQuestion from "../../../ui/form/RadioQuestion";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";

const queryProfessionalRateOnboarding = graphql(`
  query ProfessionalRateOnboarding {
    currentUser {
      id
      professional {
        coachingEnabled
        coachingRate
        id
        standardDuration
        standardRate
        therapyEnabled
      }
    }
  }
`);

const updateRateOnboardingMutation = graphql(`
  mutation UpdateProfessionalRateOnboarding(
    $input: UpdateProfessionalRateInput!
  ) {
    updateProfessionalRate(input: $input) {
      coachingEnabled
      coachingRate
      id
      standardDuration
      standardRate
      therapyEnabled
    }
  }
`);

export default function TargetRateStep() {
  const { formState, next, previous, step, totalSteps } = useContext(
    ProfessionalOnboardingContext,
  );

  const { t } = useTranslation();

  const { data } = useQuery(queryProfessionalRateOnboarding);
  const [updateRate] = useMutation(updateRateOnboardingMutation);

  const error = formState.testForErrors(ConsultationDurationDataType.getPath());

  if (!data?.currentUser?.professional) {
    return (
      <OnboardingContainer>
        <OnboardingStepContainer
          disable={true}
          fullCopyright
          headline={t("professional:onboarding.title")}
          labelBack={t("common:onboarding.button.label.previous")}
          labelNext={t("common:onboarding.button.label.next")}
          onClickBack={previous}
          onClickNext={() => next()}
          showLanguageSelector={false}
          step={step}
          subhead={t("professional:onboarding.targetrate.title")}
          subheadicon={<EuroIcon />}
          totalSteps={totalSteps}
        >
          <CircularProgress />
        </OnboardingStepContainer>
      </OnboardingContainer>
    );
  }

  return (
    <Formik
      initialValues={{
        coachingRate: data.currentUser.professional.coachingRate || 0,
        therapyDuration:
          data.currentUser.professional.standardDuration ||
          environment.REACT_APP_APPOINTMENT_DURATION,
        therapyRate: data.currentUser.professional.standardRate || 0,
      }}
      onSubmit={async (values) => {
        await updateRate({
          onCompleted: () => {},
          variables: {
            input: {
              coachingRate: data.currentUser?.professional?.coachingEnabled
                ? values.coachingRate
                : undefined,
              therapyDuration: data.currentUser?.professional?.therapyEnabled
                ? values.therapyDuration
                : undefined,
              therapyRate: data.currentUser?.professional?.therapyEnabled
                ? values.therapyRate
                : undefined,
            },
          },
        });
      }}
      validationSchema={Yup.object({
        coachingRate: data.currentUser.professional.coachingEnabled
          ? Yup.number()
              .required(t("validation:rate.mandatory"))
              .min(
                environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING,
                t("validation:rate.tooLow", {
                  serviceCost:
                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING,
                }),
              )
          : Yup.number(),
        therapyRate: data.currentUser.professional.therapyEnabled
          ? Yup.number()
              .required(t("validation:rate.mandatory"))
              .min(
                environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                t("validation:rate.tooLow", {
                  serviceCost:
                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                }),
              )
          : Yup.number(),
      })}
    >
      {(props) => (
        <OnboardingContainer>
          {/* no form tag because this has one */}
          <OnboardingStepContainer
            disable={
              error ||
              props.errors.therapyRate ||
              props.errors.coachingRate ||
              props.values.coachingRate === 0 ||
              props.values.therapyRate === 0
            }
            fullCopyright
            headline={t("professional:onboarding.title")}
            labelBack={t("common:onboarding.button.label.previous")}
            labelNext={t("common:onboarding.button.label.next")}
            onClickBack={previous}
            onClickNext={() => {
              props.handleSubmit();
              next();
            }}
            showLanguageSelector={false}
            step={step}
            subhead={t("professional:onboarding.targetrate.title")}
            subheadicon={<EuroIcon />}
            totalSteps={totalSteps}
          >
            <Stack spacing={4}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  component="div"
                  sx={{ fontWeight: "medium", mb: 1.5 }}
                  variant="subtitle1"
                >
                  {t("professional:onboarding.duration")}
                </Typography>
                <RadioQuestion
                  errorText={props.errors.therapyDuration as string}
                  onChange={(value) => {
                    props.setFieldValue("therapyDuration", parseInt(value));
                  }}
                  options={[
                    {
                      label: t("professional:profile.duration", {
                        duration: environment.REACT_APP_APPOINTMENT_DURATION,
                      }),
                      value: environment.REACT_APP_APPOINTMENT_DURATION,
                    },
                    {
                      label: t("professional:profile.duration", {
                        duration:
                          environment.REACT_APP_LONG_APPOINTMENT_DURATION,
                      }),
                      value: environment.REACT_APP_LONG_APPOINTMENT_DURATION,
                    },
                  ]}
                  value={props.values.therapyDuration}
                />
              </Box>
              <Box>
                <Typography
                  component="div"
                  sx={{ fontWeight: "medium", mb: 1.5 }}
                  variant="subtitle1"
                >
                  {t("professional:onboarding.rate.header")}
                </Typography>
                <FormControl
                  fullWidth
                  sx={(theme) => ({
                    background: theme.palette.white.main,
                    ...theme.controls.border.normal,
                    "&:hover": {
                      ...theme.controls.border.hover,
                      borderRadius: 1,
                    },
                    borderRadius: 1,
                    padding: "1rem",
                  })}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "row", xs: "column" },
                      gap: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        component="div"
                        sx={{ fontWeight: "medium", mb: 1.5 }}
                        variant="subtitle1"
                      >
                        {t("professional:onboarding.rate.coaching", {
                          coachingDuration:
                            environment.REACT_APP_COACHING_DURATION,
                        })}
                      </Typography>
                      <TextField
                        error={Boolean(props.errors.coachingRate)}
                        fullWidth
                        helperText={props.errors.coachingRate}
                        onChange={(e) => {
                          props.setFieldValue(
                            "coachingRate",
                            parseFloat(e.target.value),
                          );
                        }}
                        size="medium"
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          },
                        }}
                        type="number"
                        value={props.values.coachingRate}
                      />
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Typography
                        component="div"
                        sx={{ fontWeight: "medium", mb: 1.5 }}
                        variant="subtitle1"
                      >
                        {t("professional:onboarding.rate.therapy", {
                          therapyDuration: props.values.therapyDuration,
                        })}
                      </Typography>
                      <TextField
                        error={Boolean(props.errors.therapyRate)}
                        fullWidth
                        helperText={props.errors.therapyRate}
                        onChange={(e) => {
                          props.setFieldValue(
                            "therapyRate",
                            parseFloat(e.target.value),
                          );
                        }}
                        size="medium"
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          },
                        }}
                        type="number"
                        value={props.values.therapyRate}
                      />
                    </Box>
                  </Box>
                </FormControl>
              </Box>
            </Stack>
          </OnboardingStepContainer>
        </OnboardingContainer>
      )}
    </Formik>
  );
}
