import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const PREFIX = "LoadingPage";

const classes = {
  fullScreen: `${PREFIX}-fullScreen`,
  loader: `${PREFIX}-loader`,
};

const StyledBox = styled(Box)<{ isTransparant: boolean }>(
  ({ isTransparant, theme }) => ({
    [`&.${classes.loader}`]: {
      alignItems: "center",
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      width: "100%",
      zIndex: 10,
    },

    [`&.${classes.fullScreen}`]: {
      background: isTransparant
        ? "rgba(0, 0, 0, 0.2)"
        : theme.palette.whiteSmoke.main,
      bottom: 0,
      left: 0,
      position: "fixed",
      right: 0,
      top: 0,
    },
  }),
);

interface ILoadingPage {
  full?: boolean;
  isTransparant?: boolean;
  logText?: string;
}

const LoadingPage = ({
  full = false,
  isTransparant = false,
  logText,
}: ILoadingPage) => {
  if (logText) console.log(`LOADING: ${logText}`);

  return (
    <StyledBox
      className={`${classes.loader} ${full ? classes.fullScreen : ""}`}
      isTransparant={isTransparant}
    >
      <CircularProgress />
    </StyledBox>
  );
};

export default LoadingPage;
