/* eslint-disable react-hooks/exhaustive-deps */

import DefaultIcon from "@mui/icons-material/InfoOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IWithChildren } from "../../../baseInterfaces";
import { I18Namespaces } from "../../language/I18Namespaces";
import Footer from "../../ui/Footer";
import SanitizedText from "../../ui/text/SanitizedText";
import Copyright from "../../utils/Copyright";
import ProgressBar from "../ProgressBar";
import OnboardingHeader from "./OnboardingHeader";
import StepFooter, { BloomColor } from "./StepFooter";

const StyledForm = styled("form")(({ theme }) => ({
  marginBottom: 40,
  // Fix IE 11 issue.
  marginTop: theme.spacing(1),
  width: "100%",
}));

type Justify = "center" | "left" | "right";

interface OnboardingStepContainerProps extends IWithChildren {
  colorBack?: BloomColor;
  colorNext?: BloomColor;
  colorSkip?: BloomColor;
  copyright?: string;
  description?: string | null;
  disable?: boolean;
  endIconBack?: React.ReactNode;
  endIconNext?: React.ReactNode;
  endIconSkip?: React.ReactNode;
  error?: string;
  fullColoredBack?: boolean;
  fullColoredNext?: boolean;
  fullColoredSkip?: boolean;
  fullContent?: boolean;
  fullCopyright: boolean;
  headline?: string | null;
  headlineFontSize?: string | number;
  headlineLabelColor?: string;
  justify?: Justify;
  labelBack?: string | null;
  labelNext?: string | null;
  labelSkip?: string | null;
  loading?: boolean;
  noFooter?: boolean;
  onClickBack?: (event?: Event) => Promise<void> | void;
  onClickNext?: (event?: Event) => Promise<void> | void;
  onClickSkip?: (event?: Event) => Promise<void> | void;
  onSubmit?: () => void;
  paddingTop?: number;
  paddingTopLarge?: number;
  showInDangerNotification?: boolean;
  showLanguageSelector?: boolean;
  showSpecialConditionsForTherapist?: boolean;
  step?: number;
  subhead?: string | null;
  subheadicon?: React.ReactNode;
  totalSteps?: number;
}

/**
 * Container used in all registration and onboarding wizards.
 * It provides a header, subtitle and footer.
 *
 * @param       {String}    headline
 *              What headline should be shown. (ex: Login, Register)
 * @param       {String}    subhead
 *              What subhead line should be shown.
 * @param       {Material UI Icon}  [subheadicon=InfoOutlined]
 *              The icon to display as the subheading. The default icon is
 * @param       {String}    description
 *              What is the description that should be shown under the subhead?
 * @param       {Number}    step
 *              What step is this in the wizard? Will be used to show a progress bar.
 * @param       {Number}    totalSteps
 *              What is the total steps in this wizard.
 * @param       {Callback Function} onClickBack
 *              What happens when the back or previous button is pressed?
 * @param       {Callback Function} onClickNext
 *              What happens when the next button is pressed?
 * @param       {String}    [labelNext="Volgende"]
 *              What is the label of the next button?
 * @param       {String}    [colorNext="bloom"]
 *              What is the color of the next button?
 *              Can be "bloom", "porple", "orange", "red", "gray"
 * @param       {String}    [colorBack="bloom"]
 *              What is the color of the back link?
 *              Can be "bloom", "porple", "orange", "red", "gray"
 * @param       {String}    [labelBack="Vorige"]
 *              What is the label of the previous or back button?
 * @param       {Boolean}   disable
 *              When or should the next button be disabled?
 * @param       {[type]}    error
 *              Pass the error state.
 * @param       {Boolean}   [full=true]
 *              If false the height of the form will take up the height of the window.
 * @param       {Boolean}   [fullContent=false]
 *              Give access to the full inner content. (No margins and paddings applied)
 * @param       {Boolean}   noFooter
 *              Should the footer be hidden or not?
 * @param       {Boolean}   copyright
 *              Show copyright?
 * @param       {Boolean}   fullCopyright
 *              Should the full copyright be shown with links to T&Cs & privacy policy?
 *              This is mutually exclusive with copyright
 * @param       {Boolean}   [showSpecialConditionsForTherapist=true]
 *              If fullCopyright is selected this option will show T&Cs en privacy for therapists.
 * @param       {Boolean}   [showInDangerNotification=false]
 *              ???
 * @param       {String}    [justify='center']
 *              How should the content by aligned horizontally?
 * @param       {Number}    [paddingTop=4]
 *              Top padding used. Will be multiplied by the theme spacing factor.
 * @param       {Number}    [paddingTopLarge=3}]
 *              Top padding for smaller screens then 1280px (lg)
 * @constructor
 */
const OnboardingStepContainer = ({
  children,
  colorBack = "bloom",
  colorNext = "bloom",
  colorSkip = "bloom",
  copyright,
  description,
  disable,
  endIconBack,
  endIconNext,
  endIconSkip,
  fullColoredBack = false,
  fullColoredNext = false,
  fullColoredSkip = false,
  fullContent = false,
  fullCopyright,
  headline,
  headlineFontSize,
  headlineLabelColor,
  labelBack = "Vorige",
  labelNext = "Volgende",
  labelSkip = "Overslaan",
  loading,
  noFooter,
  onClickBack,
  onClickNext,
  onClickSkip,
  onSubmit,
  paddingTop = 4,
  paddingTopLarge = 3,
  showInDangerNotification = false,
  showLanguageSelector = true,
  showSpecialConditionsForTherapist = true,
  step,
  subhead,
  subheadicon = <DefaultIcon />,
  totalSteps,
}: OnboardingStepContainerProps) => {
  const { t: translate } = useTranslation<I18Namespaces>(["common", "human"]);

  const amountOfSteps = useMemo(() => totalSteps ?? 1, [totalSteps]);
  const currentStep = useMemo(
    () => (step === undefined ? 0 : step + 1),
    [step],
  );

  const [lblBack, lblSkip, lblNext] = useMemo(() => {
    return [
      labelBack ?? translate("common:back", "Vorige"),
      labelSkip ?? translate("common:skip", "Overslaan"),
      labelNext ?? translate("common:next", "Volgende"),
    ];
  }, [labelBack, labelNext, labelSkip]);

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.white.light,
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          marginBottom: theme.spacing(11),
          minHeight: "calc(100vh - 170px)",
          position: "relative",

          [theme.breakpoints.down("xl")]: {
            marginBottom: theme.spacing(3),
          },

          [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(5.5),
          },

          ...(fullContent && {
            marginLeft: 0,
            marginRight: 0,
            paddingRight: 0,
            paddingTop: 0,
          }),
        })}
      >
        <OnboardingHeader small={false}>
          {headline ? (
            <Typography
              sx={(theme) => ({
                color: headlineLabelColor
                  ? headlineLabelColor
                  : theme.palette.bloom.dark,
                fontSize: headlineFontSize ? headlineFontSize : undefined,

                [theme.breakpoints.down("md")]: {
                  fontSize: "2.12em",
                },
              })}
              variant="h1"
            >
              {headline}
            </Typography>
          ) : null}
          {!!(currentStep && currentStep > 0 && amountOfSteps) && (
            <ProgressBar step={currentStep} totalSteps={amountOfSteps} />
          )}
        </OnboardingHeader>
        <Box
          sx={(theme) => ({
            alignItems: "center",
            display: "flex",
            flexFlow: "column nowrap",
            height: "100%",
            justifyContent: "space-between",
            minHeight: 436,
            position: "relative",
            width: "100%",
            zIndex: 1,

            [theme.breakpoints.down("lg")]: {
              height: "calc(100% - 200px)",
            },
          })}
        >
          <Grid
            item
            md={8}
            sm={10}
            sx={(theme) => ({
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "center",
              marginTop: "50px",
              maxHeight: "100%",
              overflow: "visible",
              padding: theme.spacing(paddingTop, 3, 0),
              textAlign: "center",
              width: "100%",

              [theme.breakpoints.down("xl")]: {
                marginBottom: 0,
                padding: theme.spacing(paddingTopLarge, 3, 0),
              },

              [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(paddingTop, 0, 0),
              },

              ...(fullContent && {
                marginLeft: 0,
                marginRight: 0,
                maxWidth: "100% !important",
                textAlign: "left",
              }),
            })}
            xs={12}
          >
            {subhead || description ? (
              <Avatar
                sx={(theme) => ({
                  backgroundColor: theme.palette[colorNext].main,
                  color: theme.palette.white.main,
                  margin: theme.spacing(1),
                })}
              >
                {subheadicon}
              </Avatar>
            ) : null}
            <Typography
              align="center"
              component="h1"
              id="subhead"
              sx={(theme) => ({
                fontSize: 18,
                fontWeight: 600,
                marginBottom: theme.spacing(2),
              })}
              variant="h5"
            >
              {subhead}
            </Typography>
            {description ? (
              <Typography
                sx={{
                  paddingBottom: "20px",
                  textAlign: "center",
                  width: "70%",
                }}
                variant="body1"
              >
                <SanitizedText input={description} />
              </Typography>
            ) : null}
            <StyledForm onSubmit={onSubmit}>
              {/* Prevent implicit submission of the form */}
              <button
                aria-hidden="true"
                disabled
                style={{ display: "none" }}
                type="submit"
              >
                Hidden
              </button>
              {children}
              {!noFooter && (
                <StepFooter
                  colorBack={colorBack}
                  colorNext={colorNext}
                  colorSkip={colorSkip}
                  disable={disable === undefined ? false : disable}
                  endIconBack={endIconBack}
                  endIconNext={endIconNext}
                  endIconSkip={endIconSkip}
                  fullColoredBack={fullColoredBack}
                  fullColoredNext={fullColoredNext}
                  fullColoredSkip={fullColoredSkip}
                  labelBack={lblBack}
                  labelNext={lblNext}
                  labelSkip={lblSkip}
                  loading={loading === undefined ? false : loading}
                  onClickBack={onClickBack}
                  onClickNext={onClickNext}
                  onClickSkip={onClickSkip}
                />
              )}
            </StyledForm>
          </Grid>
          {showInDangerNotification ? (
            <Typography
              sx={(theme) => ({
                "& A": {
                  color: `${theme.palette.bloom.main} !important`,
                },
                padding: "20px 0 70px",
                textAlign: "center",

                width: "70%",
              })}
            >
              <b>
                {translate(
                  "human:onboarding.consent.important.title",
                  "Belangrijk",
                )}
                !
              </b>
              <br />
              <SanitizedText
                input={translate(
                  "human:onboarding.consent.important.description.part_1",
                  'Indien jij of iemand anders zich in een gevaarlijke situatie bevindt of in gevaar is, verwijzen we je door naar <a href="https://www.belgium.be/nl/gezondheid/gezondheidszorg/spoedgevallen" target="_blank" rel="noopener noreferrer">de geschikte platformen</a>.',
                )}
              />{" "}
              {translate(
                "human:onboarding.consent.important.description.part_2",
              )}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "-67px",
          width: "100%",
        }}
      >
        {copyright && !fullCopyright ? (
          <Box pb={1}>
            <Copyright />
          </Box>
        ) : null}
        {fullCopyright ? (
          <Footer
            showLanguageSelector={showLanguageSelector}
            showSpecialConditionsForTherapist={
              showSpecialConditionsForTherapist
            }
            style={{
              width: "100%",
            }}
          />
        ) : null}
      </Box>
    </>
  );
};

export default OnboardingStepContainer;
