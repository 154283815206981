import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ListItemWithIconProps {
  Icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">>;
  iconColor?: string;
  iconSize?: string;
  sx?: SxProps<Theme>;
  text?: string | null;
}

export default function ListItemWithIcon({
  Icon = RadioButtonUncheckedIcon,
  iconColor,
  iconSize,
  sx,
  text,
}: ListItemWithIconProps) {
  const theme = useTheme();

  return (
    <ListItem
      sx={[
        {
          marginLeft: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: "8px",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <ListItemIcon
        sx={{
          marginRight: 0,
          minWidth: 30,
        }}
      >
        <Icon
          sx={{
            color: iconColor ?? theme.palette.bloom.main,
            fontSize: iconSize ?? "small",
          }}
        />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
