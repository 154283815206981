import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import { createBreakpoints, Breakpoints } from "@mui/system";
import MulishVariableFont from "../../ui/fonts/Mulish-VariableFont_wght.woff";
import PoppinsSemiBold from "../../ui/fonts/poppins-semibold-webfont.woff";
import type {} from "@mui/x-date-pickers/themeAugmentation";

declare module "@mui/material/styles" {
  interface Theme {
    controls: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    controls?: any;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    accessibleLink: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    outlinedV2: true;
  }
}

const breakpointValues = {
  values: {
    desktop: 1280,
    laptop: 1024,
    lg: 1440,

    md: 1024,

    phone: 0,

    // Width of larger phones in portrait
    sm: 768,

    tablet: 640,

    ultrawide: 2100,
    widescreen: 1920,
    xl: 1920,
    xs: 440,
    xxl: 2100,
  },
};

const breakpoints: Breakpoints = createBreakpoints({
  ...breakpointValues,
});

export const colors = {
  black: {
    light: "#C3BFBF",
    main: "#2E2E2E",
  },
  bloom: {
    dark: "#56B1EB",
    light: "#ECF8FF",
    main: "#86C5ED",
  },
  gray: {
    dark: "#666",
    light: "#F2F2F2",
    main: "#AAA",
  },
  green: {
    dark: "#609D76",
    light: "#81C784",
    main: "#84C59C",
  },
  opaque: {
    main: "rgba(137, 197, 204, .7)",
  },

  orange: {
    dark: "#F57C00",
    light: "#FFB74D",
    main: "#FEE06C",
  },

  porple: {
    dark: "#303676",
    light: "#B4B8DB",
    main: "#6B72BD",
  },

  red: {
    dark: "#D86A5E",
    light: "#E57373",
    main: "#F0867B",
  },
  white: {
    dark: "#f3e9e1",
    light: "#FFF",
    main: "#FDF9F6",
  },

  whiteSmoke: {
    main: "#F5F5F5",
  },
};

interface IThemeOptions extends ThemeOptions {
  controls: any;
}

export const theme = createTheme({
  breakpoints: breakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'poppins';
          font-style: normal;
          font-weight: normal;
          src: local('Poppins'), local('Poppins-SemiBold'), url(${PoppinsSemiBold}) format('woff2');
        }

        @font-face {
          font-family: 'mulish';
          src: local('mulish'), url(${MulishVariableFont}) format('woff2');
          textRendering: 'optimizeLegibility';
          webkitFontSmoothing: 'antialiased';
          font-weight: 100 1000;
        }
        `,
    },

    MuiDialogContentText: {
      styleOverrides: { root: { fontFamily: "Mulish", fontSize: 16 } },
    },

    MuiDialogTitle: {
      defaultProps: { variant: "h1" },
    },

    MuiLink: {
      styleOverrides: {
        button: {
          fontFamily: "inherit",
          fontSize: "inherit",
        },
      },
      variants: [
        {
          props: { variant: "accessibleLink" },
          style: {
            color: colors.bloom.dark,
            textDecorationColor: colors.bloom.dark,
          },
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `3px solid ${colors.bloom.main}`,
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },

      styleOverrides: {
        filled: {
          backgroundColor: colors.white.main,
        },
        root: {
          ":after": {
            border: 0,
          },
          ":before": {
            border: 0,
          },
          ":hover": {
            borderColor: colors.bloom.dark,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 3,
          },
          borderColor: colors.black.light,

          borderRadius: 5,
          borderStyle: "solid",

          borderWidth: 3,
        },

        select: {
          paddingBottom: 18.5,
          paddingLeft: 15,
          paddingRight: 5,
          paddingTop: 18.5,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: "Mulish",
          fontSize: 16,
        },

        body2: {
          fontSize: 14,
        },

        caption: {
          color: "#c2c2c2",
          display: "block",
          fontSize: 12,
          marginTop: 8,
        },

        h1: {
          fontFamily: "Poppins",
          fontSize: "2.812em",

          [breakpoints.down("sm")]: {
            fontSize: 36,
          },
        },

        h2: {
          fontFamily: "Poppins",
          fontSize: "1.5em",

          [breakpoints.down("xs")]: {
            fontSize: 26,
          },
        },
        h3: {
          fontFamily: "Poppins",
          fontSize: 22,
        },
        h4: {
          fontFamily: "Poppins",
          fontSize: 18,
        },
        h5: {
          fontSize: 16,
        },
        h6: {
          fontSize: 12,
          marginBottom: 8,
          textTransform: "uppercase",
        },
        subtitle1: {
          fontSize: 14,
          fontWeight: "normal",
          lineHeight: "22px",
          marginTop: 8,
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "24px",
          marginBottom: 16,
        },
      },
    },
  },
  controls: {
    border: {
      hover: {
        borderColor: colors.bloom.dark,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 3,
      },

      normal: {
        borderColor: colors.black.light,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 3,
      },
    },

    chip: {
      container: {
        backgroundColor: colors.white.light,
        borderRadius: 14,
        borderStyle: "solid",
        borderWidth: 3,
      },

      label: {
        fontSize: 14,
        fontWeight: 600,
        paddingBottom: 4,
        paddingLeft: 12,
        paddingRight: 8,
        paddingTop: 4,
      },

      remove: {
        "&:hover": {
          backgroundColor: colors.red.dark,
        },
        backgroundColor: colors.gray.main,
        borderRadius: 25,

        color: colors.white.main,
        fontSize: 14,
        marginBottom: 5,
        marginRight: 5,
        marginTop: 5,

        padding: 4,
      },
    },

    fontSize: 16,

    input: {
      color: colors.black.main,
      fontSize: 14,
      padding: "18.5px 15px",
    },

    link: {
      "&:hover": {
        textDecoration: "underline",
      },

      textDecoration: "none",
    },

    placeholder: {
      color: colors.black.main,
      fontSize: 14,
      padding: 15,
    },

    validation: {
      error: {
        color: `${colors.red.main} !important`,
        fontSize: 14,
        textAlign: "left",
      },
    },
  },
  palette: {
    ...colors,

    background: {
      default: colors.white.main,
    },

    controls: {
      label: "rgba(0, 0, 0, 0.87)",
      placeholder: "rgba(0, 0, 0, 0.87)",
    },

    primary: {
      contrastText: colors.black.main,
      main: colors.bloom.main,
    },

    secondary: {
      main: colors.bloom.main,
    },

    tertiary: {
      main: colors.bloom.light,
    },
  },
  shadows: Array(25).fill("none") as Shadows,

  typography: {
    fontFamily: [
      "Mulish",
      "-apple-system",
      "BlinkMacSystemFont",
      "sans-serif",
    ].join(","),

    fontSize: 12,

    sizes: {
      large: 16,
      normal: 14,
      small: 12,
      xl: 18,
      xxl: 20,
    },
  },
} as IThemeOptions);
