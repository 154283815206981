/**
 * The message attached to a consultation.
 */

import type { TFunction } from "i18next";
import Moment from "moment";
import { APIMessage } from "../../../api/messages/messages";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import Consultation from "./Consultation";
import "moment/locale/nl-be";

class ConsultationMessage extends DataType<
  APIMessage.Output,
  DataTypePaths.Consultation.Message
> {
  protected static path = "message";
  protected static defaultValue = "";

  protected type: any = ConsultationMessage;

  protected id = 0;
  protected fromProfessional = false;
  protected consultation?: Consultation;
  protected createdAt: Moment.Moment = Moment();

  constructor(model?: APIMessage.Output) {
    super(model);

    if (!model) return;

    this.id = model.id;
    this.setValue(model);
    this.fromProfessional = model.fromProfessional;
    this.createdAt = Moment(model.createdAt);
  }

  /**
   * Get the id of this message.
   */
  public getID(): number {
    return this.id;
  }

  /**
   * What is the psy id this message is from.
   */
  public getPsyID(): boolean {
    return this.fromProfessional;
  }

  /**
   * Get the message body for this message.
   */
  public getMessage(): APIMessage.Output {
    return this.getValue();
  }

  /**
   * Get the date, time and timezone of creation (and sending) of this message.
   */
  public getCreatedAtDate(): Moment.Moment {
    return this.createdAt;
  }

  /**
   * Get the consultation this message was send for.
   */
  public getConsultation(): Consultation {
    return this.consultation || new Consultation();
  }

  /**
   * Assign a consultation to this consultation message.
   *
   * @param consult A consultation
   */
  public assignConsultation(consult: Consultation): ConsultationMessage {
    this.consultation = consult;

    return this;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:message.mandatory",
          "Geef een boodschap mee.",
        ),
        path: ConsultationMessage.getPath(),
        validate: (message) => {
          return message !== "" && message;
        },
      },
    ];
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, APIMessage.Output>
  > {
    return { message: this.value };
  }
}

export default ConsultationMessage;
