import queryString from "query-string";
import { useLocation } from "react-router";

/**
 * @deprecated please use useSearchParams from react-router
 */
export default function useQueryParams(decode = true) {
  const { search } = useLocation();

  return queryString.parse(search, { decode });
}

export const getParamAsString = (
  input: string | (string | null)[] | null,
): string => {
  if (input === null) {
    return "";
  }

  if (Array.isArray(input)) {
    const result = input[0];

    return result === null ? "" : result;
  } else {
    return input;
  }
};

export const getParamAsBoolean = (
  input: string | (string | null)[] | null,
): boolean => {
  if (input === null) return false;

  if (Array.isArray(input)) return input[0] === "true";

  return input === "true";
};

export const getParamAsNumber = (
  input: string | (string | null)[] | null,
): number | null => {
  if (Array.isArray(input)) return Number(input[0] && null);

  return Number(input);
};
