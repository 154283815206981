import {
  PauseCircleOutline,
  PlayCircleOutline,
  Replay,
} from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useContext } from "react";
import TimerContext from "../timerContext/TimerContext";

const TimerControl = () => {
  const { isTimerRunning, pauseTimer, resetTimer, startTimer, timerValue } =
    useContext(TimerContext);

  return (
    <>
      <Typography component="span" sx={{ paddingRight: "16px" }}>
        {timerValue}
      </Typography>
      <IconButton onClick={isTimerRunning ? pauseTimer : startTimer}>
        {isTimerRunning ? <PauseCircleOutline /> : <PlayCircleOutline />}
      </IconButton>
      <IconButton onClick={resetTimer}>
        <Replay />
      </IconButton>
    </>
  );
};

export default TimerControl;
