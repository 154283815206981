import { BrowserRouter, Navigate, Route, Routes } from "react-router";
import CrispChat from "../utils/crisp-chat/CrispChat";
import MeetingPage from "./meeting/meetingPage";
import ConfirmConsultationPage from "./pages/confirm-consultation/ConfirmConsultationPage";
import Match from "./pages/match/Match";
import RedirectFromOldMatching from "./pages/match/RedirectFromOldMatching";
import OrganizationLandingPage from "./pages/organizations/landing-pages";
import SelfCareLandingPage from "./pages/organizations/landing-pages/SelfCareLandingPage";
import VerifyOrganizationPage from "./pages/organizations/landing-pages/VerifyOrganizationPage";
import VideoLandingPage from "./pages/organizations/landing-pages/VideoLandingPage";
import AcceptInvite from "./pages/professional-clients/AcceptInvite";
import WelcomePage from "./pages/welcome/WelcomePage";
import AuthRoute from "./routes/AuthRoute";
import HumanHomeRoutes from "./routes/HumanHomeRoutes";
import HumanRegistrationRoutes from "./routes/HumanRegistrationRoutes";
import LoginRoutes from "./routes/LoginRoutes";
import PasswordRoutes from "./routes/PasswordRoutes";
import PaymentRoutes from "./routes/PaymentRoutes";
import ProfessionalHomeRoutes from "./routes/ProfessionalHomeRoutes";
import ProfessionalOnboardingRoutes from "./routes/ProfessionalOnboardingRoutes";
import ProfessionalRegistrationRoutes from "./routes/ProfessionalRegistrationRoutes";
import QualityQuestionRoutes from "./routes/QualityQuestionRoutes";
import { RouteNames } from "./routes/routeNames";

function BaseRouting({ children }) {
  return (
    <Routes>
      <Route element={children} path="/*" />
    </Routes>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <BaseRouting>
        <Routes>
          <Route element={<RedirectFromOldMatching />} path={`matching/*`} />
          <Route element={<Navigate to="/employee" />} path="/partner/*" />
        </Routes>
        <>
          <CrispChat />
          <Routes>
            <Route
              element={<VerifyOrganizationPage />}
              path={`employee/verify`}
            />
            <Route element={<OrganizationLandingPage />} path={`/employee`} />
            <Route
              element={<Navigate replace to="/employee" />}
              path={`${RouteNames.NewB2BLandingPages.name}/:slug`}
            />
            <Route
              element={<SelfCareLandingPage />}
              path={`/employee/${RouteNames.NewB2BLandingPages.selfCare.name}`}
            />
            <Route element={<VideoLandingPage />} path={`/employee/video`} />
            <Route
              element={<VideoLandingPage />}
              path={`${RouteNames.NewB2BLandingPages.name}/:slug/video`}
            />
          </Routes>
        </>
        <Routes>
          <Route element={<Match />} path={`Match/*`} />
          <Route
            element={<ProfessionalRegistrationRoutes />}
            path={`${RouteNames.Register.Professional.path}/*`}
          />
          <Route
            element={<HumanRegistrationRoutes />}
            path={`${RouteNames.Register.Human.path}/*`}
          />
          <Route
            element={<PasswordRoutes />}
            path={`${RouteNames.Password.path}/*`}
          />
          <Route
            element={<LoginRoutes />}
            path={`${RouteNames.Login.path}/*`}
          />
          <Route
            element={<ConfirmConsultationPage />}
            path={`${RouteNames.Home.Consultations.ShowConsultation.Confirm.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <ProfessionalHomeRoutes />
                <HumanHomeRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.Home.path}/*`}
          />
          <Route
            element={<AcceptInvite />}
            path={`${RouteNames.Invite.Human.path}/*`}
          />
          <Route
            element={<ProfessionalOnboardingRoutes />}
            path={`${RouteNames.Onboarding.Professional.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <MeetingPage />
              </AuthRoute>
            }
            path={`${RouteNames.Call.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <PaymentRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.Payment.Consultation.Pay.path}/*`}
          />
          <Route
            element={
              <AuthRoute>
                <QualityQuestionRoutes />
              </AuthRoute>
            }
            path={`${RouteNames.QualityQuestions.path}/*`}
          />
          {/** Catch all */}
          <Route element={<WelcomePage />} path="/" />
        </Routes>
      </BaseRouting>
    </BrowserRouter>
  );
}
