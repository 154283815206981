import { useMutation } from "@apollo/client";
import { APIAuthentication } from "../auth";
import { MUTATION_AUTH_RESET_PASSWORD } from "../mutations";

export default function useResetPasswordMutation() {
  const [resetPasswordFunction, { data, error, loading }] =
    useMutation<APIAuthentication.ResetPassword.Input>(
      MUTATION_AUTH_RESET_PASSWORD,
    );

  const resetPassword = (email: string) =>
    resetPasswordFunction({
      variables: { email },
    });

  return {
    data,
    error,
    loading,
    resetPassword,
  };
}
