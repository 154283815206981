import DefaultIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { IWithChildren } from "../../../baseInterfaces";
import { I18Namespaces } from "../../language/I18Namespaces";
import OnboardingHeader from "../../layout/wizard/OnboardingHeader";
import StepFooter, { BloomColor } from "../../layout/wizard/StepFooter";
import Footer from "../../ui/Footer";
import Copyright from "../../utils/Copyright";

const PREFIX = "LoginContainer";

const classes = {
  avatar: `${PREFIX}-avatar`,
  content: `${PREFIX}-content`,
  description: `${PREFIX}-description`,
  form: `${PREFIX}-form`,
  full: `${PREFIX}-full`,
  headline: `${PREFIX}-headline`,
  inDanger: `${PREFIX}-inDanger`,
  scroller: `${PREFIX}-scroller`,
  step: `${PREFIX}-step`,
  subheading: `${PREFIX}-subheading`,
  submit: `${PREFIX}-submit`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")<{
  colorNext: BloomColor;
  pt: number;
  ptlg: number;
}>(({ colorNext, pt, ptlg, theme }) => ({
  [`& .${classes.step}`]: {
    backgroundColor: theme.palette.white.light,
    borderRadius: 30,
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 170px)",
    marginBottom: theme.spacing(2),
    minHeight: 566,

    [theme.breakpoints.down("xl")]: {
      height: "calc(100vh - 100px)",
    },

    [theme.breakpoints.down("lg")]: {
      height: "calc(100vh - 120px)",
    },
  },

  [`& .${classes.full}`]: {
    height: "auto",
    marginBottom: theme.spacing(11),
    minHeight: "calc(100vh - 170px)",
    position: "relative",

    [theme.breakpoints.down("xl")]: {
      marginBottom: theme.spacing(3),
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(5.5),
    },
  },

  [`& .${classes.headline}`]: {
    color: theme.palette.bloom.dark,
    fontSize: "3.4em",

    [theme.breakpoints.down("md")]: {
      fontSize: "2.12em",
    },
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette[colorNext].main,
    color: theme.palette.white.main,
    margin: theme.spacing(1),
  },

  [`& .${classes.subheading}`]: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.description}`]: {
    paddingBottom: 20,
    width: "70%",
  },

  [`& .${classes.inDanger}`]: {
    padding: "20px 0 70px",
    textAlign: "center",
    width: "70%",
  },

  [`& .${classes.content}`]: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    justifyContent: "space-between",
    minHeight: 436,
    position: "relative",
    width: "100%",
    zIndex: 1,

    [theme.breakpoints.down("lg")]: {
      height: "calc(100% - 200px)",
    },
  },

  [`& .${classes.scroller}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    marginTop: 50,
    maxHeight: "100%",
    maxWidth: 450,
    overflow: "visible",
    padding: theme.spacing(pt, 3, 0),
    textAlign: "center",
    width: "100%",

    [theme.breakpoints.down("xl")]: {
      marginBottom: 0,
      padding: theme.spacing(ptlg, 3, 0),
    },
  },

  [`& .${classes.form}`]: {
    marginBottom: 40,
    // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    width: "100%",
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface LoginContainerProps extends IWithChildren {
  colorBack?: BloomColor;
  colorNext?: BloomColor;
  copyright?: string;
  description?: string;
  disable?: boolean;
  error?: string;
  full?: boolean;
  fullCopyright: boolean;
  headline?: string | null;
  labelBack?: string | null;
  labelNext?: string | null;
  loading?: boolean;
  noFooter?: boolean;
  onClickBack?: (event?: Event) => Promise<void> | void;
  onClickNext?: (event?: Event) => Promise<void> | void;
  onSubmit?: () => void;
  pt?: number;
  ptlg?: number;
  showInDangerNotification?: boolean;
  showSpecialConditionsForTherapist?: boolean;
  step?: number;
  subhead?: string;
  subheadicon?: React.ReactNode;
  totalSteps?: number;
}

export default function LoginContainer({
  children,
  colorBack = "bloom",
  colorNext = "bloom",
  full = true,
  fullCopyright,
  headline,
  labelBack = "Vorige",
  labelNext = "Volgende",
  loading,
  noFooter,
  onClickBack,
  onClickNext,
  onSubmit,
  pt = 4,
  ptlg = 3,
  showInDangerNotification = false,
  subhead,
  subheadicon = <DefaultIcon />,
}: LoginContainerProps) {
  const { t: translate } = useTranslation<I18Namespaces>(["human"]);

  return (
    <Root {...{ colorNext, pt, ptlg }}>
      <Box className={full ? `${classes.step} ${classes.full}` : classes.step}>
        <OnboardingHeader small={false}>
          {headline ? (
            <Typography className={classes.headline} variant="h1">
              {headline}
            </Typography>
          ) : null}
        </OnboardingHeader>
        <Box className={classes.content}>
          <Grid className={classes.scroller} item md={8} sm={10} xs={12}>
            {subhead ? (
              <Avatar className={classes.avatar}>{subheadicon}</Avatar>
            ) : null}
            <Typography
              align="center"
              className={classes.subheading}
              component="h1"
              variant="h5"
            >
              {subhead}
            </Typography>
            <form className={classes.form} onSubmit={onSubmit}>
              {/* Prevent implicit submission of the form */}
              <button
                aria-hidden="true"
                disabled
                style={{ display: "none" }}
                type="submit"
              >
                Hidden
              </button>
              {children}
              {!noFooter && (
                <StepFooter
                  colorBack={colorBack}
                  colorNext={colorNext}
                  disable={false}
                  labelBack={labelBack}
                  labelNext={labelNext}
                  loading={loading}
                  onClickBack={onClickBack}
                  onClickNext={onClickNext}
                />
              )}
            </form>
          </Grid>
          {showInDangerNotification ? (
            <Typography className={classes.inDanger}>
              <b>
                {translate(
                  "human:onboarding.consent.important.title",
                  "Belangrijk!",
                )}
              </b>
              <br />
              <Trans i18nKey="human:onboarding.consent.important.description">
                Indien jij of iemand anders zich in een gevaarlijke situatie
                bevindt of in gevaar is, verwijzen we je door naar{" "}
                <Link
                  //alt="link naar geschikte platformen"
                  href="https://www.vlaanderen.be/hulp-zoeken-bij-psychische-problemen"
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="hover"
                >
                  de geschikte platformen
                </Link>
                . Indien je jonger bent dan 18 jaar, vind je hier ook gepaste
                alternatieven.
              </Trans>
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box
        style={{
          marginTop: -67,
          width: "100%",
        }}
      >
        {!fullCopyright && (
          <Box pb={1}>
            <Copyright />
          </Box>
        )}
        {fullCopyright ? (
          <Footer
            style={{
              width: "100%",
            }}
          />
        ) : null}
      </Box>
    </Root>
  );
}
