/* eslint-disable react-hooks/exhaustive-deps */

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useProfessionalExiststLazyQuery from "../../../api/professionals/hooks/useProfessionalExiststLazyQuery";
import Email from "../../../data-model/types/profile/Email";
import LoadingPage from "../../layout/LoadingPage";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import ToastContext from "../../providers/toast/ToastContext";
import { IToastContext } from "../../providers/toast/toast";
import RegisterForm from "./RegisterForm";
import ProfessionalRegistrationContext from "./context/ProfessionalRegistrationContext";
import { IProfessionalRegistrationContext } from "./context/professionalRegistration";

export default function RegisterProfessionalPage() {
  const { t } = useTranslation();

  const [emailSuppliedByUser, setEmailSuppliedByUser] = useState<
    string | undefined
  >(undefined);

  const { acceptedConsent, formState, next, setAcceptedConsent } =
    useContext<IProfessionalRegistrationContext>(
      ProfessionalRegistrationContext,
    );

  const { setToast, toast } = useContext<IToastContext>(ToastContext);

  const {
    execute: checkIfEmailExists,
    isFetching: validatingEmail,
    response,
  } = useProfessionalExiststLazyQuery();

  useEffect(() => {
    if (!validatingEmail && !toast) {
      if (response?.professionalExists === false) {
        next();
      } else if (response?.professionalExists) {
        setToast({
          closeAfter: 2000,
          message: "Dit e-mail adres is al in gebruik!",
          severity: "warning",
        });
      }
    }
  }, [validatingEmail, response, next]);

  const checkEmail = () => {
    if (formState.validate()) {
      const email = formState.getInputProps(Email.getPath()).value;

      if (email !== emailSuppliedByUser) {
        if (email) setEmailSuppliedByUser(email);

        checkIfEmailExists(email);
      }
    }
  };

  const disable = formState.testForErrors() || !acceptedConsent;

  return (
    <>
      {!formState || validatingEmail ? <LoadingPage full /> : null}
      <OnboardingContainer>
        <OnboardingStepContainer
          disable={disable}
          fullCopyright
          headline={t("professional:register.title")}
          labelNext={t("common:confirmandcontinue")}
          onClickNext={() => checkEmail()}
          subhead={t("professional:register.personaldetails.subtitle")}
          subheadicon={<PersonOutlinedIcon />}
        >
          <RegisterForm
            checked={acceptedConsent}
            formState={formState}
            setChecked={setAcceptedConsent}
          />
        </OnboardingStepContainer>
      </OnboardingContainer>
    </>
  );
}
