import DevicesOutlined from "@mui/icons-material/DevicesOutlined";
import ExitToAppOutlined from "@mui/icons-material/ExitToAppOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import MicNoneOutlined from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlined from "@mui/icons-material/MicOffOutlined";
import MoreHorizOutlined from "@mui/icons-material/MoreHorizOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import VideocamOffOutlined from "@mui/icons-material/VideocamOffOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import { Box, Button, styled } from "@mui/material";
import {
  useLocalVideo,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import { motion } from "motion/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import AuthContext from "../providers/auth/AuthContext";
import AudioVideoSettings from "./AudioVideoSettings";
import BookingSidePanel from "./sidePanel/BookingSidePanel";
import ClientInfoSidePanel from "./sidePanel/ClientInfoSidePanel";
import MenuSidePanel from "./sidePanel/MenuSidePanel";
import SidePanel from "./sidePanel/SidePanel";
import SidePanelContainer from "./sidePanel/SidePanelContainer";

const RoundButton = styled(Button)<{
  bloomColor: string;
}>(({ bloomColor, theme }) => ({
  "&:hover": {
    background: theme.palette[bloomColor].main,
    opacity: 1,
  },
  background: theme.palette[bloomColor].dark,
  borderRadius: "50%",
  color: theme.palette.black.main,
  height: 40,
  minWidth: 40, // Needs to be overwritten because of the default of MUI
  opacity: 0.6,
  width: 40,
  [theme.breakpoints.up(320)]: {
    height: 60,
    width: 60,
  },
}));

export type Sidepanels =
  | "booking"
  | "clientInfo"
  | "settings"
  | "menu"
  | "none";

const ConsultationFragment = graphql(`
  fragment ButtonBarConsultation on Consultation {
    ...BookingSidePanelConsultation
    ...ClientInfoSidePanelConsultation
    id
    professional {
      id
      user {
        id
      }
    }
  }
`);

export default function ButtonBar({
  amountOfAttendees,
  consultation,
  exitCall,
}: {
  amountOfAttendees: number;
  consultation: FragmentType<typeof ConsultationFragment>;
  exitCall: () => void;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const [sidePanelToShow, setSidePanelToShow] = useState<Sidepanels>("none");
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const consultationData = getFragmentData(ConsultationFragment, consultation);
  const isProfessional =
    currentUser?.getUUID() === consultationData.professional?.user.id;

  return (
    <>
      <Box
        animate={{ bottom: isOpen ? 60 : -60 }}
        component={motion.div}
        initial={{ bottom: 60 }}
        sx={{
          display: "flex",
          gap: "15px",
          justifyContent: "space-between",
          position: "absolute",
          width: "100%",
          zIndex: 6,
        }}
        transition={{ duration: 0.3 }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 8,
            gap: "15px",
            justifyContent: "center",
          }}
        >
          {isProfessional ? (
            <RoundButton
              bloomColor={sidePanelToShow === "clientInfo" ? "green" : "bloom"}
              onClick={() => {
                setSidePanelToShow(
                  sidePanelToShow === "clientInfo" ? "none" : "clientInfo",
                );
              }}
            >
              <PersonOutline />
            </RoundButton>
          ) : null}
          <RoundButton
            bloomColor={isVideoEnabled ? "green" : "bloom"}
            onClick={toggleVideo}
          >
            {isVideoEnabled ? <VideocamOutlined /> : <VideocamOffOutlined />}
          </RoundButton>
          <RoundButton
            bloomColor={muted ? "bloom" : "green"}
            onClick={toggleMute}
          >
            {muted ? <MicOffOutlined /> : <MicNoneOutlined />}
          </RoundButton>
          <RoundButton
            bloomColor={
              ["none", "clientInfo"].includes(sidePanelToShow)
                ? "bloom"
                : "green"
            }
            onClick={() => {
              setSidePanelToShow(sidePanelToShow === "none" ? "menu" : "none");
            }}
          >
            <MoreHorizOutlined />
          </RoundButton>
          <RoundButton bloomColor="red" onClick={exitCall}>
            <ExitToAppOutlined />
          </RoundButton>
        </Box>
      </Box>
      <Box
        sx={{
          bottom: 0,
          color: "white",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          width: "100%",
          zIndex: 6,
        }}
      >
        <Box
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isOpen ? (
            <ExpandMoreOutlined sx={{ fontSize: "35px" }} />
          ) : (
            <ExpandLess sx={{ fontSize: "35px" }} />
          )}
        </Box>
      </Box>
      <SidePanelContainer
        amountOfAttendees={amountOfAttendees}
        isOpen={sidePanelToShow !== "none"}
        isProfessional={isProfessional}
      >
        {sidePanelToShow === "clientInfo" ? (
          <ClientInfoSidePanel
            consultation={consultationData}
            returnToPreviousPane={() => setSidePanelToShow("menu")}
          />
        ) : null}
        {sidePanelToShow === "menu" ? (
          <MenuSidePanel
            isProfessional={isProfessional}
            setSidePanelToShow={setSidePanelToShow}
          />
        ) : null}
        {sidePanelToShow === "settings" ? (
          <SidePanel
            TitleIcon={DevicesOutlined}
            returnToPreviousPane={() => setSidePanelToShow("menu")}
            title={t("ui:sidepane.menu.videoaudio.title")}
          >
            <AudioVideoSettings />
          </SidePanel>
        ) : null}
        {sidePanelToShow === "booking" ? (
          <BookingSidePanel
            consultation={consultationData}
            returnToPreviousPane={() => setSidePanelToShow("menu")}
          />
        ) : null}
      </SidePanelContainer>
    </>
  );
}
