import { useQuery } from "@apollo/client";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router";
import { graphql } from "../../api/__generated__";
import { BloomUpNamespacesEnum } from "../language/I18Namespaces";
import LeftSection from "../layout/LeftSection";
import TitleWithButton from "../ui/text/TitleWithButton";
import ClientConsultationDetail from "./ClientConsultationDetail";
import ProfessionalConsultstionActions from "./ProfessionalConsultationActions";
import ProfessionalConsultationList from "./ProfessionalConsultationList";

const query = graphql(`
  query ProfessionalCompletedConsultations($after: String) {
    currentUser {
      id
      professional {
        id
      }
    }
    myProfessionalConsultations(
      after: $after
      filter: {
        status: [
          DONE
          CANCELLED
          CANCELLED_BY_HUMAN
          CANCELLED_BY_PROFESSIONAL
          NO_SHOW_HUMAN
          NO_SHOW_PROFESSIONAL
        ]
      }
      first: 20
    ) {
      edges {
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      ...ProfessionalConsultationList
    }
  }
`);

export default function ProfessionalCompletedConsultations() {
  const { data, fetchMore } = useQuery(query);
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const { t } = useTranslation([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
  ]);

  const { uuid } = useParams();

  if (!data) return null;

  const clientList = (
    <InfiniteScroll
      dataLength={data.myProfessionalConsultations.edges.length}
      hasChildren
      hasMore={data.myProfessionalConsultations.pageInfo?.hasNextPage ?? false}
      loader={
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      }
      next={() =>
        fetchMore({
          variables: {
            after: data.myProfessionalConsultations.pageInfo?.endCursor,
          },
        })
      }
      scrollableTarget="leftSection"
    >
      <ProfessionalConsultationList
        consultations={data.myProfessionalConsultations}
        onOpenConsultation={(uuid) => {
          navigate("/home/completed-consultations/" + uuid);
        }}
      />
    </InfiniteScroll>
  );

  const rightSectionContent = uuid && (
    <Grid
      item
      md={6}
      sm={12}
      sx={({ breakpoints, spacing }) => ({
        height: "100%",
        overflowY: "auto",
        padding: spacing(4, 0, 4, 2),

        [breakpoints.down("md")]: {
          height: "auto",
          padding: spacing(4, 1),
        },
      })}
      xs={12}
    >
      <Box
        sx={({ breakpoints, spacing }) => ({
          display: "flex",
          flexFlow: "column nowrap",
          height: "100%",
          padding: spacing(0, 1),
          position: "relative",

          [breakpoints.down("md")]: {
            height: "auto",
          },
        })}
      >
        <TitleWithButton
          buttonIcon={<CloseOutlinedIcon />}
          buttonLabel={t("common:close")}
          handleButton={() => {
            navigate("/home/completed-consultations");
          }}
          title={t("common:consultation")}
        />
        <Box>
          <ClientConsultationDetail consultationUuid={uuid} showMore={true} />

          <ProfessionalConsultstionActions consultationUuid={uuid} />
        </Box>
      </Box>
    </Grid>
  );

  if (small) {
    return (
      <>
        {rightSectionContent ? (
          rightSectionContent
        ) : (
          <LeftSection
            pageTitle={t("professional:completedConsultations.title")}
            short={false}
          >
            {clientList}
          </LeftSection>
        )}
      </>
    );
  } else {
    return (
      <>
        <LeftSection
          pageTitle={t("professional:completedConsultations.title")}
          short={false}
        >
          {clientList}
        </LeftSection>
        {rightSectionContent}
      </>
    );
  }
}
