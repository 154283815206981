import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../../language/I18Namespaces";
import Button from "../buttons/Button";
import FormLabel from "./FormLabel";

interface IYesNoQuestion {
  formLabel: string;
  hasError: boolean;
  name: string;
  noBtnTestId?: string;
  onChange: (event: any) => void;
  style: any;
  value: any;
  yesBtnTestId?: string;
}

const YesNoQuestion = ({
  formLabel,
  hasError,
  name,
  noBtnTestId,
  onChange,
  style = {},
  value,
  yesBtnTestId,
  ...other
}: IYesNoQuestion) => {
  const onChangeCustom = (event) => {
    event.stopPropagation();
    onChange(event.currentTarget.value);
  };

  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  return (
    <Box style={style} textAlign="left">
      <FormLabel
        error={hasError}
        label={formLabel}
        style={{
          textAlign: "left",
        }}
      />
      <ButtonGroup color="primary" {...other}>
        <Button
          buttonSelected={value === "1" ? "yes" : "no"}
          id={yesBtnTestId}
          label={translate("common:yes", "Ja")}
          name={name}
          onClick={onChangeCustom}
          style={{
            marginRight: "8px",
          }}
          value="1"
        />
        <Button
          buttonSelected={value === "0" ? "yes" : "no"}
          id={noBtnTestId}
          label={translate("common:no", "Nee")}
          name={name}
          onClick={onChangeCustom}
          value="0"
        />
      </ButtonGroup>
    </Box>
  );
};

export default YesNoQuestion;
