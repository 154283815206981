export const shared = {
  REACT_APP_APPOINTMENT_DURATION: 50,
  REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING: 9,
  REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT: 12,
  REACT_APP_COACHING_DURATION: 30,
  REACT_APP_GRAPHQL: "/api",
  REACT_APP_I18N_APP_KEY: "mSXug9LBWQwh0wqBy8ufWQ",
  REACT_APP_I18N_VERSION: 322,
  REACT_APP_INTRO_DURATION: 10,
  REACT_APP_LANDING_PAGE_SALT: "PLVAs!er2kZhyMNnWA",
  REACT_APP_LONG_APPOINTMENT_DURATION: 60,
  REACT_APP_SENTRY_DSN:
    "https://a299d1c7833043cc8b8014f67ec5c7ee@sentry.bloomup.org/7",
  REACT_APP_SENTRY_SAMPLE_RATE: 1,
  YOURMINDLAB_URL: "https://yourmindlab.be",
};

export default shared;
