import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import environment from "../../../../environment/environment";
import InlineEditableControls from "../../../ui/edit/common/InlineEditableControls";
import FormLabel from "../../../ui/form/FormLabel";
import RadioQuestion from "../../../ui/form/RadioQuestion";
import TextField from "../../../ui/form/TextField";

const rateSelectFragment = graphql(`
  fragment RateSelect on Professional {
    coachingEnabled
    coachingRate
    standardDuration
    standardRate
    therapyEnabled
  }
`);

const updateRateMutation = graphql(`
  mutation UpdateProfessionalRate($input: UpdateProfessionalRateInput!) {
    updateProfessionalRate(input: $input) {
      id
      ...RateSelect
    }
  }
`);

interface NumberSelectProps {
  label: string;
  professional: FragmentType<typeof rateSelectFragment>;
}

const RateSelect = ({ label, professional }: NumberSelectProps) => {
  const { t } = useTranslation();
  const initialData = getFragmentData(rateSelectFragment, professional);
  const [updateProfessionalRate] = useMutation(updateRateMutation);

  return (
    <Formik
      initialValues={{
        coachingRate: initialData.coachingRate,
        therapyDuration: initialData.standardDuration,
        therapyRate: initialData.standardRate,
      }}
      onSubmit={async (values) => {
        await updateProfessionalRate({
          onCompleted: () => {},
          variables: {
            input: {
              coachingRate: initialData.coachingEnabled
                ? values.coachingRate
                : undefined,
              therapyDuration: initialData.therapyEnabled
                ? values.therapyDuration
                : undefined,
              therapyRate: initialData.therapyEnabled
                ? values.therapyRate
                : undefined,
            },
          },
        });
      }}
      validationSchema={Yup.object({
        coachingRate: initialData.coachingEnabled
          ? Yup.number()
              .required(t("validation:rate.mandatory"))
              .min(
                environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING,
                t("validation:rate.tooLow", {
                  serviceCost:
                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING,
                }),
              )
          : Yup.number(),
        therapyRate: initialData.therapyEnabled
          ? Yup.number()
              .required(t("validation:rate.mandatory"))
              .min(
                environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                t("validation:rate.tooLow", {
                  serviceCost:
                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING,
                }),
              )
          : Yup.number(),
      }).required(t("rate.mandatory"))}
    >
      {(props) => {
        return (
          <InlineEditableControls
            edit={
              <form onSubmit={props.handleSubmit}>
                <Box>
                  {initialData.therapyEnabled ? (
                    <>
                      <Box sx={{ marginBottom: 4 }}>
                        <FormLabel
                          label={t("professional:onboarding.duration")}
                        />
                        <RadioQuestion
                          errorText={props.errors.therapyDuration as string}
                          onChange={(value) => {
                            props.setFieldValue(
                              "therapyDuration",
                              parseInt(value),
                            );
                          }}
                          options={[
                            {
                              label: t("professional:profile.duration", {
                                duration:
                                  environment.REACT_APP_APPOINTMENT_DURATION,
                              }),
                              value: environment.REACT_APP_APPOINTMENT_DURATION,
                            },
                            {
                              label: t("professional:profile.duration", {
                                duration:
                                  environment.REACT_APP_LONG_APPOINTMENT_DURATION,
                              }),
                              value:
                                environment.REACT_APP_LONG_APPOINTMENT_DURATION,
                            },
                          ]}
                          value={props.values.therapyDuration}
                        />
                      </Box>

                      <Box sx={{ marginBottom: 4 }}>
                        <FormLabel
                          label={t(
                            "professional:onboarding.therapyRate.description",
                            { therapyDuration: props.values.therapyDuration },
                          )}
                        />
                        <TextField
                          errorText={props.errors.therapyRate}
                          onChange={(value) => {
                            props.setFieldValue(
                              "therapyRate",
                              parseFloat(value),
                            );
                          }}
                          type="number"
                          value={props.values.therapyRate}
                        />
                      </Box>
                    </>
                  ) : null}

                  {initialData.coachingEnabled ? (
                    <Box sx={{ marginBottom: 4 }}>
                      <FormLabel
                        label={t(
                          "professional:onboarding.coachingRate.description",
                          {
                            coachingDuration:
                              environment.REACT_APP_COACHING_DURATION,
                          },
                        )}
                      />
                      <TextField
                        onChange={(value) => {
                          props.setFieldValue(
                            "coachingRate",
                            parseFloat(value),
                          );
                          console.log({ value });
                        }}
                        type="number"
                        value={props.values.coachingRate}
                      />
                    </Box>
                  ) : null}
                </Box>
              </form>
            }
            editable={true}
            label={label}
            onDiscard={() => {
              props.resetForm();

              return true;
            }}
            onSave={async () => {
              await props.submitForm();

              return true;
            }}
            read={t("professional:profile.rateline", {
              coachingDuration: environment.REACT_APP_COACHING_DURATION,
              coachingRate: props.values.coachingRate,
              context:
                initialData.coachingEnabled && initialData.therapyEnabled
                  ? null
                  : initialData.therapyEnabled
                    ? "therapyOnly"
                    : initialData.coachingEnabled
                      ? "coachingOnly"
                      : null,
              therapyDuration: props.values.therapyDuration,
              therapyRate: props.values.therapyRate,
            })}
          />
        );
      }}
    </Formik>
  );
};

export default RateSelect;
