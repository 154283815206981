import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../../language/I18Namespaces";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";

export default function RegisterProfessionalThankyouPage() {
  const { t: translate } = useTranslation<I18Namespaces>([
    "professional",
    "common",
  ]);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate(
          "professional:professional.registration.verification",
          "We verifiëren momenteel of je voldoet aan de registratiecriteria. Je ontvangt hiervoor binnenkort een uitnodiging voor het verificatiegesprek. Hou er rekening mee dat dit even kan duren.",
        )}
        fullCopyright
        headline={translate(
          "professional:register.register.thankyou",
          "Bedankt",
        )}
        subhead={translate(
          "professional:register.register.success",
          "Je bent succesvol geregistreerd!",
        )}
        subheadicon={<CheckCircleIcon />}
      ></OnboardingStepContainer>
    </OnboardingContainer>
  );
}
