/**
 * Address of user, psy or client.
 */
import { APIAddress } from "../../../api/addresses/addresses";
import { DataTypePaths } from "../../dataTypePaths";
import Address from "../profile/Address";

export default class BillingAddress extends Address<DataTypePaths.Billing.BillingAddress> {
  protected static path: DataTypePaths.Billing.BillingAddress =
    "billingAddress";

  protected static defaultValue = {};

  protected type: any = BillingAddress;

  constructor(model?: Partial<APIAddress.Result>) {
    super(model);
  }

  getAsFormStateValue(): Record<
    DataTypePaths.Billing.BillingAddress,
    Partial<APIAddress.Result>
  > {
    return { billingAddress: this.value };
  }
}
