import AttachFileIcon from "@mui/icons-material/AttachFile";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";

const PREFIX = "UploadFileBox";

const classes = {
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  formHelper: `${PREFIX}-formHelper`,
  main: `${PREFIX}-main`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.main}`]: {
    width: "100%",
  },

  [`& .${classes.buttonWrapper}`]: {
    "& .button": {
      color: `${theme.palette.red.main} !important`,
      paddingTop: 10,
      textDecoration: "underline",
    },
    marginBottom: theme.spacing(3),

    marginTop: -theme.spacing(1),
  },

  [`& .${classes.formHelper}`]: {
    ...theme.controls?.validation?.error,
  },
}));

interface IUploadFileBox {
  formState: any;
}

interface FileWithPreview extends File {
  preview: string;
}

export default function UploadFileBox({ formState }: IUploadFileBox) {
  const { licenseFiles } = formState.values;
  const { t: translate } = useTranslation<I18Namespaces>("ui");

  const onDrop = (acceptedFiles: Array<File>) => {
    const acceptedFilesWithPreview = acceptedFiles.map((file) => {
      Object.defineProperty(file, "preview", {
        value: URL.createObjectURL(file),
        writable: false,
      });

      return file;
    });

    formState.setValue(
      "licenseFiles",
      licenseFiles.concat(acceptedFilesWithPreview),
    );
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    maxSize: 5242880,
    multiple: true,
    onDrop,
  });

  const clearPreviews = (files: Array<FileWithPreview>) => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    formState.setValue("licenseFiles", []);
  };

  return (
    <StyledBox className={classes.main}>
      <Box
        {...getRootProps()}
        px={2}
        py={4}
        style={{
          border: "2px dotted #69A1AC",
          borderRadius: 8,
        }}
      >
        <input id="dropzone" {...getInputProps()} />
        <Typography align="center" variant="body2">
          {translate(
            "ui:uploadfilebox.label",
            "Klik op dit vak of sleep er een document op.",
          )}
        </Typography>
      </Box>
      {formState.hasError("licenseFiles") && (
        <FormHelperText className={classes.formHelper}>
          {translate(
            "validation:uploadfilebox.mandatory",
            "Voeg de vereiste documenten toe.",
          )}
        </FormHelperText>
      )}
      {licenseFiles?.length !== 0 && (
        <Box className={classes.buttonWrapper}>
          <Button
            label={translate("ui:uploadfilebox.remove", "Verwijder selectie.")}
            onClick={() => {
              clearPreviews(licenseFiles);
            }}
            variant="text"
          />
        </Box>
      )}
      {licenseFiles?.map((file) => {
        const isPdf = file.path.split(".").pop().toLowerCase() === "pdf";

        return (
          <Box
            key={file.name}
            my={1}
            p={2}
            style={{
              border: "2px solid #69A1AC",
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              justifyContent: isPdf ? "flex-start" : "center",
            }}
          >
            {isPdf ? (
              <>
                <AttachFileIcon />
                <Typography>{file.path}</Typography>
              </>
            ) : (
              <img
                alt=""
                src={file.preview}
                style={{ display: "block", maxWidth: 200 }}
              />
            )}
          </Box>
        );
      })}
    </StyledBox>
  );
}
