import { Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { WaveBox } from "wave-box";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../../components/language/I18Namespaces";

const PREFIX = "Privacy";

const classes = {
  content: `${PREFIX}-content`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  mainText: `${PREFIX}-mainText`,
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    padding: "64px 96px",
    [theme.breakpoints.down(900)]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "0 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 2rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "0 1rem",
    },
  },

  [`& .${classes.content}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1100px",
    textAlign: "center",
    width: "100%",
  },

  [`& .${classes.iconWrapper}`]: {
    alignContent: "center",
    backgroundColor: theme.palette.white.main,
    borderRadius: "50%",
    display: "flex",
    height: "100px",
    justifyContent: "center",
    width: "100px",
  },

  [`& .${classes.title}`]: { marginTop: "2rem" },
  [`& .${classes.text}`]: { fontSize: "18px", margin: "2rem 0" },
  [`& .${classes.mainText}`]: {},
}));

interface PrivacyProps {
  organizationName?: string | null;
}

const Privacy = ({ organizationName }: PrivacyProps) => {
  const smallScreen = useMediaQuery("(max-width: 500px)");

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const backgroundColor = "#E5DCD4";

  return (
    <WaveBox background={backgroundColor} fillBackground waveHeight="100px">
      <StyledBox className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.iconWrapper}>
            <img
              alt="Shield Icon"
              src={
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/shield.svg"
              }
            />
          </Box>
          <Typography
            className={classes.title}
            variant={smallScreen ? "h2" : "h1"}
          >
            {t(
              "organization:landing.privacy.title",
              "Alles start bij ons met je privacy",
            )}
          </Typography>
          <Typography className={classes.text}>
            {t("organization:landing.privacy.text", {
              context:
                (organizationName === "" || organizationName) && "withCompany",
              organizationName,
            })}
          </Typography>
          {/* <Button
            bloomColor="black"
            onClick={() => {}}
            label={t(
              "organization:landing.privacy.buttonLabel",
              "Wat betekent dit concreet?"
            )}
            fillColor={backgroundColor}
            size={smallScreen ? "small" : "large"}
          ></Button> */}
        </Box>
      </StyledBox>
    </WaveBox>
  );
};

export default Privacy;
