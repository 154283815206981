import { Navigate, Route, Routes } from "react-router";
import CrispChat from "../../utils/crisp-chat/CrispChat";
import { defaultConsultationsValues } from "../consultations/context/ConsultationsOverviewContext";
import ConsultationsOverviewContextProvider from "../consultations/context/ConsultationsOverviewProvider";
import SidebarWithState from "../layout/sidebar/SidebarWithState";
import HumanHomePage from "../pages/human-home/HumanHomePage";
import ProfessionalsPage from "../pages/human-professionals/ProfessionalsPage";
import HumanProfile from "../pages/human-profile/HumanProfile";
import HumanProfileContextProvider from "../pages/human-profile/context/HumanProfileContextProvider";
import { defaultRequestConsultationValues } from "../pages/human-request-consultation/context/RequestConsultationContext";
import RequestConsultationStateProvider from "../pages/human-request-consultation/context/RequestConsultationStateProvider";
import OrganizationUsagePage from "../pages/organization-usage/OrganizationUsagePage";
import HumanGuard from "./guards/HumanGuard";
import HumanRegistrationGuard from "./guards/HumanRegistrationGuard";
import { RouteNames } from "./routeNames";

export default function HumanHomeRoutes() {
  return (
    <HumanGuard>
      <HumanRegistrationGuard>
        <ConsultationsOverviewContextProvider {...defaultConsultationsValues}>
          <RequestConsultationStateProvider
            {...defaultRequestConsultationValues}
          >
            <CrispChat />
            <SidebarWithState>
              <Routes>
                <Route
                  element={<HumanHomePage />}
                  path={RouteNames.Home.Consultations.name}
                >
                  <Route
                    element={<HumanHomePage />}
                    path={RouteNames.Home.Consultations.ShowConsultation.name}
                  />
                </Route>
                <Route
                  element={<ProfessionalsPage />}
                  path={RouteNames.Home.Professionals.name}
                >
                  <Route
                    element={<HumanHomePage />}
                    path={RouteNames.Home.Professionals.ShowProfessional.name}
                  />
                </Route>
                <Route
                  element={
                    <HumanProfileContextProvider>
                      <HumanProfile />
                    </HumanProfileContextProvider>
                  }
                  path={RouteNames.Home.Profile.name}
                />
                <Route
                  element={<OrganizationUsagePage />}
                  path={RouteNames.Home.OrganizationUsage.name}
                />

                <Route
                  element={<Navigate replace={true} to="consultations" />}
                  path={""}
                />
              </Routes>
            </SidebarWithState>
          </RequestConsultationStateProvider>
        </ConsultationsOverviewContextProvider>
      </HumanRegistrationGuard>
    </HumanGuard>
  );
}
