import { Button, Theme } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SelectButton = ({ icon, id, label, name, onChange, selected, value }) => {
  const isSelected = selected.includes(value);
  const match = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const responsiveIcon = {
    ...icon,
    props: {
      ...icon.props,
      size: 60,
      value,
    },
  };

  return (
    <Button
      id={id}
      name={name}
      onClick={onChange}
      sx={(theme) => ({
        "& svg": {
          marginBottom: theme.spacing(2),
        },
        "&:hover": {
          background: theme.palette.white.main,
          border: 0,
        },
        background: isSelected ? theme.palette.white.main : "white",
        border: 0,
        color: theme.palette.black.main,
        flexDirection: "column",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.25,
        margin: match ? 0 : theme.spacing(1),
        maxWidth: "120px",
        padding: theme.spacing(match ? 2 : 3, 1),

        textTransform: "inherit",
        width: "100%",
      })}
      value={value}
      variant="outlined"
    >
      {responsiveIcon}
      {label}
    </Button>
  );
};

const StyledSection = styled("section")<{ noWrap: boolean }>(({ noWrap }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: noWrap ? "nowrap" : "wrap",
  justifyContent: "center",
  margin: "0 auto",
  maxWidth: 440,
  width: "100%",
}));

export function GenderSelect({
  emoticons,
  errorText,
  hasError,
  name,
  noWrap,
  onChange,
  value: selected,
}) {
  const handleChange = (e) => {
    const { value } = e.currentTarget;

    onChange(value);
  };

  return (
    <>
      <StyledSection noWrap={noWrap}>
        {emoticons.map(({ icon, id, label, value }) => (
          <SelectButton
            icon={icon}
            id={id}
            key={value}
            label={label}
            name={name}
            onChange={handleChange}
            selected={value === selected ? [value] : []}
            value={value}
          />
        ))}
      </StyledSection>
      {hasError ? (
        <FormHelperText
          error={hasError}
          sx={(theme) => ({
            ...theme.controls.validation.error,
            textAlign: "center",
          })}
        >
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  );
}
