import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Website from "../../../data-model/types/professional/Website";
import createFormValidation from "../../../utils/forms/createFormValidation";
import useFormState from "../../hooks/useFormState";
import { I18Namespaces } from "../../language/I18Namespaces";

const INITIAL_VALUES = {
  licenseFiles: [],
  psychoTherapyTraining: "",
  ...Website.getInitialValue(),
};

export default function useRegisterProfessionalVerifyFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        {
          message: translate(
            "validation:uploadfilebox.mandatory",
            "Voeg de vereiste documenten toe.",
          ),
          path: "licenseFiles",
          validate: (licenseFiles) => licenseFiles.length !== 0,
        },
        {
          message: translate(
            "validation:training.mandatory",
            "Geef een training op.",
          ),
          path: "psychoTherapyTraining",
          validate: (psychoTherapyTraining, { professionalType }) => {
            if (professionalType !== "cp") {
              return psychoTherapyTraining !== "";
            }

            return true;
          },
        },
        ...Website.getFormValidation(translate),
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
