import { createContext } from "react";

const defaultTimerProviderValues = {
  isTimerRunning: false,
  pauseTimer: () => {},
  resetTimer: () => {},
  startTimer: () => {},
  timerValue: "00:00:00",
};

const TimerContext = createContext(defaultTimerProviderValues);

export default TimerContext;
