import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const PREFIX = "UnavailableSection";

const classes = {
  card: `${PREFIX}-card`,
  cardInner: `${PREFIX}-cardInner`,
};

const Root = styled("article")(({ theme }) => ({
  [`&.${classes.card}`]: {},

  [`& .${classes.cardInner}`]: {
    background: theme.palette.white.main,
    borderRadius: 5,
    color: theme.palette.black.main,
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(3, 4),
    position: "relative",
    textAlign: "center",
    textDecoration: "none",
    width: "100%",
  },
}));

export default function UnavailableSection({ text }: { text?: string | null }) {
  return (
    <Root className={classes.card}>
      <div className={classes.cardInner}>
        <Typography variant="body1">{text}</Typography>
      </div>
    </Root>
  );
}
