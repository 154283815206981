import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../buttons/Button";

const PREFIX = "TitleWithButton";

const classes = {
  textContainer: `${PREFIX}-textContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.titleContainer}`]: {
    marginBottom: 20,
  },

  [`& .${classes.textContainer}`]: {
    alignSelf: "center",
  },
}));

/**
 *  Shows a title with a button on the right.
 *
 * @param       {String} title
 *              The title that should be shown.
 * @param       {Function} [handleButton]
 *              Button click event handler.
 * @param       {String} [buttonLabel]
 *              The label that should be shown on the button.
 *              The button is not shown if this is undefined
 *              and the icon is undefined.
 * @param       {MUIIcon} [buttonIcon]
 *              What icon should be displayed on the button?
 */

interface ITitleWithButton {
  buttonIcon?: React.ReactNode;
  buttonLabel?: string;
  handleButton?: () => void;
  labelTestId?: string;
  title: string;
}

export default function TitleWithButton({
  buttonIcon,
  buttonLabel,
  handleButton = () => {},
  labelTestId,
  title,
}: ITitleWithButton) {
  const smallScreen = useMediaQuery("(max-width: 400px)");
  const breakpointForButton = useMediaQuery("(max-width: 600px)");
  const smallRightScreen = useMediaQuery(
    "only screen and (min-width: 1024px) and (max-width: 1175px)",
  );

  return (
    <StyledGrid className={classes.titleContainer} container>
      <Grid className={classes.textContainer} item sm={6} xs={6}>
        <Typography id={labelTestId} noWrap variant={smallScreen ? "h4" : "h2"}>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        sm={6}
        style={{
          textAlign: "right",
        }}
        xs={6}
      >
        {buttonLabel || buttonIcon ? (
          <Button
            icon={buttonIcon}
            label={buttonLabel}
            noLabel={breakpointForButton || smallRightScreen}
            onClick={handleButton}
          />
        ) : null}
      </Grid>
    </StyledGrid>
  );
}
