/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { graphql } from "../../api/__generated__";
import { ConsultationTypes } from "../../data-model/types/consultation/Consultation";
import { getDateFnsLocale } from "../language/languagesUtil";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import Avatar from "../ui/Avatar";
import FAQAccordion from "../ui/FAQAccordion";
import FAQAccordionItem, { IFAQData } from "../ui/FAQAccordionItem";
import SanitizedText from "../ui/text/SanitizedText";
import AffiliatedWithInsuranceCompany from "./components/AffiliatedWithInsuranceCompany";
import PaymentContext from "./context/PaymentContext";
import { IPaymentContext } from "./context/payment";

const PREFIX = "PaymentPage";

const classes = {
  consultationDetailsContainer: `${PREFIX}-consultationDetailsContainer`,
  consultationDetailsTextContainer: `${PREFIX}-consultationDetailsTextContainer`,
  faq: `${PREFIX}-faq`,
  leftSide: `${PREFIX}-leftSide`,
  rightSide: `${PREFIX}-rightSide`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  [`& .${classes.rightSide}`]: { flex: 1, margin: theme.spacing(5) },
  [`& .${classes.leftSide}`]: { flex: 1, margin: theme.spacing(5) },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.consultationDetailsContainer}`]: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(6),
  },

  [`& .${classes.consultationDetailsTextContainer}`]: {
    marginLeft: 20,
  },

  [`& .${classes.faq}`]: {
    marginBottom: theme.spacing(2),
  },
}));

const paymentHumanQuery = graphql(`
  query PaymentPageHuman($consultationUuid: String!) {
    consultation(uuid: $consultationUuid) {
      cashTransaction {
        amount
        id
      }
      human {
        id
      }
      id
      professional {
        id
        user {
          ...Avatar
          firstName
          id
          lastName
        }
      }
      scheduledFrom
      scheduledTo
      type
      uuid
    }
    currentUser {
      human {
        ...AffiliatedMutualityHuman
        id
      }
      id
    }
  }
`);

export default function PaymentPage() {
  const { pay } = useContext<IPaymentContext>(PaymentContext);
  const { i18n, t } = useTranslation();

  const { uuid } = useParams();
  const { data } = useQuery(paymentHumanQuery, {
    variables: { consultationUuid: uuid || "" },
  });

  const consultation = data?.consultation;

  const locale = getDateFnsLocale(i18n.language);
  const eligibleForReimbursement =
    consultation?.type === ConsultationTypes.APPOINTMENT ||
    consultation?.type === ConsultationTypes.COACHING;

  const questions: Array<IFAQData> = [
    {
      answer: t("payments:payment.page.faq.pay.upfront.answer"),
      question: t("payments:payment.page.faq.pay.upfront.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.cancellation.answer"),
      question: t("payments:payment.page.faq.pay.cancellation.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.paymentdata.answer"),
      question: t("payments:payment.page.faq.pay.paymentdata.question"),
    },
    {
      answer: t("payments:payment.page.faq.pay.proof.answer"),
      question: t("payments:payment.page.faq.pay.proof.question"),
    },
  ];

  if (eligibleForReimbursement) {
    questions.push({
      answer: t("payments:payment.page.faq.othermutualities.answer.helan"),
      question: t("payments:payment.page.faq.othermutualities.question"),
    });
  }

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullContent={true}
        fullCopyright
        headline={t("payments:payment")}
        showLanguageSelector={false}
      >
        <StyledBox className={classes.root}>
          <Box className={classes.leftSide} mb={3}>
            {data?.currentUser?.human ? (
              <AffiliatedWithInsuranceCompany
                eligibleForReimbursement={eligibleForReimbursement}
                human={data.currentUser.human}
                pay={pay}
                price={consultation?.cashTransaction?.amount}
                uuid={uuid}
              />
            ) : null}
          </Box>
          <Box className={classes.rightSide}>
            <Box>
              <Typography className={classes.title} variant="h2">
                {t("common:consultation_type.APPOINTMENT")}
              </Typography>

              <Box className={classes.consultationDetailsContainer}>
                {consultation?.professional ? (
                  <Avatar size={40} user={consultation.professional.user} />
                ) : null}
                <Box className={classes.consultationDetailsTextContainer}>
                  {consultation?.professional?.user ? (
                    <Typography>{`${consultation.professional.user.firstName} ${consultation.professional.user.lastName}`}</Typography>
                  ) : null}
                  <Typography>
                    {consultation?.scheduledFrom
                      ? format(consultation.scheduledFrom, "d MMM u", {
                          locale,
                        })
                      : null}
                    &emsp;
                    {consultation?.scheduledFrom && consultation.scheduledTo ? (
                      <SanitizedText
                        input={t("payments:payment.page.time.format", {
                          defaultValue: "{{startTime}} &rarr; {{endTime}}",
                          endTime: format(consultation.scheduledTo, "HH:mm", {
                            locale,
                          }),
                          startTime: format(
                            consultation.scheduledFrom,
                            "HH:mm",
                            {
                              locale,
                            },
                          ),
                        })}
                      />
                    ) : null}
                  </Typography>
                </Box>
              </Box>
              <Typography className={classes.title} variant="h2">
                {t("payments:payment.page.faq.title")}
              </Typography>
              <Box className={classes.faq}>
                <FAQAccordion>
                  {questions.map((question) => {
                    return (
                      <FAQAccordionItem
                        answer={question.answer}
                        key={question.question}
                        question={question.question}
                      />
                    );
                  })}
                </FAQAccordion>
              </Box>
              <Box>
                <Typography className={classes.title} variant="h2">
                  {t("payments:payment.page.terms.title")}
                </Typography>
                <Typography>
                  <SanitizedText
                    input={t("payments:payment.page.terms.text")}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
