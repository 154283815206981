import { EventAvailableOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import ProfessionalScheduleConsultation from "../../professionals/ProfessionalRequestConsultation";
import SidePanel from "./SidePanel";

const consultationFragment = graphql(`
  fragment BookingSidePanelConsultation on Consultation {
    human {
      id
      ...RequestConsultationHumanInfo
    }
    id
    professional {
      id
    }
  }
`);

const BookingSidePanel = ({
  consultation,
  returnToPreviousPane,
}: {
  consultation: FragmentType<typeof consultationFragment>;
  returnToPreviousPane: () => void;
}) => {
  const { t } = useTranslation();

  const consultationData = getFragmentData(consultationFragment, consultation);

  return (
    <SidePanel
      TitleIcon={EventAvailableOutlined}
      returnToPreviousPane={returnToPreviousPane}
      title={t("ui:sidepane.menu.book.consultation.title")}
    >
      {consultationData.professional && consultationData.human ? (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          {
            <ProfessionalScheduleConsultation
              humanData={consultationData.human}
              onCancel={returnToPreviousPane}
              onSchedule={returnToPreviousPane}
              professionalId={consultationData.professional.id}
            />
          }
        </Box>
      ) : null}
    </SidePanel>
  );
};

export default BookingSidePanel;
