/**
 * Gender of a psy, client or user.
 */
import type { TFunction } from "i18next";
import {
  FemaleIcon,
  MaleIcon,
  NoGenderPreferenceIcon,
} from "../../../components/icons/emoticons.js";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export enum GenderOptions {
  FEMALE = "female",
  MALE = "male",
  OTHER = "none",
}

export default class Gender extends DataType<
  GenderOptions,
  DataTypePaths.Profile.Gender
> {
  protected static path = "gender";
  protected static defaultValue = GenderOptions.OTHER;

  protected type: any = Gender;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate("gender.mandatory", "Selecteer je geslacht."),
        path: this.getPath(),
        validate: (g: string): boolean => !!g && g.trim() !== "",
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionaly, an icon.
   *
   * @param  {any} translate The i18next translate hook with the common namespace.
   */
  static getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<Record<string, unknown>> {
    return [
      {
        icon: <FemaleIcon />,
        label: translate("common:gender.female", "Vrouw"),
        value: GenderOptions.FEMALE,
      },
      {
        icon: <MaleIcon />,
        label: translate("common:gender.male", "Man"),
        value: GenderOptions.MALE,
      },
      {
        icon: <NoGenderPreferenceIcon />,
        label: translate("common:gender.user.other", "Genderneutraal"),
        value: GenderOptions.OTHER,
      },
    ];
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  static getOptions() {
    return GenderOptions;
  }

  getAsFormStateValue(): Record<DataTypePaths.Profile.Gender, GenderOptions> {
    return { gender: this.value };
  }
}
