import type { TFunction } from "i18next";
import {
  APIOrganization,
  _shared,
} from "../../api/organizations/organizations";
import { I18Namespaces } from "../../components/language/I18Namespaces";
import BaseType from "../BaseType";
import { DataTypePaths, PossibleFormStatePaths } from "../dataTypePaths";

interface IGetTranslation {
  (
    translate: TFunction<I18Namespaces>,
    keyWithoutNS: string,
    defaultValue?: string,
  ): string;
}

class Organization extends BaseType<
  APIOrganization.Result,
  DataTypePaths.OrganizationPath
> {
  protected type: any = Organization;

  protected slug = "";
  protected creditsPerHuman: number | null = null;

  constructor(model?: APIOrganization.Result) {
    super(model);

    if (!model) return;

    if (model.slug) this.slug = model.slug;

    if (model.creditsPerHuman !== undefined)
      this.creditsPerHuman = model.creditsPerHuman;
  }

  /**
   * Get the slug of this organization.
   * This is the part that comes after app.bloomup.org/organization/
   * ex: mantelzorgersstadantwerpen
   */
  public getSlug(): string {
    return this.slug;
  }

  /**
   * Set the slug of this organization.
   * This is the part that comes after app.bloomup.org/organization/
   * ex: mantelzorgersstadantwerpen
   */
  public setSlug(slug: string): Organization {
    this.slug = slug;

    return this;
  }

  /**
   * Gets the max amount of credits per human for this organization.
   */
  public getCreditsPerHuman(): number | null {
    return this.creditsPerHuman;
  }

  public getName(translate: TFunction<I18Namespaces>): string {
    return this.getTranslation(translate, "name", this.getSlug());
  }

  public getTranslation: IGetTranslation = (
    translate,
    keyWithoutNS,
    defaultValue,
  ) => {
    const orgVars = {
      commissionPerCredit: 15,
      creditsPerHuman: this.getCreditsPerHuman(),
      name: translate("partners:name", { context: this.getSlug() }),
      slug: this.getSlug(),
    };

    return (
      translate(`partners:${keyWithoutNS}`, {
        ...orgVars,
        context: this.getSlug(),
        defaultValue: defaultValue,
      }) || ""
    );
  };

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }

  getAsFormStateValue(): Partial<Record<PossibleFormStatePaths, _shared>> {
    return {};
  }
}

export default Organization;
