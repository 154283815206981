import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { IWithChildren } from "../../../baseInterfaces";
import { UserRoles } from "../../../data-model/types/User";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";

//Interface to be used by Guards that create a new component using this Guard
//export interface IGuardImplementation extends IWithChildren {}

// Interface for this Guard
interface IGuard extends IWithChildren {
  allowAccessToAdmin?: boolean;
  condition: boolean | undefined;
  logText?: string;
  redirectPath?: string;
}

export default function Guard({
  allowAccessToAdmin,
  children,
  condition,
  logText,
  redirectPath,
}: IGuard): JSX.Element | null {
  const { currentUser } = useContext(AuthContext);

  const location = useLocation();

  if (!currentUser) {
    return <LoadingPage full logText={logText} />;
  } else if (
    condition ||
    (allowAccessToAdmin && currentUser.getRole() === UserRoles.ADMIN)
  ) {
    return <>{children}</>;
  } else if (redirectPath) {
    return (
      <Navigate
        state={{
          from: location,
        }}
        to={redirectPath}
      />
    );
  }

  return null;
}
