import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TodayIcon from "@mui/icons-material/Today";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { addDays, format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../../../language/languagesUtil";
import Button from "../../../ui/buttons/Button";
import { ReactComponent as BloomCircleVariable } from "../Common/BloomCircleVariable.svg";
import { IntroDetails } from "../Match";

const groupByDay = (dates: string[]) => {
  return dates.reduce((acc, dateStr) => {
    const date = new Date(dateStr);
    const dayKey = date.toDateString();

    // If the dayKey doesn't exist in the Map, initialize it as an empty array
    if (!acc.has(dayKey)) {
      acc.set(dayKey, []); // Initialize the array for that day
    }

    // Now, safely add the date to the existing array
    acc.get(dayKey)?.push(date);

    return acc;
  }, new Map<string, Date[]>());
};

const getNextXDays = (selectedWeek: number, x: number) => {
  const tomorrow = addDays(new Date(), selectedWeek * x + 1);
  const dates: string[] = [];

  for (let i = 0; i < x; i++) {
    dates.push(addDays(tomorrow.setHours(0, 0, 0, 0), i).toDateString());
  }

  return dates;
};

interface ChooseConsultationProps {
  availability: string[];
  bookIntroConsult: (introDetails: IntroDetails) => Promise<void>;
  profId: number;
}

const ChooseConsultation = ({
  availability,
  bookIntroConsult,
  profId,
}: ChooseConsultationProps) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const [selectedWeek, setselectedWeek] = useState<number>(0);

  const [selectedTime, setSelectedTime] = useState<number>();

  const groupedAvailability = groupByDay(availability);
  const mediumSize = useMediaQuery("(min-width:800px)");
  const notPhone = useMediaQuery("(min-width:500px)");

  const nextDays = getNextXDays(selectedWeek, mediumSize ? 7 : 3);

  const [selectedDay, setSelectedDay] = useState<string | undefined>(
    getNextXDays(0, mediumSize ? 7 : 3).find((day) =>
      groupedAvailability.has(day),
    ),
  );

  const lastElement = Array.from(groupedAvailability.keys()).pop();

  return (
    <Box sx={{ maxWidth: "850px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            [theme.breakpoints.down(600)]: {
              gap: "5px",
            },
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => {
              if (selectedWeek !== 0) {
                const prevWeek = selectedWeek - 1;

                setselectedWeek(prevWeek);

                const availableDay = getNextXDays(
                  prevWeek,
                  mediumSize ? 7 : 3,
                ).find((day) => groupedAvailability.has(day));

                setSelectedDay(availableDay ? availableDay : undefined);
                setSelectedTime(undefined);
              }
            }}
            sx={{
              cursor: "pointer",
              position: "relative",
              transition: "opacity 0.3s ease-in-out",
              ...(selectedWeek === 0
                ? { cursor: "not-allowed", opacity: 0.5 }
                : null),
            }}
          >
            <BloomCircleVariable
              fill={theme.palette.white.dark}
              height={notPhone ? "78px" : "50px"}
              width={notPhone ? "72px" : "46px"}
            />
            <ArrowBackIcon
              sx={{
                height: notPhone ? "32px" : "26px",
                left: notPhone ? "20px" : "10px",
                position: "absolute",
                top: notPhone ? "27px" : "13px",
                width: notPhone ? "32px" : "26px",
              }}
            />
          </Box>
          {nextDays.map((day) => {
            const dateString = day;

            const isAvailable = groupedAvailability.has(dateString);

            return (
              <Box
                key={dateString}
                onClick={() => {
                  if (isAvailable) setSelectedDay(dateString);
                }}
                sx={{
                  cursor: isAvailable ? "pointer" : "not-allowed",
                  position: "relative",
                  textAlign: "center",
                  transition: "background-color 0.5s ease-in-out",
                }}
              >
                <BloomCircleVariable
                  height={notPhone ? "78px" : "50px"}
                  style={{
                    fill: isAvailable
                      ? selectedDay === dateString
                        ? theme.palette.green.dark
                        : theme.palette.white.dark
                      : theme.palette.red.dark,
                    transition: "fill 0.5s ease-in-out",
                  }}
                  width={notPhone ? "72px" : "46px"}
                />
                <Typography
                  sx={{
                    "&::first-letter": {
                      textTransform: "capitalize",
                    },
                    color:
                      selectedDay === dateString || !isAvailable
                        ? theme.palette.white.light
                        : theme.palette.black.dark,
                    fontSize: notPhone ? undefined : "14px",
                    fontWeight: 700,
                    left: "50%",
                    position: "absolute",
                    top: notPhone ? "27px" : "15px",
                    transform: "translateX(-50%)",
                    transition: "color 0.5s ease-in-out",
                    userSelect: "none",
                    width: "100%",
                  }}
                  variant="body1"
                >
                  {format(day, "EEEEEE dd", {
                    locale: getDateFnsLocale(i18n.language),
                  })}
                </Typography>
                {selectedDay === dateString && (
                  <Box
                    sx={{
                      background: theme.palette.green.dark,
                      borderRadius: "10px",
                      height: "22px",
                      left: "27px",
                      opacity: selectedDay === dateString ? 1 : 0,
                      position: "absolute",
                      top: "85px",
                      transition:
                        "opacity 0.5s ease-in-out, background-color 0.5s ease-in-out",
                      width: "18px",
                    }}
                  />
                )}
              </Box>
            );
          })}
          <Box
            onClick={() => {
              if (lastElement && !nextDays.includes(lastElement)) {
                const nextWeek = selectedWeek + 1;

                const availableDay = getNextXDays(
                  nextWeek,
                  mediumSize ? 7 : 3,
                ).find((day) => groupedAvailability.has(day));

                setSelectedDay(availableDay ? availableDay : undefined);
                setselectedWeek(nextWeek);
                setSelectedTime(undefined);
              }
            }}
            sx={{
              cursor: "pointer",
              position: "relative",
              transition: "opacity 0.3s ease-in-out",
              ...(lastElement && nextDays.includes(lastElement)
                ? { cursor: "not-allowed", opacity: 0.5 }
                : null),
            }}
          >
            <BloomCircleVariable
              fill={theme.palette.white.dark}
              height={notPhone ? "78px" : "50px"}
              width={notPhone ? "72px" : "46px"}
            />
            <ArrowForwardIcon
              sx={{
                height: notPhone ? "32px" : "26px",
                left: notPhone ? "20px" : "10px",
                position: "absolute",
                top: notPhone ? "27px" : "13px",
                width: notPhone ? "32px" : "26px",
              }}
            />
          </Box>
        </Box>
        {selectedDay ? (
          <Box
            sx={{
              background: theme.palette.green.dark,
              borderRadius: "83px 116px 93px 105px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
              paddingX: "5rem",
              paddingY: "30px",
              [theme.breakpoints.down(600)]: {
                gap: "5px",
                paddingX: "1rem",
                paddingY: "16px",
              },
              transition: "height 0.5s ease-in-out",
              // width: "100%",
            }}
          >
            {groupedAvailability.get(selectedDay)?.map((time, index) => {
              const selected = selectedTime === time.getTime();

              return (
                <Box
                  data-testId={`timeSlot${index}`}
                  key={time.toString()}
                  onClick={() => {
                    setSelectedTime(time.getTime());
                  }}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <BloomCircleVariable
                    height={notPhone ? "72px" : "46px"}
                    style={{
                      fill: selected
                        ? theme.palette.green.main
                        : theme.palette.white.dark,
                      transition: "fill 0.3s ease-in-out",
                    }}
                    width={notPhone ? "72px" : "46px"}
                  />
                  <Typography
                    sx={{
                      color: selected
                        ? theme.palette.white.light
                        : theme.palette.black.dark,
                      fontWeight: 700,
                      left: "50%",
                      lineHeight: notPhone ? "20px" : undefined,
                      position: "absolute",
                      top: notPhone ? "27px" : "11px",
                      transform: "translateX(-50%)",
                      transition: "color 0.3s ease-in-out",
                      userSelect: "none",
                      width: "100%",
                    }}
                    variant="body1"
                  >
                    {format(time, "k:mm")}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ) : null}
        <Box sx={{ paddingTop: "8px", textAlign: "center" }}>
          <Button
            disabled={selectedTime === undefined}
            fullyColored
            label={t("match:chooseProfstep.card.booking.button")}
            onClick={() => {
              if (selectedTime) {
                bookIntroConsult({
                  professionalId: profId,
                  startTime: new Date(selectedTime),
                });
              }
            }}
            startIcon={<TodayIcon />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseConsultation;
