import BaseSimpleSelect, {
  ISelectObject,
  ISelectProps,
} from "./BaseSimpleSelect";

interface ISingleSelect<T = any> extends ISelectProps<T> {
  onChange: (value: ISelectObject<T>) => void;
  onSelected?: (value: ISelectObject<T>) => void;
  value?: ISelectObject<T> | undefined;
}

export default function SingleSelect<T>({
  errorText,
  hasError,
  isSearchable,
  name,
  onChange,
  onSelected,
  options,
  placeholder,
  value,
}: ISingleSelect<T>) {
  return (
    <BaseSimpleSelect<T>
      errorText={errorText}
      hasError={hasError}
      isMulti={false}
      isSearchable={isSearchable}
      name={name}
      onChange={(value) => {
        onChange(value);

        if (onSelected) onSelected(value);
      }}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}
