import { useMutation, useQuery } from "@apollo/client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, Link, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "../../../api/__generated__";
import {
  Treatment,
  TreatmentsQuery,
  UseSelectTranslationStringInputFragment,
} from "../../../api/__generated__/graphql";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import environment from "../../../environment/environment";
import { I18NexusLanguages } from "../../language/languagesUtil";
import useTrackPageView from "../../providers/matomo/useTrackPageView";
import Button from "../../ui/buttons/Button";
import BloomieCard from "./Common/BloomieCard";
import Footer from "./Common/Footer";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";

const treatmentsQuery = graphql(`
  query Treatments {
    currentMatchedHuman {
      id
      organization {
        coachingsPerHuman
        consultsPerHuman
        id
        mindlabEnabled
        name {
          ...useSelectTranslationStringInput
        }
      }
      treatmentOptions {
        availableTreatments
        module {
          id
          title {
            ...useSelectTranslationStringInput
          }
          url
        }
        proposedTreatment
      }
    }
  }
`);

const submitTreatmentStepMutation = graphql(`
  mutation SubmitTreatmentStep($treatment: Treatment!) {
    submitTreatmentStep(treatment: $treatment) {
      matchedHuman {
        id
        treatment
      }
      matchingToken
      nextStep
    }
  }
`);

interface TreatmentStepProps {
  stepBack: () => void;
  stepForward: (nextStep: string, token: string) => void;
}

interface TreatmentCard {
  buttonOnClick: () => void;
  buttonText?: {
    __typename?: "MultiLanguageString";
  } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
  buttonTextKey: string;
  descKey: string;
  id: string;
  imgUrl: string;
  proposed: boolean;
  titleKey: string;
  treatment: Treatment;
}

const formatTreatments = (
  data: TreatmentsQuery,
  submit: (treatment: Treatment) => void,
  mindlabLanguage: string,
) => {
  const proposedTreatment =
    data.currentMatchedHuman?.treatmentOptions.proposedTreatment;

  const module = data.currentMatchedHuman?.treatmentOptions.module;
  const organizationData = data.currentMatchedHuman?.organization;
  const mindlabBaseUrl = organizationData?.mindlabEnabled
    ? environment.MINDLAB_FRONTEND_URL
    : environment.YOURMINDLAB_URL;

  const coachingCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("COACHING");
    },
    buttonTextKey: "match:treatmentStep.treatments.coaching.button",
    descKey: `match:treatmentStep.treatments.coaching.desc${
      organizationData
        ? organizationData.coachingsPerHuman === null
          ? "_org_unlimited"
          : "_org_limited"
        : ""
    }`,
    id: "COACHING",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D75-coaching%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "COACHING",
    titleKey: "match:treatmentStep.treatments.coaching.title",
    treatment: "COACHING",
  };

  const therapyCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("THERAPY");
    },
    buttonTextKey: "match:treatmentStep.treatments.coaching.button",
    descKey: `match:treatmentStep.treatments.therapy.desc${
      organizationData
        ? organizationData.consultsPerHuman === null
          ? "_org_unlimited"
          : "_org_limited"
        : ""
    }`,
    id: "THERAPY",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D77-therapy%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "THERAPY",
    titleKey: "match:treatmentStep.treatments.therapy.title",
    treatment: "THERAPY",
  };

  const selfHelpCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("SELFHELP");

      if (module) {
        window.open(`${mindlabBaseUrl}${module.url}`, "_blank");
      } else {
        window.open(`${mindlabBaseUrl}/${mindlabLanguage}/modules`, "_blank");
      }
    },
    buttonText: module?.title,
    buttonTextKey: "match:treatmentStep.treatments.selfHelp.button",
    descKey: "match:treatmentStep.treatments.selfHelp.desc",
    id: "SELFHELP",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "SELFHELP",
    titleKey: "match:treatmentStep.treatments.selfHelp.title",
    treatment: "SELFHELP",
  };

  const treatmentCards: TreatmentCard[] = [];

  data.currentMatchedHuman?.treatmentOptions.availableTreatments.forEach(
    (treatment) => {
      switch (treatment) {
        case "COACHING":
          treatmentCards.push(coachingCard);
          break;

        case "THERAPY":
          treatmentCards.push(therapyCard);
          break;

        case "SELFHELP":
          treatmentCards.push(selfHelpCard);
          break;
      }
    },
  );

  const result = treatmentCards.sort((a, b) =>
    a.proposed ? -1 : b.proposed ? 1 : 0,
  );

  return result;
};

const TreatmentStep = ({ stepBack, stepForward }: TreatmentStepProps) => {
  useTrackPageView("Match > Treatment");

  const { data } = useQuery(treatmentsQuery);
  const [submitTreatmentStep] = useMutation(submitTreatmentStepMutation);
  const { i18n, t } = useTranslation();
  const selectTranslation = useSelectTranslation();

  const mindlabLanguagesMap = new Map<I18NexusLanguages, string>([
    [I18NexusLanguages.FLEMISH, "nl"],
    [I18NexusLanguages.FRENCH, "fr"],
    [I18NexusLanguages.ENGLISH, "en"],
  ]);

  const mindlabLanguage = mindlabLanguagesMap.get(
    i18n.language as I18NexusLanguages,
  );

  const submit = async (treatment: Treatment) => {
    const { data: submitData } = await submitTreatmentStep({
      variables: {
        treatment,
      },
    });

    submitData &&
      stepForward(
        submitData.submitTreatmentStep.nextStep,
        submitData.submitTreatmentStep.matchingToken,
      );
  };

  const treatmentsData = data?.currentMatchedHuman?.treatmentOptions;
  const organizationData = data?.currentMatchedHuman?.organization;

  const formattedTreatmentCards = treatmentsData
    ? formatTreatments(data, submit, mindlabLanguage || "nl")
    : [];

  const hasProposedTreatment = Boolean(
    formattedTreatmentCards.find((treatment) => {
      return treatment.proposed;
    }),
  );

  const theme = useTheme();

  return (
    <>
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D66-colleague%2C+Size%3DSmall.png"
        }
        subtitle={t("match:treatmentStep.subTitle")}
        title={t("match:treatmentStep.title")}
      />
      <StepMainContent onClickPrevious={stepBack}>
        <Box
          sx={(theme) => ({
            alignItems: "start",
            columnGap: "30px",
            display: "grid",
            gridTemplateColumns: `1fr`,
            justifyItems: "center",
            marginBottom: "3rem",
            [theme.breakpoints.up(700)]: {
              columnGap: "50px",
              gridTemplateColumns: `repeat(${Math.min(
                formattedTreatmentCards.length,
                2,
              )}, 1fr)`,
            },
            [theme.breakpoints.up(1000)]: {
              gridTemplateColumns: `repeat(${formattedTreatmentCards.length}, 1fr)`,
              marginBottom: "7.5rem",
            },
          })}
        >
          {formattedTreatmentCards.map((treatmentCard, i) => {
            return (
              <>
                <BloomieCard
                  bodyChildren={
                    <Typography
                      sx={{
                        fontSize: "14px",
                        marginBottom: "1.5rem",
                      }}
                    >
                      <Trans
                        components={{ bold: <strong /> }}
                        i18nKey={treatmentCard.descKey}
                        values={{
                          count:
                            treatmentCard.treatment === "THERAPY"
                              ? organizationData?.consultsPerHuman
                              : organizationData?.coachingsPerHuman,
                          orgName: selectTranslation(organizationData?.name),
                        }}
                      />
                    </Typography>
                  }
                  imgUrl={treatmentCard.imgUrl}
                  key={treatmentCard.id}
                  size={treatmentCard.proposed ? "xl" : "lg"}
                  subTitle={
                    hasProposedTreatment
                      ? treatmentCard.proposed
                        ? t("match:treatmentStep.treatments.ourAdvice")
                        : treatmentCard.id === "SELFHELP"
                          ? t("match:treatmentStep.treatments.selfHelp")
                          : t("match:treatmentStep.treatments.alternative")
                      : undefined
                  }
                  subTitleColor={treatmentCard.proposed ? "#4F7D5F" : "#1C7CBA"}
                  sx={{
                    "& strong": {
                      color: treatmentCard.proposed ? "#4F7D5F" : "#1C7CBA",
                    },
                    [theme.breakpoints.up(700)]: {
                      order: `${i === 0 ? "1" : i === 1 ? "2" : "7"}`,
                      ...(i === 2 ? { gridColumn: "1 / 3" } : undefined),
                      ...(i === 2
                        ? { marginTop: "50px" }
                        : { marginTop: "initial" }),
                    },
                    [theme.breakpoints.up(1000)]: {
                      gridColumn: "initial",
                      marginTop: "initial",
                      ...(formattedTreatmentCards.length === 3
                        ? { order: `${i === 0 ? "2" : i === 1 ? "1" : "3"}` }
                        : undefined),
                    },
                    marginTop: "30px",
                  }}
                  title={t(treatmentCard.titleKey)}
                  type={treatmentCard.proposed ? "filled" : "outlined"}
                />
                <Box
                  sx={{
                    alignSelf: "center",
                    [theme.breakpoints.up(700)]: {
                      order: `${i === 0 ? "3" : i === 1 ? "4" : "8"}`,
                      ...(i === 2 ? { gridColumn: "1 / 3" } : undefined),
                    },
                    [theme.breakpoints.up(1000)]: {
                      gridColumn: "initial",
                      ...(formattedTreatmentCards.length === 3
                        ? { order: `${i === 0 ? "5" : i === 1 ? "4" : "6"}` }
                        : undefined),
                    },
                  }}
                >
                  <Button
                    bloomColor={treatmentCard.proposed ? "green" : undefined}
                    data-testId={treatmentCard.id + "Btn"}
                    endIcon={
                      treatmentCard.proposed ? <ArrowForwardIcon /> : undefined
                    }
                    fullyColored={treatmentCard.proposed}
                    label={
                      treatmentCard.buttonText
                        ? selectTranslation(treatmentCard.buttonText)
                        : t(treatmentCard.buttonTextKey)
                    }
                    onClick={treatmentCard.buttonOnClick}
                    size="medium"
                    sx={(theme) => ({
                      borderRadius: "100px !important",
                      color: theme.palette.black.main,
                      height: "unset !important",
                      [theme.breakpoints.up(700)]: {
                        fontSize: treatmentCard.proposed
                          ? "18px !important"
                          : undefined,
                        padding: treatmentCard.proposed
                          ? "16px 32px !important"
                          : undefined,
                      },
                      [theme.breakpoints.up(700)]: {
                        fontSize: treatmentCard.proposed
                          ? "20px !important"
                          : undefined,
                        padding: treatmentCard.proposed
                          ? "20px 40px !important"
                          : undefined,
                      },
                    })}
                  />
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up(700)]: {
                      order: `${i === 0 ? "5" : i === 1 ? "6" : "9"}`,
                      ...(i === 2 ? { gridColumn: "1 / 3" } : undefined),
                    },
                    [theme.breakpoints.up(1000)]: {
                      gridColumn: "initial",
                      ...(formattedTreatmentCards.length === 3
                        ? { order: `${i === 0 ? "8" : i === 1 ? "7" : "9"}` }
                        : undefined),
                    },
                  }}
                >
                  {treatmentCard.id === "SELFHELP" && treatmentsData?.module ? (
                    <Link
                      flexDirection="row"
                      href={`${
                        organizationData?.mindlabEnabled
                          ? environment.MINDLAB_FRONTEND_URL
                          : environment.YOURMINDLAB_URL
                      }/${mindlabLanguage}/modules`}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                      target="blank"
                      underline="none"
                      variant="accessibleLink"
                    >
                      <Typography>
                        {t("match:treatmentStep.moduleLink")}
                      </Typography>
                      <ArrowForwardIcon
                        sx={{ marginLeft: "3px", marginTop: "3px" }}
                      />
                    </Link>
                  ) : null}
                </Box>
              </>
            );
          })}
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default TreatmentStep;
