/* eslint-disable react-hooks/exhaustive-deps */

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../api/api";
import useFetchAllConsultationsForCurrentUser from "../../../api/consultations/hooks/useFetchAllConsultationsForCurrentUser";
import useLazyFetchConsultationByUuid from "../../../api/consultations/hooks/useLazyFetchConsultationByUuid";
import Consultation from "../../../data-model/types/consultation/Consultation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";
import ToastContext from "../../providers/toast/ToastContext";
import { IToastContext } from "../../providers/toast/toast";
import { IConsultationListDescription } from "../overview/ConsultationList";
import ConsultationsOverviewContext from "./ConsultationsOverviewContext";
import { IConsultationsOverviewContext } from "./consultations";

export default function ConsultationsOverviewProvider({
  children,
}: IConsultationsOverviewContext) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  const { setToast } = useContext<IToastContext>(ToastContext);
  const [selectedConsultation, setSelectedConsultation] = useState<
    Consultation | undefined
  >(undefined);

  const [showAddConsultation, setShowAddConsultation] =
    useState<boolean>(false);

  const { currentUser } = useContext(AuthContext);
  const role = currentUser?.getRole();

  const { trackEvent } = useMatomo();
  const {
    data: consultations,
    loading,
    refetch,
  } = useFetchAllConsultationsForCurrentUser();

  const {
    consultation: refetchedConsultation,
    error: refetchError,
    fetchConsultation,
    loading: refetchingOneConsultation,
  } = useLazyFetchConsultationByUuid();

  useEffect(() => {
    if (refetchError) {
      setToast({
        message: translate(
          "refetch.consultation.error",
          "We konden de laatste wijzigingen aan deze consultatie niet ophalen.",
        ),
        severity: "error",
      });
    }

    if (!refetchingOneConsultation && !refetchError && refetchedConsultation) {
      setSelectedConsultation(refetchedConsultation);
    }
  }, [
    refetchedConsultation,
    refetchError,
    refetchingOneConsultation,
    setToast,
  ]);

  const openConsultationWithUuid = (uuid: UUID) => {
    if (uuid !== selectedConsultation?.getUUID()) {
      setShowAddConsultation(false);

      if (consultations) {
        const selected = consultations.find((c) => c.getUUID() === uuid);

        setSelectedConsultation(selected);
      }
    }
  };

  const closeConsultationRightPane = () => {
    setSelectedConsultation(undefined);
    setShowAddConsultation(false);
  };

  const closeAddConsultation = () => setShowAddConsultation(false);
  const openAddConsultation = () => {
    // Track click on button
    trackEvent({
      action: "button-click",
      category: "consultations",
      name: "add-consultation",
    });
    setShowAddConsultation(true);
  };

  const sortConsultationsByDate = (
    list: Array<Consultation>,
    direction = "ASC",
  ) => {
    const ascFunc = (a: Consultation, b: Consultation) =>
      a.getStartDate() < b.getStartDate() ? 1 : -1;

    const descFunc = (a: Consultation, b: Consultation) =>
      a.getStartDate() > b.getStartDate() ? 1 : -1;

    return list.sort(direction === "ASC" ? ascFunc : descFunc);
  };

  const groupedConsultations: Array<IConsultationListDescription> =
    useMemo(() => {
      const lists: Array<IConsultationListDescription> = [];

      if (!loading && consultations) {
        const awaitingConfirmation = consultations.filter(
          (c: Consultation) =>
            (c.getStatus().isWaiting() ||
              c.getStatus().isWaitingForConfirmation()) &&
            c.getEndDate() > moment(),
        );

        const awaitingConfirmationList: IConsultationListDescription = {
          consultations: sortConsultationsByDate(awaitingConfirmation, "DESC"),
          emptyText: translate(
            "professional:consultation_list.to_be_approved.empty",
            "Er zijn geen te bevestigen consultaties.",
          ),
          isOpen: true,
          listName: translate(
            "professional:consultation_list.to_be_approved.title",
            "Te bevestigen",
          ),
          onSelectConsultation: (uuid) => openConsultationWithUuid(uuid),
          roleType: role,
        };

        if (awaitingConfirmation && awaitingConfirmation.length > 0)
          lists.push(awaitingConfirmationList);

        const future = consultations.filter(
          (c: Consultation) =>
            (c.getStatus().isAccepted() || c.getStatus().isInProgress()) &&
            c.getEndDate() > moment(),
        );

        const futureList: IConsultationListDescription = {
          consultations: sortConsultationsByDate(future),

          emptyText: translate(
            "professional:consultation_list.scheduled.empty",
            "Je hebt geen geplande consultaties.",
          ),

          isOpen: true,
          //TODO: translate
          listName: translate(
            "professional:consultation_list.scheduled.title",
            "Gepland",
          ),
          onSelectConsultation: (uuid) => openConsultationWithUuid(uuid),
          roleType: role,
        };

        if (future && future.length > 0) lists.push(futureList);

        const past = consultations.filter((c) => {
          const status = c.getStatus().getValue();

          return (
            status === "DONE" ||
            status === "CANCELLED" ||
            status === "CANCELLED_BY_HUMAN" ||
            status === "CANCELLED_BY_PROFESSIONAL" ||
            status === "NO_SHOW_HUMAN" ||
            status === "NO_SHOW_PROFESSIONAL" ||
            c.getEndDate() < moment()
          );
        });

        const pastList: IConsultationListDescription = {
          consultations: sortConsultationsByDate(past),
          emptyText: translate(
            "professional:consultation_list.completed.empty",
            "Je hebt nog geen consultaties.",
          ),
          isOpen: true,
          listName: translate(
            "professional:consultation_list.completed.title",
            "Afgerond",
          ),
          onSelectConsultation: (uuid) => openConsultationWithUuid(uuid),
          roleType: role,
        };

        if (past && past.length > 0) lists.push(pastList);
      }

      return lists;
    }, [consultations, loading, role, openConsultationWithUuid]);

  if (!consultations) return <LoadingPage />;

  return (
    <ConsultationsOverviewContext.Provider
      value={{
        closeAddConsultation,
        closeConsultation: closeConsultationRightPane,
        consultations,
        groupedConsultations,
        loading,
        openAddConsultation,
        openConsultationWithUuid,
        refetchAll: refetch,
        refetchOneConsultation: fetchConsultation,
        selectedConsultation,
        setSelectedConsultation,
        showAddConsultation,
      }}
    >
      {children}
    </ConsultationsOverviewContext.Provider>
  );
}
