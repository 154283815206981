import { Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { WaveBox } from "wave-box";
import { BloomColor } from "../../../layout/wizard/StepFooter";
import Button from "../../../ui/buttons/Button";

const PREFIX = "Header";

const classes = {
  button: `${PREFIX}-button`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  root: `${PREFIX}-root`,
  textContent: `${PREFIX}-textContent`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 96px",
    [theme.breakpoints.down(900)]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "0 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 18px",
    },
  },

  [`& .${classes.content}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "1300px",
    width: "100%",
    [theme.breakpoints.down(1160)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },

  [`& .${classes.textContent}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "510px",
    paddingRight: "5rem",
    [theme.breakpoints.down(1160)]: {
      paddingBottom: "2rem",
      paddingRight: "0",
    },
  },

  [`& .${classes.title}`]: { marginBottom: "0.5rem" },

  [`& .${classes.image}`]: {
    borderRadius: "10%",
    height: "auto",
    maxHeight: "550px",
    maxWidth: "100%",
    [theme.breakpoints.down(1160)]: {
      maxHeight: "300px",
    },
  },

  [`& .${classes.button}`]: {
    marginTop: "1rem",
  },
}));

interface headerProps {
  backgroundColor?: string;
  button?: headerButton;
  headerImageUrl: string;
  text: string;
  title: string;
  transform?: string;
}

interface headerButton {
  color?: BloomColor;
  href?: string;
  label: string;
  onClick?: () => void;
}

const Header = ({
  backgroundColor = "#F3E9E1",
  button,
  headerImageUrl,
  text,
  title,
}: headerProps) => {
  const smallScreen = useMediaQuery("(max-width: 400px)");

  return (
    <WaveBox background={backgroundColor} fillBackground>
      <StyledBox className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.textContent}>
            <Typography
              className={classes.title}
              variant={smallScreen ? "h2" : "h1"}
            >
              {title}
            </Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>{text}</Typography>
            {button ? (
              <Button
                bloomColor={button.color}
                className={classes.button}
                fullyColored
                href={button.href}
                label={button.label}
                onClick={button.onClick}
                size={smallScreen ? "small" : "large"}
              />
            ) : null}
          </Box>
          <Box>
            <img
              alt="Header bloomup"
              className={classes.image}
              src={headerImageUrl}
            />
          </Box>
        </Box>
      </StyledBox>
    </WaveBox>
  );
};

export default Header;
