import { Typography, useTheme, Grid } from "@mui/material";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import { RequestedByType } from "../../../api/__generated__/graphql";
import Avatar from "../../ui/Avatar";
import ButtonWithStatus from "../../ui/buttons/ButtonWithStatus";

interface ProfessionalListItemProps {
  professional: FragmentType<typeof professionalListItemFragment>;
  selectedProfId?: number;
  setSelected: (id: number) => void;
}

const professionalListItemFragment = graphql(`
  fragment ProfessionalListItem on Professional {
    id
    user {
      ...Avatar
      firstName
      lastName
    }
  }
`);

const ProfessionalListItem = ({
  professional,
  selectedProfId,
  setSelected,
}: ProfessionalListItemProps) => {
  const theme = useTheme();

  const extractedProf = getFragmentData(
    professionalListItemFragment,
    professional,
  );

  return (
    <ButtonWithStatus
      isOpen={selectedProfId === extractedProf.id}
      key={extractedProf.id}
      onClick={() => {
        setSelected(extractedProf.id);
      }}
      requestedBy={undefined as unknown as RequestedByType}
      status={undefined}
    >
      <Grid container>
        <Grid item md={1} sm={1} xs={1}>
          <Avatar size={30} user={extractedProf.user} />
        </Grid>
        <Grid item md={11} sm={11} xs={11}>
          <Typography
            sx={{
              fontSize: theme.typography.sizes.xl,
              textAlign: "center",
            }}
          >
            {extractedProf.user.firstName + " " + extractedProf.user.lastName}
          </Typography>
        </Grid>
      </Grid>
    </ButtonWithStatus>
  );
};

export default ProfessionalListItem;
