import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/buttons/Button";

const PREFIX = "ClientSuccess";

const classes = {
  buttonBar: `${PREFIX}-buttonBar`,
  success: `${PREFIX}-success`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.success}`]: {
    padding: theme.spacing(3, 4),
  },

  [`& .${classes.buttonBar}`]: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

export default function ClientSuccess({ handleReset, isInvite, toggleModal }) {
  const { t: translate } = useTranslation(["professional", "common"]);

  return (
    <StyledBox className={classes.success}>
      <Typography align="center" gutterBottom variant="h4">
        {isInvite
          ? translate(
              "professional:add.client.success",
              "Je cliënt is uitgenodigd",
            )
          : translate(
              "professional:add.client.added",
              "Je cliënt is toegevoegd",
            )}
      </Typography>
      <Box className={classes.buttonBar} mt={2}>
        <Button
          label={translate("common:close", "Sluit")}
          onClick={toggleModal}
          variant="text"
        />
        <Button
          label={translate(
            "professional:add.client.another",
            "Voeg nog een client toe",
          )}
          onClick={handleReset}
          style={{ marginLeft: 32 }}
        />
      </Box>
    </StyledBox>
  );
}
