import { useMutation } from "@apollo/client";
import { useMemo } from "react";
import { APIClient } from "../clients";
import { MUTATION_CLIENT_INVITE } from "../mutations";

export default function useInviteClientMutation() {
  const [inviteClientFunction, { data, error, loading }] = useMutation<
    APIClient.Invite.Result,
    APIClient.Invite.APIInput
  >(MUTATION_CLIENT_INVITE);

  const inviteClient = async (input: APIClient.Invite.Input) =>
    inviteClientFunction({ variables: { input } });

  const getClient = useMemo(() => {
    if (!data) return undefined;

    return data.inviteClient;
  }, [data]);

  return {
    error,
    inviteClient,
    loading,
    newClientWasCreated: getClient,
  };
}
