import { Navigate, RouteProps, useLocation } from "react-router";

type IProtectedRoute = {
  accessCondition: boolean;
  redirectPath: string;
} & RouteProps;

export default function ProtectedRoute({
  accessCondition,
  children,
  redirectPath,
}: IProtectedRoute) {
  const { pathname, search } = useLocation();

  return accessCondition ? (
    <>{children}</>
  ) : (
    <Navigate
      state={{
        originalRequest: pathname,
        search: search,
      }}
      to={redirectPath}
    />
  );
}
