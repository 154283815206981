import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../ui/buttons/Button";

interface IErrorPage {
  onReportFeedback?: () => unknown;
}

const ErrorPage = ({ onReportFeedback }: IErrorPage) => {
  return (
    <Box p={3}>
      <Typography variant="h4">Something went wrong!</Typography>
      <div>Error</div>
      {onReportFeedback ? (
        <Button
          label="Report feedback"
          onClick={onReportFeedback}
          variant="contained"
        />
      ) : null}
    </Box>
  );
};

export default ErrorPage;
