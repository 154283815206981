import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import BloomUpLogo from "../illustrations/BloomUpLogo";
import { colors } from "../providers/theme/ThemeProvider";

const PREFIX = "Navigation";

const classes = {
  logo: `${PREFIX}-logo`,
  navigation: `${PREFIX}-navigation`,
};

const Root = styled("nav")(({ theme }) => ({
  [`&.${classes.navigation}`]: {
    padding: "14px 0",
    textAlign: "right",
    width: "100%",

    [theme.breakpoints.down("xl")]: {
      paddingBottom: theme.spacing(1),
    },
  },

  [`& .${classes.logo}`]: {
    display: "block",
    marginLeft: "auto",
    marginRight: 50,
  },
}));

export default function Navigation() {
  return (
    <Root className={classes.navigation}>
      <a href="https://bloomup.org">
        <Box className={classes.logo}>
          <BloomUpLogo fill={colors.bloom.main} height="45" width="110" />
        </Box>
      </a>
    </Root>
  );
}
