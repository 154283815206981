import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { IInputBaseProps as IBaseProps } from "./FormField";
import TextField from "./TextField";

const PREFIX = "PasswordField";

const classes = {
  eyecon: `${PREFIX}-eyecon`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    position: "relative",
  },

  [`& .${classes.eyecon}`]: {
    color: theme.palette.black.main,
    cursor: "pointer",
    position: "absolute",
    right: "15px",
    top: "13px",
  },
}));

interface IPasswordField extends IBaseProps {
  onChange: () => void;
  onVisibilityChanged?: () => void;
}

export default function PasswordField(props: IPasswordField) {
  const [showInput, setShowInput] = useState(false);

  const visibleStateChanged = () => {
    setShowInput(!showInput);

    if (props.onVisibilityChanged) props.onVisibilityChanged();
  };

  return (
    <Root className={classes.wrapper}>
      <TextField
        {...props}
        fullWidth={true}
        required={true}
        type={showInput ? "text" : "password"}
        variant="outlined"
      />
      <div className={classes.eyecon} onClick={visibleStateChanged}>
        {showInput ? (
          <Visibility fontSize="medium" />
        ) : (
          <VisibilityOff fontSize="medium" />
        )}
      </div>
    </Root>
  );
}
