import { FormHelperText } from "@mui/material";
import Select from "react-select";
import { RootStyle, classes } from "./SharedStyles";
import { MenuOption } from "./custom-render-select/MenuOption";
import TranslatedMultiValueLabel from "./custom-render-select/TranslatedMultiValueLabel";

export function isSelectObject(
  possibleSelectObject: ISelectObject | any,
): possibleSelectObject is ISelectObject {
  return possibleSelectObject.value && possibleSelectObject.label;
}

export interface ISelectObject<T = any> {
  color?: string;
  label: string;
  toolTip?: string;
  value: T;
}

export interface ISelectProps<T = any> {
  closeMenuOnSelect?: boolean;
  errorText?: string;
  hasError?: boolean;
  isSearchable?: boolean;
  menuWithTooltip?: boolean;
  name?: string;
  options: Array<ISelectObject<T>>;
  placeholder?: string | null;
}

interface IBaseSelect<T = any> extends ISelectProps<T> {
  isMulti: boolean;
  onChange: (value: any) => void;
  value?: any | null;
}

export default function BaseSimpleSelect<T>({
  closeMenuOnSelect = true,
  errorText,
  hasError = false,
  isMulti = true,
  isSearchable,
  name,
  onChange,
  options,
  placeholder,
  value = null,
}: IBaseSelect<T>) {
  return (
    <RootStyle>
      <Select
        className={classes.root}
        classNamePrefix="select"
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          MultiValueLabel: TranslatedMultiValueLabel,
          Option: MenuOption,
        }}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
      {hasError ? (
        <FormHelperText className={classes.formHelper}>
          {errorText}
        </FormHelperText>
      ) : null}
    </RootStyle>
  );
}
