import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { APIAuthentication } from "../../../../api/auth/auth";
import useCompleteHumanRegistrationMutation from "../../../../api/humans/hooks/useCompleteHumanRegistrationMutation";
import useRegisterHumanWithInviteMutation from "../../../../api/humans/hooks/useRegisterHumanWithInviteMutation";
import { APIHuman } from "../../../../api/humans/humans";
import { getGenericErrorMessage } from "../../../../constants";
import Email from "../../../../data-model/types/profile/Email";
import FirstName from "../../../../data-model/types/profile/FirstName";
import LastName from "../../../../data-model/types/profile/LastName";
import Password from "../../../../data-model/types/profile/Password";
import Phone from "../../../../data-model/types/profile/Phone";
import Timezone from "../../../../data-model/types/profile/Timezone";
import { parseGraphQLErrors } from "../../../../utils/graphql";
import { BloomUpNamespaces } from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import AuthContext from "../../../providers/auth/AuthContext";
import { IAuthContext } from "../../../providers/auth/auth";
import ToastContext from "../../../providers/toast/ToastContext";
import { IToastContext } from "../../../providers/toast/toast";
import { RouteNames } from "../../../routes/routeNames";
import useRegisterFormState from "../useRegisterFormState";
import RegistrationContext from "./RegistrationContext";
import {
  INFO_STEP,
  PASSWORD_STEP,
  SUCCESS_STEP,
  TOTAL_STEPS,
} from "./registrationSteps";

const RegistrationContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const {
    currentUser,
    internalAppLogin: login,
    //refetchMe,
    updateCurrentHuman,
  } = useContext<IAuthContext>(AuthContext);

  const { t: translate } = useTranslation<BloomUpNamespaces>("errors");

  const formState = useRegisterFormState({
    email: currentUser ? (currentUser.getEmail().getValue() ?? "") : "",
    firstName: currentUser ? (currentUser.getFirstName().getValue() ?? "") : "",
    lastName: currentUser ? (currentUser.getLastName().getValue() ?? "") : "",
    phone: currentUser ? (currentUser.getPhone().getValue() ?? "") : "",
    timezone: new Timezone().updateToCurrent(),
  });

  const { setToast } = useContext<IToastContext>(ToastContext);

  const { loading: registrationLoading, registerHumanWithInvite } =
    useRegisterHumanWithInviteMutation();

  const { completeHumanRegistration } = useCompleteHumanRegistrationMutation();

  const [step, setStep] = useState<number>(1);

  const logUserIn = (tokens: APIAuthentication.Result) => {
    if (tokens && tokens.accessToken && tokens.refreshToken)
      login(tokens.accessToken, tokens.refreshToken);
    else console.error("Cannot login without access & refresh tokens!");
  };

  const handleWithInvite = async () => {
    const input = {
      email: formState.getValue(Email.getPath()),
      firstName: formState.getValue(FirstName.getPath()),
      lastName: formState.getValue(LastName.getPath()),
      onboardedAt: moment().toDate(),
      password: formState.getValue(Password.getPath()),
      phone: formState.getValue(Phone.getPath()),
    };

    const { data } = await registerHumanWithInvite(
      input,
      formState.getValue("inviteToken"),
    );

    if (data) {
      const { accessToken, refreshToken } = data.registerHumanWithInvite;

      logUserIn({ accessToken, refreshToken });
    }
  };

  const handleCompleteRegistration = async () => {
    const input: APIHuman.Register.Input = {
      email: formState.getValue(Email.getPath()),
      firstName: formState.getValue(FirstName.getPath()),
      lastName: formState.getValue(LastName.getPath()),
      phone: formState.getValue(Phone.getPath()),
    };

    const result = await completeHumanRegistration(input);

    if (result && result.data)
      updateCurrentHuman(result.data.completeHumanRegistration);

    return navigate(RouteNames.Login.path);
  };

  const handleSubmit = async () => {
    try {
      const isValid =
        formState.validate(Email.getPath()) &&
        formState.validate(FirstName.getPath()) &&
        formState.validate(LastName.getPath());

      if (isValid) {
        const withInvite = formState.getValue("inviteToken");

        if (withInvite && withInvite !== "") {
          await handleWithInvite();
        } else {
          await handleCompleteRegistration();
        }
      }
    } catch (error) {
      console.error("[RegistrationContextProvider] Caught error:", error);

      if (error) {
        const graphqlErrors = parseGraphQLErrors(error);

        if (graphqlErrors) formState.updateErrors(graphqlErrors);
      }

      setToast({
        message: getGenericErrorMessage(translate),
        severity: "warning",
      });
    }
  };

  const formIsValid = (path: string) => formState.validate(path);

  const next = async () => {
    switch (step) {
      case INFO_STEP:
        if (
          formIsValid("firstName") &&
          formIsValid("lastName") &&
          formIsValid("email") &&
          formIsValid("phone")
        ) {
          navigate(RouteNames.Register.Human.SetPassword.path);
        }

        break;

      case PASSWORD_STEP:
        try {
          if (formState.validate()) {
            await handleSubmit();
            navigate(RouteNames.Register.Human.Success.path);
          }
        } catch (error) {
          console.error("Error:", error);
        }
        break;

      case SUCCESS_STEP:
        //logUserIn();
        return navigate(RouteNames.Login.path);

      default:
        throw new Error("Registration step not implemented!");
    }
  };

  const previous = () => {
    switch (step) {
      case INFO_STEP:
        navigate(RouteNames.Login.path);
        break;

      case PASSWORD_STEP:
        navigate(RouteNames.Register.Human.Start.path);
        break;

      case SUCCESS_STEP:
        navigate(RouteNames.Register.Human.SetPassword.path);
        break;

      default:
        throw new Error("Registration step not implemented!");
    }
  };

  if (!formState || registrationLoading) return <LoadingPage full />;

  return (
    <RegistrationContext.Provider
      value={{
        formState,
        handleSubmit,
        isSubmitting: registrationLoading,
        next,
        previous,
        setStep,
        step,
        totalSteps: TOTAL_STEPS,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextProvider;
