/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo, useState } from "react";
import DataType from "../../../data-model/DataType";
import { IFormState } from "../../hooks/useFormState";
import TextField from "../form/TextField";
import ValueWithLabel from "../text/ValueWithLabel";
import InlineEditableControls from "./common/InlineEditableControls";

interface IEFProps<T extends DataType<any, any>> {
  dataObject: T;
  formState: IFormState;
  label: string;
  large?: boolean;
  multiline?: boolean;
  onSave: () => void;
  pathOverride?: string;
  placeholder?: string | null;
}

export default function InlineEditableField<T extends DataType<any, any>>({
  dataObject,
  formState,
  label,
  large = false,
  multiline = false,
  onSave,
  pathOverride,
  placeholder,
}: IEFProps<T>) {
  const [localValue, setLocalValue] = useState(dataObject.getValue());
  const path: string = pathOverride ?? dataObject.getType().getPath();
  const CHAR_LIMIT = 300;

  const initialValue = useMemo(() => dataObject.getValue(), []);

  const formStateInputProps = formState.getInputProps(path);

  return (
    <InlineEditableControls
      edit={
        <TextField
          autoFocus
          inputProps={{ maxLength: CHAR_LIMIT }}
          multiline={multiline}
          placeholder={placeholder ?? undefined}
          rows={multiline ? 4 : 1}
          {...formStateInputProps}
          onChange={(newValue) => {
            formStateInputProps.onChange(newValue);
            dataObject.setValue(newValue);
            setLocalValue(newValue);
          }}
          value={localValue}
        />
      }
      editable={true}
      key={path}
      label={label || "-"}
      onDiscard={() => {
        if (initialValue) {
          dataObject.setValue(initialValue);
          formState.setValue(path, initialValue);
        }

        return true;
      }}
      onSave={async () => {
        if (formState.validate(path)) {
          // This is a hack.
          // This works because it is instant, and the setState one below it is a async method that takes time to dispatch.
          formState.values[path] = localValue;
          // Keep this, because this triggers rerenders, the previous one doesn't
          formState.setValue(path, localValue);
          await onSave();

          return true;
        } else {
          return false;
        }
      }}
      read={
        <ValueWithLabel label="" large={large}>
          {dataObject.getValue()}
        </ValueWithLabel>
      }
    />
  );
}
