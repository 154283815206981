import { Box } from "@mui/material";
import { motion } from "motion/react";
import { PropsWithChildren } from "react";
import Timer from "./Timer";

interface SidePanelContainerProps extends PropsWithChildren {
  amountOfAttendees: number;
  collapsedInfo?: string;
  isOpen: boolean;
  isProfessional: boolean;
}

export default function SidePanelContainer({
  amountOfAttendees,
  children,
  isOpen,
  isProfessional,
}: SidePanelContainerProps) {
  return (
    <>
      <Box
        animate={isOpen ? "open" : "closed"}
        component={motion.div}
        initial={isOpen ? "open" : "closed"}
        sx={(theme) => ({
          "--closed-left-value": "-350px",
          "--open-left-value": "30px",
          background: theme.palette.whiteSmoke.main,
          borderRadius: "20px",
          color: theme.palette.bloom.main,
          display: "flex",
          height: "80%",
          left: "30px",
          position: "absolute",
          top: "30px",
          width: "350px",
          zIndex: 8,
          [theme.breakpoints.down(500)]: {
            "--closed-left-value": "-100%",
            "--open-left-value": "0.1px", // for some reason, 0px doesn't work
            borderRadius: 0,
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          },
        })}
        transition={{ duration: 0.5 }}
        variants={{
          closed: { left: "var(--closed-left-value)" },
          open: { left: "var(--open-left-value)" },
        }}
      >
        {children}
      </Box>
      {isProfessional ? (
        <Timer amountOfAttendees={amountOfAttendees} isVisible={!isOpen} />
      ) : null}
    </>
  );
}
