import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import BloomUpSwitch from "../../ui/BloomUpSwitch";
import PopUpMenu from "../../ui/PopUpMenu";
import Button from "../../ui/buttons/Button";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import { IProfessionalAvailabilityContext } from "./context/ProfessionalAvailability";
import ProfessionalAvailabilityContext from "./context/ProfessionalAvailabilityContext";

interface HeaderToolBarFullCalendar {
  handleAgendaButton: () => void;
  handleDisplayOptionButton: () => void;
  handleNextButton: () => void;
  handlePreviousButton: () => void;
  headerTitle: string;
}

export enum OnlineDurationOptions {
  thirtyMinutes = 30,
  fourtyFiveMinutes = 45,
  oneHour = 60,
  twoHours = 120,
  threeHours = 180,
  shortest = thirtyMinutes,
}

const HeaderToolBarFullCalendar = ({
  handleNextButton,
  handlePreviousButton,
  headerTitle,
}: HeaderToolBarFullCalendar) => {
  const { t } = useTranslation();

  const {
    formState,
    onlineUntil,
    popUpAnchorEl,
    popUpMenuOnClose,
    popUpMenuOnSelectItem,
    updateIsAcceptingNewClients,
    updateIsOnline,
  } = useContext<IProfessionalAvailabilityContext>(
    ProfessionalAvailabilityContext,
  );

  const getDate = (onlineDurationOptions: OnlineDurationOptions): Date => {
    return moment().add(onlineDurationOptions, "minutes").toDate();
  };

  const options: Array<ISelectObject<Date>> = [
    {
      label: "30 minuten",
      value: getDate(OnlineDurationOptions.thirtyMinutes),
    },
    {
      label: "45 minuten",
      value: getDate(OnlineDurationOptions.fourtyFiveMinutes),
    },
    {
      label: "1 hour",
      value: getDate(OnlineDurationOptions.oneHour),
    },
    {
      label: "2 hours",
      value: getDate(OnlineDurationOptions.twoHours),
    },
    {
      label: "3 hours",
      value: getDate(OnlineDurationOptions.threeHours),
    },
  ];

  return (
    <Box marginBottom={"20px"} width={"100%"}>
      <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 1,
          }}
        >
          <Box marginRight={"15px"}>
            <Button
              circular={true}
              icon={<KeyboardArrowLeftIcon />}
              noLabel={true}
              onClick={handlePreviousButton}
            />
          </Box>
          <Box marginRight={"15px"}>
            <Button
              circular={true}
              icon={<KeyboardArrowRightIcon />}
              noLabel={true}
              onClick={handleNextButton}
            />
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            p: 1,
          }}
        >
          <Box>
            <Typography variant="h3">{headerTitle}</Typography>
          </Box>
        </Box>
        <Box
          marginRight={"5px"}
          sx={{
            alignItems: "center",
            display: "flex",
            flexShrink: 1,
            p: 1,
          }}
        >
          <BloomUpSwitch
            {...formState.getInputProps("isAcceptingNewClients")}
            label={t("professional:availability.new.clients.switch.label")}
            onChange={(value) => {
              formState.getInputProps("isAcceptingNewClients").onChange(value);

              updateIsAcceptingNewClients(value);
            }}
            toolTip={t("professional:availability.new.clients.switch.tooltip")}
          />
          <BloomUpSwitch
            {...formState.getInputProps("isOnline")}
            label={t("professional:availability.is.online.switch.label", {
              context: onlineUntil ? "online" : "offline",
              defaultValue: "Online tot {{onlineUntil}}",
              onlineUntil: moment(onlineUntil).format("HH:MM"),
            })}
            onChange={(value, onClick) => {
              formState.getInputProps("isOnline").onChange(value);

              updateIsOnline(value, onClick);
            }}
            toolTip={t("professional:availability.is.online.switch.tooltip")}
          />
          <PopUpMenu
            anchorEl={popUpAnchorEl}
            onClose={popUpMenuOnClose}
            options={options}
            {...formState.getInputProps("isOnlineOption")}
            handleMenuItemClick={popUpMenuOnSelectItem}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderToolBarFullCalendar;
