import { useMutation } from "@apollo/client";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../../api/__generated__";
import Therapies from "../../../../data-model/types/professional/Therapies";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import CheckBox from "../../../ui/form/CheckBox";
import Label from "../../../ui/form/FormLabel";
import MultiSelect from "../../../ui/form/select/MultiSelect";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";

/**
 * Shows the therapy step in during the Onboarding
 * of a psychologist. It asks which forms of therapy the
 * psy can work with and if they want to do coaching.
 */

const togglecoachingOnboardingMutation = graphql(`
  mutation ToggleCoachingOnboarding($input: Boolean!) {
    toggleCoachingEnabledForProfessional(input: $input) {
      coachingEnabled
      id
    }
  }
`);

export default function TherapyStep() {
  const { t } = useTranslation();

  const { formState, next, previous, step, totalSteps } = useContext(
    ProfessionalOnboardingContext,
  );

  const [toggleCoaching] = useMutation(togglecoachingOnboardingMutation);
  const [coachingEnabled, setCoachingEnabled] = useState(true);

  const error = formState.testForErrors(Therapies.getPath());

  const selectDefaultValue = () => {
    const selectedItems = new Therapies();

    selectedItems.setValue(formState.getValue(Therapies.getPath()));

    return selectedItems.getSelectedOptions();
  };

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={t("professional:onboarding.title")}
        labelBack={t("common:onboarding.button.label.previous")}
        labelNext={t("common:onboarding.button.label.next")}
        onClickBack={previous}
        onClickNext={() => {
          next();
          toggleCoaching({ variables: { input: coachingEnabled } });
        }}
        showLanguageSelector={false}
        step={step}
        subhead={t("professional:onboarding.therapytype.title")}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Label
              label={t("professional:onboarding.therapytype.description")}
            />
            <MultiSelect
              closeMenuOnSelect
              options={new Therapies().getSelectOptions()}
              placeholder={t("professional:onboarding.therapytype.placeholder")}
              value={selectDefaultValue()}
              {...formState.getInputProps(Therapies.getPath())}
              selectTestId={`test_onboarding_professional_therapy_select`}
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              marginX: "auto",
              mt: 2,
            }}
          >
            <FormControlLabel
              control={
                <CheckBox
                  checked={coachingEnabled}
                  onChange={(e) => {
                    setCoachingEnabled(e.target.checked);
                  }}
                />
              }
              label={
                <Typography>
                  {t("professional:onboarding.coachingToggle.label")}
                </Typography>
              }
              sx={{
                "& .MuiFormControlLabel-label": { mt: 0 },
                alignItems: "center",
                m: 0,
              }}
            />
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
