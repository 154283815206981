import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import thumb from "./AgeRangeThumb.svg";

const PREFIX = "AgeRangeSlider";

const classes = {
  rail: `${PREFIX}-rail`,
  root: `${PREFIX}-root`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  valueLabel: `${PREFIX}-valueLabel`,
};

type CustomThumbComponentProps = React.HTMLAttributes<unknown>;

function CustomThumbComponent(props: CustomThumbComponentProps) {
  const { children, ...other } = props;

  return (
    <SliderThumb {...other}>
      {children}
      <img alt="leaf" src={thumb} />
    </SliderThumb>
  );
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.primary.main,
    // marginBottom: theme.spacing(8),
    height: 8,
  },

  [`& .${classes.thumb}`]: {
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    height: 24,
    width: 24,
  },

  [`& .${classes.track}`]: {
    borderRadius: 4,
    height: 8,
  },

  [`& .${classes.rail}`]: {
    borderRadius: 4,
    height: 8,
  },

  [`& .${classes.valueLabel}`]: {
    left: "calc(-50% + 4px)",
  },
}));

interface IAgeRangeSlider {
  customThumb?: boolean;
  defaultValue: number;
  min: number;
  name: string;
  onChange: (value: number) => void;
  value: number;
}

export default function AgeRangeSlider({
  customThumb,
  defaultValue,
  min,
  name,
  onChange,
  value,
}: IAgeRangeSlider) {
  const handleChange = (_, value) => onChange(value);

  return (
    <StyledSlider
      classes={{
        rail: classes.rail,
        root: classes.root,
        thumb: classes.thumb,
        track: classes.track,
        valueLabel: classes.valueLabel,
      }}
      defaultValue={defaultValue}
      getAriaLabel={(i) => `${i} jaar`}
      min={min}
      name={name}
      onChange={handleChange}
      slots={customThumb ? { thumb: CustomThumbComponent } : {}}
      value={value ?? defaultValue}
      valueLabelDisplay={customThumb ? "off" : "auto"}
    />
  );
}
