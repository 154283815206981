/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import { APIHuman } from "../humans";
import { QUERY_FETCH_HUMAN_CURRENT } from "../queries";

interface IHumanResult {
  human: APIHuman.Result;
}

export default function useFetchHuman(currentUser?: Professional | Human) {
  const { data, error, loading, updateQuery } = useQuery<IHumanResult>(
    QUERY_FETCH_HUMAN_CURRENT,
    {
      fetchPolicy: "network-only",
    },
  );

  const parsedData = useMemo(() => {
    if (!loading && data)
      return new Human(
        data.human,
        true,
        currentUser?.getRole() === "ORGANIZATION_ADMIN",
      );
  }, [data, loading]);

  return {
    error,
    human: parsedData,
    loading,
    updateQuery,
  };
}
