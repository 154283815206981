import { Navigate, useSearchParams } from "react-router";

const RedirectFromOldMatching = () => {
  const [searchParams] = useSearchParams();

  const lngParam = searchParams.get("lng");
  const newLngParam =
    lngParam === "nlBe"
      ? "nl-BE"
      : lngParam === "frBe"
        ? "fr-BE"
        : lngParam === "en"
          ? "en"
          : undefined;

  return <Navigate to={`/match${newLngParam ? "?lng=" + newLngParam : ""}`} />;
};

export default RedirectFromOldMatching;
