import { ApolloError, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { APIProfessional } from "../professionals";
import { QUERY_PROFESSIONAL_EXISTS } from "../queries";

interface IEmailQueryResponse {
  error?: ApolloError | undefined;
  execute: (email: string) => void;
  isFetching: boolean;
  response: APIProfessional.Exists.Result | undefined;
}

//TODO: we can make this lazy query generic, so that it is reusable without having to recreate
// all these interfaces everytime
export default function useProfessionalExiststLazyQuery(): IEmailQueryResponse {
  const [checkEmailExists, { data, error, loading }] = useLazyQuery<
    APIProfessional.Exists.Result,
    APIProfessional.Exists.Input
  >(QUERY_PROFESSIONAL_EXISTS, {
    fetchPolicy: "network-only",
  });

  const execute = useCallback(
    (email: string) => {
      checkEmailExists({
        variables: {
          email: email.toLowerCase(),
        },
      });
    },
    [checkEmailExists],
  );

  return {
    error,
    execute,
    isFetching: loading,
    response: data,
  };
}
