import { useMutation } from "@apollo/client";
import { IMutationInput } from "../../api";
import { MUTATION_CREATE_PAYMENT } from "../mutations";

interface ICreatePaymentResult {
  payForConsultation: string;
}

interface ICreatePaymentInput {
  cashTransactionId: number;
}

export default function usePayForConsultation() {
  const [execute, { data, error, loading }] = useMutation<
    ICreatePaymentResult,
    IMutationInput<ICreatePaymentInput>
  >(MUTATION_CREATE_PAYMENT);

  function payForConsultation(cashTransactionId: number) {
    return execute({
      variables: { input: { cashTransactionId } },
    });
  }

  return {
    error,
    loading,
    payForConsultation,
    result: data?.payForConsultation,
  };
}
