import { PropsWithChildren } from "react";
import { useStopwatch } from "react-timer-hook";
import TimerContext from "./TimerContext";

const TimerProvider = ({ children }: PropsWithChildren) => {
  const { hours, isRunning, minutes, pause, reset, seconds, start } =
    useStopwatch();

  return (
    <TimerContext.Provider
      value={{
        isTimerRunning: isRunning,
        pauseTimer: pause,
        resetTimer: reset,
        startTimer: start,
        timerValue: `${hours.toString(10).padStart(2, "0")}:${minutes.toString(10).padStart(2, "0")}:${seconds.toString(10).padStart(2, "0")}`,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
