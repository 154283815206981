import { Grid } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import CheckBox from "../../ui/form/CheckBox";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerWithOptionsProps } from "./answers";

export default function AnswerCheckboxes({
  onAnswerChanged,
  options,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.Checkbox>) {
  const [selected, setSelected] = useState<{ [x: string]: boolean }>({});

  const onChange = useCallback(({ target }) => {
    setSelected((old) => ({
      ...old,
      [target.name]: target.checked,
    }));
  }, []);

  useMemo(() => {
    const answers = Object.entries(selected)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value === true)
      .map(([key]) => key);

    onAnswerChanged(answers);
  }, [selected, onAnswerChanged]);

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "80%",
        }}
      >
        {options.map((option: QualityQuestions.Options.Option, i: number) => (
          <Grid item md={4} sm={6} xs={12}>
            <div style={{ textAlign: "left" }}>
              <CheckBox
                key={i}
                label={option.label}
                name={option.label}
                onChange={onChange}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
