/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import useLazyFetchConsultationByUuid from "../../../api/consultations/hooks/useLazyFetchConsultationByUuid";
import usePayForConsultation from "../../../api/payments/hooks/usePayForConsultation";
import CashTransaction from "../../../data-model/types/CashTransaction";
import PaymentContext from "./PaymentContext";

export default function PaymentContextProvider({ children }) {
  const { consultation, fetchConsultation } = useLazyFetchConsultationByUuid();

  const [checked, setChecked] = useState<boolean>(false);

  const [paymentStarted, setPaymentStarted] = useState<boolean>(false);

  const {
    loading: paymentOngoing,
    payForConsultation,
    result,
  } = usePayForConsultation();

  useEffect(() => {
    if (!paymentOngoing && result) {
      window.location.replace(result);
    }
  }, [paymentOngoing, result]);

  useEffect(() => {
    if (!consultation) return;

    const cashTransaction: CashTransaction | undefined =
      consultation.getCashTransaction();

    if (cashTransaction && !paymentOngoing && !paymentStarted) {
      setPaymentStarted(true);
      payForConsultation(cashTransaction.getID());
    }
  }, [consultation, paymentOngoing]);

  const pay = async (uuid: string) => {
    fetchConsultation(uuid);
  };

  // if (/*|| loading*/)
  //     return <LoadingPage full={false} />;

  return (
    <PaymentContext.Provider
      value={{
        checked,
        pay,
        setChecked,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}
