import CssBaseline from "@mui/material/CssBaseline";
import Ably from "ably";
import { AblyProvider } from "ably/react";
import { useContext, useMemo } from "react";
import environment from "../environment/environment";
import AppRouter from "./AppRouter";
import LoadingPage from "./layout/LoadingPage";
import AuthContext from "./providers/auth/AuthContext";
import { IAuthContext } from "./providers/auth/auth";
import FullAppToast from "./ui/alerts/Toast";

const App = () => {
  const { accessToken, currentUser, loading } =
    useContext<IAuthContext>(AuthContext);

  const ablyClient = useMemo(() => {
    const userUuid = currentUser ? currentUser.getUUID() : null;

    return new Ably.Realtime({
      authHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
      authUrl: `${environment.REACT_APP_SERVER_URL}/ably_auth`,
      ...(userUuid ? { clientId: userUuid } : {}),
    });
  }, [accessToken, currentUser]);

  return (
    <>
      <CssBaseline />
      {loading ? (
        <LoadingPage full logText="App Loader (loading from AuthContext)" />
      ) : null}
      <FullAppToast />
      <AblyProvider client={ablyClient}>
        <AppRouter />
      </AblyProvider>
    </>
  );
};

export default App;
