import AnnouncementIcon from "@mui/icons-material/Announcement";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonIcon from "@mui/icons-material/Person";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import {
  ConsultationStatus,
  RequestedByType,
} from "../../api/__generated__/graphql";
import { HumanStatus } from "../../data-model/types/human/Human";
import { I18Namespaces } from "../language/I18Namespaces";

interface IStatusIcon {
  isProfessional: boolean;
  requestedBy: RequestedByType;
  status: ConsultationStatus | HumanStatus | undefined;
}

const StatusIcon = ({ isProfessional, requestedBy, status }: IStatusIcon) => {
  const { t: translate } = useTranslation<I18Namespaces>("common");

  let title: string;
  let icon: any;

  switch (status) {
    case "ACCEPTED":
      title = translate("common:status.scheduled", "Ingepland");
      icon = <ScheduleIcon color="primary" />;
      break;

    // TODO: after migration, delete case "REQUESTED_BY_X"
    case "REQUESTED":
      if (requestedBy === "HUMAN") {
        title = isProfessional
          ? translate(
              "common:status.reschedule.requested",
              "Herplanning aangevraagd",
            )
          : translate(
              "common:status.reschedule.awaiting",
              "Wachten op herplanning",
            );
        icon = isProfessional ? (
          <AnnouncementIcon style={{ color: "#e57373" }} />
        ) : (
          <WatchLaterIcon color="secondary" />
        );
      } else {
        title = isProfessional
          ? translate(
              "common:status.confirmation.awaiting",
              "Wachten op bevestiging",
            )
          : translate("common:status.requested", "Aangevraagd");
        icon = isProfessional ? (
          <WatchLaterIcon color="secondary" />
        ) : (
          <NewReleasesIcon style={{ color: "#e57373" }} />
        );
      }

      break;

    case "DONE":
      title = translate("common:status.completed", "Voltooid");
      icon = <CheckIcon style={{ color: "#81c784" }} />;
      break;

    case "CANCELLED_BY_PROFESSIONAL":
      title = translate(
        "common:status.cancelled.therapist",
        "Afgelast door professional",
      );
      icon = <CancelIcon style={{ color: "#e57373" }} />;
      break;

    case "CANCELLED_BY_HUMAN":
      title = translate("common:status.cancelled.user", "Afgelast door cliënt");
      icon = <CancelIcon style={{ color: "#e57373" }} />;
      break;

    case "NO_SHOW_PROFESSIONAL":
      title = translate(
        "common:status.noshow.therapist",
        "Professional niet opgedaagd",
      );
      icon = <HelpIcon style={{ color: "#e57373" }} />;
      break;

    case "NO_SHOW_HUMAN":
      title = translate("common:status.noshow.user", "Cliënt niet opgedaagd");
      icon = <HelpIcon style={{ color: "#e57373" }} />;
      break;

    //TODO: These never show up in the database. Should we use them again?
    case "INVITED":
      title = translate(
        "common:status.invitation.sent",
        "Uitnodiging verstuurd",
      );
      icon = <EmailIcon color="primary" />;
      break;

    case HumanStatus.PENDING:

    // Falls Through
    case HumanStatus.ONLY_INTRO:
      title = translate(
        "common:status.confirmation.awaiting",
        "Wachten op bevestiging",
      );
      icon = isProfessional ? (
        <WatchLaterIcon color="primary" />
      ) : (
        <NewReleasesIcon style={{ color: "#e57373" }} />
      );
      break;

    case HumanStatus.CURRENT:
      title = isProfessional
        ? translate("common:status.current.user", "Huidige cliënt")
        : translate("common:status.current.psy", "Huidige professional");
      icon = <PersonIcon color="primary" />;
      break;

    default:
      return null;
  }

  return (
    <Tooltip placement="right" title={title}>
      {icon}
    </Tooltip>
  );
};

export default StatusIcon;
