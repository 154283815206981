/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";
import { IHumanProfileContext } from "./humanProfile";

const humanProfileDefaultValues: IHumanProfileContext = {
  formState: undefined,
  human: undefined,
  loading: false,
  onSave: () => new Promise(() => {}),
  updatePreferredLanguage: () => new Promise(() => {}),
  updateTimezone: () => new Promise(() => {}),
};

const HumanProfileContext = createContext<IHumanProfileContext>(
  humanProfileDefaultValues,
);

export default HumanProfileContext;
