/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import Human, { HumanStatus } from "../../../data-model/types/human/Human";
import { connectionToCollection } from "../../../utils/graphql";
import IQueryHookReturnType from "../../IQueryHookReturnType";
import { APIHuman } from "../../humans/humans";
import { APIClients, APIClient } from "../clients";
import { QUERY_CLIENTS_FOR_PROFESSIONAL } from "../queries";

export default function useFetchClientsForProfessional(/* searchTerm*/): IQueryHookReturnType<Human> {
  const { data, error, loading, refetch } =
    useQuery<APIClients.ForProfessional.Result>(QUERY_CLIENTS_FOR_PROFESSIONAL);

  function isClient(
    toBeDetermined: APIClient.Result | APIClient.RestrictedResult | any,
  ): toBeDetermined is APIClient.Result {
    if (toBeDetermined.human) return true;

    return false;
  }

  const extract = (dataObjects) => connectionToCollection(dataObjects);
  const createHumans = useCallback(
    (clients: Array<APIClient.Result | APIClient.RestrictedResult>) => {
      return clients.map((c: APIClient.Result | APIClient.RestrictedResult) => {
        let human:
          | APIHuman.Result
          | Pick<APIHuman.Result, "createdAt" | "email" | "id">;

        if (isClient(c)) {
          human = c.human;
        } else {
          human = {
            createdAt: c.createdAt,
            email: c.email,
            id: c.humanId,
          };
        }

        const humanModel: any = {
          ...human,
          clientID: c.id,
          description: c.description,
          status: HumanStatus[c.status],
        };

        return new Human(humanModel, true);
      });
    },
    [],
  );

  const humanData = useMemo((): Human[] | undefined => {
    if (data) {
      const extracted = extract(data.clientsForProfessional);

      return createHumans(extracted);
    }
  }, [data]);

  return {
    data: humanData,
    error,
    loading,
    refetch,
  };
}
