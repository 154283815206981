import { colors } from "../../providers/theme/ThemeProvider";

const BloomUpLogoWithTag = ({
  fill = colors.black.main,
  height = "59px",
  width = "200px",
}) => (
  <svg
    fill={fill}
    height={height}
    viewBox="0 0 200 59"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5231 9.22586C10.6401 9.22586 8.14122 10.3311 6.21923 12.5415V0H0.0691528V33.8277H6.21923V31.2802C8.14122 33.4907 10.6401 34.5959 13.5231 34.5959C20.1058 34.5959 25.4392 28.9256 25.4392 21.9101C25.4392 14.8947 20.1058 9.22586 13.5231 9.22586ZM12.7534 28.4458C9.14932 28.4458 6.21923 25.5142 6.21923 21.9101C6.21923 18.306 9.15079 15.3759 12.7534 15.3759C16.3575 15.3759 19.2876 18.3075 19.2876 21.9101C19.2876 25.5128 16.3575 28.4458 12.7534 28.4458Z"
      fill="#2E2E2E"
    />
    <path d="M35.8144 0H29.6643V33.8276H35.8144V0Z" fill="#2E2E2E" />
    <path
      d="M52.3441 9.22586C45.3287 9.22586 39.6584 14.8962 39.6584 21.9116C39.6584 28.927 45.3287 34.5973 52.3441 34.5973C59.3595 34.5973 65.0299 28.927 65.0299 21.9116C65.0299 14.8962 59.3595 9.22586 52.3441 9.22586ZM52.3441 28.4458C48.74 28.4458 45.8099 25.5142 45.8099 21.9101C45.8099 18.306 48.7415 15.3759 52.3441 15.3759C55.9482 15.3759 58.8783 18.3075 58.8783 21.9101C58.8783 25.5128 55.9482 28.4458 52.3441 28.4458Z"
      fill="#2E2E2E"
    />
    <path
      d="M80.7869 9.22586C73.7715 9.22586 68.1012 14.8962 68.1012 21.9116C68.1012 28.927 73.7715 34.5973 80.7869 34.5973C87.8024 34.5973 93.4727 28.927 93.4727 21.9116C93.4727 14.8962 87.8024 9.22586 80.7869 9.22586ZM80.7869 28.4458C77.1828 28.4458 74.2513 25.5142 74.2513 21.9101C74.2513 18.306 77.1828 15.3759 80.7869 15.3759C84.391 15.3759 87.3211 18.3075 87.3211 21.9101C87.3211 25.5128 84.391 28.4458 80.7869 28.4458Z"
      fill="#2E2E2E"
    />
    <path
      d="M132.679 33.8262V19.9881C132.679 12.7814 128.931 9.2244 123.453 9.2244C120.186 9.2244 118.072 10.4738 116.294 12.4444C114.852 10.2825 112.643 9.2244 109.614 9.2244C107.164 9.2244 104.953 10.4253 103.464 12.3002V9.99407H97.3137V33.8262H103.464V21.526C103.464 17.2023 105.29 15.3759 108.077 15.3759C110.816 15.3759 111.921 17.0581 111.921 20.7578V33.8277H118.072V21.5275C118.072 17.2038 119.898 15.3774 122.684 15.3774C125.615 15.3774 126.528 17.0595 126.528 20.7593V33.8291H132.679V33.8262Z"
      fill="#2E2E2E"
    />
    <path
      d="M145.842 34.5959C149.446 34.5959 151.415 33.4421 153.049 31.5201V33.8262H159.199V9.99407H153.049V22.2942C153.049 26.618 150.454 28.4443 147.667 28.4443C144.495 28.4443 143.055 26.7622 143.055 23.0624V9.9926H136.904V23.8307C136.904 31.0403 140.364 34.5959 145.842 34.5959Z"
      fill="#2E2E2E"
    />
    <path
      d="M171.113 31.2802C173.035 33.4907 175.534 34.5959 178.417 34.5959C185 34.5959 190.333 28.9256 190.333 21.9101C190.333 14.8947 184.998 9.2244 178.417 9.2244C175.534 9.2244 173.035 10.3296 171.113 12.54V9.99407H164.963V46.1278H171.113V31.2802ZM177.647 15.3759C181.252 15.3759 184.183 18.3075 184.183 21.9101C184.183 25.5128 181.252 28.4458 177.647 28.4458C174.043 28.4458 171.113 25.5142 171.113 21.9101C171.113 18.306 174.043 15.3759 177.647 15.3759Z"
      fill="#2E2E2E"
    />
    <path
      d="M199.376 46.2927C198.543 45.3861 197.159 45.2757 196.184 46.0292C192.368 48.9858 187.505 51.1654 181.664 52.5266C173.603 54.4074 165.718 54.2676 160.522 53.8188C152.572 53.133 144.913 51.2742 138.376 48.4457C136.177 47.495 134.085 46.4207 132.154 45.2537C131.215 44.6856 129.986 44.7916 129.208 45.5642C128.632 46.1352 128.389 46.949 128.565 47.754C128.706 48.4001 129.13 48.949 129.695 49.2904C131.801 50.562 134.091 51.7364 136.5 52.7783C143.499 55.807 151.664 57.7937 160.114 58.5222C162.43 58.7224 164.782 58.8239 167.101 58.8239C172.641 58.8239 177.9 58.2514 182.735 57.1241C189.256 55.6024 194.735 53.1286 199.094 49.7467C200.183 48.9049 200.307 47.3066 199.376 46.2927Z"
      fill="#2E2E2E"
    />
    <path
      d="M7.33477 45.8438L3.27445 55.052H1.84693L3.14347 52.1042L0 45.8438H1.454L3.77187 50.6635L5.89401 45.8438H7.33477Z"
      fill="#2E2E2E"
    />
    <path
      d="M13.9234 48.9343C13.9234 50.821 12.5607 52.169 10.649 52.169C8.73727 52.169 7.37451 50.8195 7.37451 48.9343C7.37451 47.0491 8.73727 45.6996 10.649 45.6996C12.5607 45.6996 13.9234 47.0476 13.9234 48.9343ZM12.5607 48.9343C12.5607 47.7687 11.7615 46.9299 10.649 46.9299C9.53638 46.9299 8.73727 47.7687 8.73727 48.9343C8.73727 50.0866 9.53638 50.9387 10.649 50.9387C11.7615 50.9387 12.5607 50.0866 12.5607 48.9343Z"
      fill="#2E2E2E"
    />
    <path
      d="M20.9432 45.8556V52.0115H19.5937V51.4096C19.0566 51.8938 18.3884 52.169 17.5893 52.169C15.9911 52.169 14.9168 51.0299 14.9168 49.261V45.8556H16.2663V49.183C16.2663 50.3088 16.8815 50.9387 17.8645 50.9387C18.4664 50.9387 19.0963 50.5987 19.5937 49.9689V45.8556H20.9432Z"
      fill="#2E2E2E"
    />
    <path
      d="M26.0911 45.7246V47.0079C25.1081 47.0476 24.3619 47.414 23.8115 48.1337V52.0115H22.4488V45.8556H23.8115V46.6944C24.3752 46.0793 25.1478 45.7246 26.0911 45.7246Z"
      fill="#2E2E2E"
    />
    <path
      d="M40.0925 48.4884V52.0115H38.743V48.4884C38.743 47.4008 38.1661 46.9299 37.289 46.9299C36.6871 46.9299 36.1102 47.1521 35.651 47.7687C35.6908 47.9659 35.704 48.1881 35.704 48.4104V52.013H34.3545V48.4104C34.3545 47.3228 33.7776 46.9299 32.9785 46.9299C32.3369 46.9299 31.76 47.1918 31.3155 47.807V52.0115H29.9528V45.8556H31.3155V46.3795C31.7747 45.9601 32.3236 45.6981 33.136 45.6981C34.0131 45.6981 34.7209 45.9998 35.1786 46.5884C35.7805 45.9983 36.4752 45.6981 37.3788 45.6981C38.9932 45.6996 40.0925 46.6679 40.0925 48.4884Z"
      fill="#2E2E2E"
    />
    <path
      d="M47.3626 49.3404H42.5296C42.6606 50.2705 43.2507 50.9784 44.403 50.9784C45.005 50.9784 45.4641 50.7944 45.7128 50.4015H47.2066C46.8136 51.5155 45.7261 52.1704 44.3898 52.1704C42.4516 52.1704 41.1418 50.7694 41.1418 48.9357C41.1418 47.1153 42.4384 45.7011 44.3118 45.7011C46.353 45.6996 47.6245 47.258 47.3626 49.3404ZM42.5546 48.3971H46.0131C45.9351 47.389 45.2802 46.8519 44.3236 46.8519C43.3008 46.8519 42.7121 47.5332 42.5546 48.3971Z"
      fill="#2E2E2E"
    />
    <path
      d="M54.5001 48.5811V52.013H53.1373V48.6591C53.1373 47.5333 52.5354 46.9299 51.5391 46.9299C50.9504 46.9299 50.3073 47.2581 49.8232 47.86V52.0115H48.4604V45.8556H49.8232V46.406C50.3603 45.9468 51.0285 45.6981 51.8143 45.6981C53.4126 45.6996 54.5001 46.7989 54.5001 48.5811Z"
      fill="#2E2E2E"
    />
    <path
      d="M60.1719 51.9203C59.6347 52.116 59.1888 52.169 58.7709 52.169C57.1991 52.169 56.3603 51.2256 56.3603 49.6407V46.9946H54.9063V45.8556H56.3603V43.5892H57.723V45.8556H60.0027V46.9946H57.723V49.6407C57.723 50.531 58.169 50.9107 58.9283 50.9107C59.2948 50.9107 59.6612 50.8062 59.9762 50.6753L60.1719 51.9203Z"
      fill="#2E2E2E"
    />
    <path
      d="M66.9695 45.8556V52.0115H65.62V51.5274C65.0961 51.9335 64.428 52.169 63.6421 52.169C61.9129 52.169 60.5251 50.7282 60.5251 48.9343C60.5251 47.1403 61.9129 45.6996 63.6421 45.6996C64.428 45.6996 65.0961 45.935 65.62 46.3412V45.857H66.9695V45.8556ZM65.62 50.0483V47.8217C65.1609 47.1668 64.5192 46.9314 63.8393 46.9314C62.7135 46.9314 61.8879 47.835 61.8879 48.9358C61.8879 50.0366 62.7135 50.9402 63.8393 50.9402C64.5192 50.9387 65.1609 50.7032 65.62 50.0483Z"
      fill="#2E2E2E"
    />
    <path d="M69.8378 42.7121V52.0115H68.475V42.7121H69.8378Z" fill="#2E2E2E" />
    <path
      d="M76.9091 51.0564C77.7083 51.0564 78.2587 50.7945 78.2587 50.3103C78.2587 49.9438 77.957 49.6819 77.4331 49.5892L76.0703 49.3537C75.127 49.1962 74.2498 48.7253 74.2498 47.6113C74.2498 46.4192 75.2844 45.6863 76.8164 45.6863C78.0085 45.6863 79.3845 46.0925 79.514 47.704H78.2572C78.1924 47.0358 77.6023 46.7739 76.8164 46.7739C76.0306 46.7739 75.5979 47.0623 75.5979 47.4935C75.5979 47.8217 75.8334 48.1352 76.5545 48.2529L77.7068 48.4369C78.6369 48.6076 79.5537 49.0138 79.5537 50.2058C79.5537 51.5023 78.4147 52.1705 76.8679 52.1705C75.4139 52.1705 74.1704 51.5936 74.0129 49.9703L75.2697 49.9836C75.4169 50.7415 75.9805 51.0564 76.9091 51.0564Z"
      fill="#2E2E2E"
    />
    <path
      d="M80.734 43.5907C80.734 43.1065 81.1137 42.7518 81.5979 42.7518C82.0688 42.7518 82.4617 43.105 82.4617 43.5907C82.4617 44.0748 82.0688 44.4295 81.5979 44.4295C81.1137 44.4281 80.734 44.0748 80.734 43.5907ZM82.2792 45.8556V52.0115H80.9297V45.8556H82.2792Z"
      fill="#2E2E2E"
    />
    <path
      d="M90.0334 42.7121V52.0115H88.6839V51.5141C88.16 51.9335 87.4919 52.169 86.706 52.169C84.9768 52.169 83.589 50.7282 83.589 48.921C83.589 47.1403 84.9768 45.6981 86.706 45.6981C87.4919 45.6981 88.16 45.9336 88.6839 46.3397V42.7121H90.0334ZM88.6854 50.0351V47.8217C88.2262 47.1668 87.5846 46.9313 86.9047 46.9313C85.7788 46.9313 84.9532 47.8349 84.9532 48.9225C84.9532 50.0233 85.7788 50.9401 86.9047 50.9401C87.5846 50.9387 88.2262 50.7032 88.6854 50.0351Z"
      fill="#2E2E2E"
    />
    <path
      d="M97.4079 49.3404H92.575C92.706 50.2705 93.2961 50.9784 94.4484 50.9784C95.0503 50.9784 95.5095 50.7944 95.7582 50.4015H97.2519C96.859 51.5155 95.7714 52.1704 94.4352 52.1704C92.497 52.1704 91.1872 50.7694 91.1872 48.9357C91.1872 47.1153 92.4838 45.7011 94.3572 45.7011C96.3998 45.6996 97.6699 47.258 97.4079 49.3404ZM92.6 48.3971H96.0584C95.9804 47.389 95.3255 46.8519 94.3689 46.8519C93.3476 46.8519 92.7575 47.5332 92.6 48.3971Z"
      fill="#2E2E2E"
    />
    <path
      d="M101.102 49.3802L99.9363 50.559V52.013H98.5735V42.7135H99.9363V48.765L102.831 45.857H104.56L102.032 48.4236L104.953 52.013H103.184L101.102 49.3802Z"
      fill="#2E2E2E"
    />
    <path
      d="M105.66 43.5907C105.66 43.1065 106.039 42.7518 106.523 42.7518C106.994 42.7518 107.389 43.105 107.389 43.5907C107.389 44.0748 106.996 44.4295 106.523 44.4295C106.039 44.4281 105.66 44.0748 105.66 43.5907ZM107.205 45.8556V52.0115H105.855V45.8556H107.205Z"
      fill="#2E2E2E"
    />
    <path
      d="M113.374 49.7731H114.762C114.54 51.1874 113.308 52.1705 111.763 52.1705C109.916 52.1705 108.515 50.7695 108.515 48.9358C108.515 47.1153 109.916 45.701 111.763 45.701C113.308 45.701 114.54 46.6841 114.762 48.0984H113.374C113.203 47.4038 112.588 46.9328 111.763 46.9328C110.623 46.9328 109.876 47.8232 109.876 48.9372C109.876 50.0513 110.622 50.9416 111.763 50.9416C112.588 50.9387 113.203 50.4663 113.374 49.7731Z"
      fill="#2E2E2E"
    />
    <path
      d="M118.429 49.3802L117.264 50.559V52.013H115.901V42.7135H117.264V48.765L120.158 45.857H121.888L119.359 48.4236L122.281 52.013H120.512L118.429 49.3802Z"
      fill="#2E2E2E"
    />
  </svg>
);

export default BloomUpLogoWithTag;
