import shared from "./shared";

export const environment = {
  ...shared,
  MINDLAB_FRONTEND_URL: "https://mindlab.be",
  REACT_APP_ENV: "production",
  REACT_APP_MATOMO_KEY: 5,
  REACT_APP_SENTRY_SAMPLE_RATE: 0.1,
  REACT_APP_SERVER_URL: "https://api.bloomup.org",
  REACT_APP_URL: "https://app.bloomup.org",
};

export default environment;
