import type { TFunction } from "i18next";
import _ from "lodash";
import moment from "moment";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import SelectionDataType from "../../SelectionDataType";
import { DataTypePaths } from "../../dataTypePaths";
import "moment-timezone";

export enum Timezones {
  DEFAULT = "Europe/Brussels",
}

moment.tz
  .names()
  .forEach((timezone) => (Timezones[timezone.toUpperCase()] = timezone));

export default class Timezone extends SelectionDataType<
  Timezones | string,
  Timezones | string,
  DataTypePaths.Profile.Timezone
> {
  protected static path: DataTypePaths.Profile.Timezone = "timezone";

  protected static defaultValue: ISelectObject<Timezones> = {
    label: Timezones.DEFAULT,
    value: Timezones.DEFAULT,
  };

  protected type: any = Timezone;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "preferences.timezone.mandatory",
          "Gelieve een tijdszone op te geven.",
        ),
        path: this.getPath(),
        validate: (timezone: ISelectObject<string>): boolean => {
          return !!timezone && timezone.value.trim() !== "";
        },
      },
    ];
  }

  public getSelectOptions() {
    const options: Array<ISelectObject<Timezones | string>> =
      this.getOptions().map((timezone: Timezones | string) => {
        return {
          label: timezone.replace("_", " "),
          value: timezone,
        };
      });

    return _.orderBy<ISelectObject<Timezones | string>>(
      options,
      ["label"],
      ["asc"],
    );
  }

  public getSelectedOptions(): Array<ISelectObject<Timezones | string>> {
    if (this.getValue() === null) return [];

    const selected = this.getValue();

    return [
      {
        label: selected.replace("_", " "),
        value: selected,
      },
    ];
  }

  public getTranslatedValues(): Array<string> {
    if (this.getValue() === null) return [];

    const selected = this.getValue();

    const translatedValues = [selected].map((val) => {
      return val;
    });

    return _.orderBy(translatedValues, ["label"], ["asc"]);
  }

  public getOptions(): any {
    return Object.keys(Timezones)
      .filter((key) => key !== "DEFAULT")
      .map((key) => Timezones[key]);
  }

  /**
   * Set timezone to current timezone gotten from he browser.
   */
  public updateToCurrent(): Timezone {
    this.setValue(
      Intl.DateTimeFormat().resolvedOptions().timeZone || Timezones.DEFAULT,
    );

    return this;
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.Timezone, ISelectObject<string | Timezones>>
  > {
    return {
      [Timezone.path]: {
        label: this.value,
        value: this.value,
      },
    };
  }
}
