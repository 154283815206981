import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { HumanInput } from "../../__generated__/graphql";
import { MUTATION_HUMAN_UPDATE } from "../mutations";

const useUpdateHumanMutation = () => {
  const [updateHumanFunction, { data, error, loading }] = useMutation(
    MUTATION_HUMAN_UPDATE,
  );

  const updateHuman = useCallback(
    async (input: HumanInput): Promise<void> => {
      updateHumanFunction({ variables: { input } });
    },
    [updateHumanFunction],
  );

  return {
    data,
    error,
    loading,
    updateHuman,
  };
};

export default useUpdateHumanMutation;
