import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const PREFIX = "RangeSlider";

const classes = {
  rail: `${PREFIX}-rail`,
  root: `${PREFIX}-root`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  valueLabel: `${PREFIX}-valueLabel`,
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
    // marginBottom: theme.spacing(8),
    height: 8,
  },

  [`& .${classes.thumb}`]: {
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    height: 24,
    marginLeft: -12,
    marginTop: -8,
    width: 24,
  },

  [`& .${classes.track}`]: {
    borderRadius: 4,
    height: 8,
  },

  [`& .${classes.rail}`]: {
    borderRadius: 4,
    height: 8,
  },

  [`& .${classes.valueLabel}`]: {
    left: "calc(-50% + 4px)",
  },
}));

interface IRangeSlider {
  defaultValue?: number;
  max: number;
  min: number;
  onChange: (value: number) => void;
  value: number;
}

export default function RangeSlider({
  defaultValue,
  max,
  min,
  onChange,
  value,
}: IRangeSlider) {
  const handleChange = (_, value) => onChange(value);

  return (
    <StyledSlider
      classes={{
        rail: classes.rail,
        root: classes.root,
        thumb: classes.thumb,
        track: classes.track,
        valueLabel: classes.valueLabel,
      }}
      defaultValue={defaultValue}
      max={max}
      min={min}
      onChange={handleChange}
      value={value ?? defaultValue}
      valueLabelDisplay="auto"
    />
  );
}
