import { tz } from "@date-fns/tz";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { Box, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { OrgProfileDialog } from "../../organizations/OrgProfileDialog";
import SidePanel from "./SidePanel";
import TimerControl from "./TimerControl";

const ConsultationFragment = graphql(`
  fragment ClientInfoSidePanelConsultation on Consultation {
    cashTransaction {
      amount
      id
      status
    }
    human {
      creditBalance {
        total
      }
      id
      organization {
        id
        name {
          ...useSelectTranslationStringInput
        }
        ...OrgProfileDialogOrganization
      }
      user {
        firstName
        id
        lastName
        preferredLanguage
        timezone
      }
    }
    id
    professional {
      id
      user {
        firstName
        id
        lastName
        timezone
      }
    }
  }
`);

const SubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 700,
  marginTop: "20px",
}));

const ClientInfoSidePanel = ({
  consultation,
  returnToPreviousPane,
}: {
  consultation: FragmentType<typeof ConsultationFragment>;
  returnToPreviousPane: () => void;
}) => {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();

  const [orgProfileDialogOpen, setOrgProfileDialogOpen] = useState(false);

  const consultationData = getFragmentData(ConsultationFragment, consultation);
  const human = consultationData.human;
  const cashTransaction = consultationData.cashTransaction;
  const organization = human?.organization;
  const creditsLeft = human?.creditBalance?.total;
  const professional = consultationData.professional;
  const professionalTimezone = professional?.user.timezone;
  const humanTimezone = human?.user.timezone;

  return (
    <SidePanel
      TitleIcon={PersonOutline}
      returnToPreviousPane={returnToPreviousPane}
      title={
        human?.user.firstName
          ? `${human.user.firstName} ${human.user.lastName}`
          : t("ui:sidepane.Info.pane.title")
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <SubTitle>{t("professional:call.lanuage.client")}</SubTitle>
          <Typography>
            {t(`ui:languages.translated.${human?.user.preferredLanguage}`, "-")}
          </Typography>
          {cashTransaction ? (
            <>
              <SubTitle>{t("common:payment")}</SubTitle>
              <Typography>
                €{cashTransaction.amount} -{" "}
                {t(`common:payment.status.${cashTransaction.status}`, "")}
              </Typography>
            </>
          ) : null}
          {humanTimezone && professionalTimezone !== humanTimezone ? (
            <>
              <SubTitle>{t("professional:call.timezone.client")}</SubTitle>
              <Typography>
                {humanTimezone}
                <br />
                {t("professional:call.drawer.timezone.currentTime", {
                  currentTime: format(new Date(), "p", {
                    in: tz(humanTimezone),
                  }),
                })}
              </Typography>
            </>
          ) : null}
          {organization ? (
            <>
              <SubTitle>{t("common:partner")}</SubTitle>
              <Box
                onClick={() => setOrgProfileDialogOpen(true)}
                sx={{
                  alignItems: "center",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {selectTranslation(organization.name)}
                <InfoOutlined color="action" sx={{ marginLeft: "4px" }} />
              </Box>
              <Typography sx={{ fontWeight: 700, marginTop: "12px" }}>
                {`${
                  creditsLeft === null
                    ? t(
                        "professional:call.drawer.partner.creditsleft.unlimited",
                      )
                    : creditsLeft === 0
                      ? t("professional:call.drawer.partner.creditsleft.part_1")
                      : creditsLeft
                } ${t("professional:call.drawer.partner.creditsleft.part_2")}`}
              </Typography>

              <OrgProfileDialog
                onClose={() => {
                  setOrgProfileDialogOpen(false);
                }}
                open={orgProfileDialogOpen}
                organization={organization}
              />
            </>
          ) : null}
        </Box>
        <Box>
          <SubTitle>{t("professional:call.drawer.timer")}</SubTitle>
          <TimerControl />
        </Box>
      </Box>
    </SidePanel>
  );
};

export default ClientInfoSidePanel;
