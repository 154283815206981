import { Link, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { SingleWave } from "wave-box";
import BloomUpLogoWithTag from "../../../illustrations/logos/BloomUpLogoWithTag";
import LanguageDropdown from "../../../ui/LanguageDropdown";

interface FooterProps {
  atomiumText?: boolean;
  waveBackground?: string;
}

const Footer = ({ atomiumText, waveBackground }: FooterProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: waveBackground }}>
      <SingleWave
        secondWaveColor="#FBF5F0"
        waveColor={theme.palette.bloom.light}
        waveHeight="100px"
      />
      <Box
        sx={{
          backgroundColor: theme.palette.bloom.light,
          display: "flex",
          justifyContent: "center",
          padding: "0 6rem 3rem",
          [theme.breakpoints.down(600)]: {
            padding: "0 3rem 3rem",
          },
          [theme.breakpoints.down(400)]: {
            padding: "0 2rem 2rem",
          },
          [theme.breakpoints.down(280)]: {
            padding: "0 1rem 2rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "1300px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginRight: "2rem",
                marginTop: "2rem",
                [theme.breakpoints.down(500)]: {
                  marginTop: "1rem",
                },
              }}
            >
              <BloomUpLogoWithTag />
              <Box
                sx={{
                  marginTop: "2rem",
                  [theme.breakpoints.down(500)]: {
                    marginTop: "1rem",
                  },
                }}
              >
                <Typography>
                  {t("organization:landing.footer.address") + "  "}
                  <Link
                    href="mailto:support@bloomup.org"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      color: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "16px",
                      textDecoration: "underline",
                    }}
                    underline="hover"
                  >
                    {"support@bloomup.org"}
                  </Link>
                </Typography>
                <Typography sx={{ marginTop: "0.5rem" }}>
                  <Link
                    href={t("match:privacyAgeStep.main.privacy.terms.link")}
                    sx={{
                      color: theme.palette.text.primary,
                      marginRight: "1rem",
                      textDecorationColor: theme.palette.text.primary,
                    }}
                    target="blank"
                  >
                    {t("organization:landing.footer.terms")}
                  </Link>

                  <Link
                    href={t("match:privacyAgeStep.main.privacy.terms.data")}
                    sx={{
                      color: theme.palette.text.primary,
                      marginRight: "1rem",
                      textDecoration: "underline",
                    }}
                    target="blank"
                  >
                    {t("organization:landing.footer.privacy")}
                  </Link>
                  {atomiumText ? t("match:footer.atomium") : null}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <LanguageDropdown
                updateMatchedHuman={!!localStorage.getItem("matchingToken")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
