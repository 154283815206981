import { Box } from "@mui/material";
import {
  CameraSelection,
  MicSelection,
  SpeakerSelection,
  useLocalAudioInputActivityPreview,
} from "amazon-chime-sdk-component-library-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const AudioVideoSettings = () => {
  const { t } = useTranslation();

  const activityBarRef = useRef<HTMLDivElement>();

  useLocalAudioInputActivityPreview(activityBarRef);

  return (
    <>
      <CameraSelection label={t("ui:video.input.device.selection.label")} />
      <MicSelection label={t("ui:audio.input.device.selection.label")} />
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.gray.main,
          borderRadius: "4px",
          height: "10px",
          marginBottom: "12px",
          width: "100%",
        })}
      >
        <Box
          ref={activityBarRef}
          sx={(theme) => ({
            backgroundColor: theme.palette.green.dark,
            borderRadius: "4px",
            height: "10px",
            transform: "scaleX(0)",
            transformOrigin: "left",
            transition: "transform 33ms ease-in-out",
            willChange: "transform",
          })}
        />
      </Box>
      <SpeakerSelection label={t("ui:video.output.device.selection.label")} />
    </>
  );
};

export default AudioVideoSettings;
