/* eslint-disable react-hooks/exhaustive-deps */

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Grid from "@mui/material/Grid";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { passwordValidationMessage } from "../../../../data-model/types/profile/Password";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import Label from "../../../ui/form/FormLabel";
import PasswordField from "../../../ui/form/PasswordField";
import RegistrationContext from "../context/RegistrationContext";
import { PASSWORD_STEP } from "../context/registrationSteps";

const PasswordStep = () => {
  const { t } = useTranslation();

  const { formState, next, previous, setStep } =
    useContext(RegistrationContext);

  useEffect(() => setStep(PASSWORD_STEP), []);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={t("human:registration.title")}
        labelNext={t("common:next")}
        onClickBack={previous}
        onClickNext={() => next()}
        subheadicon={<LockOutlinedIcon />}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Label
            label={t(
              "human:onboarding.account.password.instructions",
              passwordValidationMessage,
            )}
            style={{
              marginBottom: 15,
            }}
          />
          <PasswordField
            autoComplete="password"
            autoFocus
            id="password"
            label={t("common:password")}
            type="password"
            {...formState.getInputProps("password")}
          />
        </Grid>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default PasswordStep;
