import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";

const PREFIX = "Steps";

const classes = {
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  mainText: `${PREFIX}-mainText`,
  mainTitle: `${PREFIX}-mainTitle`,
  root: `${PREFIX}-root`,
  step: `${PREFIX}-step`,
  stepContainer: `${PREFIX}-stepContainer`,
  stepTitle: `${PREFIX}-stepTitle`,
  text: `${PREFIX}-text`,
  textContent: `${PREFIX}-textContent`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 96px",
    [theme.breakpoints.down(900)]: {
      padding: "1rem 6rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "1rem 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "1rem 2rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "1rem",
    },
  },

  [`& .${classes.content}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1100px",
    textAlign: "center",
    width: "100%",
  },

  [`& .${classes.mainTitle}`]: {
    marginBottom: "2rem",
    textAlign: "center",
  },

  [`& .${classes.mainText}`]: {
    fontSize: "18px",
    marginBottom: "3rem",
    maxWidth: "500px",
    textAlign: "center",
  },

  [`& .${classes.stepContainer}`]: {
    "& img": {
      "&:nth-of-type(even)": {
        gridColumn: "col2-start /col2-end",
      },
      display: "flex",
      gridColumn: "col1-start / col1-end",
      placeSelf: "center",
    },
    display: "grid",
    gap: "40px",
    gridAutoFlow: "dense",
    gridTemplateColumns:
      "[col1-start] 1fr [col1-end col2-start] 1fr [col2-end]",
    [theme.breakpoints.down(1200)]: {
      gridTemplateColumns: "[col1-start col2-start] 1fr [col1-start col2-end]",
      textAlign: "center",
    },
    textAlign: "left",
  },

  [`& .${classes.step}`]: {
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: "center",
    marginTop: "90px",
    textAlign: "left",
    [theme.breakpoints.up(1333)]: {
      "&:nth-child(even)": {
        flexDirection: "row-reverse",
      },
    },
  },

  [`& .${classes.stepTitle}`]: {
    alignItems: "center",
    display: "initial",
    flexDirection: "row",
    left: "inherit",
    paddingBlock: "30px",
    position: "relative",
    textAlign: "inherit",
    [theme.breakpoints.down(780)]: {
      alignItems: "flex-start",
      flexDirection: "column",
      left: "inherit",
    },
  },

  [`& .${classes.textContent}`]: {
    "&:nth-of-type(even)": {
      gridColumn: "col1-start / col1-end",
    },
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    gridColumn: "col2-start /col2-end",
    justifyContent: "center",
    maxWidth: "500px",
  },

  [`& .${classes.image}`]: {
    height: "auto",
    maxWidth: "100%",
  },

  [`& .${classes.title}`]: { fontSize: "18px" },
  [`& .${classes.text}`]: { fontSize: "18px" },
}));

interface RepresentProps {
  mainText: string;
  mainTitle: string;
  steps: {
    imageUrl: string;
    text: string;
    title: string;
  }[];
}

function Steps({ mainText, mainTitle, steps }: RepresentProps) {
  const titleFormatSmall = useMediaQuery("(max-width: 400px)");

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.content}>
        <Typography
          className={classes.mainTitle}
          variant={titleFormatSmall ? "h2" : "h1"}
        >
          {mainTitle}
        </Typography>
        <Typography className={classes.mainText}>{mainText}</Typography>
        <Box className={classes.stepContainer}>
          {steps.map((step) => (
            <Fragment key={step.title}>
              <img alt="blogo" className={classes.image} src={step.imageUrl} />

              <Box className={classes.textContent}>
                <Box className={classes.stepTitle}>
                  <Typography
                    className={classes.title}
                    variant={titleFormatSmall ? "h4" : "h2"}
                  >
                    {step.title}
                  </Typography>
                </Box>
                <Typography className={classes.text}>{step.text}</Typography>
              </Box>
            </Fragment>
          ))}
        </Box>
      </Box>
    </StyledBox>
  );
}

export default Steps;
