import { useCallback, useMemo, useState } from "react";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../ui/form/select/SingleSelect";
import { QualityQuestions } from "../QualityQuestions.types";
import AnswerRadioButtons from "./AnswerRadioButtons";
import { SharedAnswerWithOptionsProps } from "./answers";

type SingleSelectComponentOptions =
  | QualityQuestions.Component.Dropdown
  | QualityQuestions.Component.Radio;

interface AnswerSingleSelectProps
  extends SharedAnswerWithOptionsProps<QualityQuestions.Options.SingleSelect> {
  component?: SingleSelectComponentOptions;
}

export default function AnswerSingleSelect({
  component,
  onAnswerChanged,
  options,
  selectedAnswers,
}: AnswerSingleSelectProps) {
  const componentToRender = useMemo(() => {
    switch (component) {
      case QualityQuestions.Component.Radio:
        return (
          <AnswerRadioButtons
            onAnswerChanged={onAnswerChanged}
            options={options}
            selectedAnswers={selectedAnswers}
          />
        );

      case QualityQuestions.Component.Dropdown:
      // Fallthrough because Dropdown is the default

      default:
        return (
          <SingleSelectForAnswerWrapper
            onAnswerChanged={onAnswerChanged}
            options={options}
          />
        );
    }
  }, [component, onAnswerChanged, options, selectedAnswers]);

  return <div>{componentToRender}</div>;
}

const SingleSelectForAnswerWrapper = ({
  onAnswerChanged,
  options,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.SingleSelect>) => {
  const [selected, setSelected] = useState<ISelectObject<string | number>>();

  const onChange = useCallback(
    (selected: ISelectObject<string | number>) => {
      setSelected(selected);
      onAnswerChanged([selected.value]);
    },
    [onAnswerChanged],
  );

  return (
    <SingleSelect<string | number>
      onChange={onChange}
      options={options}
      value={selected}
    />
  );
};
