import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router";
const PREFIX = "LinkButton";

const classes = {
  button: `${PREFIX}-button`,
  iconBg: `${PREFIX}-iconBg`,
  link: `${PREFIX}-link`,
  text: `${PREFIX}-text`,
};

const StyledLink = styled(Link)(({ theme }) => ({
  [`&.${classes.link}`]: {
    textDecoration: "none",
  },

  [`& .${classes.button}`]: {
    "&:hover": {
      background: theme.palette.bloom.main,
      color: theme.palette.white.main,
    },
    alignItems: "center",
    background: theme.palette.white.main,
    border: "none",
    borderRadius: 10,
    color: theme.palette.black.main,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: theme.spacing(3, 4),

    width: "100%",
  },

  [`& .${classes.iconBg}`]: {
    background: theme.palette.bloom.dark,
    color: theme.palette.white.main,
    height: 50,
    margin: theme.spacing(1),
    width: 50,
  },

  [`& .${classes.text}`]: {
    textAlign: "center",
  },
}));

const LinkButton = ({ icon, label, to, ...rest }) => {
  return (
    <StyledLink className={classes.link} to={to} {...rest}>
      <button className={classes.button} type="button">
        <Avatar className={classes.iconBg}>{icon}</Avatar>
        <Typography className={classes.text}>{label}</Typography>
      </button>
    </StyledLink>
  );
};

export default LinkButton;
