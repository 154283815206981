import { addSeconds, isBefore } from "date-fns";
import { jwtDecode, JwtPayload } from "jwt-decode";
import refreshAccessToken from "../api/refreshAccessToken";
import { Token } from "../components/hooks/useStorage";
import { UserRoles } from "../data-model/types/User";
import BULocalStorage from "./BULocalStorage";

type tokenType = "access_token" | "matching_token";

interface AccessTokenPayload extends JwtPayload {
  id: number;
  org_id: number;
  scope: UserRoles;
  token_type: tokenType;
}

const hasTokenExpired = (token: string): boolean => {
  const decodedToken = jwtDecode<JwtPayload>(token);
  const tokenExpiresAtSeconds = decodedToken.exp ? decodedToken.exp : 0;
  const tokenExpiresAt = new Date(tokenExpiresAtSeconds * 1000);

  return isBefore(tokenExpiresAt, addSeconds(new Date(), 60));
};

export const getTokenPayload = (token: string): AccessTokenPayload => {
  const decodedToken = jwtDecode<AccessTokenPayload>(token);

  return decodedToken;
};

export const loadAndValidateTokens = async (): Promise<{
  accessToken: Token;
  refreshToken: Token;
}> => {
  const storage = BULocalStorage.getInstance();
  let accessToken: string | null = await storage.getAccessToken();
  let refreshToken = await storage.getRefreshToken();

  if (
    accessToken &&
    accessToken !== null &&
    refreshToken &&
    refreshToken !== null
  ) {
    if (hasTokenExpired(refreshToken)) {
      await storage.removeAccessToken();
      await storage.removeRefreshToken();
      accessToken = null;
      refreshToken = null;
    } else if (hasTokenExpired(accessToken)) {
      const newTokens = await refreshAccessToken(refreshToken);

      accessToken = newTokens?.accessToken ?? null;

      if (accessToken) {
        await storage.setAccessToken(accessToken);
      }

      refreshToken = newTokens?.refreshToken ?? null;

      if (refreshToken) {
        await storage.setRefreshToken(refreshToken);
      }
    }
  }

  return {
    accessToken,
    refreshToken,
  };
};

export const loadAndValidateMatchingToken = async (): Promise<Token> => {
  const storage = BULocalStorage.getInstance();
  let matchingToken = await storage.getMatchingToken();

  if (matchingToken && matchingToken !== null) {
    if (hasTokenExpired(matchingToken)) {
      await storage.removeMatchingToken();
      matchingToken = null;
    }
  }

  return matchingToken;
};

export const loadAndValidateMatchToken = async (): Promise<
  string | undefined
> => {
  const matchingToken = localStorage.getItem("matchingToken");

  if (matchingToken) {
    if (hasTokenExpired(matchingToken)) {
      localStorage.removeItem("matchingToken");
    } else {
      return matchingToken;
    }
  }

  return undefined;
};
