import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
const PREFIX = "CircleWithNumber";

const classes = {
  circle: `${PREFIX}-circle`,
  circleText: `${PREFIX}-circleText`,
};

const StyledBox = styled(Box)<{
  backgroundColor?: string;
  numberColor?: string;
}>(
  ({
    theme,
    backgroundColor = theme.palette.porple.main,
    numberColor = "white",
  }) => ({
    [`&.${classes.circle}`]: {
      alignItems: "center",
      backgroundColor: backgroundColor,
      borderRadius: "100%",
      display: "flex",
      flexShrink: 0,
      height: "36px",
      justifyContent: "center",
      padding: "8px",
      width: "36px",
    },

    [`& .${classes.circleText}`]: {
      color: numberColor,
    },
  }),
);

interface ICircleWithNumber {
  backgroundColor?: string;
  number: number;
  numberColor?: string;
}

export default function CircleWithNumber({
  backgroundColor,
  number,
  numberColor,
}: ICircleWithNumber) {
  return (
    <StyledBox
      backgroundColor={backgroundColor}
      className={classes.circle}
      numberColor={numberColor}
    >
      <Typography className={classes.circleText}>{number}</Typography>
    </StyledBox>
  );
}
