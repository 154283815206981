import { useMutation, useQuery } from "@apollo/client";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { graphql } from "../../../../api/__generated__";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import { RouteNames } from "../../../routes/routeNames";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import MultiSelect from "../../../ui/form/select/MultiSelect";
import Label from "../../../ui/text/Label";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";
import {
  AVATAR_STEP,
  THERAPY_STEP,
} from "../context/professionalOnboardingSteps";

const onboardingSubtopicsQuery = graphql(`
  query OnboardingSubtopics {
    currentUser {
      id
      professional {
        id
        primarySubtopics {
          id
          titleTranslationKey
        }
        secondarySubtopics {
          id
          titleTranslationKey
        }
      }
    }
    subtopics {
      id
      titleTranslationKey
    }
  }
`);

const onboardProfessionalSubtopicsMutation = graphql(`
  mutation OnboardProfessionalSubtopics(
    $input: UpdateProfessionalSubtopicsInput!
  ) {
    updateProfessionalSubtopics(input: $input) {
      id
      primarySubtopics {
        id
        titleTranslationKey
      }
      secondarySubtopics {
        id
        titleTranslationKey
      }
    }
  }
`);

const mapSubtopics = (subtopics) =>
  subtopics.map((t) => {
    return { label: t.titleTranslationKey, value: t.id };
  });

export default function TopicsStep() {
  const { setStep, step, totalSteps } =
    useContext<ProfessionalOnboardingContextProps>(
      ProfessionalOnboardingContext,
    );

  const [updateProfessionalSubtopics] = useMutation(
    onboardProfessionalSubtopicsMutation,
  );

  const { data } = useQuery(onboardingSubtopicsQuery, {
    onCompleted: (data) => {
      const storedPrimaries =
        data.currentUser?.professional &&
        mapSubtopics(data.currentUser.professional.primarySubtopics);

      if (storedPrimaries && storedPrimaries.length > 0)
        setselectedPrimaries(storedPrimaries);

      const storedSecondaries =
        data.currentUser?.professional &&
        mapSubtopics(data.currentUser.professional.secondarySubtopics);

      if (storedSecondaries.length > 0)
        setselectedSecondaries(storedSecondaries);
    },
  });

  const options = data?.subtopics && mapSubtopics(data.subtopics);

  const [selectedPrimaries, setselectedPrimaries] = useState<
    ISelectObject<string>[]
  >([]);

  const [selectedSecondaries, setselectedSecondaries] = useState<
    ISelectObject<string>[]
  >([]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={selectedPrimaries.length < 1 || selectedSecondaries.length < 1}
        fullCopyright
        headline={t("professional:onboarding.title")}
        labelBack={t("common:onboarding.button.label.previous")}
        labelNext={t("common:onboarding.button.label.next")}
        onClickBack={() => {
          setStep(AVATAR_STEP);
          navigate(RouteNames.Onboarding.Professional.Avatar.path);
        }}
        onClickNext={async () => {
          await updateProfessionalSubtopics({
            variables: {
              input: {
                primary: true,
                subtopicIds: selectedPrimaries.map(
                  (subtopic) => subtopic.value,
                ),
              },
            },
          });
          await updateProfessionalSubtopics({
            variables: {
              input: {
                primary: false,
                subtopicIds: selectedSecondaries.map(
                  (subtopic) => subtopic.value,
                ),
              },
            },
          });

          setStep(THERAPY_STEP);
          navigate(RouteNames.Onboarding.Professional.Therapy.path);
        }}
        showLanguageSelector={false}
        step={step}
        subhead={t("professional:onboarding.specialities.title")}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Box>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("professional:onboarding.specialities.description")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              marginTop: "0.5rem",
              textAlign: "left",
            }}
          >
            <Box>
              <Label value={t("profile:primary_subtopics_label")} />
              <MultiSelect<string>
                onChange={(selected) => {
                  if (selected.length <= 5) {
                    setselectedPrimaries(selected);
                  }
                }}
                options={
                  selectedSecondaries.length > 0
                    ? options?.filter((o) => {
                        if (
                          selectedSecondaries.find(
                            (topic) => topic.value === o.value,
                          )
                        )
                          return false;

                        return true;
                      })
                    : (options ?? [])
                }
                placeholder={t("profile:subtopics_placeholder")}
                value={selectedPrimaries}
              />
            </Box>
            <Box>
              <Label value={t("profile:secondary_subtopics_label")} />
              <MultiSelect<string>
                onChange={(selected) => {
                  if (selected.length <= 5) {
                    setselectedSecondaries(selected);
                  }
                }}
                options={
                  selectedPrimaries.length > 0
                    ? options?.filter((o) => {
                        if (
                          selectedPrimaries.find(
                            (topic) => topic.value === o.value,
                          )
                        )
                          return false;

                        return true;
                      })
                    : (options ?? [])
                }
                placeholder={t("profile:subtopics_placeholder")}
                value={selectedSecondaries}
              />
            </Box>
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
