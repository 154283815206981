import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { google, office365, ics, CalendarEvent } from "calendar-link";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../language/I18Namespaces";
import Label from "../ui/text/Label";

const PREFIX = "CalendarLinks";

const classes = {
  buttonLink: `${PREFIX}-buttonLink`,
  calendarLinks: `${PREFIX}-calendarLinks`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.calendarLinks}`]: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "left",
  },

  [`& .${classes.buttonLink}`]: {
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    color: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: 16,
    fontWeight: 600,
    justifyContent: "center",
    letterSpacing: "0.24px",
    margin: theme.spacing(1, 1, 0, 0),
    padding: theme.spacing(1, 2),
    textDecoration: "none",
  },
}));

interface ICalenderLinks {
  appUrl: string;
  clientName: string;
  path: string;
  scheduledFrom: string;
  scheduledTo: string;
}

export default function CalendarLinks({
  appUrl,
  clientName,
  path,
  scheduledFrom,
  scheduledTo,
}: ICalenderLinks) {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  const event: CalendarEvent = {
    description: `Deze consultatie werd automatisch gegenereerd door https://www.bloomup.org. Gebruik volgende link om de gebruiker te bellen: ${appUrl}/${path}`,
    end: scheduledTo,
    location: `${appUrl}/${path}`,
    start: scheduledFrom,
    title: `BloomUp Consultatie met ${clientName}`,
    url: `${path}`,
  };

  return (
    <StyledBox style={{ marginBottom: 30 }}>
      <Label
        value={translate(
          "common:consultation.add_to_calendar.title",
          "Voeg de consultatie toe aan je kalender:",
        )}
      />
      <Box className={classes.calendarLinks}>
        <a className={classes.buttonLink} href={ics(event)}>
          Apple
        </a>
        <a className={classes.buttonLink} href={ics(event)}>
          Outlook
        </a>
        <a
          className={classes.buttonLink}
          href={google(event)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Google
        </a>
        <a
          className={classes.buttonLink}
          href={office365(event)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Office365
        </a>
      </Box>
    </StyledBox>
  );
}
