/* eslint-disable react-hooks/exhaustive-deps */

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { styled, lighten } from "@mui/material/styles";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import TextLabel from "../../../../components/ui/text/Label";
import { Key } from "../../Key";

const PREFIX = "InlineEditableControls";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& .inline-edit-header": {
      "& .inline-edit-controls-edit": {
        cursor: "pointer",
        display: isMobile ? "flex" : "none",
        width: 125,
      },
      "& .inline-edit-icon": {
        color: theme.palette.orange.dark,
        width: 19,
      },
      "& .inline-edit-label": {
        color: theme.palette.orange.dark,
        paddingTop: 5,
      },
      borderBottom: `1px solid ${theme.palette.gray.light}`,

      display: "flex",

      marginBottom: 5,

      minHeight: 30,
    },

    "&.edit": {
      "& .inline-edit-header": {
        "& .inline-edit-controls-edit": {
          "& .inline-edit-icon.save": {
            color: theme.palette.green.dark,
          },
          "& .inline-edit-icon.stop": {
            color: theme.palette.red.dark,
          },
          "& .inline-edit-label.save": {
            color: theme.palette.green.dark,
          },

          display: "flex",

          justifyContent: "flex-end",

          width: 200,
        },
      },

      "& .inline-edit-value": {
        "& [button-selected='no']": {
          backgroundColor: theme.palette.white,
        },
        backgroundColor: lighten(theme.palette.orange.main, 0.6),
        marginTop: "-5px",

        padding: "20px 15px 20px",
      },
    },

    "&:hover": {
      "& .inline-edit-header": {
        "& .inline-edit-controls-edit": {
          display: "flex",
          justifyContent: "flex-end",
        },

        borderBottomColor: theme.palette.orange.dark,
      },

      "&.edit .inline-edit-header": {
        borderBottomColor: "transparent",
      },
    },

    minWidth: "100%",
  },
}));

interface IECProps {
  cancelBtnTestId?: string | undefined;
  edit: JSX.Element[] | JSX.Element | string | null;
  editable?: boolean;
  editBtnTestId?: string | undefined;
  label: string;
  labelTestId?: string | undefined;
  onChange?: () => void;
  onDiscard: () => boolean;
  onSave: () => Promise<boolean> | void;
  read: JSX.Element[] | JSX.Element | string;
  saveBtnTestId?: string | undefined;
  selectTestId?: string | undefined;
}

export default function InlineEditableControls({
  cancelBtnTestId,
  edit,
  editBtnTestId,
  editable,
  label,
  labelTestId,
  onChange,
  onDiscard,
  onSave,
  read,
  saveBtnTestId,
}: IECProps) {
  const smallScreen = useMediaQuery("(max-width: 440px)");

  const { t } = useTranslation(["common"]);
  const [editing, setEditing] = useState<boolean>(false);

  const stopEditing = (): void => setEditing(false);
  const startEditing = (): void => setEditing(true);
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onKeyPressed = useCallback(
    async (event: KeyboardEvent): Promise<void> => {
      if (event.key === Key.Escape) {
        const isValid = onDiscard();

        if (isValid) stopEditing();
      }

      if (event.key === Key.Enter) {
        if (onChange && mounted.current) onChange();

        if (mounted.current) {
          if (await onSave()) {
            stopEditing();
          }
        }
      }
    },
    [onSave, onChange],
  );

  useEffect(() => {
    if (editing) {
      document.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === Key.Enter) {
          event.stopPropagation();
          onKeyPressed(event);
        }
      });
    } else {
      document.removeEventListener("keydown", onKeyPressed);
    }

    return () => document.removeEventListener("keydown", onKeyPressed);
  }, [editing, onSave]);

  return editing ? (
    <Root className={`${classes.root} edit`}>
      <Box className="inline-edit-header">
        <TextLabel value={label} />
        <Grid
          className="inline-edit-controls-edit"
          container
          id={saveBtnTestId ?? ""}
          onClick={() => {
            onKeyPressed(new KeyboardEvent("keydown", { key: Key.Enter }));
          }}
          spacing={1}
        >
          <Grid item>
            <CheckIcon className="inline-edit-icon save" />
          </Grid>
          <Grid className="inline-edit-label save" item>
            {t("common:confirm")}
          </Grid>

          <Grid item>
            <CloseIcon
              className="inline-edit-icon stop"
              id={cancelBtnTestId ?? ""}
              onClick={(evt) => {
                evt.stopPropagation();
                const isValid = onDiscard();

                if (isValid) stopEditing();
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="inline-edit-value">{edit}</Box>
    </Root>
  ) : (
    <Root className={`${classes.root} read ${editable ? "editable" : ""}`}>
      <Box className="inline-edit-header" id={labelTestId ?? ""}>
        <TextLabel value={label} />
        {editable ? (
          <Grid
            className="inline-edit-controls-edit"
            container
            id={editBtnTestId ?? ""}
            onClick={startEditing}
            spacing={1}
          >
            <Grid item>
              <EditIcon className="inline-edit-icon" />
            </Grid>
            {smallScreen ? null : (
              <Grid className="inline-edit-label" item>
                {t("common:edit", "Wijzig")}
              </Grid>
            )}
          </Grid>
        ) : null}
      </Box>
      {typeof read === "string" ? (
        <Typography
          className="inline-edit-value"
          sx={{ fontSize: "14px", whiteSpace: "pre-line" }}
        >
          {read}
        </Typography>
      ) : (
        <Box className="inline-edit-value">{read}</Box>
      )}
    </Root>
  );
}
