import { makeFragmentData } from "../../api/__generated__";
import Human from "../../data-model/types/human/Human";
import Professional from "../../data-model/types/professional/Professional";
import Avatar, { avatarFragment } from "./Avatar";

type AvatarProps = {
  size: number;
  user: Professional | Human;
};

export default function DeprecatedAvatar({ size, user }: AvatarProps) {
  return (
    <Avatar
      showGender={false}
      size={size}
      user={makeFragmentData(
        {
          firstName: user.getFirstName().getValue(),
          id: user.getUUID(),
          lastName: user.getLastName().getValue(),
          professional: {
            avatar: { url: user.getAvatar().getUrl() },
            id: user.getID(),
          },
        },
        avatarFragment,
      )}
    />
  );
}
