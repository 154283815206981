import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UpdateCalendarEventInput } from "../../__generated__/graphql";
import { MUTATION_UPDATE_FULL_CALENDAR_EVENT } from "../mutations";

export default function useUpdateFullCalendarEvent() {
  const [execute, { data, error, loading }] = useMutation(
    MUTATION_UPDATE_FULL_CALENDAR_EVENT,
  );

  const updateFullCalendarEvent = useCallback(
    (input: UpdateCalendarEventInput) => {
      return execute({
        variables: { input: { ...input, returnAsFullCalendar: true } },
      });
    },
    [execute],
  );

  return {
    data: data?.updateCalendarEvent,
    error,
    loading,
    updateFullCalendarEvent,
  };
}
