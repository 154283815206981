/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { UserRoles } from "../../../data-model/types/User";
import ConsultationsPage from "../../consultations/ConsultationsPage";
import ConsultationsOverviewContext from "../../consultations/context/ConsultationsOverviewContext";
import { IConsultationsOverviewContext } from "../../consultations/context/consultations";

const HumanHomePage = () => {
  const { t: translate } = useTranslation();
  const { uuid } = useParams();

  const { openConsultationWithUuid } =
    useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  useEffect(() => {
    if (uuid) openConsultationWithUuid(uuid);
  }, []);

  return (
    <ConsultationsPage
      addButtonLabel={translate("common:consultation")}
      pageTitle={translate("ui:menu.consultations")}
      userRole={UserRoles.HUMAN}
    />
  );
};

export default HumanHomePage;
