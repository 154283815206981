import EmailIcon from "@mui/icons-material/Email";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";
import SanitizedText from "../../ui/text/SanitizedText";

const PREFIX = "InviteClient";

const classes = {
  buttons: `${PREFIX}-buttons`,
  invite: `${PREFIX}-invite`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.invite}`]: {
    padding: theme.spacing(3, 4),
  },

  [`& .${classes.buttons}`]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface IInviteClient {
  email: string;
  handleInvite: () => void;
  handleReset: () => void;
}

const InviteClient = ({ email, handleInvite, handleReset }: IInviteClient) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    "professional",
    "common",
  ]);

  return (
    <StyledBox className={classes.invite}>
      <Typography gutterBottom variant="h2">
        {translate("professional:add.user.notfound.title", "Nodig cliënt uit")}
      </Typography>
      <Typography variant="body2">
        <SanitizedText
          input={translate("professional:add.user.notfound.description", {
            defaultValue:
              'De gebruiker met het e-mail adres "<b>{{email}}</b>" maakt nog geen gebruik van BloomUp.<br /><br />Wil je deze gebruiker een uitnodiging sturen?<br />De gebruiker zal automatisch jouw client worden na registratie.',
            email,
          })}
        />
      </Typography>
      <Box className={classes.buttons} mt={2}>
        <Button
          label={translate("common:back", "Terug")}
          onClick={handleReset}
          style={{
            marginRight: 50,
          }}
          variant="text"
        />
        <Button
          icon={<EmailIcon />}
          label={translate("common:invite.label", "Nodig uit")}
          onClick={handleInvite}
        />
      </Box>
    </StyledBox>
  );
};

export default InviteClient;
