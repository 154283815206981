import { useMutation, useQuery } from "@apollo/client";
import TodayIcon from "@mui/icons-material/Today";
import { Box, Typography, useTheme } from "@mui/material";
import { PresenceMessage } from "ably";
import { ChannelProvider } from "ably/react";
import { t } from "i18next";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { graphql } from "../../../api/__generated__";
import PresenceTracker from "../../meeting/PresenceTracker";
import { RouteNames } from "../../routes/routeNames";
import ChooseConsultation from "./ChooseProf/ChooseConsultation";
import ConsultationRules from "./ChooseProf/ConsultationRules";
import Divider from "./Common/Divider.svg";
import Footer from "./Common/Footer";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import { IntroDetails } from "./Match";

const waitingRoomQuery = graphql(`
  query WaitingRoom {
    currentMatchedHuman {
      id
      matchConsultation {
        id
        professional {
          availabilityV2(amountOfDaysInTheFuture: 31, consultationType: INTRO)
          id
          user {
            firstName
            id
          }
        }
        uuid
      }
      subtopic {
        id
        titleTranslationKey
      }
    }
  }
`);

const waitingRoomCancelConsultationMutation = graphql(`
  mutation WaitingRoomCancelConsultation($input: CancelConsultationInput!) {
    cancelConsultation(input: $input) {
      id
    }
  }
`);

interface WaitingRoomProps {
  bookIntroConsult: (introDetails: IntroDetails) => Promise<void>;
  stepBack: () => void;
}

const WaitingRoom = ({ bookIntroConsult, stepBack }: WaitingRoomProps) => {
  const theme = useTheme();
  const { data } = useQuery(waitingRoomQuery);
  const [showBookPane, setShowBookPane] = useState(false);
  const navigate = useNavigate();
  const consultationUuid = data?.currentMatchedHuman?.matchConsultation?.uuid;
  const channelName = `consultation:${consultationUuid}`;
  const [cancelConsultation] = useMutation(
    waitingRoomCancelConsultationMutation,
  );

  const cancelWaitingConsult = async () => {
    if (data?.currentMatchedHuman?.matchConsultation?.id)
      await cancelConsultation({
        variables: {
          input: {
            id: data.currentMatchedHuman.matchConsultation.id,
          },
        },
      });
  };

  const handlePresenceChange = useCallback(
    (presenceData: PresenceMessage[]) => {
      if (consultationUuid && presenceData.length > 1) {
        navigate(RouteNames.Call.Enter.path.replace(":uuid", consultationUuid));
      }
    },
    [consultationUuid, navigate],
  );

  return (
    <>
      {consultationUuid ? (
        <ChannelProvider channelName={channelName}>
          <PresenceTracker
            channel={channelName}
            onChange={handlePresenceChange}
          />
        </ChannelProvider>
      ) : null}
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D48-talk-to-professional%2C+Size%3DSmall.png"
        }
        subtitle={
          data?.currentMatchedHuman?.subtopic?.titleTranslationKey
            ? t(data.currentMatchedHuman.subtopic.titleTranslationKey)
            : undefined
        }
        title={t("match:treatmentStep.treatments.coaching.title")}
      />
      <StepMainContent
        nextButtonIcon={<TodayIcon />}
        nextButtonLabel={t(
          "match:chooseProfstep.card.chooseConsultation.chooseTime",
        )}
        nextButtonVariant="outlined"
        onClickNext={() => {
          setShowBookPane(!showBookPane);
        }}
        onClickPrevious={() => {
          cancelWaitingConsult();
          stepBack();
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box
            component={"img"}
            src="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png"
            sx={{
              maxHeight: "320px",
              maxWidth: "520px",
              [theme.breakpoints.down(500)]: {
                maxHeight: "190px",
                maxWidth: "312px",
              },
            }}
          />
          <Box sx={{ paddingTop: "50px" }}>
            <Typography variant="h2">
              {t("match:waitingStep.title", {
                profName:
                  data?.currentMatchedHuman?.matchConsultation?.professional
                    ?.user.firstName,
              })}
            </Typography>
            <Typography
              sx={{ marginY: "20px", whiteSpace: "pre-line" }}
              variant="body1"
            >
              {t("match:waitingStep.body1", {
                profName:
                  data?.currentMatchedHuman?.matchConsultation?.professional
                    ?.user.firstName,
              })}
            </Typography>
            <Box component="img" src={Divider} sx={{ paddingBottom: "50px" }} />
            <Typography
              sx={{ marginBottom: "30px", whiteSpace: "pre-line" }}
              variant="body1"
            >
              {t("match:waitingStep.body2")}
            </Typography>
          </Box>
          {showBookPane &&
          data?.currentMatchedHuman?.matchConsultation?.professional &&
          data.currentMatchedHuman.matchConsultation.id ? (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ChooseConsultation
                availability={
                  data.currentMatchedHuman.matchConsultation.professional
                    .availabilityV2
                }
                bookIntroConsult={async (props) => {
                  cancelWaitingConsult();

                  return bookIntroConsult(props);
                }}
                profId={
                  data.currentMatchedHuman.matchConsultation.professional.id
                }
              />
              <ConsultationRules />
            </Box>
          ) : null}
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default WaitingRoom;
