import {
  MeetingProvider,
  lightTheme,
  GlobalStyles,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import VideoCall from "./VideoCall";
import TimerProvider from "./timerContext/TimerProvider";

const MeetingPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <MeetingProvider>
        <TimerProvider>
          <VideoCall />
        </TimerProvider>
      </MeetingProvider>
    </ThemeProvider>
  );
};

export default MeetingPage;
