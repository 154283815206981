import { Box } from "@mui/material";
import ButtonMUI, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { memo } from "react";
import { IWithStyle } from "../../../baseInterfaces";
import { BloomColor } from "../../layout/wizard/StepFooter";
import { theme } from "../../providers/theme/ThemeProvider";

const PREFIX = "Button";

const classes = {
  root: `${PREFIX}-root`,
  spinner: `${PREFIX}-spinner`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledBox = styled(Box)<{
  bloomColor: BloomColor;
  disabled: boolean;
  fillColor: string;
}>(({ bloomColor, disabled, fillColor, theme }) => ({
  [`& .${classes.root}`]: {
    "&.ALERT-state": {
      "&:hover": {
        backgroundColor: theme.palette.whiteSmoke.main,
      },
      backgroundColor: theme.palette.white.main,

      color: theme.palette.red.main,
    },
    "&.button__circular": {
      borderRadius: "50%",
      minWidth: 50,
      padding: 16,
      width: 50,
    },
    "&.button__disabled": {
      "&:hover": {
        background: theme.palette.gray.light,
      },
      background: theme.palette.white.main,
      borderColor: theme.palette.gray.main,
      color: theme.palette.gray.main,
    },
    "&.button__extralarge": {
      fontSize: 24,
      fontWeight: 600,
      height: 78,
    },
    "&.button__fullwidth": {
      maxWidth: 324,
      width: "100%",
    },
    "&.button__fullwidth__extra": {
      margin: "16px 0",
      width: "100%",
    },
    "&.button__fully__colored": {
      "&.button__disabled": {
        opacity: 0.5,
      },
      "&:hover": {
        background: theme.palette[bloomColor].dark,
        borderColor: theme.palette[bloomColor].dark,
        color: theme.palette.black.main,
      },
      background: theme.palette[bloomColor].main,
      borderColor: theme.palette[bloomColor].main,
      color: theme.palette.black.main,
    },
    "&.button__medium": {
      fontSize: 14,
      height: 40,
      padding: "12px 24px",
    },
    "&.button__non-desktop": {
      display: "none",
    },
    "&.button__outlined__v2": {
      "&.button__disabled": {
        opacity: 0.5,
      },
      "&:hover": {
        background: "#FFFFFF",
        borderColor: "#1C7CBA",
        color: "#1C7CBA",
      },
      background: "#FFFFFF",
      borderColor: "#2E2E2E",
      color: "#2E2E2E",
    },
    "&.button__secondary__action": {
      "&:hover": {
        color: theme.palette.white.main,
      },
      background: "transparent",

      color: theme.palette.bloom.light,
    },
    "&.button__secondary__action.button__disabled": {
      color: theme.palette.bloom.light,
    },
    "&.button__small": {
      fontSize: 11,
      height: "auto",
      padding: "6px 12px",
    },
    "&.button__text": {
      "&:hover": {
        background: "transparent",
        textDecoration: "underline",
      },
      background: "none",
      border: "none",
      color: theme.palette[bloomColor].dark,

      padding: 0,
    },
    "&.nolabel": {
      "& .MuiButton-startIcon": {
        marginRight: -4,
      },
    },
    "&:focus": {
      outline: 0,
    },
    "&:hover": {
      background: bloomColor === "black" ? "" : theme.palette[bloomColor].main,
      color: theme.palette.white.main,
    },

    '&[button-selected="no"]': {
      background: theme.palette.white.main,
      color: theme.palette[bloomColor].dark,
    },

    '&[button-selected="yes"]': {
      background: theme.palette[bloomColor].main,
      color: theme.palette.white.main,
    },

    alignItems: "center",

    background: fillColor,

    border: "3px solid",

    borderColor: theme.palette[bloomColor].dark,

    borderRadius: 30,

    color: theme.palette[bloomColor].dark,

    display: "inline-flex",

    fontSize: 16,

    fontWeight: 600,

    height: 50,

    justifyContent: "center",

    letterSpacing: 0.24,

    padding: "16px 32px",
    textTransform: "initial",
  },

  [`&.${classes.wrapper}`]: {
    cursor: disabled ? "pointer" : "not-allowed",
    display: "inline-block",
    position: "relative",
  },

  [`& .${classes.spinner}`]: {
    color: theme.palette.porple.dark,
    left: "50%",
    marginLeft: "-12px",
    marginTop: "-12px",
    position: "absolute",
    top: "50%",
  },
}));

/* eslint-disable */

/**
 * A BloomUp custom button element.
 *
 * @param {Callback Function} onClick
 *        What happens when the button is clicked?
 * @param {String} label
 *        What is the text that needs to be displayed on the button?
 * @param {Boolean} [noLabel=false]
 *        Don't show the label. Is mainly used for breakpoint changes.
 * @param {String} [variant='button']
 *        What variant of button is this? (button, link, text)
 * @param {Boolean} disabled
 *        Is the button disabled or not?
 * @param {[type]} secondaryAction
 *        Is this a secondary button? Shows without border.
 * @param {[type]} fullyColored
 *        Will fill whole button with provided bloom color.
 * @param {[type]} role
 *        ???
 * @param {String} state
 *        Create a state in the class. (ex: .[state]-state)
 * @param {MUI Icon} icon
 *        The icon that should be shown on the button.
 * @param {Boolean} buttonSelected
 *        Is this button selected? Used in button groups.
 * @param {String} [color=bloom]
 *        Gray, bloom, red, orange, porple, black, white.
 * @param {String} fillColor
 *        Gives the button a filled color.
 * @param {Params} other All other params.
 */

type ButtonVariant = "contained" | "text" | "outlined" | "outlinedV2";
type ButtonSelected = "yes" | "no";
type ButtonSize = "small" | "medium" | "large";

interface IButton extends ButtonProps, IWithStyle {
  onClick?: (event?: any) => void;
  //state => What was this used for?
  label?: string | null;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  buttonSelected?: ButtonSelected;
  className?: string;
  secondaryAction?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
  circular?: boolean;
  fullyColored?: boolean;
  bloomColor?: BloomColor;
  fillColor?: string;
  variant?: ButtonVariant;
  loading?: boolean;
  size?: ButtonSize;
  target?: string;
}

const Button = ({
  onClick,
  label,
  noLabel = false,
  variant = "contained",
  disabled = false,
  circular = false,
  loading = false,
  fullyColored = false,
  secondaryAction,
  icon,
  endIcon,
  //state => What was this used for?
  buttonSelected,
  bloomColor = "bloom",
  fillColor = theme.palette.white.main,
  size = "large",
  className: extraClass = "",
  ...other
}: IButton) => {
  return (
    <StyledBox
      className={classes.wrapper}
      bloomColor={bloomColor}
      fillColor={fillColor}
      disabled={disabled}
    >
      <ButtonMUI
        href=""
        // was in classname: ${state ? state + "-state" : ""}
        className={clsx("button", classes.root, extraClass, {
          button__disabled: disabled,
          button__circular: circular,
          button__text: variant === "text",
          button_secondary_action: secondaryAction,
          button__fully__colored: fullyColored,
          button__medium: size === "medium",
          button__small: size === "small",
          nolabel: noLabel,
          button__outlined__v2: variant === "outlinedV2",
        })}
        onClick={onClick}
        disabled={disabled || loading}
        button-selected={buttonSelected}
        variant={variant}
        startIcon={icon}
        endIcon={endIcon}
        {...other}
      >
        {!noLabel && label}
      </ButtonMUI>
      {loading && <CircularProgress size={24} className={classes.spinner} />}
    </StyledBox>
  );
};

export default memo(Button);
