import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import ClientForm from "./ClientForm";
import ClientSuccess from "./ClientSuccess";
import InviteClient from "./InviteClient";

const PREFIX = "AddClientModal";

const classes = {
  modalBg: `${PREFIX}-modalBg`,
  modalChild: `${PREFIX}-modalChild`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`&.${classes.modalBg}`]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.modalChild}`]: {
    background: theme.palette.white.light,
    borderRadius: 5,
    outline: 0,
  },
}));

interface IAddClientModal {
  formState: any;
  // handleSubmit,
  handleInvite: () => void;
  handleReset: () => void;
  invite: boolean;
  isModalOpen: boolean;
  success: boolean;
  toggleModal: () => void;
}

const AddClientModal = ({
  formState,
  handleInvite,
  handleReset,
  // handleSubmit,
  invite,
  isModalOpen,
  success,
  toggleModal,
}: IAddClientModal) => {
  return (
    <StyledModal
      aria-describedby="add-an-existing-user-as-a-client-or-invite-an-existing-client-to-bloomup"
      aria-labelledby="add-client-modal"
      className={classes.modalBg}
      onClose={toggleModal}
      open={isModalOpen}
    >
      <Grid className={classes.modalChild} item md={4} sm={8} xs={10}>
        {success ? (
          <ClientSuccess
            handleReset={handleReset}
            isInvite={invite}
            toggleModal={toggleModal}
          />
        ) : null}
        {invite ? (
          <InviteClient
            email={formState.getValue("email")}
            handleInvite={handleInvite}
            handleReset={handleReset}
          />
        ) : null}
        {!invite && !success && (
          <ClientForm
            formState={formState}
            onSubmit={handleInvite}
            toggleModal={toggleModal}
          />
        )}
      </Grid>
    </StyledModal>
  );
};

export default AddClientModal;
