/**
 * The standard duration of a consultation.
 */

import type { TFunction } from "i18next";
import moment, { Moment } from "moment";
import { RequestedByType } from "../../../api/__generated__/graphql";
import { ID, UUID } from "../../../api/api";
import { APIConsultation } from "../../../api/consultations/consultations";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { callIsOver } from "../../../utils/dates";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import BaseType from "../../BaseType";
import { DateFormat } from "../../Date";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import CashTransaction from "../CashTransaction";
import Human from "../human/Human";
import Professional from "../professional/Professional";
import ConsultationDuration from "./ConsultationDuration";
import ConsultationMessage from "./ConsultationMessage";
import ConsultationStatus from "./ConsultationStatus";
import "moment/locale/nl-be";
import "moment/locale/fr";
import "moment/locale/en-gb";

export enum ConsultationTypes {
  APPOINTMENT = "APPOINTMENT",
  CHECKIN = "CHECKIN",
  COACHING = "COACHING",
  INTRO = "INTRO",
}

export class Consultation extends BaseType<
  APIConsultation.Result,
  DataTypePaths.ConsultationPath
> {
  protected type: any = Consultation;

  protected id: ID = 0;
  protected uuid: UUID = "";
  protected professional: Professional = new Professional();
  protected client: Human = new Human();
  protected scheduledFrom: moment.Moment = moment();
  protected scheduledTo: moment.Moment = moment();
  protected status: ConsultationStatus = new ConsultationStatus();
  protected message?: ConsultationMessage;
  protected clientMatched = false;
  protected clientRequested = false;
  protected cashTransaction?: CashTransaction;
  protected updatedAt: moment.Moment = moment();
  protected createdAt: moment.Moment = moment();
  protected consultType: ConsultationTypes = ConsultationTypes.APPOINTMENT;
  public requestedBy?: RequestedByType;
  public acceptedByProfessional: boolean | undefined;
  public acceptedByHuman: boolean | undefined;
  protected expectedReimbursementDate: moment.Moment | null = null;
  public creditTransactions: { amount: number; id: number }[] = [];

  constructor(model?: APIConsultation.Result) {
    super(model);
    this.update(model);
  }

  public update(
    model?:
      | (Partial<APIConsultation.Result> & { id: number; uuid: string })
      | null,
  ) {
    if (!model) return;

    this.id = model.id;
    this.uuid = model.uuid;

    if (model.status) {
      this.status = new ConsultationStatus(model.status);
    }

    if (model.scheduledFrom) {
      this.scheduledFrom = moment(model.scheduledFrom);
    }

    if (model.scheduledTo) {
      this.scheduledTo = moment(model.scheduledTo);
    }

    if (model.fromMatching) {
      this.clientMatched = model.fromMatching;
    }

    if (model.clientRequested) {
      this.clientRequested = model.clientRequested;
    }

    if (model.message) {
      this.setMessage(new ConsultationMessage(model.message));
    }

    if (model.professional) {
      this.assignProfessional(new Professional(model.professional, true));
    }

    if (model.human) {
      this.assignClient(new Human(model.human, true));
    }

    if (model.cashTransaction) {
      this.cashTransaction = new CashTransaction(model.cashTransaction);
    }

    if (model.creditTransactions) {
      this.creditTransactions = model.creditTransactions;
    }

    if (model.updatedAt) {
      this.updatedAt = moment(model.updatedAt);
    }

    if (model.createdAt) {
      this.createdAt = moment(model.createdAt);
    }

    if (model.type) {
      this.consultType = model.type;
    }

    if (model.expectedReimbursementDate) {
      this.expectedReimbursementDate = moment(model.expectedReimbursementDate);
    }

    if (model.requestedBy) {
      this.requestedBy = model.requestedBy;
    }

    if (model.acceptedByProfessional) {
      this.acceptedByProfessional = model.acceptedByProfessional;
    }

    if (model.acceptedByHuman) {
      this.acceptedByHuman = model.acceptedByHuman;
    }
  }

  /**
   * Get the id of this consultation.
   */
  public getID(): number {
    return this.id;
  }

  /**
   * Get the unique identifier for this consultation.
   */
  public getUUID(): string {
    return this.uuid;
  }

  /**
   * Get the start date, time and timezone of this consult.
   */
  public getStartDate(format?: DateFormat | string, locale?: string) {
    let result: moment.Moment | string = this.scheduledFrom;

    if (locale) {
      result = result.locale(locale);
    }

    if (format) {
      result = result.format(format);
    }

    return result;
  }

  /**
   * Set the date and time this consultation begins.
   */
  public setStartDate(startDate: moment.Moment | string): Consultation {
    let date: any;

    if (startDate instanceof String) {
      date = moment(startDate);
    } else {
      date = startDate;
    }

    this.scheduledFrom = date;

    return this;
  }

  /**
   * Get the end date, time and timezone of this consult.
   */
  public getEndDate(
    format?: DateFormat | string,
    locale?: string,
  ): moment.Moment | string {
    if (locale) moment.locale(locale);

    return format ? this.scheduledTo.format(format) : this.scheduledTo;
  }

  /**
   * Set the date and time this consultation ends.
   */
  public setEndDate(endDate: moment.Moment): Consultation {
    let date: any;

    if (endDate instanceof String) {
      date = moment(endDate);
    } else {
      date = endDate;
    }

    this.scheduledTo = date;

    return this;
  }

  /**
   * Get the duration in minutes for this consultation.
   */
  public getDurationInMinutes(): number {
    const mins: number = this.scheduledTo.diff(this.scheduledFrom, "minutes");

    return mins;
  }

  /**
   * Get the professional assigned to this consultation.
   */
  public getProfessional(): Professional {
    return this.professional;
  }

  /**
   * Get the client assigned to this consultation.
   */
  public getClient(): Human {
    return this.client;
  }

  /**
   * Get the message sent within this consultation scheduling.
   */
  public getMessage(): ConsultationMessage {
    return this.message || new ConsultationMessage();
  }

  /**
   * Set the message sent within this consultation scheduling.
   */
  public setMessage(message: ConsultationMessage): Consultation {
    message.assignConsultation(this);
    this.message = message;

    return this;
  }

  /**
   * Get the type of this consultation.
   */
  public getConsultationType(): ConsultationTypes {
    return this.consultType;
  }

  /**
   * Set the consultation type of this consult.
   */
  public setConsultationType(type: ConsultationTypes): Consultation {
    this.consultType = type;

    return this;
  }

  /**
   * Assign a professional to this consultation.
   *
   * @param pro A professional
   */
  public assignProfessional(pro: Professional): Consultation {
    this.professional = pro;

    return this;
  }

  /**
   * Assign a client to this consultation.
   *
   * @param client A client object.
   */
  public assignClient(client: Human): Consultation {
    this.client = client;

    return this;
  }

  /**
   * What is the status of this consultation?
   */
  public getStatus(): ConsultationStatus {
    return this.status;
  }

  /**
   * Set the status of the consultation.
   */
  public setStatus(status: ConsultationStatus): Consultation {
    this.status = status;

    return this;
  }

  /**
   * Is this a consultation that originated from the matching?
   */
  public fromMatch(): boolean {
    return this.clientMatched;
  }

  /**
   * Set whether or not this consultation results from a match.
   */
  public setFromMatch(fromMatch = true): Consultation {
    this.clientMatched = fromMatch;

    return this;
  }

  /**
   * Was this consultation requested by the client?
   */
  public requestedByClient(): boolean {
    return this.clientRequested;
  }

  /**
   * Set whether or not this consultation is requested by the client.
   */
  public setRequestedByClient(clientRequested = true): Consultation {
    this.clientRequested = clientRequested;

    return this;
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }

  /**
   * Is this consultation over?
   *
   * @return {boolean} Whether or not the consultation has passed.
   */
  public isOver(): boolean {
    return callIsOver(this.getEndDate(), 1000 * 60 * 60); // one hour in ms
  }

  public getCashTransaction(): CashTransaction | undefined {
    return this.cashTransaction;
  }

  public getExpectedReimbursementDate(): Moment | null {
    return this.expectedReimbursementDate;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:date.in.past",
          "De datum ligt in het verleden.",
        ),
        path: "date",
        validate: (date?: Moment) => {
          if (!date) return false;

          const diff = date.diff(moment(), "days");

          return diff >= 0;
        },
      },
      {
        message: translate(
          "validation:timeto.later.timefrom",
          "De eindtijd moet later zijn dan de begintijd.",
        ),
        path: ConsultationDuration.timeToPath,
        validate: (timeTo, { timeFrom }) => {
          if (!timeTo || !timeFrom) return false;

          return timeTo > timeFrom;
        },
      },
      {
        message: translate(
          "validation:needs.to.be.minimum.duration",
          "De consult moet minstens 10 mins duren.",
        ),
        path: ConsultationDuration.timeToPath,
        validate: (timeTo: moment.Moment, { timeFrom }) => {
          if (!timeTo || !timeFrom) return false;

          if (!moment.isMoment(timeTo)) return false;

          return timeTo.diff(timeFrom, "minutes") >= 10;
        },
      },
      {
        message: translate(
          "validation:time.in.past",
          "De tijd ligt in het verleden.",
        ),
        path: ConsultationDuration.timeFromPath,
        validate: (timeFrom) => {
          if (!timeFrom) return false;

          const diff = timeFrom.diff(moment(), "days");

          return diff >= 0;
        },
      },
      {
        message: translate(
          "validation:already.consult.at.time",
          "Je hebt al een consultatie op dat moment.",
        ),
        path: ConsultationDuration.timeFromPath,
        validate: (timeFrom, { consultations, timeTo }, options) => {
          if (!timeFrom || !timeTo) return false;

          if (!moment.isMoment(timeFrom) || !moment.isMoment(timeTo)) {
            return true;
          }

          let consultationsToBeSearched = consultations ?? [];

          // Filter current consultation if we're editing an existing one
          if (options && options.currentConsultation) {
            consultationsToBeSearched = consultations.filter(
              (c) => c.id !== options.currentConsultation.id,
            );
          }

          const filterFunction = (consult: Consultation) => {
            const status = consult.getStatus().getValue();

            const consultStart: moment.Moment | string = consult.getStartDate();

            const consultEnd: moment.Moment | string = consult.getEndDate();

            return (
              status !== "DONE" &&
              status !== "CANCELLED_BY_PROFESSIONAL" &&
              status !== "CANCELLED_BY_HUMAN" &&
              (timeTo.isBetween(consultStart, consultEnd) ||
                timeFrom.isBetween(consultStart, consultEnd))
            );
          };

          const result =
            consultationsToBeSearched.filter(filterFunction).length > 0;

          return !result;
        },
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, APIConsultation.Result>
  > {
    return { consultation: this.value };
  }
}

export default Consultation;
