/* eslint-disable react-hooks/exhaustive-deps */

import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import InlineEditableControls from "../../../ui/edit/common/InlineEditableControls";
import ChipList from "../../../ui/form/ChipList";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import MultiSelect from "../../../ui/form/select/MultiSelect";

const subtopicFragment = graphql(`
  fragment Subtopic on Subtopic {
    descriptionTranslationKey
    imageUrl
    titleTranslationKey
  }
`);

const subtopicsOnProfessionalFragment = graphql(`
  fragment SubtopicsOnProfessional on Professional {
    primarySubtopics {
      id
      ...Subtopic
    }
    secondarySubtopics {
      id
      ...Subtopic
    }
  }
`);

const allSubtopicsQuery = graphql(`
  query AllSubtopics {
    subtopics {
      id
      ...Subtopic
    }
  }
`);

const updateProfessionalSubtopicsMutation = graphql(`
  mutation UpdateProfessionalSubtopics(
    $input: UpdateProfessionalSubtopicsInput!
  ) {
    updateProfessionalSubtopics(input: $input) {
      id
      ...SubtopicsOnProfessional
    }
  }
`);

interface SubtopicSelectProps {
  label: string;
  primary: boolean;
  professional: FragmentType<typeof subtopicsOnProfessionalFragment>;
}

export default function SubtopicSelect({
  label,
  primary,
  professional,
}: SubtopicSelectProps) {
  const { t } = useTranslation();
  const primarySubtopicsOfProfessional = getFragmentData(
    subtopicsOnProfessionalFragment,
    professional,
  ).primarySubtopics;

  const secondarySubtopicsOfProfessional = getFragmentData(
    subtopicsOnProfessionalFragment,
    professional,
  ).secondarySubtopics;

  const defaultSubtopics: ISelectObject<string>[] = (
    primary ? primarySubtopicsOfProfessional : secondarySubtopicsOfProfessional
  ).map((subtopic) => ({
    label: t(getFragmentData(subtopicFragment, subtopic).titleTranslationKey),
    value: subtopic.id,
  }));

  const [selectedSubtopics, setSelectedSubtopics] = useState(defaultSubtopics);

  const { data } = useQuery(allSubtopicsQuery);
  const options = data?.subtopics.reduce((acc, subtopic) => {
    if (
      !(
        primary
          ? secondarySubtopicsOfProfessional
          : primarySubtopicsOfProfessional
      )
        .map((alreadySelectedSubtopic) => alreadySelectedSubtopic.id)
        .includes(subtopic.id)
    ) {
      acc.push({
        label: t(
          getFragmentData(subtopicFragment, subtopic).titleTranslationKey,
        ),
        value: subtopic.id,
      });
    }

    return acc;
  }, [] as ISelectObject<string>[]);

  const [updateProfessionalSubtopics] = useMutation(
    updateProfessionalSubtopicsMutation,
  );

  return (
    <Box mt={3}>
      <InlineEditableControls
        edit={
          <MultiSelect<string>
            closeMenuOnSelect
            onChange={(selected) => {
              // Don't update if 5 are selected, will be explained in text
              if (selected.length <= 5) {
                setSelectedSubtopics(selected);
              }
            }}
            options={options ?? []}
            placeholder={t("profile:subtopics_placeholder")}
            value={selectedSubtopics}
          />
        }
        editable={true}
        label={label}
        onDiscard={() => {
          setSelectedSubtopics(defaultSubtopics);

          return true;
        }}
        onSave={async () => {
          await updateProfessionalSubtopics({
            variables: {
              input: {
                primary,
                subtopicIds: selectedSubtopics.map(
                  (subtopic) => subtopic.value,
                ),
              },
            },
          });

          return true;
        }}
        read={
          <ChipList
            list={selectedSubtopics.map((subtopic) => subtopic.label)}
          />
        }
      />
    </Box>
  );
}
