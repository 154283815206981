import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import CheckBox from "../../../ui/form/CheckBox";
import Label from "../../../ui/form/FormLabel";
import SanitizedText from "../../../ui/text/SanitizedText";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";
import { FORM_KEY_ACCEPTED_CONSENT } from "../useProfessionalOnboardFormState";

export default function ConsentStep() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Common,
  ]);

  const { disable, formState, next, previous, step, totalSteps } =
    useContext<ProfessionalOnboardingContextProps>(
      ProfessionalOnboardingContext,
    );

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={disable || formState.testForErrors(FORM_KEY_ACCEPTED_CONSENT)}
        fullCopyright
        headline={translate("professional:onboarding.title")}
        labelBack={translate("common:onboarding.button.label.previous")}
        labelNext={
          disable ? translate("common:loading") : translate("common:complete")
        }
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.consent.title")}
        subheadicon={<CheckBoxIcon />}
        totalSteps={totalSteps}
      >
        <Label
          label={
            <SanitizedText
              input={translate("professional:onboarding.consent.description")}
            />
          }
          style={{
            marginBottom: 25,
          }}
        />
        <CheckBox
          {...formState.getInputProps(FORM_KEY_ACCEPTED_CONSENT)}
          handleNativeChange={(_, checked) =>
            formState.handleChange(FORM_KEY_ACCEPTED_CONSENT, checked)
          }
          label={translate("professional:onboarding.consent.agree")}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
