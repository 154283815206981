import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";

const PREFIX = "RadioQuestion";

const classes = {
  checkedIcon: `${PREFIX}-checkedIcon`,
  formControlLabelLabel: `${PREFIX}-formControlLabelLabel`,
  formControlLabelLabelChecked: `${PREFIX}-formControlLabelLabelChecked`,
  formControlLabelRoot: `${PREFIX}-formControlLabelRoot`,
  helperText: `${PREFIX}-helperText`,
  icon: `${PREFIX}-icon`,
  radioBox: `${PREFIX}-radioBox`,
  radiosBox: `${PREFIX}-radiosBox`,
  root: `${PREFIX}-root`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.root}`]: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`&.${classes.radiosBox}`]: {
    background: theme.palette.white.main,
    display: "flex",
    padding: "10px",
    paddingBottom: "5px",
    ...theme.controls.border.normal,

    "&:hover": {
      ...theme.controls.border.hover,
    },
  },

  [`& .${classes.radioBox}`]: {
    "&:hover": {
      color: theme.palette.bloom.dark,
    },
    marginBottom: theme.spacing(1),
    padding: "2px",
    paddingLeft: "5px",

    textAlign: "left",
  },

  [`& .${classes.formControlLabelRoot}`]: {
    display: "flex",
    marginLeft: -8,
    marginRight: 0,
  },

  [`& .${classes.formControlLabelLabel}`]: { fontSize: 14 },

  [`& .${classes.formControlLabelLabelChecked}`]: {
    color: theme.palette.bloom.dark,
    fontSize: 14,
  },

  [`& .${classes.icon}`]: {
    "$root.Mui-focusVisible &": {
      outline: "3px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    borderRadius: "50%",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    height: 16,

    "input:disabled ~ &": {
      background: "rgba(206,217,224,.5)",
      boxShadow: "none",
    },

    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },

    width: 16,
  },

  [`& .${classes.checkedIcon}`]: {
    "&:before": {
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
      display: "block",
      height: 16,
      width: 16,
    },
    backgroundColor: theme.palette.bloom.main,

    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",

    "input:hover ~ &": {
      backgroundColor: theme.palette.bloom.main,
    },
  },

  [`& .${classes.helperText}`]: {
    ...theme.controls.validation.error,
  },
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  return (
    <Radio
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      className={classes.root}
      color="default"
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function RadioQuestion({
  errorText,
  onChange: callback,
  options,
  value: currentValue,
}) {
  const handleChange = (event) => {
    callback(event.target.value);
  };

  return (
    <StyledFormControl className={classes.radiosBox} error variant="standard">
      <RadioGroup aria-label="motivation" onChange={handleChange}>
        {options.map(({ label, value }) => {
          return (
            <Box className={classes.radioBox} key={value}>
              <FormControlLabel
                checked={value.toString() === currentValue.toString()}
                classes={{
                  label:
                    value === currentValue
                      ? classes.formControlLabelLabelChecked
                      : classes.formControlLabelLabel,
                  root: classes.formControlLabelRoot,
                }}
                control={<StyledRadio />}
                id={`test_rate_duration_${value}`}
                label={label}
                value={value}
              />
            </Box>
          );
        })}
      </RadioGroup>
      <FormHelperText className={classes.helperText} id="component-helper-text">
        {errorText}
      </FormHelperText>
    </StyledFormControl>
  );
}
