import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { IMutationInput } from "../../api";
import { MUTATION_ANSWER_QUESTION } from "../mutations";
import { APIQualityQuestions } from "../qualityQuestions";

export default function useAnswerQuestion() {
  const [mutate, { data, error, loading }] = useMutation<
    boolean,
    IMutationInput<APIQualityQuestions.Answer.Input>
  >(MUTATION_ANSWER_QUESTION);

  const answerQuestion = useCallback(
    (
      questionId: number,
      answers: Array<string | number>,
      consultationId: number | null,
    ) => {
      mutate({
        variables: {
          input: {
            answer: JSON.stringify(answers),
            consultationId,
            questionId,
          },
        },
      });
    },
    [mutate],
  );

  return {
    answerQuestion,
    data,
    error,
    loading,
  };
}
