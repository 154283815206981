/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useRegisterProfessional from "../../../../api/professionals/hooks/useRegisterProfessional";
import { ERROR_CODE_UPLOAD } from "../../../../constants";
import Timezone from "../../../../data-model/types/profile/Timezone";
import { parseGraphQLErrors } from "../../../../utils/graphql";
import { I18Namespaces } from "../../../language/I18Namespaces";
import { prepareOneLanguageForAPI } from "../../../language/languagesUtil";
import ToastContext from "../../../providers/toast/ToastContext";
import { IToastContext } from "../../../providers/toast/toast";
import { RouteNames } from "../../../routes/routeNames";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import useRegisterFormState from "../useRegisterFormState";
import useRegisterProfessionalVerifyFormState from "../useRegisterProfessionalVerifyFormState";
import ProfessionalRegistrationContext from "./ProfessionalRegistrationContext";
import { IProfessionalRegistrationContext } from "./professionalRegistration";

export default function ProfessionalRegistrationProvider({
  children,
}: IProfessionalRegistrationContext) {
  const { setToast } = useContext<IToastContext>(ToastContext);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [acceptedConsent, setAcceptedConsent] = useState<boolean>(false);
  const registerFormState = useRegisterFormState();
  const verifyFormState = useRegisterProfessionalVerifyFormState({
    professionalType: registerFormState.values?.professionalType,
  });

  const navigate = useNavigate();

  const { loading: isRegistering, registerProfessional } =
    useRegisterProfessional();

  const { i18n, t: translate } = useTranslation<I18Namespaces>("validation");

  const next = useCallback(
    () => navigate(RouteNames.Register.Professional.Verify.path),
    [history],
  );

  const previous = useCallback(
    () => navigate(RouteNames.Register.Professional.Start.path),
    [history],
  );

  const register = async () => {
    const isValid = registerFormState.validate() && verifyFormState.validate();

    if (isValid) {
      try {
        const { consultationLanguages, email, firstName, lastName, phone } =
          registerFormState.values;

        const {
          licenseFiles,
          professionalType,
          psychoTherapyTraining,
          website,
        } = verifyFormState.values;

        //TODO: do we need this await?
        await registerProfessional({
          acceptedConsent,
          consultationLanguages: JSON.stringify(
            consultationLanguages.map((l: ISelectObject) =>
              prepareOneLanguageForAPI(l.value),
            ),
          ),
          email: email.toLowerCase(),
          firstName,
          lastName,
          licenseFiles,
          phone,
          preferredLanguage: prepareOneLanguageForAPI(i18n.language),
          timezone: new Timezone().updateToCurrent().getValue(),
          training: psychoTherapyTraining.value,
          type: professionalType,
          website,
        });

        navigate(RouteNames.Register.Professional.Success.path);
      } catch (e) {
        const graphqlErrors = parseGraphQLErrors(e);

        if (graphqlErrors === ERROR_CODE_UPLOAD) {
          setUploadError(true);
        } else if (graphqlErrors === null) {
          setToast({
            message: translate(
              "validation:graphql.error",
              "Er liep iets mis. Probeer het later opnieuw aub.",
            ),
            severity: "warning",
          });
        } else {
          verifyFormState.updateErrors(graphqlErrors);
          setToast(graphqlErrors.map(({ message }) => message).join(";"));
        }
      }
    }
  };

  return (
    <ProfessionalRegistrationContext.Provider
      value={{
        acceptedConsent,
        formState: registerFormState,
        hasUploadError: uploadError,
        isRegistering,
        next,
        previous,
        registerProfessional: register,
        setAcceptedConsent,
        verifyFormState,
      }}
    >
      {children}
    </ProfessionalRegistrationContext.Provider>
  );
}
