import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerProps } from "./answers";

const PREFIX = "AnswerYesNoMaybe";

const classes = {
  buttonSpacing: `${PREFIX}-buttonSpacing`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.buttonSpacing}`]: {
    margin: 10,
  },
}));

type AnswerButtonsOptions =
  | QualityQuestions.Type.YesNo
  | QualityQuestions.Type.YesNoMaybe;

interface AnswerButtonsProps extends SharedAnswerProps {
  type: AnswerButtonsOptions;
}

enum AnswerOptions {
  MAYBE = "maybe",
  NO = "no",
  NONE = "none",
  YES = "yes",
}

export default function AnswerYesNoMaybe({
  onAnswerChanged,
  type,
}: AnswerButtonsProps) {
  const [answer, setAnswer] = useState<AnswerOptions>(AnswerOptions.NONE);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const onChange = useCallback(
    ({ currentTarget }) => {
      setAnswer(currentTarget.value);
      onAnswerChanged([currentTarget.value]);
    },
    [onAnswerChanged],
  );

  return (
    <StyledBox className={classes.root}>
      <Button
        buttonSelected={answer === AnswerOptions.NO ? "yes" : "no"}
        className={classes.buttonSpacing}
        label={translate("common:no", "Nee")}
        onClick={onChange}
        value={AnswerOptions.NO}
      />
      {type === QualityQuestions.Type.YesNoMaybe && (
        <Button
          buttonSelected={answer === AnswerOptions.MAYBE ? "yes" : "no"}
          className={classes.buttonSpacing}
          label={translate("common:misschien", "Misschien")}
          onClick={onChange}
          value={AnswerOptions.MAYBE}
        />
      )}
      <Button
        buttonSelected={answer === AnswerOptions.YES ? "yes" : "no"}
        className={classes.buttonSpacing}
        label={translate("common:yes", "Ja")}
        onClick={onChange}
        value={AnswerOptions.YES}
      />
    </StyledBox>
  );
}
