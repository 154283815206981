import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerWithOptionsProps } from "./answers";

export default function AnswerLikert({
  onAnswerChanged,
  options,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.Likert>) {
  const sortedOptions = useMemo(() => {
    options.sort((a, b) => a.step - b.step);

    return options;
  }, [options]);

  const [selected, setSelected] = useState<number>(0);

  const onChange = useCallback(
    (_, value) => {
      setSelected(Number(value));

      const chosenOption = options.find(
        (o) => Number(o.value) === Number(value),
      );

      if (chosenOption) onAnswerChanged([chosenOption.value]);
    },
    [onAnswerChanged, options],
  );

  return (
    <FormControl style={{ overflow: "visible" }} variant="standard">
      <RadioGroup onChange={onChange} row value={selected}>
        {sortedOptions.map(
          (option: QualityQuestions.Options.Option, i: number) => (
            <FormControlLabel
              control={<Radio />}
              key={i}
              label={option.label}
              value={option.value}
            />
          ),
        )}
      </RadioGroup>
    </FormControl>
  );
}
