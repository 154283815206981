/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../components/language/I18Namespaces";
import Human from "../../data-model/types/human/Human";
import Professional from "../../data-model/types/professional/Professional";

enum CrispEvent {
  HumanB2BSignin = "human:b2b:signin",
  HumanB2CSignin = "human:b2c:signin",
  HumanSignin = "human:signin",
  ProfessionalSignin = "professional:signin",
  UserSignin = "user:signin",
}

enum CrispValue {
  Session = "session:event",
  SessionData = "session:data",
  UserCompany = "user:company",
  UserEmail = "user:email",
}

export default function useSendCrispEvent() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
  ]);

  const [isSending, setIsSending] = useState<boolean>(false);
  const setCrispValue = useCallback(
    (value: CrispValue, event: CrispEvent | string | string[][]) => {
      if (window.$crisp && window.$crisp.push) {
        window.$crisp.push(["set", value, event]);
      }
    },
    [window.$crisp],
  );

  const sendUserLogin = useCallback(
    (currentUser: Professional | Human) => {
      try {
        setIsSending(true);

        if (currentUser && !isSending) {
          // Add this user's email
          setCrispValue(
            CrispValue.UserEmail,
            currentUser.getEmail().getValue(),
          );

          if (currentUser instanceof Human) {
            const organization = currentUser.getOrganization();

            if (
              organization.getSlug() !== "" &&
              organization.getName(translate)
            ) {
              setCrispValue(
                CrispValue.UserCompany,
                organization.getName(translate),
              );

              setCrispValue(CrispValue.SessionData, [["client-type", "b2b"]]);

              setCrispValue(CrispValue.Session, CrispEvent.HumanB2BSignin);
            } else {
              setCrispValue(CrispValue.SessionData, [["client-type", "b2c"]]);

              setCrispValue(CrispValue.Session, CrispEvent.HumanB2CSignin);
            }
          }

          // Send user login event
          setCrispValue(CrispValue.Session, CrispEvent.UserSignin);

          // Send session event for professional or Human
          setCrispValue(
            CrispValue.Session,
            currentUser.isProfessional()
              ? CrispEvent.ProfessionalSignin
              : CrispEvent.HumanSignin,
          );
        }

        setIsSending(false);
      } catch (error) {
        console.warn(`Could not send value to Crisp: ${error}`);
      }
    },
    [setCrispValue, isSending],
  );

  return {
    isSending,
    sendUserLoginToCrisp: sendUserLogin,
  };
}
