import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CopyCalendarEventsInput } from "../../__generated__/graphql";
import { MUTATION_COPY_CALENDAR_EVENTS } from "../mutations";

export default function useCopyCalendarEvents() {
  const [execute, { data, error, loading }] = useMutation(
    MUTATION_COPY_CALENDAR_EVENTS,
  );

  const copyCalendarEvents = useCallback(
    (input: CopyCalendarEventsInput) => {
      return execute({
        variables: {
          input: {
            ...input,
            returnAsFullCalendar: true,
          },
        },
      });
    },
    [execute],
  );

  return {
    copyCalendarEvents,
    data: data?.copyCalendarEvents,
    error,
    loading,
  };
}
