import { Box, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { MouseEvent } from "react";
import { BloomColor } from "../layout/wizard/StepFooter";
import StyledTooltip from "./Tooltip";

const PREFIX = "BloomUpSwitch";

const classes = {
  root: `${PREFIX}-root`,
  switch: `${PREFIX}-switch`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
  },

  [`& .${classes.switch}`]: {
    "& .Mui-checked": {
      color: theme.palette.green.main,
    },
    "& .MuiSwitch-switchBase": {
      color: theme.palette.red.light,
    },
    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.red.light,
    },
  },
}));

interface BloomUpSwitchProps {
  disabled?: boolean;
  label?: string;
  onChange: (checked: boolean, onClick?: MouseEvent<HTMLElement>) => void;
  switchColor?: BloomColor;
  toolTip?: string;
  value: boolean;
}

export default function BloomUpSwitch({
  disabled,
  label,
  onChange,
  toolTip,
  value,
}: BloomUpSwitchProps) {
  return (
    <StyledTooltip placement="bottom-start" text={toolTip ?? ""}>
      <StyledBox
        className={classes.root}
        onClick={(onClick) => !disabled && onChange(!value, onClick)}
      >
        <Switch
          checked={value}
          className={classes.switch}
          color="primary"
          disabled={disabled}
        />
        {label ? <Typography>{label}</Typography> : null}
      </StyledBox>
    </StyledTooltip>
  );
}
