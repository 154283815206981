import { Box } from "@mui/material";
import { Moment } from "moment";
import Consultation from "../../../data-model/types/consultation/Consultation";
import CalendarLinks from "../../layout/CalendarLinks";

interface IConsultationCalendarLinks {
  consultation: Consultation;
}

export default function ConsultationCalendarLinks({
  consultation,
}: IConsultationCalendarLinks) {
  const appUrl = window.location.origin;
  const path = `consultation/${consultation.getUUID()}`;

  return (
    <Box mt={4}>
      <CalendarLinks
        appUrl={appUrl}
        clientName={consultation.getClient().getFullName() ?? ""}
        path={path}
        scheduledFrom={(consultation.getStartDate() as Moment).toLocaleString()}
        scheduledTo={(consultation.getEndDate() as Moment).toLocaleString()}
      />
    </Box>
  );
}
