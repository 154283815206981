import { useContext } from "react";
import { RouteProps } from "react-router";
import AuthContext from "../providers/auth/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import { RouteNames } from "./routeNames";

export default function AuthRoute({ children }: RouteProps) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <ProtectedRoute
      accessCondition={isAuthenticated}
      redirectPath={RouteNames.Login.path}
    >
      {children}
    </ProtectedRoute>
  );
}
