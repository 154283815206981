import { Navigate, Route, Routes } from "react-router";
import CrispChat from "../../utils/crisp-chat/CrispChat";
import { defaultConsultationsValues } from "../consultations/context/ConsultationsOverviewContext";
import ConsultationsOverviewContextProvider from "../consultations/context/ConsultationsOverviewProvider";
import SidebarWithState from "../layout/sidebar/SidebarWithState";
import ProfessionalAvailabilityPage from "../pages/professional-availability/ProfessionalAvailabilityPage";
import ProfessionalAvailabilityContextProvider from "../pages/professional-availability/context/ProfessionalAvailabilityContextProvider";
import ClientsForProfessionalsPage from "../pages/professional-clients/ClientsForProfessionalsPage";
import { defaulClientsForProfessionalsValues } from "../pages/professional-clients/context/ClientsForProfessionalsContext";
import ClientsForProfessionalsProvider from "../pages/professional-clients/context/ClientsForProfessionalsProvider";
import ColleaguesPage from "../pages/professional-colleagues/ColleaguesPage";
import ProfessionalProfilePage from "../pages/professional-profile/ProfessionalProfilePage";
import ProfessionalProfileContextProvider from "../pages/professional-profile/context/ProfessionalProfileContextProvider";
import ProfessionalCompletedConsultations from "../professionals/professionalCompletedConsultations";
import ProfessionalConsultations from "../professionals/professionalConsultations";
import OnboardRequired from "./guards/OnboardedGuard";
import ProfessionalGuard from "./guards/ProfessionalGuard";
import { RouteNames } from "./routeNames";

export default function ProfessionalHomeRoutes() {
  const ClientsForProfessionalsPageWithContexts = (
    <ConsultationsOverviewContextProvider {...defaultConsultationsValues}>
      <ClientsForProfessionalsProvider {...defaulClientsForProfessionalsValues}>
        <ClientsForProfessionalsPage />
      </ClientsForProfessionalsProvider>
    </ConsultationsOverviewContextProvider>
  );

  return (
    <ProfessionalGuard>
      <OnboardRequired>
        <CrispChat />
        <SidebarWithState>
          <Routes>
            <Route
              element={<ProfessionalConsultations />}
              path={`consultations/:uuid?`}
            />
            <Route
              element={<ProfessionalCompletedConsultations />}
              path={`completed-consultations/:uuid?`}
            />
            <Route
              element={
                <ProfessionalAvailabilityContextProvider>
                  <ProfessionalAvailabilityPage />
                </ProfessionalAvailabilityContextProvider>
              }
              path={RouteNames.Home.Availability.name}
            />

            <Route
              element={ClientsForProfessionalsPageWithContexts}
              path={RouteNames.Home.Clients.name}
            >
              <Route
                element={ClientsForProfessionalsPageWithContexts}
                path={RouteNames.Home.Clients.ShowClient.name}
              />
            </Route>
            <Route
              element={<ColleaguesPage />}
              path={RouteNames.Home.Colleagues.name}
            >
              <Route
                element={<ColleaguesPage />}
                path={RouteNames.Home.Colleagues.ShowColleague.name}
              />
            </Route>
            <Route
              element={
                <ProfessionalProfileContextProvider>
                  <ProfessionalProfilePage />
                </ProfessionalProfileContextProvider>
              }
              path={RouteNames.Home.Profile.name}
            />
            <Route
              element={<Navigate replace={true} to="consultations" />}
              path={""}
            />
          </Routes>
        </SidebarWithState>
      </OnboardRequired>
    </ProfessionalGuard>
  );
}
