import { formatISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { makeFragmentData } from "../../../api/__generated__";
import { Language } from "../../../api/__generated__/graphql";
import useFetchConsultationsForProfessionalAndHuman from "../../../api/consultations/hooks/useFetchConsultationsForProfessionalAndHuman";
import { UserRoles } from "../../../data-model/types/User";
import Human from "../../../data-model/types/human/Human";
import ConsultationList from "../../consultations/overview/ConsultationList";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import RightSection from "../../layout/RightSection";
import ViewOnlyProfileInfo, {
  viewOnlyProfileInfoFragment,
} from "../../profile/ViewOnlyProfileInfo";
import TitleWithButton from "../../ui/text/TitleWithButton";

interface IClientDetailWithState {
  client: Human;
  handleClick: (id: number) => void;
  handleClientNotFound: () => void;
}

export default function ClientDetailWithState({
  client,
  handleClick,
}: IClientDetailWithState) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Client,
  ]);

  //TODO: this query should check (in the backend) if the professionalID actually belongs to
  // a professional that is linked to this human
  const { consultations, loading } =
    useFetchConsultationsForProfessionalAndHuman(client.getID());

  if (loading) return <LoadingPage full={false} />;

  const birthDate = client.getBirthDate().getValue();

  return (
    <RightSection
      handleClose={handleClick}
      title={translate("client:client_detail.title", "Cliënt")}
    >
      -{" "}
      <ViewOnlyProfileInfo
        user={makeFragmentData(
          {
            birthDate: birthDate && formatISO(birthDate),
            email: client.getEmail().getValue(),
            firstName: client.getFirstName().getValue(),
            id: client.getUUID(),
            lastName: client.getLastName().getValue(),
            phone: client.getPhone().getValue(),
            preferredLanguage: client
              .getPreferredLanguage()
              .getValue() as Language,
            professional: null,
            timezone: client.getTimezone().getValue(),
          },
          viewOnlyProfileInfoFragment,
        )}
      />
      <TitleWithButton
        handleButton={() => console.log("ClientDetailWithState Handle button")}
        title={translate("common:consultations", "Consultaties")}
      />
      <ConsultationList
        //TODO: add consultations and check other properties
        consultations={consultations}
        emptyText={translate(
          "common:status.not_found.consultations",
          "Geen consultaties gevonden",
        )}
        isOpen={true}
        listName={translate(
          "common:client_detail.consultation_list.title",
          "Alle consultaties",
        )}
        onSelectConsultation={() => console.log("Consultation selected")}
        roleType={UserRoles.PROFESSIONAL}
      />
    </RightSection>
  );
}
