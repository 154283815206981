import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";

export default function WelcomeStep() {
  const { next } = useContext<ProfessionalOnboardingContextProps>(
    ProfessionalOnboardingContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
  ]);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("common:welcome", "Welkom")}
        labelNext={translate("common:start", "Start")}
        onClickNext={() => next()}
        showLanguageSelector={false}
        subhead={translate("common:welcome.long", "Welkom bij Bloomup!")}
        subheadicon={<PersonOutlinedIcon />}
      >
        <Box py={1}>
          <Typography variant="body1">
            {translate(
              "professional:onboarding.welcome.description",
              "Vooraleer je verder kan moet je nog enkele korte vragen beantwoorden om je profiel te vervolledigen. Daarna kan je meteen aan de slag met je eerste consultatie.",
            )}
          </Typography>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
