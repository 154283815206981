import DevicesOutlined from "@mui/icons-material/DevicesOutlined";
import EventAvailableOutlined from "@mui/icons-material/EventAvailableOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import ScreenShareOutlined from "@mui/icons-material/ScreenShareOutlined";
import { Box, styled, Typography } from "@mui/material";
import {
  useContentShareControls,
  useContentShareState,
} from "amazon-chime-sdk-component-library-react";
import { useTranslation } from "react-i18next";
import { Sidepanels } from "../ButtonBar";
import SidePanel from "./SidePanel";

const MenuItem = styled(Box)({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  flexFlow: "row nowrap",
  paddingTop: "20px",
  width: "100%",
});

const MenuSidePanel = ({
  isProfessional,
  setSidePanelToShow,
}: {
  isProfessional: boolean;
  setSidePanelToShow: (sidePanel: Sidepanels) => void;
}) => {
  const { t } = useTranslation();

  const { toggleContentShare } = useContentShareControls();

  const { isLocalUserSharing } = useContentShareState();

  return (
    <SidePanel
      TitleIcon={MoreHoriz}
      returnToPreviousPane={() => setSidePanelToShow("none")}
      title={t("ui:sidepane.menu.title")}
    >
      {isProfessional ? (
        <MenuItem
          onClick={() => {
            setSidePanelToShow("booking");
          }}
        >
          <EventAvailableOutlined
            sx={{ fontSize: "40px", marginRight: "30px" }}
          />
          <Typography sx={(theme) => ({ ...theme.typography.h6 })}>
            {t("ui:sidepane.menu.item.book.consultation")}
          </Typography>
        </MenuItem>
      ) : null}
      <MenuItem onClick={() => toggleContentShare()}>
        <ScreenShareOutlined sx={{ fontSize: "40px", marginRight: "30px" }} />
        <Typography sx={(theme) => ({ ...theme.typography.h6 })}>
          {isLocalUserSharing
            ? t("ui:sidepane.menu.item.stop.screenshare")
            : t("ui:sidepane.menu.item.screenshare")}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSidePanelToShow("settings");
        }}
      >
        <DevicesOutlined sx={{ fontSize: "40px", marginRight: "30px" }} />
        <Typography sx={(theme) => ({ ...theme.typography.h6 })}>
          {t("ui:sidepane.menu.videoaudio.title")}
        </Typography>
      </MenuItem>
    </SidePanel>
  );
};

export default MenuSidePanel;
