import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { ChannelProvider } from "ably/react";
import {
  DeviceLabels,
  MeetingStatus,
  useLocalVideo,
  useMeetingManager,
  useMeetingStatus,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import {
  EventAttributes,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { graphql } from "../../api/__generated__";
import LoadingPage from "../layout/LoadingPage";
import AuthContext from "../providers/auth/AuthContext";
import ToastContext from "../providers/toast/ToastContext";
import { IToastContext } from "../providers/toast/toast";
import { QualityQuestions } from "../quality-questions/QualityQuestions.types";
import { RouteNames } from "../routes/routeNames";
import ButtonBar from "./ButtonBar";
import RealTimeRefetchConsultation from "./RealTimeRefetchConsultation";
import StartCallPopup from "./StartCallPopup";
import { VideoTileGrid } from "./VideoTileGrid";

const consultationQuery = graphql(`
  query VideoCallConsultation($uuid: String!) {
    consultation(uuid: $uuid) {
      ...ButtonBarConsultation
      id
      professional {
        id
        user {
          id
        }
      }
      ...StartCallPopupConsultation
      type
      uuid
    }
  }
`);

const userJoinedConsultationMutation = graphql(`
  mutation UserJoinedConsultation($consultationId: Int!) {
    userJoinedConsultation(consultationId: $consultationId) {
      id
      status
    }
  }
`);

const startVideoCallMutation = graphql(`
  mutation StartVideoCallV2($consultationUuid: String!) {
    startVideoCallV2(consultationUuid: $consultationUuid) {
      attendeeInfo {
        AttendeeId
        ExternalUserId
        JoinToken
      }
      meetingInfo {
        ExternalMeetingId
        MediaPlacement {
          AudioFallbackUrl
          AudioHostUrl
          ScreenDataUrl
          ScreenSharingUrl
          ScreenViewingUrl
          SignalingUrl
        }
        MediaRegion
        MeetingId
      }
    }
  }
`);

const VideoCall = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { setToast } = useContext<IToastContext>(ToastContext);

  const [startVideoCall, startVideoCallResponse] = useMutation(
    startVideoCallMutation,
  );

  const [userJoinedConsultation] = useMutation(userJoinedConsultationMutation);

  const { data: consultationData, refetch: refetchConsultationData } = useQuery(
    consultationQuery,
    {
      variables: { uuid: uuid ?? "" },
    },
  );

  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const meetingStatus = useMeetingStatus();
  const { roster } = useRosterState();
  const amountOfAttendees = useMemo(
    () => Object.values(roster).length,
    [roster],
  );

  const [hasJoinedMeeting, setHasJoinedMeeting] = useState(false);
  const [hasStartedMeeting, setHasStartedMeeting] = useState(false);
  const [hadAtLeastTwoAttendees, setHadAtLeastTwoAttendees] = useState(false);

  const isProfessional =
    currentUser?.getUUID() ===
    consultationData?.consultation?.professional?.user.id;

  useEffect(() => {
    if (!startVideoCallResponse.loading && !startVideoCallResponse.called) {
      const init = async () => {
        if (!uuid) {
          // should never happing because of the routing
          throw new Error("NO_CONSULTATION_UUID");
        }

        try {
          const response = await startVideoCall({
            variables: { consultationUuid: uuid },
          });

          if (!response.data?.startVideoCallV2) {
            // this should never happen because the promise would be rejected
            throw new Error("NO_VIDEO_CALL_DATA");
          }

          const meetingSessionConfiguration = new MeetingSessionConfiguration(
            response.data.startVideoCallV2.meetingInfo,
            response.data.startVideoCallV2.attendeeInfo,
          );

          await meetingManager.join(meetingSessionConfiguration, {
            deviceLabels: DeviceLabels.AudioAndVideo,
          });
          setHasJoinedMeeting(true);
        } catch (error) {
          let message = t("errors:videocall.start");

          if (error instanceof ApolloError) {
            if (error.message === "USER_NOT_ALLOWED_IN_CALL") {
              message = t("errors:videocall.user_not_allowed");
            } else if (error.message === "AWS_CHIME_ERROR") {
              message = t("errors:videocall.chime_error");
            } else if (error.message === "CONSULTATION_NOT_ACTIVE") {
              message = t("errors:videocall.inactive_consultation");
            }
          }

          setToast({
            message,
            noHide: true,
            severity: "error",
          });
        }
      };

      init();
    }
  }, [
    meetingManager,
    setToast,
    startVideoCall,
    startVideoCallResponse,
    t,
    uuid,
  ]);

  const startCall = async () => {
    await meetingManager.start();

    if (consultationData?.consultation?.id !== undefined) {
      await userJoinedConsultation({
        variables: { consultationId: consultationData.consultation.id },
      });
    }

    setHasStartedMeeting(true);
  };

  // Turns on the video when the meeting has started, needs a useEffect see
  // https://github.com/aws/amazon-chime-sdk-component-library-react/issues/659
  useEffect(() => {
    async function toggle() {
      if (meetingStatus === MeetingStatus.Succeeded && !isVideoEnabled) {
        await toggleVideo();
      }
    }
    toggle();
    // This is needed because for some reason the toggleVideo function is redefined everytime it's called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingStatus]);

  const exitCall = async () => {
    meetingManager.audioVideo?.stopVideoInput();

    await meetingManager.leave();

    if (hadAtLeastTwoAttendees) {
      const entrypoint =
        isProfessional || consultationData?.consultation?.type === "APPOINTMENT" // No idea why this is different for profs was copied from the previous implementation
          ? QualityQuestions.Entrypoints.AfterConsultation
          : QualityQuestions.Entrypoints.AfterIntro;

      navigate(
        QualityQuestions.getQuestionPath(
          entrypoint,
          RouteNames.Home.path,
          true,
          consultationData?.consultation?.id,
        ),
      );
    } else {
      navigate(RouteNames.Home.path);
    }
  };

  useEffect(() => {
    if (amountOfAttendees === 2 && !hadAtLeastTwoAttendees) {
      setHadAtLeastTwoAttendees(true);
    }
  }, [amountOfAttendees, hadAtLeastTwoAttendees]);

  useEffect(() => {
    const observer = {
      eventDidReceive: (name: string, attributes: EventAttributes) => {
        const { meetingHistory, ...otherAttributes } = attributes;

        switch (name) {
          case "videoInputFailed":
            setToast({
              message: t("error:videocall.videoinputfailed"),
              noHide: true,
              severity: "error",
            });
            break;

          case "audioInputFailed":
            setToast({
              message: t("error:videocall.audioinputfailed"),
              noHide: true,
              severity: "error",
            });
            break;

          case "meetingStartFailed":
          // falls through

          case "meetingFailed":
            setToast({
              message: t("error:videocall.meetingfailed"),
              severity: "error",
            });
            break;

          default:
            break;
        }

        if (
          name === "meetingFailed" ||
          name === "audioInputFailed" ||
          name === "videoInputFailed" ||
          name === "meetingStartFailed"
        ) {
          Sentry.captureMessage(
            JSON.stringify({
              attributes: {
                ...otherAttributes,
                meetingHistory: meetingHistory?.filter(({ timestampMs }) => {
                  return Date.now() - timestampMs < 5 * 60 * 1000;
                }),
              },
              name,
            }),
          );
        }
      },
    };

    meetingManager.meetingSession?.eventController.addObserver(observer);

    return (): void =>
      meetingManager.meetingSession?.eventController.removeObserver(observer);
  }, [meetingManager.meetingSession?.eventController, setToast, t]);

  if (!consultationData?.consultation) {
    return <LoadingPage full />;
  }

  const channelName = `consultation:${consultationData.consultation.uuid}`;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.white.dark,
        height: "100vh",
      })}
    >
      {hasStartedMeeting ? (
        <>
          <VideoTileGrid
            layout="standard"
            noRemoteVideoView={
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.white.dark,
                  height: "100vh",
                })}
              >
                <Box
                  sx={(theme) => ({
                    background: theme.palette.bloom.light,
                    borderRadius: "20px",
                    color: theme.palette.black.main,
                    left: "50%",
                    maxWidth: "250px",
                    padding: "20px",
                    position: "absolute",
                    textAlign: "center",
                    top: "40%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 7,
                  })}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "21px",
                      fontWeight: 700,
                    }}
                  >
                    {isProfessional
                      ? t("professional:videocall.popup.patience")
                      : t("human:videocall.popup.patience")}
                  </Typography>
                </Box>
              </Box>
            }
          />
          <ButtonBar
            amountOfAttendees={amountOfAttendees}
            consultation={consultationData.consultation}
            exitCall={exitCall}
          />
        </>
      ) : (
        <>
          <ChannelProvider channelName={channelName}>
            <RealTimeRefetchConsultation
              channelName={channelName}
              refetchConsultation={refetchConsultationData}
            />
          </ChannelProvider>
          <StartCallPopup
            consultation={consultationData.consultation}
            hasJoinedMeeting={hasJoinedMeeting}
            startCall={startCall}
          />
        </>
      )}
    </Box>
  );
};

export default VideoCall;
