/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { motion } from "motion/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Profile from "../icons/Profile";
import Logout from "../icons/logout";
import SidebarNavigationAction from "../layout/sidebar/SidebarNavigationAction";
import { RouteNames } from "../routes/routeNames";

const UserMenu = ({ buttonRef, isMenuOpen, logout, openMenu }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { t: translate } = useTranslation(["common"]);

  const handleClick = (e) => {
    if ((menuRef.current as any).contains(e.target)) return;

    if (buttonRef.current.contains(e.target)) return;

    openMenu(false);
  };

  const handleEsc = (e) => {
    if (e.key === "Escape") openMenu(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    document.addEventListener("keydown", handleEsc, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, []);

  return (
    <Box
      animate={{ height: isMenuOpen ? 105 : 0 }}
      component={motion.div}
      ref={menuRef}
      sx={(theme) => ({
        background: theme.palette.bloom.dark,
        display: "flex",
        flexDirection: "column",
        height: 0,
        overflow: "hidden",
        position: "absolute",
        top: theme.spacing(10),
        width: "100%",
        zIndex: 2,
      })}
    >
      <SidebarNavigationAction
        icon={<Profile />}
        id={"test_sidebar_button_my_profile"}
        label={translate("common:myprofile", "Mijn Profiel")}
        onClick={(ev) => {
          ev.stopPropagation();
          openMenu(false);
          navigate(RouteNames.Home.Profile.path);
        }}
      />
      <SidebarNavigationAction
        icon={<Logout />}
        id={"test_sidebar_button_logout"}
        label={translate("common:signout", "Afmelden")}
        onClick={logout}
      />
    </Box>
  );
};

export default UserMenu;
