import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import { theme } from "../providers/theme/ThemeProvider";
import { RouteNames } from "../routes/routeNames";
import { QualityQuestions } from "./QualityQuestions.types";
import AnswerLikert from "./answerComponents/AnswerLikert";
import AnswerMultiSelect from "./answerComponents/AnswerMultiSelect";
import AnswerOpen from "./answerComponents/AnswerOpen";
import AnswerSingleSelect from "./answerComponents/AnswerSingleSelect";
import AnswerSlider from "./answerComponents/AnswerSlider";
import AnswerToggle from "./answerComponents/AnswerToggle";
import AnswerYesNoOrMaybe from "./answerComponents/AnswerYesNoMaybe";

const PREFIX = "QualityQuestionImplementation";

const classes = {
  center: `${PREFIX}-center`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.center}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

interface QualityQuestionImplementationProps {
  category: QualityQuestions.Category;
  component?: QualityQuestions.Component;
  id: number;
  nextQuestionId?: number;
  onAnswerQuestion?: (value) => void;
  options?: any;
  questionType: QualityQuestions.Type;
  redirectPathAfterQuestions: string;
  showThankyouPage: boolean;
  title: string;
  titleTranslationKey?: string;
}

export default function QualityQuestionImplementation({
  component,
  id,
  nextQuestionId,
  onAnswerQuestion,
  options,
  questionType,
  redirectPathAfterQuestions,
  showThankyouPage,
  title,
  titleTranslationKey,
}: QualityQuestionImplementationProps) {
  const [selected, setSelected] = useState<Array<string | number>>([]);

  const navigate = useNavigate();
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.QualityQuestions,
    BloomUpNamespacesEnum.Common,
  ]);

  const toNext = useCallback(
    (path: string) => {
      const to =
        path === redirectPathAfterQuestions && showThankyouPage
          ? `${RouteNames.QualityQuestions.Question.ThankYou.path}?redirectPath=${path}`
          : `${path}?redirectPath=${redirectPathAfterQuestions}&thankyouPage=${showThankyouPage}`;

      navigate(to);
    },
    [navigate, redirectPathAfterQuestions, showThankyouPage],
  );

  const goToNext = useCallback(() => {
    let path = RouteNames.Home.path;

    if (nextQuestionId) {
      path = RouteNames.QualityQuestions.Question.EntryPointOrId.path.replace(
        ":entrypointOrId",
        String(nextQuestionId),
      );
    } else if (redirectPathAfterQuestions) {
      path = redirectPathAfterQuestions;
    }

    return toNext(path);
  }, [nextQuestionId, redirectPathAfterQuestions, toNext]);

  const onClickNext = useCallback(() => {
    if (onAnswerQuestion) {
      const data = selected.map((item: any) => {
        if (!isNaN(item)) return parseInt(item);
        else return item as string;
      });

      onAnswerQuestion(data);
    }

    goToNext();
  }, [onAnswerQuestion, selected, goToNext]);

  const translatedOptions = useMemo(() => {
    if (
      !options ||
      questionType === QualityQuestions.Type.Open ||
      questionType === QualityQuestions.Type.YesNo ||
      questionType === QualityQuestions.Type.YesNoMaybe
    ) {
      return [];
    }

    if (questionType === QualityQuestions.Type.Likert) {
      return (options as QualityQuestions.Options.Likert).map(
        (o: QualityQuestions.Options.LikertStep) => ({
          label:
            o.translationKey && o.translationKey !== ""
              ? translate(o.translationKey, o.label)
              : o.label,
          step: o.step,
          value: o.value,
        }),
      );
    } else if (questionType === QualityQuestions.Type.Slider) {
      const sliderOptions: QualityQuestions.Options.Slider = options;

      const translatedOptions: QualityQuestions.Options.Slider = {
        max: { label: "", value: 0 },
        min: { label: "", value: 0 },
      };

      Object.keys(sliderOptions).forEach((key) => {
        translatedOptions[key] = {
          label:
            sliderOptions[key].translationKey &&
            sliderOptions[key].translationKey !== ""
              ? translate(
                  sliderOptions[key].translationKey,
                  sliderOptions[key].label,
                )
              : sliderOptions[key].label,
          value: sliderOptions[key].value,
        };
      });

      return translatedOptions;
    } else {
      return (options as QualityQuestions.Options.OptionArray).map(
        (o: QualityQuestions.Options.Option) => ({
          label:
            o.translationKey && o.translationKey !== ""
              ? translate(o.translationKey, o.label)
              : o.label,
          value: o.value,
        }),
      );
    }
  }, [options, questionType, translate]);

  return (
    <OnboardingContainer includeNavigation={false}>
      {/** TODO: Copy */}
      <OnboardingStepContainer
        colorNext={"green"}
        colorSkip={"gray"}
        endIconNext={<NavigateNextIcon />}
        endIconSkip={<NavigateNextIcon />}
        fullColoredNext={true}
        fullContent={true}
        fullCopyright={false}
        headline={translate("question.page.title", "Mogen we je wat vragen?")}
        headlineFontSize={27}
        headlineLabelColor={theme.palette.black.main}
        key={id}
        labelNext={translate("common:next", "Volgende")}
        labelSkip={translate("common:skip", "Overslaan")}
        onClickNext={onClickNext}
        onClickSkip={goToNext}
      >
        <StyledBox className={classes.center}>
          <h2 data-testid="quality_questions:quality-question-title">
            {titleTranslationKey && titleTranslationKey !== ""
              ? translate(titleTranslationKey, title)
              : title}
          </h2>
          {questionType === QualityQuestions.Type.MultiSelect ? (
            <AnswerMultiSelect
              component={
                component === QualityQuestions.Component.Checkbox
                  ? QualityQuestions.Component.Checkbox
                  : QualityQuestions.Component.Dropdown
              }
              onAnswerChanged={setSelected}
              options={
                translatedOptions as QualityQuestions.Options.OptionArray
              }
            />
          ) : null}
          {questionType === QualityQuestions.Type.SingleSelect ? (
            <AnswerSingleSelect
              component={
                // eslint-disable-next-line lines-around-comment
                component === QualityQuestions.Component.Radio
                  ? QualityQuestions.Component.Radio
                  : QualityQuestions.Component.Dropdown
              }
              onAnswerChanged={setSelected}
              options={
                translatedOptions as QualityQuestions.Options.OptionArray
              }
            />
          ) : null}
          {questionType === QualityQuestions.Type.YesNo ||
          questionType === QualityQuestions.Type.YesNoMaybe ? (
            <AnswerYesNoOrMaybe
              onAnswerChanged={setSelected}
              type={questionType}
            />
          ) : null}
          {questionType === QualityQuestions.Type.Toggle ? (
            <AnswerToggle
              onAnswerChanged={setSelected}
              options={
                translatedOptions as QualityQuestions.Options.OptionArray
              }
            />
          ) : null}
          {questionType === QualityQuestions.Type.Slider ? (
            <AnswerSlider
              onAnswerChanged={setSelected}
              options={translatedOptions as QualityQuestions.Options.Slider}
            />
          ) : null}
          {questionType === QualityQuestions.Type.Likert ? (
            <AnswerLikert
              onAnswerChanged={setSelected}
              options={translatedOptions as QualityQuestions.Options.Likert}
            />
          ) : null}
          {questionType === QualityQuestions.Type.Open ? (
            <AnswerOpen
              onAnswerChanged={setSelected}
              selectedAnswers={selected}
            />
          ) : null}
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
