import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "../../../../data-model/types/profile/Avatar";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import AvatarUpload from "../../../ui/form/AvatarUpload";
import Label from "../../../ui/form/FormLabel";
import SanitizedText from "../../../ui/text/SanitizedText";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import { ProfessionalOnboardingContextProps } from "../context/professionalOnboarding";

export default function AvatarStep() {
  const { formState, next, previous, step, totalSteps } =
    useContext<ProfessionalOnboardingContextProps>(
      ProfessionalOnboardingContext,
    );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
  ]);

  const hasError = formState.testForErrors(Avatar.getPath());
  const errorText =
    hasError && formState.getErrorMessages(Avatar.getPath())
      ? formState.getErrorMessages(Avatar.getPath())[0]
      : "";

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={hasError}
        fullCopyright
        headline={translate("professional:onboarding.title")}
        labelBack={translate("common:onboarding.button.label.previous")}
        labelNext={translate("common:onboarding.button.label.next")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.avatar.title")}
        subheadicon={<CameraAltOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Grid container>
          <AvatarUpload
            avatarUploadTestId={"test_onboarding_professional_avatar_upload"}
            errorText={errorText}
            hasError={hasError}
            label={translate("professional:onboarding.avatar.description")}
            onChange={(avatar) => {
              formState.setValue(Avatar.getPath(), avatar);
            }}
            value={formState.getValue(Avatar.getPath())}
          />
          <Box
            style={{
              margin: "0 auto",
            }}
          >
            <Label
              label={
                <SanitizedText
                  input={translate("professional:onboarding.avatar.criteria")}
                />
              }
              style={{
                margin: "0 auto",
                paddingBottom: 10,
                paddingTop: 20,
                textAlign: "center",
                width: "70%",
              }}
            />
          </Box>
        </Grid>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
