import { CloseOutlined, DoneOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableCell,
  TableContainer,
} from "@mui/material";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import useSelectTranslation from "../../api/languages/useSelectTranslation";
import Button from "../ui/buttons/Button";

const OrgProfileDialogFragment = graphql(`
  fragment OrgProfileDialogOrganization on Organization {
    coachingEnabled
    contractEndDate
    contractStartDate
    creditsPerCoaching
    creditsPerConsult
    creditsPerHuman
    hasRenewalContract
    id
    mindlabEnabled
    name {
      ...useSelectTranslationStringInput
    }
    profile {
      ...useSelectTranslationStringInput
    }
    videoEnabled
  }
`);

const MarkdownWrapper = ({ children }) => (
  <Box sx={{ marginTop: 2 }}>{children}</Box>
);

const CustomTable = (props) => (
  <TableContainer>
    <Table {...props} />
  </TableContainer>
);

const CustomTableCell = (props) => (
  <TableCell {...props} sx={{ textWrap: "wrap" }} />
);

const CustomTableHeader = (props) => (
  <TableCell
    {...props}
    sx={{ fontWeight: "bold", textWrap: "wrap" }}
    variant="head"
  />
);

const CustomH2 = (props) => (
  <Typography
    component={"h2"}
    variant={"h2"}
    {...props}
    sx={{ marginTop: 3 }}
  />
);

export function OrgProfileDialog({
  confirmationFooter = false,
  onClose,
  open,
  organization: organizationData,
}: {
  confirmationFooter?: boolean;
  onClose: (accepted: boolean) => void;
  open: boolean;
  organization: FragmentType<typeof OrgProfileDialogFragment>;
}) {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();
  const organization = getFragmentData(
    OrgProfileDialogFragment,
    organizationData,
  );

  const organizationName = selectTranslation(organization.name);
  const organizationProfile = selectTranslation(organization.profile);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => {
        onClose(false);
      }}
      open={open}
      scroll="paper"
    >
      <DialogTitle>{organizationName}</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          <Typography>
            {t("organization:profileDialog.selfHelp")}{" "}
            {organization.mindlabEnabled ? <GreenTag /> : <RedTag />}
          </Typography>
          <Typography>
            {t("organization:profileDialog.coaching")}{" "}
            {organization.coachingEnabled ? (
              <GreenTag
                label={t("organization:profileDialog.creditsPerSession", {
                  count: organization.creditsPerCoaching ?? undefined,
                })}
              />
            ) : (
              <RedTag />
            )}
          </Typography>
          <Typography>
            {t("organization:profileDialog.videoTherapy")}{" "}
            {organization.videoEnabled ? (
              <GreenTag
                label={t("organization:profileDialog.creditsPerSession", {
                  count: organization.creditsPerConsult ?? undefined,
                })}
              />
            ) : (
              <RedTag />
            )}
          </Typography>
          <Typography>
            {t("organization:profileDialog.creditsPerEmployee")}{" "}
            <Chip
              label={
                organization.creditsPerHuman ??
                t("organization:profileDialog.unlimited")
              }
              sx={{ fontSize: "14px" }}
            />
          </Typography>
          <Typography>
            {t("organization:profileDialog.contractStart")}{" "}
            <Chip
              label={organization.contractStartDate}
              sx={{ fontSize: "14px" }}
            />
          </Typography>
          <Typography>
            {t("organization:profileDialog.contractEnd")}{" "}
            <Chip
              label={organization.contractEndDate}
              sx={{ fontSize: "14px" }}
            />
          </Typography>
          <Typography>
            {t("organization:profileDialog.renewedContract")}{" "}
            {organization.hasRenewalContract ? (
              <GreenTag label={t("organization:profileDialog.yes")} />
            ) : (
              <Chip
                label={t("organization:profileDialog.not_yet")}
                sx={{ fontSize: "14px" }}
              />
            )}
          </Typography>
        </Box>
        {organizationProfile ? (
          <MuiMarkdown
            options={{
              forceWrapper: true,
              overrides: {
                ...getOverrides(),
                h2: { component: CustomH2 },
                table: { component: CustomTable },
                td: { component: CustomTableCell },
                th: { component: CustomTableHeader },
              },
              wrapper: MarkdownWrapper,
            }}
          >
            {organizationProfile}
          </MuiMarkdown>
        ) : null}
      </DialogContent>
      <DialogActions>
        {confirmationFooter ? (
          <>
            <Typography sx={{ mx: "8px" }}>
              {t("organization:profileDialog.confirmReading")}
            </Typography>
            <Button
              icon={<DoneOutlined />}
              label={t("organization:profileDialog.yes")}
              onClick={() => {
                onClose(true);
              }}
            ></Button>
            <Button
              icon={<CloseOutlined />}
              label={t("organization:profileDialog.no")}
              onClick={() => {
                onClose(false);
              }}
            ></Button>
          </>
        ) : (
          <Button
            icon={<CloseOutlined />}
            label={t("organization:profileDialog.closeButton")}
            onClick={() => {
              onClose(false);
            }}
          ></Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const GreenTag = (props: { label?: string }) => {
  const { t } = useTranslation();

  return (
    <Chip
      color="success"
      label={props.label ?? t("organization:profileDialog.productAvailable")}
      sx={{ fontSize: "14px" }}
    ></Chip>
  );
};

const RedTag = (props: { label?: string }) => {
  const { t } = useTranslation();

  return (
    <Chip
      color="error"
      label={props.label ?? t("organization:profileDialog.productUnavailable")}
      sx={{ fontSize: "14px" }}
    ></Chip>
  );
};
