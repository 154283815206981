/**
 * Address of user, psy or client.
 */
import type { TFunction } from "i18next";
import { APIPractice } from "../../../api/practices/practices";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";
import AddressType from "../profile/Address";

export default class Practice extends DataType<
  APIPractice.Output,
  DataTypePaths.Professional.Practice
> {
  protected type: any = Practice;
  protected static path = "practice";

  protected address: AddressType = new AddressType();
  protected chatTherapy = false;
  protected onlineTherapy = true;
  protected offlineTherapy = false;
  protected phoneTherapy = false;

  constructor(model?: APIPractice.Output) {
    super();

    if (!model) return;

    if (model.address) this.address = new AddressType(model.address);

    if (model.chatTherapy) this.chatTherapy = model.chatTherapy;

    if (model.offlineTherapy) this.offlineTherapy = model.offlineTherapy;

    if (model.onlineTherapy) this.onlineTherapy = model.onlineTherapy;

    if (model.phoneTherapy) this.phoneTherapy = model.phoneTherapy;
  }

  /**
   * Get the address of the professional practice.
   * You can call .getValue from this to get it textual.
   */
  public getAddress(): AddressType {
    return this.address;
  }

  public getFullAddress(): string | null {
    //TODO: translate
    if (
      !this.address ||
      (!this.address.getPostcode() && !this.address.getStreet())
    )
      return null;

    return this.address.prettyPrint();
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      // {
      //     path: "address",
      //     validate: (a: any): boolean => {
      //         return a[0].trim() !== "";
      //     },
      //     message: translate(
      //         "validation:address.mandatory",
      //         "Voeg je adres toe."
      //     ),
      // },
    ];
  }

  /**
   * Return an object containing the values to be used
   * as the values in the formState.
   */
  public getAsFormStateValues(): any {
    return {
      ...this.address.getAsFormStateValue(),
    };
  }

  getAsFormStateValue(): Record<"practice", APIPractice.Output> {
    return { practice: this.value };
  }
}
