import Box from "@mui/material/Box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TimeSlot from "../../../data-model/types/consultation/TimeSlot";
import { I18Namespaces } from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import Button from "../../ui/buttons/Button";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../ui/form/select/SingleSelect";

interface TimeSlotBoxesProps {
  label: string;
  onScheduleCall?: () => void;
  onSelectTime: (slot: TimeSlot) => void;
  timeslotItems: Array<ITimeslotItem>;
}

interface ITimeslotItem {
  label: string;
  value: TimeSlot;
}

function TimeSlotBoxes({
  label,
  onScheduleCall,
  onSelectTime,
  timeslotItems,
}: TimeSlotBoxesProps) {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);
  const [selectedTime, setSelectedTime] = useState<TimeSlot>();

  if (!timeslotItems) return <LoadingPage full={false} />;

  return (
    <Box>
      <SingleSelect<TimeSlot>
        isSearchable={false}
        onChange={({ value: time }: ISelectObject<TimeSlot>) => {
          setSelectedTime(time);
          onSelectTime(time);
        }}
        options={timeslotItems}
        placeholder={label}
        value={
          selectedTime
            ? {
                label: selectedTime.calendar(),
                value: selectedTime,
              }
            : undefined
        }
      />
      {onScheduleCall ? (
        <Button
          disabled={!selectedTime}
          label={translate("common:book", "Boek")}
          onClick={onScheduleCall}
          style={{
            marginTop: 15,
          }}
        />
      ) : null}
    </Box>
  );
}

export default TimeSlotBoxes;
