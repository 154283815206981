import { graphql } from "../__generated__";

export const FULL_CALENDAR_EVENT_FRAGMENT = graphql(`
  fragment FullCalendarEvent on FullCalendarEvent {
    allDay
    end
    extendedProps {
      status
      type
    }
    id
    start
  }
`);
