import { useChannel } from "ably/react";

const RealTimeRefetchConsultation = ({
  channelName,
  refetchConsultation,
}: {
  channelName: string;
  refetchConsultation: () => void;
}) => {
  useChannel(channelName, "payment_status_changed", () => {
    refetchConsultation();
  });

  return null;
};

export default RealTimeRefetchConsultation;
