import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IWithChildren } from "../../../../baseInterfaces";
import Button from "../../../ui/buttons/Button";

interface StepMainContentProps extends IWithChildren {
  enableNext?: boolean;
  nextButtonIcon?: React.ReactNode;
  nextButtonLabel?: string;
  nextButtonVariant?: "filled" | "outlined";
  onClickNext?: () => void;
  onClickPrevious?: () => void;
}

const StepMainContent = ({
  children,
  enableNext = true,
  nextButtonIcon = <ArrowForwardIosIcon />,
  nextButtonLabel,
  nextButtonVariant = "filled",
  onClickNext,
  onClickPrevious,
}: StepMainContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        [theme.breakpoints.down(500)]: {
          gap: "0.5rem",
          padding: "1rem",
        },
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(1000)]: {
          gap: "1rem",
        },
        gap: "1.5rem",
        justifyContent: "center",
        padding: "3rem",
      }}
    >
      <Box sx={{ maxWidth: "1300px" }}>
        {children}
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down(500)]: {
              flexDirection: "column-reverse",
              gap: "1rem",
              marginBottom: "1rem",
            },
            gap: "30px",
            justifyContent: "center",
            marginBottom: "2.5rem",
          }}
        >
          {onClickPrevious ? (
            <Button
              icon={<ArrowBackIosIcon />}
              label={t("match:stepMainContent.previous")}
              onClick={() => {
                onClickPrevious();
              }}
              sx={{ minWidth: "165px" }}
              variant="outlinedV2"
            />
          ) : null}
          {onClickNext ? (
            <Button
              disabled={!enableNext}
              endIcon={nextButtonIcon}
              fullyColored={nextButtonVariant !== "outlined"}
              label={nextButtonLabel ?? t("match:stepMainContent.next")}
              onClick={onClickNext}
              sx={{ minWidth: "165px" }}
              variant={
                nextButtonVariant === "outlined" ? "outlinedV2" : undefined
              }
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default StepMainContent;
