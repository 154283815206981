import { useLazyQuery } from "@apollo/client";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { WaveBox } from "wave-box";
import { graphql, makeFragmentData } from "../../../../api/__generated__";
import { UseSelectTranslationStringInputFragment } from "../../../../api/languages/fragments";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import BloomUpLogoWithTag from "../../../illustrations/logos/BloomUpLogoWithTag";
import LanguageDropdown from "../../../ui/LanguageDropdown";
import Button from "../../../ui/buttons/Button";
import FormLabel from "../../../ui/form/FormLabel";
import TextField from "../../../ui/form/TextField";

const PREFIX = "VerifyOrganizationPage";

const classes = {
  footerBox: `${PREFIX}-footerBox`,
  image: `${PREFIX}-image`,
  link: `${PREFIX}-link`,
  loginBox: `${PREFIX}-loginBox`,
  logoBox: `${PREFIX}-logoBox`,
  root: `${PREFIX}-root`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "stretch",
    display: "grid",
    flexDirection: "column",
    gridTemplate: "1fr 1fr / 550px minmax(0, 1fr)",
    height: "100vh",
    [theme.breakpoints.down(1100)]: {
      gridTemplate: "1fr 1fr / 100%",
    },
    justifyItems: "stretch",
    width: "100vw",
  },

  [`& .${classes.image}`]: {
    gridArea: "1 / 2 / 3 / 3",
    objectFit: "cover",
    objectPosition: "65% 20%",
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },

  [`& .${classes.loginBox}`]: {
    display: "flex",
    flexDirection: "column",
    gridArea: "1 / 1 / 2 / 2",
    justifyContent: "flex-end",
    paddingLeft: "80px",
    paddingRight: "80px",
    zIndex: 1,
    [theme.breakpoints.down(400)]: {
      paddingLeft: "36px",
      paddingRight: "36px",
    },
  },

  [`& .${classes.logoBox}`]: {
    flex: 1,
    marginBottom: "48px",
    marginTop: "150px",
    maxHeight: "80px",
    [theme.breakpoints.down(1100)]: {
      marginTop: "32px",
    },
  },

  [`& .${classes.footerBox}`]: {
    display: "flex",
    flexDirection: "column",
    gridArea: "2 / 1 / 3 / 2",
    justifyContent: "flex-end",
    paddingBottom: "40px",
    paddingLeft: "80px",
    paddingRight: "80px",
    [theme.breakpoints.down(400)]: {
      paddingLeft: "36px",
      paddingRight: "36px",
    },
  },

  [`& .${classes.link}`]: {
    marginBottom: "8px",
    marginRight: "8px",
  },

  [`& .${classes.submitButton}`]: {
    marginBottom: "16px",
  },
}));

const verifyOrganizationPageQuery = graphql(`
  query VerifyOrganizationPage($emailOrCode: String!) {
    organizationByEmailOrCode(emailOrCode: $emailOrCode) {
      ...Organization
    }
  }
`);

const privacyUrls = makeFragmentData(
  {
    en: "https://bloomup.notion.site/Privacyverklaring-5ee558b79b824f24b0550e9730dc138f",
    fr: "https://bloomup.notion.site/Politique-de-confidentialit-8a3067c15fe34f1cb684ad5b566c89d1",
    nl: "https://bloomup.notion.site/Privacyverklaring-5ee558b79b824f24b0550e9730dc138f",
  },
  UseSelectTranslationStringInputFragment,
);

const termsUrls = makeFragmentData(
  {
    en: "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit",
    fr: "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit",
    nl: "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit",
  },
  UseSelectTranslationStringInputFragment,
);

const VerifyOrganizationPage = () => {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();
  const navigate = useNavigate();

  const { trackPageView } = useMatomo();
  const smallScreen = useMediaQuery("(max-width: 400px)");

  useEffect(() => {
    trackPageView({ documentTitle: "Organization verify page" });
  }, [trackPageView]);

  const [verifyOrganization, { error }] = useLazyQuery(
    verifyOrganizationPageQuery,
  );

  return (
    <StyledBox className={classes.root}>
      <WaveBox
        background="#F3E9E1"
        fillBackground
        sx={{ alignSelf: "start", gridArea: "1 / 1" }}
        topWave={false}
      >
        <Box sx={{ height: "6rem" }} />
      </WaveBox>
      <Formik
        initialValues={{ inputValue: "" }}
        onSubmit={function (values: {
          inputValue: string;
        }): void | Promise<any> {
          verifyOrganization({
            onCompleted: () => {
              const searchParams = new URLSearchParams({
                "email-or-code": values.inputValue,
              });

              navigate(`/employee/?${searchParams.toString()}`, {
                replace: true,
              });
            },
            variables: { emailOrCode: values.inputValue },
          });
        }}
      >
        {({ handleChange, submitForm, values }) => (
          <Form className={classes.loginBox}>
            <Box className={classes.logoBox}>
              <BloomUpLogoWithTag />
            </Box>
            <Typography variant="h2">
              {t("organization:verify.header")}
            </Typography>
            <Typography>{t("organization:verify.description")}</Typography>
            <FormLabel label={t("organization:verify.inputLabel")} />
            <TextField
              fullWidth={false}
              name="organization-code-or-email"
              onChange={handleChange("inputValue")}
              value={values.inputValue}
            />
            <Box style={{ minHeight: "48px" }}>
              {error ? (
                <Typography color="error">
                  {t("organization:verify.noOrgError")}
                </Typography>
              ) : null}
            </Box>
            <Button
              bloomColor="bloom"
              className={classes.submitButton}
              disabled={!values.inputValue}
              fullWidth
              fullyColored
              label={t("organization:verify.submit")}
              onClick={submitForm}
              size={smallScreen ? "small" : "large"}
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <Box className={classes.footerBox}>
        <Box>
          <Link
            className={classes.link}
            href={
              selectTranslation(termsUrls) ??
              "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit"
            }
            underline="hover"
          >
            {t("organization:landing.footer.terms", "Voorwaarden")}
          </Link>
          <Link
            className={classes.link}
            href={
              selectTranslation(privacyUrls) ??
              "https://bloomup.notion.site/Privacyverklaring-5ee558b79b824f24b0550e9730dc138f"
            }
            underline="hover"
          >
            {t(
              "organization:landing.footer.doormat.content.bloomup.linkText.privacy",
            )}
          </Link>
        </Box>
        <LanguageDropdown />
      </Box>
      <img
        className={classes.image}
        src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/verify-screen-image.png"
      />
    </StyledBox>
  );
};

export default VerifyOrganizationPage;
