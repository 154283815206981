import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EmailDataType from "../../../data-model/types/profile/Email";
import FirstNameDataType from "../../../data-model/types/profile/FirstName";
import LastNameDataType from "../../../data-model/types/profile/LastName";
import PasswordDataType from "../../../data-model/types/profile/Password";
import PhoneDataType from "../../../data-model/types/profile/Phone";
import createFormValidation from "../../../utils/forms/createFormValidation";
import useFormState from "../../hooks/useFormState";
import { I18Namespaces } from "../../language/I18Namespaces";

const INITIAL_VALUES = {
  ...EmailDataType.getInitialValue(),
  ...FirstNameDataType.getInitialValue(),
  ...LastNameDataType.getInitialValue(),
  ...PhoneDataType.getInitialValue(),
  ...PasswordDataType.getInitialValue(),
};

export default function useRegisterFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        ...EmailDataType.getFormValidation(translate),
        ...FirstNameDataType.getFormValidation(translate),
        ...LastNameDataType.getFormValidation(translate),
        ...PhoneDataType.getFormValidation(translate),
        PasswordDataType.getFormValidation(translate)[0],
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
