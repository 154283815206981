/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useConfirmConsultation from "../../../api/consultations/hooks/useConfirmConsultation";
import useQueryParams, { getParamAsString } from "../../hooks/useQueryParams";
import SittingHuman from "../../illustrations/human-sorry-sitting";
import ErrorPage from "../../layout/ErrorPage";
import LoadingPage from "../../layout/LoadingPage";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";

export default function ConfirmConsultationPage() {
  const { uuid } = useParams();
  const { email } = useQueryParams(false);
  const [shouldShowErrorPage, setShouldShowErrorPage] =
    useState<boolean>(false);

  let humanEmail = getParamAsString(email);

  const { confirmConsultation, error, loading } = useConfirmConsultation();

  useEffect(() => {
    if (!humanEmail) humanEmail = "";

    confirmConsultation({ email: humanEmail, uuid: `${uuid}` });
  }, []);

  useEffect(() => {
    if (error) {
      setShouldShowErrorPage(true);
    }
  }, [error]);

  if (shouldShowErrorPage) return <ErrorPage />;

  if (loading || error) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline="Op Gesprek"
        showSpecialConditionsForTherapist={false}
      >
        <div className="content">
          <div className="content__container content__container__narrow">
            <Box className="illustration" mt={5}>
              <SittingHuman />
            </Box>
            <Box className="description" mt={5}>
              <Typography variant="subtitle2">
                We hebben je consultatie bevestigd!
              </Typography>
              <Typography variant="body1">
                Je mag nu het kader gewoon afsluiten.
              </Typography>
            </Box>
          </div>
        </div>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
