import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import { graphql } from "../../../api/__generated__";
import { useTitle } from "../../../custom-hooks/useTtitle";
import ErrorPage from "../../ui/ErrorPage";
import ChooseProfStep from "./ChooseProf/ChooseProfStep";
import EndMatchingStep from "./EndMatchingStep";
import LocationStep from "./LocationStep";
import MutualityStep from "./MutualityStep";
import PrivacyAgeStep from "./PrivacyAge/PrivacyAgeStep";
import LoginStep from "./RegisterAndLogin/LoginStep";
import RegisterStep from "./RegisterAndLogin/RegisterStep";
import SubtopicStep from "./SubtopicStep";
import TopicStep from "./TopicStep";
import TreatmentStep from "./TreatmentStep";
import WaitingRoom from "./WaitingRoom";

export type IntroDetails = {
  professionalId: number;
  startTime: Date;
};

const requestIntroConsultMutation = graphql(`
  mutation RequestIntroConsult($input: RequestIntroAsHumanInput!) {
    requestIntroAsHuman(input: $input) {
      id
      isInstant
      professional {
        avatar {
          url
        }
        id
        user {
          firstName
          id
          lastName
        }
      }
      scheduledFrom
    }
  }
`);

const Match = () => {
  useTitle("BloomUp Match");

  const [searchParams, setSearchParams] = useSearchParams();
  const paramCode = searchParams.get("code");

  useEffect(() => {
    if (paramCode) {
      localStorage.setItem("orgCode", paramCode);
      searchParams.delete("code");
      setSearchParams(searchParams);
    }
  }, [paramCode, searchParams, setSearchParams]);

  const [currentStep, setCurrentStep] = useState<string>("privacy");
  const [previousSteps, setPreviousSteps] = useState<string[]>([]);

  const stepBack = () => {
    const previousStep = previousSteps[previousSteps.length - 1];

    if (previousStep === "register") return;

    setPreviousSteps(previousSteps.slice(0, -1));
    setCurrentStep(previousStep);
  };

  const stepForward = (nextStep: string, token?: string) => {
    if (nextStep !== "mindlabRedirect") {
      setCurrentStep(nextStep);
    }

    if (!previousSteps.includes(nextStep)) {
      // To avoid having all switches between login and register be part of the list
      setPreviousSteps([...previousSteps, currentStep]);
    }

    token && localStorage.setItem("matchingToken", token);
  };

  const [introDetails, setIntroDetails] = useState<IntroDetails>();
  const [requestIntroConsult] = useMutation(requestIntroConsultMutation);

  const bookIntroConsult = async (paramDetails?: IntroDetails) => {
    const details = paramDetails ?? introDetails;

    if (paramDetails) {
      setIntroDetails(paramDetails);
    }

    if (!details) return;

    const { data } = await requestIntroConsult({
      variables: {
        input: {
          fromMatching: true,
          professionalId: details.professionalId,
          startTime: details.startTime,
        },
      },
    });

    stepForward(
      data?.requestIntroAsHuman.isInstant ? "waitingRoom" : "endMatching",
    );
  };

  const getCurrentMatchingStep = (step: string) => {
    switch (step) {
      case "privacy":
        return <PrivacyAgeStep stepForward={stepForward} />;

      case "location":
        return <LocationStep stepBack={stepBack} stepForward={stepForward} />;

      case "mutuality":
        return <MutualityStep stepBack={stepBack} stepForward={stepForward} />;

      case "topic":
        return <TopicStep stepBack={stepBack} stepForward={stepForward} />;

      case "subtopic":
        return <SubtopicStep stepBack={stepBack} stepForward={stepForward} />;

      case "treatment":
        return <TreatmentStep stepBack={stepBack} stepForward={stepForward} />;

      case "chooseProf":
        return (
          <ChooseProfStep
            bookIntroConsult={bookIntroConsult}
            setIntroDetails={setIntroDetails}
            stepBack={stepBack}
            stepForward={stepForward}
          />
        );

      case "register":
        return (
          <RegisterStep
            bookIntroConsult={bookIntroConsult}
            stepBack={stepBack}
            stepForward={stepForward}
          />
        );

      case "login":
        return (
          <LoginStep
            bookIntroConsult={bookIntroConsult}
            stepBack={() => {
              setCurrentStep("register"); //Only case where you'd want to go back to register
            }}
            stepForward={stepForward}
          />
        );

      case "endMatching":
        return <EndMatchingStep />;

      case "waitingRoom":
        return (
          <WaitingRoom
            bookIntroConsult={bookIntroConsult}
            stepBack={stepBack}
          />
        );

      default:
        return <ErrorPage />;
    }
  };

  return (
    <Box sx={{ backgroundColor: "#FFF" }}>
      {getCurrentMatchingStep(currentStep)}
    </Box>
  );
};

export default Match;
