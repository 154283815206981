import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCrispLanguage } from "../../components/language/languagesUtil";
import AuthContext from "../../components/providers/auth/AuthContext";
import { IAuthContext } from "../../components/providers/auth/auth";
import useSendCrispEvent from "./useSendCrispEvent";

const SCRIPT_SOURCE = "https://client.crisp.chat/l.js";
const CRISP_WEBSITE_ID = "4b3025fd-d5d7-491a-b0a9-8d1ac53aa633";
const IS_ASYNC = true;
const CRISP_RUNTIME_CONFIG = {
  locale: "fr",
};

interface ICrispLanguage {
  locale: string;
}

interface ICrispNative {
  push: (args: any) => void;
}

declare global {
  interface Window {
    $crisp?: string[] | ICrispNative;
    CRISP_RUNTIME_CONFIG?: ICrispLanguage;
    CRISP_WEBSITE_ID?: string;
  }
}

export default function CrispChat() {
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { isSending, sendUserLoginToCrisp } = useSendCrispEvent();
  const { i18n } = useTranslation();

  useEffect(() => {
    CRISP_RUNTIME_CONFIG.locale = getCrispLanguage(i18n.language) ?? "nl";

    let crispScript;

    try {
      if (currentUser && !isSending) {
        const script: HTMLScriptElement = document.createElement("script");

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;
        window.CRISP_RUNTIME_CONFIG = CRISP_RUNTIME_CONFIG;
        script.src = SCRIPT_SOURCE;
        script.async = IS_ASYNC;

        document.head.appendChild(script);
        crispScript = script;
        sendUserLoginToCrisp(currentUser);
      }
    } catch (error) {
      console.warn(`Could not create Crisp script.${error}`);
    }

    return () => {
      try {
        if (crispScript && window.$crisp) {
          delete window.$crisp;
          delete window.CRISP_WEBSITE_ID;
          delete window.CRISP_RUNTIME_CONFIG;

          document.head.removeChild(crispScript);
        }
      } catch (error) {
        console.error("Could not remove Crisp from source: ", error);
      }
    };
  }, [currentUser, i18n.language, isSending, sendUserLoginToCrisp]);

  return null;
}
