import type { TFunction } from "i18next";
import moment from "moment-timezone";
import { APIUser, UUID } from "../../api/api";
import { I18Namespaces } from "../../components/language/I18Namespaces";
import {
  I18NexusLanguages,
  parseOneLanguageFromAPI,
} from "../../components/language/languagesUtil";
import { ISelectObject } from "../../components/ui/form/select/BaseSimpleSelect";
import BaseType from "../BaseType";
import { DataTypePaths } from "../dataTypePaths";
import Organization from "./Organization";
import Age from "./profile/Age";
import Avatar, { AvatarData } from "./profile/Avatar";
import BirthDate from "./profile/BirthDate";
import Email from "./profile/Email";
import FirstName from "./profile/FirstName";
import Gender from "./profile/Gender";
import LastName from "./profile/LastName";
import Phone from "./profile/Phone";
import PreferredLanguage from "./profile/PreferredLanguage";
import Timezone, { Timezones } from "./profile/Timezone";

export enum UserRoles {
  ADMIN = "ADMIN",
  HUMAN = "HUMAN",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  PROFESSIONAL = "PROFESSIONAL",
  TEST = "TEST",
  UNKNOWN = "UNKNOWN",
}

export interface IUserFromStateValues {
  avatar?: AvatarData;
  birthDate?: Date | null;
  contactForFeedback?: boolean;
  email?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  phone?: string;
  preferredLanguage?: ISelectObject<I18NexusLanguages>;
  timezone?: ISelectObject<Timezones | string>;
}

export default abstract class User<
  PathName extends string = DataTypePaths.UserPath,
> extends BaseType<APIUser.GeneralizedResult, PathName> {
  protected type: any = User;
  protected uuid: UUID = "";
  protected firstName: FirstName = new FirstName();
  protected lastName: LastName = new LastName();
  protected gender: Gender = new Gender();
  protected email: Email = new Email();
  protected phone: Phone = new Phone();
  protected avatar: Avatar = new Avatar();
  protected age: Age = new Age();
  protected birthDate: BirthDate = new BirthDate();
  protected userRole?: UserRoles;
  protected onboardedAt?: moment.Moment;
  protected organization: Organization = new Organization();
  protected contactForFeedback = false;
  protected preferredLanguage: PreferredLanguage = new PreferredLanguage();
  protected timezone: Timezone = new Timezone();

  protected constructor(model?: APIUser.GeneralizedResult, fromGQL = false) {
    super(model);

    if (!model) return;

    this.uuid = model.uuid;
    this.firstName.setValue(model[FirstName.getPath()], fromGQL);
    this.lastName.setValue(model[LastName.getPath()], fromGQL);
    this.gender.setValue(model[Gender.getPath()], fromGQL);
    this.email.setValue(model[Email.getPath()], fromGQL);
    this.phone.setValue(model[Phone.getPath()], fromGQL);
    this.avatar.setValue(model[Avatar.getPath()], fromGQL);
    this.age.setValue(model[Age.getPath()], fromGQL);
    this.birthDate = new BirthDate(model[BirthDate.getPath()], fromGQL);
    this.preferredLanguage.setValue(
      parseOneLanguageFromAPI(model[PreferredLanguage.getPath()]),
      fromGQL,
    );
    this.timezone.setValue(model[Timezone.getPath()], fromGQL);
    this.isOnboardedAt(model.onboardedAt);

    if (model.roleType) this.setRole(model.roleType);
  }

  /**
   * Return an object containing the values to be used
   * as the values in the formState.
   */
  public getAsFormStateValues(
    translate: TFunction<I18Namespaces>,
  ): IUserFromStateValues {
    return {
      ...this.firstName.getAsFormStateValue(),
      ...this.lastName.getAsFormStateValue(),
      ...this.gender.getAsFormStateValue(),
      ...this.phone.getAsFormStateValue(),
      ...this.email.getAsFormStateValue(),
      ...this.avatar.getAsFormStateValue(),
      ...this.preferredLanguage.getAsFormStateValue(translate),
      ...this.timezone.getAsFormStateValue(),
      ...this.birthDate.getAsFormStateValue(),
      contactForFeedback: this.contactForFeedback,
    };
  }

  /**
   * this is the full name, and if Athat doesn't exist, the email.
   */
  public getScreenName(): string {
    const full = this.getFullName();

    return full || this.email.getValue() || "Onbekend";
  }

  /**
   * Get the id of this person.
   */
  public getUUID(): string {
    return this.uuid;
  }

  /**
   * Get the avatar of this person as an object.
   * You can call .getUrl from this to get the link.
   */
  public getAvatar(): Avatar {
    return this.avatar;
  }

  /**
   * Get the first name of this person as an object.
   * You can call .getValue from this to get it textual.
   */
  public getFirstName(): FirstName {
    return this.firstName;
  }

  /**
   * Set the first name of this person as an object.
   * If you want to change the name of an existing person
   * call getFirstName() and setValue on the returning value.
   */
  public setFirstName(firstName: FirstName): User<PathName> {
    this.firstName = firstName;

    return this;
  }

  /**
   * Get the last name of this person as an object.
   * You can call .getValue from this to get it textual.
   */
  public getLastName(): LastName {
    return this.lastName;
  }

  /**
   * Set the last name of this person as an object.
   * If you want to change the name of an existing person
   * call getLastName() and setValue on the returning value.
   */
  public setLastName(lastName: LastName): User<PathName> {
    this.lastName = lastName;

    return this;
  }

  public getInitials(): string {
    const firstName = this.getFirstName().getValue();
    const lastName = this.getLastName().getValue();

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    return "?";
  }

  public getFullName(): string | null {
    try {
      const firstName = this.getFirstName().getValue();

      if (!firstName) return null;

      const first: string = this.getFirstName().prettyPrint();
      const last: string = this.getLastName().prettyPrint();

      let full: string = first;

      if (last) {
        full += " " + last;
      }

      return full;
    } catch (e: any) {
      console.error("[User]:" + e);

      throw new Error(e);
    }
  }

  /**
   * Get the gender of this person as an object.
   * You can call .getValue from this to get it textual.
   */
  public getGender(): Gender {
    return this.gender;
  }

  /**
   * Get the birth date of this person as an object.
   * You can call .getValue from this to get it as a Date.
   */
  public getBirthDate(): BirthDate {
    return this.birthDate;
  }

  /**
   * Get the e-mail address of this person as an object.
   * You can call .getValue from this to get it textual.
   */
  public getEmail(): Email {
    return this.email;
  }

  /**
   * Get the phone number of this person as an object.
   * You can call .getValue from this to get it textual.
   */
  public getPhone(): Phone {
    return this.phone;
  }

  /**
   * Set the phone number of this person as an object.
   * If you want to change the phone of an existing person
   * call getPhone() and setValue on the returning value.
   */
  public setPhone(phone: Phone) {
    this.phone = phone;

    return this;
  }

  /**
   * What role does this user play?
   * Can be one of UserRoles
   */
  public getRole(): UserRoles | undefined {
    return this.userRole;
  }

  public isHuman(): boolean {
    return this.userRole === UserRoles.HUMAN;
  }

  public isAdmin(): boolean {
    return this.userRole === UserRoles.ADMIN;
  }

  public isProfessional(): boolean {
    return this.userRole === UserRoles.PROFESSIONAL;
  }

  public isOrganizationAdmin(): boolean {
    return this.userRole === UserRoles.ORGANIZATION_ADMIN;
  }

  /**
   * Set the role of this person / user.
   * This can be any value of UserRoles.
   */
  public setRole(role: UserRoles) {
    this.userRole = role;

    return this;
  }

  /**
   * Getter and setter for whether or not the User is onboarded.
   *
   * @param onboarded Optional new value for onboarded.
   */
  public isOnboardedAt(
    onboarded?: moment.Moment | Date | string,
  ): moment.Moment | undefined {
    if (onboarded) {
      this.onboardedAt = moment(onboarded);
    }

    return this.onboardedAt;
  }

  public getPreferredLanguage(): PreferredLanguage {
    return this.preferredLanguage;
  }

  public getTimezone(): Timezone {
    return this.timezone;
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }
}
