import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import SanitizedText from "./text/SanitizedText";

const PREFIX = "FAQAccordionItem";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledAccordion = styled(Accordion)(() => ({
  [`&.${classes.root}`]: {
    "&.Mui-expanded": {
      margin: "auto",
    },
    "&:before": {
      display: "none",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    boxShadow: "none",
  },
}));

export interface IFAQData {
  answer: string;
  question: string;
}

export default function FAQAccordionItem({ answer, question }: IFAQData) {
  return (
    <StyledAccordion className={classes.root} square>
      <AccordionSummary>
        <Typography>
          <SanitizedText input={question} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <SanitizedText input={answer} />
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
}
