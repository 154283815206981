import Done from "@mui/icons-material/Done";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import StyledTooltip from "../Tooltip";
import { hexColorCheck } from "./select/util";

const PREFIX = "GroupedChipList";

const classes = {
  chip: `${PREFIX}-chip`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.chip}`]: {
    ...theme.controls.chip.container,
    "& .MuiChip-label": {
      ...theme.controls.chip?.label,
    },
    backgroundColor: theme.palette.white.main,
    marginBottom: 5,
    marginRight: 10,

    maxWidth: "100%",
  },
}));

interface Chip {
  checkMark: boolean;
  color: string;
  fadeOut: boolean;
  label: string;
  size?: ChipSize;
  tooltip: string;
}

enum ChipSize {
  large = "medium",
  small = "small",
}
interface GroupedChipListProps {
  chipListTestId?: string | undefined;
  chips: Array<Chip>;
}

const GroupedChipList = ({ chipListTestId, chips }: GroupedChipListProps) => {
  const { t } = useTranslation();

  return (
    <StyledBox display="flex" flexWrap="wrap" id={chipListTestId} mt={1}>
      {chips.map((c) => {
        return (
          <Chip
            classes={{ root: classes.chip }}
            deleteIcon={
              <StyledTooltip
                placement="top-end"
                text={c.tooltip ?? ""}
                title={c.label}
              >
                <InfoOutlinedIcon
                  style={{
                    color: `${hexColorCheck(c.color)}`,
                  }}
                />
              </StyledTooltip>
            }
            icon={c.checkMark ? <Done /> : undefined}
            label={
              <Typography
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {t(c.label)}
              </Typography>
            }
            onDelete={() => {}}
            size={c.size}
            style={{
              color: `${hexColorCheck(c.color)}`,
              opacity: c.fadeOut ? 0.5 : 1,
            }}
          />
        );
      })}
    </StyledBox>
  );
};

export default GroupedChipList;
