import moment, { Moment } from "moment";
import "moment/locale/nl";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Consultation from "../../data-model/types/consultation/Consultation";
import ConsultationMessage from "../../data-model/types/consultation/ConsultationMessage";
import ConsultationStatus from "../../data-model/types/consultation/ConsultationStatus";
import environment from "../../environment/environment";
import createFormValidation from "../../utils/forms/createFormValidation";
import useFormState, { FormStateOptions } from "../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";

moment.locale("nl");

const INITIAL_VALUES = {
  [ConsultationStatus.getPath()]: null,
  date: moment().seconds(0).milliseconds(0),
  duration: null,
  timeFrom: moment().seconds(0).milliseconds(0),
  timeTo: moment()
    .seconds(0)
    .milliseconds(0)
    .add(environment.REACT_APP_APPOINTMENT_DURATION, "minutes"),
  ...ConsultationMessage.getInitialValue(),
};

interface IUpdateConsultationFormStateValues {
  [x: string]: any;
  consultations?: Array<Consultation>;
  date?: string | Moment | undefined;
  duration?: number;
  timeFrom?: string | Moment | undefined;
  timeTo?: string | Moment | undefined;
}

interface IUpdateConsultationValidationOptions {
  currentConsultation: Consultation | undefined;
}

export default function useUpdateConsultationFormState(
  initialValues?: IUpdateConsultationFormStateValues,
  options?: FormStateOptions<IUpdateConsultationValidationOptions>,
) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const validation = useMemo(
    () =>
      createFormValidation([
        ...Consultation.getFormValidation(translate),
        ...ConsultationMessage.getFormValidation(translate),
      ]),
    [translate],
  );

  return useFormState<IUpdateConsultationFormStateValues>(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
