import { useMutation } from "@apollo/client";
import { CalendarEventInput } from "../../__generated__/graphql";
import { MUTATION_CREATE_FULL_CALENDAR_EVENT } from "../mutations";

export default function useCreateFullCalendarEvent() {
  const [execute, { data, error, loading }] = useMutation(
    MUTATION_CREATE_FULL_CALENDAR_EVENT,
  );

  function createFullCalendarEvent(input: CalendarEventInput) {
    return execute({
      variables: { input: { ...input, returnAsFullCalendar: true } },
    });
  }

  return {
    createFullCalendarEvent,
    data: data?.createCalendarEvent,
    error,
    loading,
  };
}
