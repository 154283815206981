import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Human from "../../../data-model/types/human/Human";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import EmptyList from "../../ui/EmptyList";
import RequestConsultationForm from "./RequestConsultationForm";
import { IRequestConsultationContext } from "./context/RequestConsultation";
import RequestConsultationContext from "./context/RequestConsultationContext";

export default function RequestConsultationPage() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
  ]);

  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { formState, onSubmit, professionals } =
    useContext<IRequestConsultationContext>(RequestConsultationContext);

  if (!currentUser || !professionals || !formState) return <LoadingPage />;

  return professionals.length && Human.isHuman(currentUser) ? (
    <RequestConsultationForm
      formState={formState}
      onSubmit={onSubmit}
      professionals={professionals}
    />
  ) : (
    <EmptyList
      description={translate(
        "human:request.consultation.no.professional.description",
      )}
      navigationRoute="/match"
      submitLabel={translate(
        "human:request.consultation.no.professional.button.label",
      )}
      title={translate("human:request.consultation.no.professional.title")}
    />
  );
}
