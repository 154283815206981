import { AccessAlarm } from "@mui/icons-material";
import { Box } from "@mui/material";
import { motion } from "motion/react";
import { useContext, useEffect, useState } from "react";
import TimerContext from "../timerContext/TimerContext";

const Timer = ({
  amountOfAttendees,
  isVisible,
}: {
  amountOfAttendees: number;
  isVisible: boolean;
}) => {
  const [hasAutoStarted, setHasAutoStarted] = useState(false);
  const [hasAutoStopped, setHasAutoStopped] = useState(false);

  const { pauseTimer, startTimer, timerValue } = useContext(TimerContext);

  useEffect(() => {
    if (!hasAutoStarted && amountOfAttendees === 2) {
      setHasAutoStarted(true);
      startTimer();
    }

    if (hasAutoStarted && !hasAutoStopped && amountOfAttendees === 1) {
      setHasAutoStopped(true);
      pauseTimer();
    }
  }, [
    amountOfAttendees,
    hasAutoStarted,
    hasAutoStopped,
    pauseTimer,
    startTimer,
  ]);

  return (
    <Box
      animate={isVisible ? "closed" : "open"}
      component={motion.div}
      initial={isVisible ? "closed" : "open"}
      sx={(theme) => ({
        alignItems: "center",
        background: theme.palette.bloom.light,
        borderRadius: "30px",
        color: theme.palette.bloom.dark,
        display: "flex",
        left: "20px",
        minWidth: "120px", // otherwise the width always changes a litte bit because of the changing numbers
        opacity: 0,
        padding: "10px",
        paddingRight: "15px",
        position: "absolute",
        top: "20px",
        zIndex: 8,
      })}
      transition={{ delay: isVisible ? 0.5 : 0, duration: 0.5 }}
      variants={{
        closed: { opacity: 1 },
        open: { opacity: 0 },
      }}
    >
      <AccessAlarm
        sx={{
          marginRight: "4px",
        }}
      />
      <Box sx={{ whiteSpace: "nowrap" }}>{timerValue}</Box>
    </Box>
  );
};

export default Timer;
