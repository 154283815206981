import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateConsultation from "../../../../api/consultations/hooks/useCreateConsultation";
import useFetchProfessionalsForHuman from "../../../../api/professionals/hooks/useFetchProfessionalsForHuman";
import { UserRoles } from "../../../../data-model/types/User";
import Consultation, {
  ConsultationTypes,
} from "../../../../data-model/types/consultation/Consultation";
import ConsultationStatus from "../../../../data-model/types/consultation/ConsultationStatus";
import Human from "../../../../data-model/types/human/Human";
import ConsultationsOverviewContext from "../../../consultations/context/ConsultationsOverviewContext";
import { IConsultationsOverviewContext } from "../../../consultations/context/consultations";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import AuthContext from "../../../providers/auth/AuthContext";
import { IAuthContext } from "../../../providers/auth/auth";
import ToastContext from "../../../providers/toast/ToastContext";
import { IToastContext } from "../../../providers/toast/toast";
import useRequestConsultationFormState from "../useRequestConsultationFormState";
import { IRequestConsultationContext } from "./RequestConsultation";
import RequestConsultationContext from "./RequestConsultationContext";

export default function RequestConsultationStateProvider({
  children,
}: IRequestConsultationContext) {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Errors,
  ]);

  const { loading: loadingProfessionals, professionals } =
    useFetchProfessionalsForHuman({ withAvailability: true });

  const { currentUser, loading: loadingCurrentUser } =
    useContext<IAuthContext>(AuthContext);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const formState = useRequestConsultationFormState();

  const {
    closeAddConsultation,
    refetchAll: refetchConsultations,
    setSelectedConsultation,
  } = useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  const { setToast } = useContext<IToastContext>(ToastContext);

  const { createConsultation } = useCreateConsultation();

  const resetRequestConsultationForm = () => formState.resetForm();

  const onSubmit = async () => {
    setSubmitting(true);

    if (formState.validate()) {
      const { professional, timeslot, type } = formState.values;
      const newConsultation = new Consultation();

      if (
        currentUser &&
        professional?.value &&
        Human.isHuman(currentUser) &&
        (professional.value.getRole() === UserRoles.PROFESSIONAL ||
          professional.value.getRole() === UserRoles.ADMIN) &&
        timeslot &&
        type
      ) {
        newConsultation
          .assignProfessional(professional.value)
          .assignClient(currentUser)
          .setRequestedByClient(true)
          .setStartDate(timeslot.getStartTime())
          .setEndDate(timeslot.getEndTime())
          .setFromMatch(false)
          .setConsultationType(type.value)
          .setStatus(new ConsultationStatus("REQUESTED"));

        try {
          const consultation = await createConsultation(
            newConsultation,
            formState.getValue("type").value === ConsultationTypes.INTRO,
          );

          if (consultation) {
            formState.setValue(
              "link",
              `consultation/${consultation.getUUID()}`,
            );

            if (refetchConsultations) refetchConsultations();

            setSubmitting(false);
            closeAddConsultation();
            setSelectedConsultation(consultation);
          } else {
            throw new Error();
          }
        } catch (error: any) {
          setSubmitting(false);

          if (error.message === "create_consultation_unpaid_consultations") {
            setToast({
              message: t("errors:create_consultation_unpaid_consultations"),
              severity: "error",
            });

            return;
          }

          console.log("[AddConsultationPageWithState]:", error);
          setToast({
            message: t("errors:error.generic"),
            severity: "error",
          });
        }
      }
    }
  };

  if (loadingProfessionals || loadingCurrentUser || !currentUser || !formState)
    return <LoadingPage />;

  return (
    <RequestConsultationContext.Provider
      value={{
        formState,
        onSubmit,
        professionals,
        resetRequestConsultationForm,
        submitting,
      }}
    >
      {children}
    </RequestConsultationContext.Provider>
  );
}
