import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Divider, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { PropsWithChildren } from "react";

interface SidePanelProps extends PropsWithChildren {
  returnToPreviousPane: () => void;
  title: string;
  TitleIcon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
}

const SidePanel = ({
  TitleIcon,
  children,
  returnToPreviousPane,
  title,
}: SidePanelProps) => {
  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.black.main,
        display: "flex",
        flexFlow: "column nowrap",
        fontSize: theme.typography.sizes.large,
        justifyContent: "space-between",
        margin: "5px",
        overflow: "auto",
        width: "100%",
      })}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: "10px",
          minHeight: "55px",
          paddingLeft: "30px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            flexWrap: "nowrap",
            fontSize: "30px",
          }}
        >
          <TitleIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 7,
            flexWrap: "nowrap",
          }}
        >
          <Typography
            sx={(theme) => ({
              marginLeft: "10px",
              ...theme.typography.h5,
              fontWeight: 700,
            })}
          >
            {title}
          </Typography>
        </Box>
        <Box
          onClick={returnToPreviousPane}
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            flexGrow: 1,
            flexWrap: "nowrap",
            justifyContent: "center",
          }}
        >
          <ArrowBackIosIcon />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 6,
          marginLeft: "30px",
          marginRight: "30px",
          marginTop: "10px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidePanel;
