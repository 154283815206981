import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { IWithChildren } from "../../../baseInterfaces";
import Navigation from "../Navigation";

const PREFIX = "OnboardingContainer";

const classes = {
  container: `${PREFIX}-container`,
  grid: `${PREFIX}-grid`,
};

const StyledContainer = styled(Container)<{
  includeNavigation: boolean;
  marginBottom: number;
}>(({ includeNavigation, marginBottom: mb, theme }) => ({
  [`& .${classes.grid}`]: {
    marginBottom: theme.spacing(mb),
    [theme.breakpoints.down("xl")]: {
      marginBottom: theme.spacing(Math.round(mb / 4)),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(Math.floor(mb / 2)),
    },
  },

  [`&.${classes.container}`]: {
    height: "100%",
    marginTop: includeNavigation ? 0 : theme.spacing(5),
    minHeight: "100vh",
  },
}));

interface IOnboardingContainer extends IWithChildren {
  includeNavigation?: boolean;
  marginBottom?: number;
}

const OnboardingContainer = ({
  children,
  includeNavigation = true,
  marginBottom = 0,
}: IOnboardingContainer) => {
  return (
    <StyledContainer
      className={classes.container}
      includeNavigation={includeNavigation}
      marginBottom={marginBottom}
      maxWidth="md"
    >
      <Grid className={classes.grid} component="main" container>
        {includeNavigation ? <Navigation /> : null}
        <Grid item sm={12} xs={12}>
          <div className="tab__panel" role="tabpanel">
            {children}
          </div>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default OnboardingContainer;
