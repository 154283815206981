import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import environment from "../../../../environment/environment";

const professionalFragment = graphql(`
  fragment CostSentenceProfessional on Professional {
    coachingRate
    id
    standardDuration
    standardRate
  }
`);

const organizationFragment = graphql(`
  fragment CostSentenceOrganization on Organization {
    canCreditsBeUsed {
      forCoaching
      forTherapy
    }
    coachingEnabled
    coachingsPerHuman
    consultsPerHuman
    contractEndDate
    id
    name {
      ...useSelectTranslationStringInput
    }
    slug
    videoEnabled
  }
`);

const humanFragment = graphql(`
  fragment CostSentenceHuman on Human {
    creditBalance {
      remainingCoachingConsultations
      remainingTherapyConsultations
    }
    id
  }
`);

const CostSentence = ({
  appointmentType,
  human,
  isMatching = false,
  mutualityName,
  organization,
  professional,
}: {
  appointmentType: "COACHING" | "THERAPY";
  human?: FragmentType<typeof humanFragment> | null;
  isMatching?: boolean;
  mutualityName?: string;
  organization?: FragmentType<typeof organizationFragment> | null;
  professional: FragmentType<typeof professionalFragment>;
}) => {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();
  const translationKeyPrefix = isMatching
    ? "match:chooseProfStep.card"
    : "human:request.consultation";

  const professionalData = getFragmentData(professionalFragment, professional);
  const organizationData = getFragmentData(organizationFragment, organization);
  const humanData = getFragmentData(humanFragment, human);
  const orgCanCreditsBeUsed =
    appointmentType === "THERAPY"
      ? organizationData?.canCreditsBeUsed?.forTherapy
      : organizationData?.canCreditsBeUsed?.forCoaching;

  // Same for Helan & CM
  const reimbursementPriceTherapy = professionalData.standardRate
    ? professionalData.standardRate - 10
    : undefined;

  const reimbursementPriceCoaching = professionalData.coachingRate
    ? professionalData.coachingRate - 10
    : undefined;

  const getSentence = () => {
    const b2cTranslationProps = {
      duration:
        appointmentType === "COACHING"
          ? environment.REACT_APP_COACHING_DURATION
          : professionalData.standardDuration,
      price:
        appointmentType === "COACHING"
          ? professionalData.coachingRate
          : professionalData.standardRate,
      reimbursementPrice:
        appointmentType === "COACHING"
          ? reimbursementPriceCoaching
          : reimbursementPriceTherapy,
    };

    if (
      (organizationData?.videoEnabled === true &&
        (organizationData.consultsPerHuman ?? 0) > 0 &&
        appointmentType === "THERAPY") ||
      (organizationData?.coachingEnabled === true &&
        (organizationData.coachingsPerHuman ?? 0) > 0 &&
        appointmentType === "COACHING")
    ) {
      const orgName = selectTranslation(organizationData.name);
      const therapyCount =
        humanData?.creditBalance?.remainingTherapyConsultations ??
        organizationData.consultsPerHuman ??
        undefined;

      const coachingCount =
        humanData?.creditBalance?.remainingCoachingConsultations ??
        organizationData.coachingsPerHuman ??
        undefined;

      if (organizationData.consultsPerHuman === null && orgCanCreditsBeUsed) {
        return t(`${translationKeyPrefix}.cost_org_unlimited`, {
          duration:
            appointmentType === "COACHING"
              ? environment.REACT_APP_COACHING_DURATION
              : professionalData.standardDuration,
          orgName,
        });
      } else {
        const b2bTranslationProps = {
          ...b2cTranslationProps,
          appointmentsPerYear:
            appointmentType === "COACHING"
              ? organizationData.coachingsPerHuman
              : organizationData.consultsPerHuman,
          contractEnd: organizationData.contractEndDate,
          count: appointmentType === "COACHING" ? coachingCount : therapyCount,
          orgName,
        };

        if (
          orgCanCreditsBeUsed &&
          (humanData?.creditBalance?.remainingTherapyConsultations ===
            undefined ||
            humanData.creditBalance.remainingTherapyConsultations === null || // unlimited case should have been covered above
            humanData.creditBalance.remainingTherapyConsultations > 0)
        ) {
          return mutualityName === "CM"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_cm`,
                b2bTranslationProps,
              )
            : mutualityName === "Helan"
              ? t(
                  `${translationKeyPrefix}.cost_org_limited_helan`,
                  b2bTranslationProps,
                )
              : t(
                  `${translationKeyPrefix}.cost_org_limited`,
                  b2bTranslationProps,
                );
        } else {
          return mutualityName === "CM"
            ? t(
                `${translationKeyPrefix}.cost_org_limited_cm_limit_reached`,
                b2bTranslationProps,
              )
            : mutualityName === "Helan"
              ? t(
                  `${translationKeyPrefix}.cost_org_limited_helan_limit_reached`,
                  b2bTranslationProps,
                )
              : t(
                  `${translationKeyPrefix}.cost_org_limited_limit_reached`,
                  b2bTranslationProps,
                );
        }
      }
    } else {
      return mutualityName === "CM"
        ? t(`${translationKeyPrefix}.cost_cm`, b2cTranslationProps)
        : mutualityName === "Helan"
          ? t(`${translationKeyPrefix}.cost_helan`, b2cTranslationProps)
          : t(`${translationKeyPrefix}.cost`, b2cTranslationProps);
    }
  };

  return <Typography>{getSentence()}</Typography>;
};

export default CostSentence;
