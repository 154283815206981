import { motion } from "motion/react";
import * as React from "react";
import { SVGProps } from "react";

const StickyPlant = (props: SVGProps<SVGSVGElement>) => {
  const paths = [
    "M250.354 188.259C250.354 188.259 250.08 189.97 249.437 192.247C248.793 194.497 247.107 203.881 247.107 203.881L245.395 213.499C245.395 213.499 241.984 212.878 239.134 212.354C236.284 211.829 226.132 208.89 220.049 207.648C220.858 198.775 224.529 182.711 224.529 182.711C224.529 182.711 233.489 184.188 238.682 185.734C243.874 187.279 250.368 188.231 250.368 188.231L250.354 188.259Z",
    "M0.666382 156.022L1.6117 181.745C1.6117 181.745 5.07788 181.676 7.98235 181.621C10.8868 181.566 20.4496 178.654 26.6559 178.392C27.2587 169.491 27.0532 154.67 27.0532 154.67L0.666382 156.008V156.022Zs",
    "M7.94122 107.846C7.94122 107.846 7.74941 109.571 7.77682 111.945C7.80422 114.318 6.9822 123.785 6.9822 123.785L6.11908 133.528C6.11908 133.528 9.57155 133.832 12.4623 134.094C15.3531 134.356 25.916 134.204 32.0949 134.618C33.643 125.841 34.3006 109.378 34.3006 109.378C34.3006 109.378 25.2721 108.426 19.8605 108.536C14.4489 108.647 7.92752 107.846 7.92752 107.846H7.94122Z",
    "M34.3417 151.496C34.3417 151.496 34.2321 153.221 34.3417 155.594C34.465 157.926 34.0403 167.462 34.0403 167.462L33.5882 177.233C33.5882 177.233 37.0544 177.398 39.9451 177.522C42.8359 177.647 53.3851 177.067 59.5914 177.219C60.7696 168.387 60.7422 151.91 60.7422 151.91C60.7422 151.91 51.6863 151.344 46.2747 151.689C40.8631 152.034 34.328 151.496 34.328 151.496H34.3417Z",
    "M25.8749 56.7719L23.7651 82.4261C23.7651 82.4261 27.2176 82.7711 30.1083 83.0609C32.9991 83.3507 42.8359 81.6257 49.0284 82.1087C50.6725 73.3457 52.248 58.6073 52.248 58.6073L25.8886 56.7581L25.8749 56.7719Z",
    "M53.2345 68.4478C53.2345 68.4478 53.0975 70.1728 53.2071 72.5464C53.3167 74.92 52.8098 84.4144 52.8098 84.4144L52.2755 94.1709C52.2755 94.1709 55.7416 94.3642 58.6324 94.516C61.5232 94.6678 72.0724 94.1848 78.2786 94.3918C79.5391 85.5598 79.6487 69.0964 79.6487 69.0964C79.6487 69.0964 70.5928 68.4478 65.1811 68.7376C59.7695 69.0274 53.2345 68.4478 53.2345 68.4478Z",
    "M134.916 0.730713L109.365 1.24131C109.365 1.24131 109.365 4.73271 109.378 7.65831C109.378 10.5701 112.105 20.2715 112.255 26.5229C121.078 27.2819 135.793 27.3371 135.793 27.3371L134.929 0.744513L134.916 0.730713Z",
    "M142.41 33.105C142.41 33.105 140.697 32.9532 138.341 33.0498C135.984 33.1464 126.558 32.5392 126.558 32.5392L116.872 31.9182C116.872 31.9182 116.653 35.4096 116.475 38.3214C116.297 41.2332 116.694 51.8592 116.434 58.1106C125.188 59.4492 141.533 59.7114 141.533 59.7114C141.533 59.7114 142.259 50.6034 142.012 45.1386C141.766 39.6876 142.41 33.105 142.41 33.105Z",
    "M40.3699 107.183C40.3699 107.183 40.137 108.908 40.1233 111.282C40.1096 113.656 39.0684 123.109 39.0684 123.109L38.356 131.237C38.356 131.237 41.4385 133.21 44.3156 133.528C47.1927 133.845 57.3172 135.432 63.496 135.984C65.236 127.235 66.7019 109.322 66.7019 109.322C66.7019 109.322 58.6872 109.433 53.2619 108.757C47.8366 108.08 40.3699 107.183 40.3699 107.183Z",
    "M79.8542 23.2246C79.8542 23.2246 78.1279 23.2522 75.7989 23.5834C73.4972 23.9146 64.0303 24.301 64.0303 24.301L54.3305 24.6874C54.3305 24.6874 54.4675 28.1788 54.5908 31.0906C54.7141 34.0024 56.1937 44.5456 56.5774 50.7832C65.4141 51.211 81.7037 49.7758 81.7037 49.7758C81.7037 49.7758 81.4845 40.6402 80.6899 35.2306C79.8953 29.8348 79.8542 23.2246 79.8542 23.2246Z",
    "M108.995 40.8336C108.995 40.8336 107.268 40.806 104.926 41.0682C102.583 41.3304 93.1434 41.427 93.1434 41.427L85.0465 41.6892C85.0465 41.6892 83.471 45.015 83.4984 47.9268C83.5258 50.8386 83.1833 61.161 83.3751 67.3986C92.1981 68.1024 110.036 67.4262 110.036 67.4262C110.036 67.4262 108.981 59.4222 108.981 53.916C108.981 48.4512 108.981 40.8336 108.981 40.8336H108.995Z",
    "M298.812 150.82L297.867 176.543C297.867 176.543 294.401 176.474 291.497 176.419C288.592 176.364 279.029 173.452 272.823 173.19C272.22 164.289 272.426 149.468 272.426 149.468L298.812 150.806V150.82Z",
    "M289.839 115.009C289.839 115.009 289.894 116.747 289.674 119.107C289.455 121.439 289.524 130.975 289.524 130.975L289.606 140.746C289.606 140.746 286.14 140.773 283.235 140.801C280.344 140.828 269.823 139.835 263.616 139.752C262.767 130.879 263.411 114.415 263.411 114.415C263.411 114.415 272.48 114.194 277.878 114.746C283.276 115.298 289.825 115.009 289.825 115.009H289.839Z",
    "M274.426 69.2892L277.344 94.8606C277.344 94.8606 273.905 95.316 271.028 95.7024C268.151 96.0888 258.26 94.6674 252.094 95.3574C250.176 86.6496 248.149 71.9664 248.149 71.9664L274.44 69.2754L274.426 69.2892Z",
    "M240.655 83.4623C240.655 83.4623 240.833 85.1873 240.778 87.5609C240.723 89.9345 241.477 99.4151 241.477 99.4151L242.258 109.158C242.258 109.158 238.791 109.448 235.914 109.668C233.037 109.889 222.461 109.668 216.268 110.041C214.788 101.25 214.254 84.7871 214.254 84.7871C214.254 84.7871 223.283 83.9039 228.708 84.0557C234.133 84.2075 240.641 83.4485 240.641 83.4485L240.655 83.4623Z",
    "M166.81 6.11328L192.361 6.72048C192.361 6.72048 192.334 10.2119 192.32 13.1375C192.293 16.0493 189.553 25.7369 189.374 31.9883C180.551 32.7059 165.837 32.7059 165.837 32.7059L166.81 6.11328Z",
    "M156.274 37.7141C156.274 37.7141 157.987 37.5761 160.343 37.6727C162.7 37.7693 172.126 37.2173 172.126 37.2173L181.812 36.6377C181.812 36.6377 182.017 40.1291 182.182 43.0409C182.346 45.9527 181.908 56.5787 182.141 62.8301C173.386 64.1411 157.028 64.3205 157.028 64.3205C157.028 64.3205 156.343 55.1987 156.617 49.7477C156.891 44.2967 156.274 37.7141 156.274 37.7141Z",
    "M254.177 120.611C254.177 120.611 254.245 122.35 254.067 124.709C253.889 127.069 254.067 136.577 254.067 136.577V144.747C254.067 144.747 250.807 146.431 247.902 146.486C244.998 146.541 234.777 147.203 228.585 147.19C227.626 138.33 227.763 120.349 227.763 120.349C227.763 120.349 235.736 121.177 241.203 121.011C246.614 120.845 254.191 120.611 254.191 120.611H254.177Z",
    "M227.132 31.0912C227.132 31.0912 228.859 31.0498 231.201 31.2706C233.544 31.4914 242.984 31.4638 242.984 31.4638L252.684 31.4224C252.684 31.4224 252.697 34.9138 252.711 37.8394C252.711 40.7512 251.697 51.3496 251.588 57.601C242.778 58.4152 226.434 57.6976 226.434 57.6976C226.434 57.6976 226.256 48.562 226.817 43.1248C227.379 37.6876 227.132 31.0912 227.132 31.0912Z",
    "M195.964 52.8666C195.964 52.8666 197.69 52.8252 200.033 53.046C202.376 53.2668 211.815 53.2392 211.815 53.2392L219.912 53.391C219.912 53.391 221.529 56.6892 221.543 59.6148C221.556 62.5404 222.036 72.8352 221.926 79.0866C213.117 79.9008 195.266 79.473 195.266 79.473C195.266 79.473 196.225 71.4552 196.142 65.949C196.06 60.4428 195.964 52.8666 195.964 52.8666Z",
  ];

  const pink = "#F0867B";
  const yellow = "#FCD952";

  return (
    <svg
      fill="none"
      viewBox="0 0 299 263"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1_2)" id="StickyPlant">
        <g id="Stickies">
          {paths.map((path, index) => {
            const variant = index % 2;

            const transitionColor = variant === 0 ? pink : yellow;

            const initialFill = variant === 0 ? yellow : pink;

            return (
              <motion.path
                animate={{
                  fill: [initialFill, transitionColor, initialFill],
                  scale: variant === 0 ? [1, 1.05, 1] : undefined,
                }}
                d={path}
                fill={initialFill}
                id={`Vector_${index}`}
                key={index}
                style={{
                  originX: "50%",
                  originY: "50%",
                  transformBox: "fill-box",
                }}
                transition={{
                  delay: (index % 7) * 0.3,
                  duration: 3 + Math.random() * 2,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "mirror",
                }}
              />
            );
          })}
        </g>
        <g id="Plant">
          <path
            d="M117.749 238.188C107.926 240.451 92.8421 249.918 108.241 256.252C124.408 262.904 156.781 263.801 169.879 259.33C176.003 257.246 194.745 245.985 180.935 239.817C160.083 233.772 133.998 234.6 117.749 238.188Z"
            fill="#808080"
            id="Vector_21"
            opacity={0.15}
          />
          <path
            clipRule="evenodd"
            d="M189.32 153.235C189.32 153.235 167.002 147.674 142.876 147.315C102.926 145.245 91.5817 153.884 91.5817 153.884C91.5817 153.884 103.871 163.917 142.437 165.007C181.004 166.097 189.32 153.235 189.32 153.235Z"
            fill="#344D5C"
            fillRule="evenodd"
            id="Vector_22"
          />
          <path
            clipRule="evenodd"
            d="M187.963 156.672C187.963 156.672 169.756 152.628 145.629 152.269C121.503 151.911 97.692 153.511 97.692 153.511C97.692 153.511 104.09 168.264 142.643 169.354C181.209 170.444 187.963 156.685 187.963 156.685V156.672Z"
            fill="#473C24"
            fillRule="evenodd"
            id="Vector_23"
          />
          <path
            clipRule="evenodd"
            d="M190.636 205.308C190.825 204.219 190.209 203.122 189.139 202.746C187.949 202.329 186.649 202.962 186.234 204.16C185.679 205.764 186.419 207.248 187.002 208.133C187.658 209.128 188.619 210.127 189.683 211.078C191.831 212.997 194.802 215.072 197.835 216.968C200.885 218.875 204.081 220.653 206.728 221.974C208.05 222.634 209.26 223.191 210.261 223.596C211.146 223.954 212.173 224.324 212.984 224.376C214.023 224.445 215.133 224.103 216.04 223.748C217.027 223.362 218.119 222.817 219.232 222.193C221.464 220.944 223.958 219.282 226.174 217.656C228.377 216.04 230.399 214.39 231.655 213.149C231.973 212.835 232.27 212.519 232.519 212.216C232.735 211.953 233.042 211.547 233.235 211.066C233.329 210.829 233.482 210.367 233.424 209.792C233.354 209.097 232.989 208.416 232.335 207.985C231.792 207.628 231.249 207.575 230.965 207.567C230.651 207.559 230.367 207.603 230.153 207.647C229.041 207.878 228.286 208.887 228.333 209.993C227.288 211.002 225.531 212.442 223.488 213.942C221.371 215.495 219.039 217.044 217.016 218.177C216.002 218.744 215.109 219.182 214.388 219.465C213.671 219.745 213.339 219.784 213.289 219.79C213.283 219.79 213.281 219.791 213.283 219.791L213.271 219.79C213.276 219.79 213.269 219.788 213.248 219.783C213.152 219.757 212.757 219.654 211.961 219.332C211.107 218.986 210.012 218.484 208.753 217.856C206.239 216.602 203.17 214.895 200.241 213.064C197.296 211.222 194.575 209.306 192.71 207.64C191.767 206.797 191.141 206.104 190.803 205.591C190.721 205.467 190.668 205.374 190.636 205.308ZM190.594 205.213C190.588 205.197 190.587 205.189 190.587 205.189C190.587 205.189 190.591 205.197 190.594 205.213Z"
            fill="#48A8E4"
            fillRule="evenodd"
            id="Vector_24"
          />
          <path
            clipRule="evenodd"
            d="M107.262 204.206C107.75 205.376 107.204 206.722 106.042 207.214C104.681 207.79 103.055 208.948 101.309 210.574C99.5864 212.178 97.8538 214.135 96.2718 216.175C94.6913 218.213 93.2885 220.299 92.2178 222.143C91.1608 223.964 90.5126 225.405 90.2717 226.284C90.2891 226.339 90.3308 226.443 90.4277 226.606C90.6761 227.024 91.1522 227.585 91.9094 228.251C93.4123 229.573 95.6607 230.998 98.1905 232.252C100.709 233.5 103.384 234.518 105.687 235.083C106.839 235.365 107.844 235.521 108.658 235.552C109.507 235.584 109.968 235.473 110.156 235.388C111.305 234.868 112.655 235.385 113.172 236.542C113.688 237.699 113.175 239.06 112.026 239.58C110.926 240.078 109.663 240.189 108.486 240.144C107.275 240.098 105.952 239.877 104.608 239.548C101.918 238.889 98.9274 237.739 96.1763 236.375C93.4366 235.017 90.812 233.387 88.9082 231.712C87.9621 230.88 87.1014 229.956 86.5135 228.967C85.9356 227.995 85.4651 226.685 85.8215 225.261C86.2004 223.747 87.1258 221.811 88.2795 219.823C89.4613 217.788 90.9809 215.533 92.6765 213.346C94.3706 211.162 96.2678 209.01 98.2122 207.2C100.133 205.412 102.212 203.85 104.275 202.977C105.437 202.486 106.774 203.036 107.262 204.206Z"
            fill="#48A8E4"
            fillRule="evenodd"
            id="Vector_25"
          />
          <path
            d="M144.684 165.682C144.684 165.682 143.424 126.711 143.643 116.968C143.944 103.293 146.753 88.1401 147.958 88.2919C149.164 88.4437 148.685 105.983 148.041 114.912C147.397 123.841 149.219 165.875 149.219 165.875L144.684 165.682Z"
            fill="#473C24"
            id="Vector_26"
          />
          <path
            d="M190.786 156.438C205.089 181.291 181.25 240.493 171.687 253.714C160.001 256.929 130.888 258.502 115.859 253.714C106.542 238.603 81.7449 184.741 89.8692 156.313C90.3487 154.657 91.9242 153.015 93.623 153.553C128.696 164.442 165.55 159.405 184.264 154.133C185.675 153.733 190.046 155.14 190.799 156.438"
            fill="#86C5ED"
            id="Vector_27"
          />
          <path
            d="M184.251 154.133C185.634 153.802 186.936 153.457 188.141 153.126C189.553 152.725 191.046 153.926 191.799 155.223C206.103 180.077 181.236 240.493 171.674 253.714C169.591 254.293 166.961 254.804 163.947 255.246C174.838 237.402 197.307 179.856 184.237 154.133H184.251Z"
            fill="#86C5ED"
            id="Vector_28"
          />
          <path
            d="M184.251 154.133C185.634 153.802 186.936 153.457 188.141 153.126C189.553 152.725 191.046 153.926 191.799 155.223C206.103 180.077 181.236 240.493 171.674 253.714C169.591 254.293 166.961 254.804 163.947 255.246C174.838 237.402 197.307 179.856 184.237 154.133H184.251Z"
            fill="#9CCFF0"
            id="Vector_29"
          />
          <path
            clipRule="evenodd"
            d="M122.001 142.841C130.366 140.947 139.545 141.452 147.358 147.727C147.881 148.147 147.968 148.916 147.55 149.443C147.133 149.97 146.37 150.057 145.846 149.637C138.794 143.974 130.451 143.431 122.533 145.224C114.598 147.021 107.223 151.143 102.348 154.594C101.801 154.981 101.044 154.848 100.659 154.297C100.274 153.745 100.406 152.983 100.954 152.595C105.985 149.035 113.653 144.732 122.001 142.841Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_30"
          />
          <path
            d="M121.188 161.143C115.996 168.65 78.6623 168.678 99.0894 146.087C120.188 122.751 126.449 153.525 121.188 161.143Z"
            fill="#3C784A"
            id="Vector_31"
          />
          <path
            clipRule="evenodd"
            d="M157.831 62.6138C158.26 62.8468 158.421 63.3866 158.19 63.8195C154.768 70.2219 153.627 74.2357 152.748 78.3905C152.575 79.2087 152.411 80.0362 152.243 80.891C151.547 84.4111 150.761 88.3932 148.836 94.0859C148.679 94.5513 148.177 94.8001 147.715 94.6416C147.253 94.4831 147.006 93.9774 147.163 93.5121C149.047 87.9395 149.81 84.0817 150.503 80.5715C150.674 79.7066 150.841 78.8627 151.019 78.0195C151.928 73.7218 153.123 69.5453 156.634 62.9757C156.865 62.5428 157.401 62.3808 157.831 62.6138Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_32"
          />
          <path
            clipRule="evenodd"
            d="M120.767 69.9262C120.842 69.4405 121.294 69.108 121.776 69.1836C132.776 70.9079 138.444 73.4364 141.79 77.7689C143.45 79.9185 144.491 82.4525 145.365 85.3992C145.934 87.3156 146.445 89.4585 147.007 91.8142C147.303 93.0534 147.613 94.3515 147.952 95.7065C148.072 96.1831 147.785 96.667 147.312 96.7873C146.838 96.9076 146.358 96.6187 146.239 96.1421C145.886 94.7339 145.57 93.411 145.272 92.1627C144.719 89.8429 144.227 87.7809 143.672 85.9089C142.824 83.0519 141.861 80.7594 140.395 78.862C137.491 75.1009 132.425 72.6545 121.504 70.9426C121.022 70.867 120.692 70.4119 120.767 69.9262Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_33"
          />
          <path
            clipRule="evenodd"
            d="M173.77 104.057C162.503 98.7371 148.193 108.112 147.36 122.305C147.317 123.039 146.691 123.599 145.962 123.556C145.233 123.512 144.678 122.882 144.721 122.148C145.642 106.45 161.609 95.3732 174.893 101.645C175.554 101.958 175.839 102.75 175.529 103.416C175.219 104.082 174.432 104.369 173.77 104.057Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_34"
          />
          <path
            clipRule="evenodd"
            d="M177.14 128.965C177.16 129.719 176.57 130.348 175.821 130.368C160.551 130.794 150.315 141.216 147.793 148.838C147.556 149.553 146.788 149.94 146.077 149.702C145.367 149.463 144.983 148.689 145.22 147.973C148.095 139.283 159.314 128.095 175.746 127.637C176.495 127.616 177.119 128.211 177.14 128.965Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_35"
          />
          <path
            clipRule="evenodd"
            d="M169.551 108.271C161.154 109.048 152.676 111.446 146.82 115.754C146.231 116.188 145.403 116.058 144.973 115.464C144.542 114.87 144.671 114.037 145.261 113.603C151.653 108.9 160.663 106.419 169.309 105.619C177.95 104.82 186.478 105.677 191.973 107.88C192.652 108.152 192.983 108.926 192.713 109.609C192.443 110.293 191.674 110.626 190.995 110.354C186.024 108.362 177.953 107.494 169.551 108.271Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_36"
          />
          <path
            clipRule="evenodd"
            d="M187.173 85.9525C187.298 86.5568 186.913 87.1489 186.313 87.275C176.39 89.3604 167.94 92.1199 161.334 95.6939C154.73 99.2674 150.035 103.618 147.504 108.86C147.236 109.415 146.572 109.646 146.021 109.376C145.47 109.106 145.24 108.438 145.508 107.882C148.307 102.084 153.422 97.4373 160.284 93.7244C167.146 90.012 175.821 87.1963 185.86 85.0865C186.46 84.9604 187.048 85.3481 187.173 85.9525Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_37"
          />
          <path
            clipRule="evenodd"
            d="M165.681 82.4595C165.928 82.8829 165.789 83.4286 165.368 83.6783C156.551 88.9137 150.132 95.2276 147.9 100.171C147.698 100.619 147.174 100.816 146.729 100.613C146.285 100.409 146.089 99.8817 146.291 99.4342C148.744 94.0002 155.532 87.4525 164.471 82.1447C164.891 81.8951 165.433 82.036 165.681 82.4595Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_38"
          />
          <path
            clipRule="evenodd"
            d="M124.948 96.3827C134.282 101.981 142.664 110.523 146.993 117.831C147.307 118.361 147.135 119.047 146.609 119.364C146.083 119.68 145.401 119.507 145.087 118.976C140.963 112.015 132.857 103.728 123.812 98.3036C114.717 92.8483 105.056 90.5019 97.5867 94.679C97.0509 94.9786 96.3754 94.784 96.0779 94.2442C95.7804 93.7045 95.9736 93.024 96.5095 92.7244C105.056 87.9453 115.665 90.8152 124.948 96.3827Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_39"
          />
          <path
            clipRule="evenodd"
            d="M123.28 58.6476C123.532 58.2263 124.075 58.0902 124.493 58.3435C127.252 60.0147 129.774 63.1755 132.05 66.9929C134.339 70.8329 136.44 75.4414 138.319 80.1267C140.2 84.8156 141.867 89.6045 143.283 93.8118C143.718 95.1028 144.128 96.3359 144.513 97.4939C145.388 100.124 146.133 102.367 146.745 104.018C146.916 104.478 146.683 104.991 146.226 105.163C145.769 105.335 145.26 105.101 145.089 104.64C144.467 102.96 143.705 100.67 142.824 98.0212C142.441 96.8697 142.036 95.6503 141.61 94.3836C140.196 90.1863 138.542 85.4351 136.681 80.7935C134.818 76.1482 132.756 71.6358 130.535 67.9095C128.299 64.1606 125.961 61.3098 123.582 59.8691C123.164 59.6158 123.029 59.0689 123.28 58.6476Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_40"
          />
          <path
            clipRule="evenodd"
            d="M160.787 136.882C156.982 134.98 151.965 134.608 146.331 136.478C145.637 136.708 144.89 136.328 144.661 135.63C144.433 134.931 144.81 134.178 145.503 133.948C151.679 131.899 157.43 132.23 161.962 134.496C166.506 136.768 169.708 140.93 170.83 146.281C170.981 147.001 170.524 147.707 169.81 147.859C169.095 148.011 168.394 147.551 168.243 146.831C167.282 142.247 164.579 138.778 160.787 136.882Z"
            fill="#98A850"
            fillRule="evenodd"
            id="Vector_41"
          />
          <path
            d="M88.1155 86.4711C88.2388 75.4725 125.23 70.8633 119.914 94.9995C116.681 109.724 87.9922 97.1937 88.1155 86.4711Z"
            fill="#488857"
            id="Vector_42"
          />
          <path
            d="M171.852 141.34C161.604 137.159 158.398 108.303 184.004 118.805C206.829 128.161 185.579 146.943 171.852 141.34Z"
            fill="#3C784A"
            id="Vector_43"
          />
          <path
            d="M174.633 79.4195C179.839 91.3013 159.467 105.239 151.233 91.6601C143.794 79.4195 170.221 69.3593 174.633 79.4195Z"
            fill="#3C784A"
            id="Vector_44"
          />
          <path
            d="M102.405 133.198C85.9371 129.113 79.7994 94.213 107.844 100.188C138.176 106.647 118.763 137.269 102.405 133.198Z"
            fill="#3C784A"
            id="Vector_45"
          />
          <path
            d="M135.108 143.879C115.16 143.438 103.734 101.651 135.779 102.245C168.495 102.852 158.453 144.39 135.108 143.879Z"
            fill="#488857"
            id="Vector_46"
          />
          <path
            d="M129.545 93.7983C113.064 93.0531 103.446 56.6763 129.929 57.7665C156.96 58.8843 148.849 94.6539 129.545 93.7983Z"
            fill="#3C784A"
            id="Vector_47"
          />
          <path
            d="M160.782 53.9446C172.523 57.16 167.659 83.6836 150.835 78.2602C137.546 73.9822 148.904 50.6878 160.782 53.9446Z"
            fill="#488857"
            id="Vector_48"
          />
          <path
            d="M210.857 111.408C210.267 124.159 179.579 125.746 182.511 107.282C185.182 90.5009 211.432 98.9603 210.857 111.408Z"
            fill="#3C784A"
            id="Vector_49"
          />
          <path
            d="M167.536 114.14C156.11 115.189 151.164 96.7936 166.084 95.8828C179.702 95.0548 183.483 112.677 167.536 114.14Z"
            fill="#4A8759"
            id="Vector_50"
          />
          <path
            d="M202.993 85.7264C197.91 101.679 171.975 99.5678 177.674 84.0704C183.182 69.0974 205.911 76.5494 202.993 85.7264Z"
            fill="#488857"
            id="Vector_51"
          />
          <path
            d="M172.893 176.323C154.713 176.682 136.573 141.23 171.03 138.566C206.253 135.848 197.923 175.826 172.893 176.323Z"
            fill="#458955"
            id="Vector_52"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect fill="white" height={263} width={299} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StickyPlant;
