import { styled } from "@mui/material/styles";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl-be";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getMomentLocale } from "../../language/languagesUtil";

const PREFIX = "DatePicker";

const classes = {
  textField: `${PREFIX}-textField`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.textField}`]: {
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,

      cursor: "pointer",
      ...theme.controls.border?.normal,
      ...theme.controls.input,

      "& .MuiIconButton-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
        marginRight: "-4px",
      },
      "& INPUT": {
        padding: 0,
      },

      "&:hover": {
        ...theme.controls.border?.hover,
      },

      marginTop: 5,

      paddingRight: 5,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

interface ICustomDatePicker {
  errorText: string;
  fullWidth: boolean;
  hasError: boolean;
  name: string;
  onChange: (something) => void;
  value: Date | Moment;
}

export const CustomDatePicker = ({
  fullWidth,
  hasError,
  name,
  onChange,
  value,
  ...other
}: ICustomDatePicker) => {
  const [open, setOpen] = useState(false);

  const { i18n } = useTranslation();
  const momentLocale = getMomentLocale(i18n.language);

  moment.locale(momentLocale);

  return (
    <Root>
      <LocalizationProvider
        adapterLocale={momentLocale}
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <MUIDatePicker
          className={classes.textField}
          format="DD/MM/YYYY"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          slotProps={{
            textField: {
              error: hasError,
              fullWidth,
              name,
              placeholder: "dd/mm/yyyy",
            },
          }}
          value={moment(value)}
          {...other}
          onChange={onChange}
        />
      </LocalizationProvider>
    </Root>
  );
};
