import { InMemoryCache } from "@apollo/client/cache";
import { relayStylePagination } from "@apollo/client/utilities";
import helpers, {
  StrictTypedTypePolicies,
} from "./__generated__/apollo-helpers";

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      myProfessionalConsultations: relayStylePagination(["filter", "order"]),
    },
  },
};

const createApolloCache = () => {
  return new InMemoryCache({
    possibleTypes: helpers.possibleTypes,
    typePolicies,
  });
};

export default createApolloCache;
