import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Children } from "react";

const ExpansionToggle = ({
  children,
  isOpen,
  label,
  mb = 0,
  mt = 0,
  onClick,
  primary,
}: {
  children?: React.ReactNode;
  isOpen: boolean;
  label: string;
  mb?: number;
  mt?: number;
  onClick: () => void;
  primary: boolean;
}) => {
  return (
    <Box mb={mb} mt={mt}>
      <Button
        endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={onClick}
        sx={(theme) => ({
          "&:hover": {
            background: "transparent",
          },
          color: primary
            ? theme.palette.primary.main
            : theme.palette.black.main,
          fontSize: theme.typography.sizes.normal,
          padding: 0,
          textTransform: "none",
        })}
      >
        <Typography>{label}</Typography>
      </Button>
      {isOpen ? Children.map(children, (child) => child) : null}
    </Box>
  );
};

export default ExpansionToggle;
