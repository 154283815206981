import CalendarMonth from "@mui/icons-material/CalendarMonth";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Payment from "@mui/icons-material/Payment";
import Person from "@mui/icons-material/Person";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { Box, Divider, Link, Typography } from "@mui/material";
import { PreviewVideo } from "amazon-chime-sdk-component-library-react";
import { differenceInMinutes, format } from "date-fns";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import AuthContext from "../providers/auth/AuthContext";
import { RouteNames } from "../routes/routeNames";
import Tooltip from "../ui/Tooltip";
import Button from "../ui/buttons/Button";
import AudioVideoSettings from "./AudioVideoSettings";

const ConsultationFragment = graphql(`
  fragment StartCallPopupConsultation on Consultation {
    cashTransaction {
      id
      status
    }
    creditTransactions {
      id
    }
    human {
      id
      user {
        firstName
        id
        lastName
      }
    }
    id
    professional {
      id
      user {
        firstName
        id
        lastName
      }
    }
    scheduledFrom
    type
    uuid
  }
`);

export default function StartCallPopup({
  consultation,
  hasJoinedMeeting,
  startCall,
}: {
  consultation: FragmentType<typeof ConsultationFragment>;
  hasJoinedMeeting: boolean;
  startCall: () => void;
}) {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const consultationData = getFragmentData(ConsultationFragment, consultation);
  const isHuman = currentUser?.getUUID() === consultationData.human?.user.id;
  const shouldBePaid =
    isHuman &&
    consultationData.type !== "INTRO" &&
    consultationData.creditTransactions.length === 0 &&
    consultationData.cashTransaction?.status !== "paid";

  const getPaymentButtonLabel = useCallback(() => {
    switch (consultationData.cashTransaction?.status) {
      case "new":
      // falls through

      case "open":
        return t("payments:pay.now");

      case "failed":
      // falls through

      case "expired":
      // falls through

      case "canceled":
        return t("payments:payment.retry");

      default:
        return "";
    }
  }, [consultationData.cashTransaction?.status, t]);

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        borderRadius: "20px",
        color: "#364958",
        left: "50%",
        maxWidth: "800px",
        overflow: "hidden",
        position: "absolute",
        top: "45%",
        transform: "translate(-50%,-50%)",
        width: "50%",
        zIndex: 20,

        [theme.breakpoints.down(850)]: {
          borderRadius: 0,
          height: "100%",
          left: 0,
          maxHeight: "none",
          maxWidth: "none",
          overflow: "visible",
          top: 0,
          transform: "none",
          width: "100%",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          background: theme.palette.bloom.light,
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          paddingBottom: "20px",
          paddingTop: "20px",
          textAlign: "center",
        })}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: 21,
            fontWeight: "bold",
          }}
        >
          {t("ui:device.settings.container.welcome")}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Box sx={{ fontSize: 16, marginBottom: "32px" }}>
          <Typography variant="h4">
            {t("ui:device.settings.container.info")}
          </Typography>
          <Typography
            sx={{
              alignItems: "flex-end",
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <CalendarMonth
              sx={(theme) => ({
                color: theme.palette.bloom.dark,
                marginRight: "8px",
              })}
            />
            {format(consultationData.scheduledFrom, "eeee d MMMM p")}
          </Typography>
          <Typography
            sx={{
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            <Person
              sx={(theme) => ({
                color: theme.palette.bloom.dark,
                marginRight: "8px",
              })}
            />
            {isHuman
              ? `${consultationData.professional?.user.firstName} ${consultationData.professional?.user.lastName}`
              : `${consultationData.human?.user.firstName} ${consultationData.human?.user.lastName}`}
          </Typography>
        </Box>
        <Typography sx={{ marginBottom: "4px" }} variant="h4">
          {t("ui:device.settings.container.settings")}
        </Typography>

        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",

            [theme.breakpoints.down(850)]: {
              flexDirection: "column",
            },
          })}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <AudioVideoSettings />
            <Box
              sx={{
                display: "flex",
                marginTop: "15px",
              }}
            >
              <Tooltip text={t("ui:device.settings.help.tooltip")}>
                <HelpOutline />
              </Tooltip>
              <Link
                href={t("ui:device.settings.help.link")}
                sx={(theme) => ({
                  "&:hover": {
                    cursor: "pointer",
                  },
                  color: theme.palette.text.primary,
                  marginLeft: "5px",
                  textDecoration: "underline",
                })}
                target="_blank"
              >
                {t("ui:device.settings.help")}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              alignSelf: "center",
              flex: 1,
              padding: "20px",
            }}
          >
            <Box
              sx={(theme) => ({
                borderRadius: "20px",
                maxHeight: "200px",
                maxWidth: "400px",
                overflow: "hidden",
                [theme.breakpoints.down(850)]: {
                  maxHeight: "180px",
                },
              })}
            >
              <PreviewVideo />
            </Box>
          </Box>
        </Box>
        {shouldBePaid ? (
          <Box
            sx={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Typography>{t("ui:device.settings.payFirst")}</Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            bloomColor={hasJoinedMeeting ? "green" : "bloom"}
            disabled={
              !hasJoinedMeeting ||
              shouldBePaid ||
              differenceInMinutes(consultationData.scheduledFrom, new Date()) >
                60
            }
            fullyColored={true}
            icon={<PlayArrow />}
            label={
              hasJoinedMeeting
                ? t("ui:device.settings.container.start.call")
                : t("ui:device.settings.container.patience")
            }
            onClick={startCall}
            sx={{
              "&:disabled": {
                opacity: 0.3,
              },
            }}
          />
          {shouldBePaid ? (
            <Button
              bloomColor="porple"
              component={Link}
              href={RouteNames.Payment.Consultation.Pay.path.replace(
                ":uuid",
                consultationData.uuid,
              )}
              icon={<Payment />}
              label={getPaymentButtonLabel()}
              target="_blank"
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
