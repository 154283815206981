import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import LeftSection from "../../layout/LeftSection";
import LoadingPage from "../../layout/LoadingPage";
import EditableProfile from "../../profile/EditableProfile";
import MutualitySelect from "./MutualitySelect";
import HumanProfileContext from "./context/HumanProfileContext";
import { IHumanProfileContext } from "./context/humanProfile";

const queryHumanProfilePage = graphql(`
  query HumanProfilePage {
    human {
      creditBalance {
        total
      }
      ...HumanMutuality
      id
      organization {
        id
        name {
          ...useSelectTranslationStringInput
        }
      }
    }
  }
`);

export default function HumanProfile() {
  const { t } = useTranslation();

  const { formState, human, onSave, updatePreferredLanguage, updateTimezone } =
    useContext<IHumanProfileContext>(HumanProfileContext);

  const { data } = useQuery(queryHumanProfilePage);
  const selectTranslation = useSelectTranslation();

  if (!human) return <LoadingPage full={false} />;

  return (
    <LeftSection
      pageTitle={t("common:myprofile", "Mijn Profiel")}
      short={false}
    >
      <EditableProfile
        creditsLeft={data?.human?.creditBalance?.total}
        formState={formState}
        onSave={onSave}
        organizationName={selectTranslation(data?.human?.organization?.name)}
        person={human}
        updatePreferredLanguage={updatePreferredLanguage}
        updateTimezone={updateTimezone}
      />
      {data?.human ? (
        <Box style={{ paddingLeft: "5px" }}>
          <MutualitySelect
            human={data.human}
            label={t("common:preferences.health.insurance")}
            placeholder={t("common:preferences.health.insurance.placeholder")}
          />
        </Box>
      ) : null}
    </LeftSection>
  );
}
