import { Locale } from "date-fns";
import { enGB, fr, nlBE } from "date-fns/locale";

export enum I18NexusLanguages {
  ENGLISH = "en",
  FLEMISH = "nl-BE",
  FRENCH = "fr-BE",
}

export const languageToCaptionMap = new Map<I18NexusLanguages, string>([
  [I18NexusLanguages.ENGLISH, "English"],
  [I18NexusLanguages.FRENCH, "Français"],
  [I18NexusLanguages.FLEMISH, "Nederlands"],
]);

export enum DatabaseLanguageEquivalents {
  ENGLISH = "en",
  FLEMISH = "nlBe",
  FRENCH = "frBe",
}

export const databaseLanguageToi18NexusLanguageEquivalentsMap = new Map<
  string,
  I18NexusLanguages
>([
  ["en", I18NexusLanguages.ENGLISH],
  ["frBe", I18NexusLanguages.FRENCH],
  ["nlBe", I18NexusLanguages.FLEMISH],
]);

enum MomentLanguages {
  ENGLISH = "en-gb",
  FLEMISH = "nl-be",
  FRENCH = "fr",
}

enum CrispLanguages {
  ENGLISH = "en",
  FLEMISH = "nl",
  FRENCH = "fr",
}

const I18NexusLanguagesToCrispLanguages = new Map<
  I18NexusLanguages,
  CrispLanguages
>([
  [I18NexusLanguages.ENGLISH, CrispLanguages.ENGLISH],
  [I18NexusLanguages.FRENCH, CrispLanguages.FRENCH],
  [I18NexusLanguages.FLEMISH, CrispLanguages.FLEMISH],
]);

export const getCrispLanguage = (i18NexusLanguage: string) => {
  if (
    Object.values(I18NexusLanguages).includes(
      // This is a safe typecast because we want to check if the value is in the array
      i18NexusLanguage as I18NexusLanguages,
    )
  ) {
    return I18NexusLanguagesToCrispLanguages.get(
      i18NexusLanguage as I18NexusLanguages,
    );
  }

  return undefined;
};

const I18NexusLanguagesToMomentLangauges = new Map<
  I18NexusLanguages,
  MomentLanguages
>([
  [I18NexusLanguages.ENGLISH, MomentLanguages.ENGLISH],
  [I18NexusLanguages.FRENCH, MomentLanguages.FRENCH],
  [I18NexusLanguages.FLEMISH, MomentLanguages.FLEMISH],
]);

const I18NexusLanguagesToDateFnsLangauges = new Map<I18NexusLanguages, Locale>([
  [I18NexusLanguages.ENGLISH, enGB],
  [I18NexusLanguages.FRENCH, fr],
  [I18NexusLanguages.FLEMISH, nlBE],
]);

export const getMomentLocale = (i18NexusLanguage: string) => {
  if (
    Object.values(I18NexusLanguages).includes(
      // This is a safe typecast because we want to check if the value is in the array
      i18NexusLanguage as I18NexusLanguages,
    )
  ) {
    return I18NexusLanguagesToMomentLangauges.get(
      i18NexusLanguage as I18NexusLanguages,
    );
  }

  return undefined;
};

export const getDateFnsLocale = (i18NexusLanguage: string) => {
  if (
    Object.values(I18NexusLanguages).includes(
      // This is a safe typecast because we want to check if the value is in the array
      i18NexusLanguage as I18NexusLanguages,
    )
  ) {
    return I18NexusLanguagesToDateFnsLangauges.get(
      i18NexusLanguage as I18NexusLanguages,
    );
  }

  return undefined;
};

export const prepareLanguageInputForAPI = (
  consultationLanguagesInput: Array<string>,
): Array<DatabaseLanguageEquivalents> => {
  if (!consultationLanguagesInput) return [];

  return consultationLanguagesInput.map((cl) => switchAppInputToAPI(cl));
};

export const parseDatabaseLanguagesFromAPI = (
  consultationLanguages: Array<string>,
): Array<I18NexusLanguages> => {
  if (!consultationLanguages) return [];

  return consultationLanguages.map((cl) => switchAPIInputToApp(cl));
};

export const parseOneLanguageFromAPI = (
  language: string | DatabaseLanguageEquivalents,
): I18NexusLanguages => switchAPIInputToApp(language);

export const prepareOneLanguageForAPI = (
  languageInput: string | I18NexusLanguages,
): DatabaseLanguageEquivalents => switchAppInputToAPI(languageInput);

function switchAPIInputToApp(
  input: string | DatabaseLanguageEquivalents,
): I18NexusLanguages {
  switch (input) {
    case DatabaseLanguageEquivalents.FRENCH:
      return I18NexusLanguages.FRENCH;

    case DatabaseLanguageEquivalents.ENGLISH:
      return I18NexusLanguages.ENGLISH;

    case DatabaseLanguageEquivalents.FLEMISH:
      return I18NexusLanguages.FLEMISH;

    default:
      return I18NexusLanguages.FLEMISH;
  }
}

function switchAppInputToAPI(
  input: string | I18NexusLanguages,
): DatabaseLanguageEquivalents {
  switch (input) {
    case I18NexusLanguages.FLEMISH:
      return DatabaseLanguageEquivalents.FLEMISH;

    case I18NexusLanguages.FRENCH:
      return DatabaseLanguageEquivalents.FRENCH;

    case I18NexusLanguages.ENGLISH:
      return DatabaseLanguageEquivalents.ENGLISH;

    default:
      return input as any;
  }
}
