import { useMutation } from "@apollo/client";
import { APIClient } from "../clients";
import { MUTATION_CLIENT_ACCEPT } from "../mutations";

const useAcceptInvite = () => {
  const [acceptInviteFunction, { data, error, loading }] = useMutation<
    APIClient.AcceptInvite.Result,
    APIClient.AcceptInvite.APIInput
  >(MUTATION_CLIENT_ACCEPT);

  const acceptInvite = (input: APIClient.AcceptInvite.Input) =>
    acceptInviteFunction({ variables: { input } });

  return {
    acceptInvite,
    data,
    error,
    loading,
  };
};

export default useAcceptInvite;
