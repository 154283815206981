import { Route, Routes } from "react-router";
import { defaultRegistrationValues } from "../pages/human-register/context/RegistrationContext";
import RegistrationContextProvider from "../pages/human-register/context/RegistrationContextProvider";
import HumanInfoStep from "../pages/human-register/steps/HumanInfoStep";
import PasswordStep from "../pages/human-register/steps/PasswordStep";
import SuccesStep from "../pages/human-register/steps/SuccesStep";
import { RouteNames } from "./routeNames";

export default function HumanRegistrationRoutes() {
  return (
    <RegistrationContextProvider {...defaultRegistrationValues}>
      <Routes>
        <Route
          element={<HumanInfoStep />}
          path={RouteNames.Register.Human.Start.name}
        />
        <Route
          element={<PasswordStep />}
          path={RouteNames.Register.Human.SetPassword.name}
        />
        <Route
          element={<SuccesStep />}
          path={RouteNames.Register.Human.Success.name}
        />
      </Routes>
    </RegistrationContextProvider>
  );
}
