import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import CashTransaction, {
  CashTransactionModel,
} from "../../../data-model/types/CashTransaction";
import { QUERY_GET_LAST_CASH_TRANSACTION_BY_CONSULTATION_ID } from "../queries";

interface IGetCashTransaction {
  lastCashTransactionByConsultationId: CashTransactionModel;
}

interface IGetCashTransactionInput {
  consultationId: number;
}

export default function useLazyGetLastCashTransactionsByConsultationId() {
  const [execute, { data, error, loading, refetch }] = useLazyQuery<
    IGetCashTransaction,
    IGetCashTransactionInput
  >(QUERY_GET_LAST_CASH_TRANSACTION_BY_CONSULTATION_ID);

  const getCashTransaction = (consultationId: number) =>
    execute({
      variables: {
        consultationId,
      },
    });

  const cashTransaction: CashTransaction | undefined = useMemo<
    CashTransaction | undefined
  >(() => {
    if (data && data.lastCashTransactionByConsultationId) {
      return new CashTransaction(data.lastCashTransactionByConsultationId);
    }
  }, [data]);

  return {
    cashTransaction,
    error,
    getCashTransaction,
    loading,
    refetch,
  };
}
