/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { graphql } from "../../api/__generated__";
import useLazyGetLastCashTransactionByConsultationId from "../../api/cashTransactions/hooks/useLazyGetLastCashTransactionByConsultationId";
import useFetchConsultationByUuid from "../../api/consultations/hooks/useFetchConsultationByUuid";
import useInterval from "../../custom-hooks/useInterval";
import { ConsultationTypes } from "../../data-model/types/consultation/Consultation";
import LoadingPage from "../layout/LoadingPage";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import { RouteNames } from "../routes/routeNames";

const humanQuery = graphql(`
  query MollieRedirectHuman {
    currentUser {
      human {
        id
        mutuality {
          id
          title
        }
      }
      id
    }
  }
`);

export default function MollieRedirectPage() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const {
    cashTransaction,
    getCashTransaction,
    loading: loadingCashTransaction,
  } = useLazyGetLastCashTransactionByConsultationId();

  const { data: humanData } = useQuery(humanQuery);

  const { t } = useTranslation();

  //1. start fetching the consultation
  const { consultation, loading: loadingConsultation } =
    useFetchConsultationByUuid(uuid || "");

  // 4.1 Function that gets called when the payment has completed successfully.
  const success = useCallback(() => {
    if (consultation) {
      let redirectPath =
        RouteNames.Payment.Consultation.Pay.Success.path.replace(
          ":uuid",
          consultation.getUUID(),
        );

      if (
        humanData?.currentUser?.human?.mutuality?.title &&
        ["CM", "Helan"].includes(humanData.currentUser.human.mutuality.title) &&
        consultation.getConsultationType() === ConsultationTypes.APPOINTMENT
      ) {
        redirectPath = `${redirectPath}?reimbursement=true&consultation=${consultation.getUUID()}`;
      }

      navigate(redirectPath);
    }
  }, [consultation]);

  // 4.2 Function that gets called when the payment has failed or timeout expires.
  const fail = useCallback(() => {
    if (consultation) {
      navigate(
        RouteNames.Payment.Consultation.Pay.Failure.path.replace(
          ":uuid",
          consultation.getUUID(),
        ),
      );
    }
  }, [consultation]);

  // 2. set the timeout
  useEffect(() => {
    // Redirect to failure route if we do not have a valid status after 60 seconds.
    const timeout = setTimeout(() => {
      return fail();
    }, 60000);

    // 5. make sure we clean up.
    return () => {
      return clearTimeout(timeout);
    };
  }, [fail]);

  // 3. Start polling for the cash Transaction when we have fetched the consultation
  useInterval(() => {
    if (consultation) {
      getCashTransaction(consultation.getID());
    }
  }, 3000);

  // 4. Handle status changes.
  useEffect(() => {
    if (!loadingCashTransaction && cashTransaction) {
      const status = cashTransaction.getStatus();

      if (status === "open") {
        //If status is still 'OPEN', we should continue polling.
        return;
      } else if (status === "paid") {
        success();
      } else if (
        status === "expired" ||
        status === "canceled" ||
        status === "failed"
      ) {
        fail();
      }
    }
  }, [loadingCashTransaction, cashTransaction, fail, success]);

  if (loadingConsultation) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={t("payments:processing")}
      >
        <CircularProgress />
        <Box mt={2}>
          <Typography variant="h2">{t("payments:patience")}!</Typography>
          <Box mt={5}>
            <Typography>{t("payments:processing.page.text_1")}</Typography>
            <Typography>{t("payments:processing.page.text_2")}</Typography>
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
