/* eslint-disable padding-line-between-statements */
/*
    Defines all routes that are available in the app.
*/

export namespace RouteNames {
  export const path = "/";
  export const name = "/";

  //===============================
  //    LOGIN
  //================================
  export namespace Login {
    export const name = "login";
    export const path = `${RouteNames.path}${RouteNames.Login.name}`;

    export namespace Sso {
      export const name = "sso";
      export const path = `${RouteNames.path}${RouteNames.Login.Sso.name}`;
    }
    export namespace SsoRedirect {
      export const name = "ssoredirect";
      export const path = `${RouteNames.path}${RouteNames.Login.SsoRedirect.name}`;
    }
  }

  //================================
  //    PASSWORD
  //================================
  export namespace Password {
    export const name = "password";
    export const path = `${RouteNames.path}${RouteNames.Password.name}`;

    export namespace Reset {
      export const name = "reset";
      export const path = `${RouteNames.Password.path}/${RouteNames.Password.Reset.name}`;
    }

    export namespace Choose {
      export const name = "choose";
      export const path = `${RouteNames.Password.path}/${RouteNames.Password.Choose.name}`;
    }

    export namespace Renew {
      export const name = "renew";
      export const path = `${RouteNames.Password.path}/${RouteNames.Password.Renew.name}`;
    }
  }

  //================================
  //    REGISTRATION
  //================================
  export namespace Register {
    export const name = "register";
    export const path = `${RouteNames.path}${RouteNames.Register.name}`;

    //================================
    //    PROFESSIONAL REGISTRATION
    //================================
    export namespace Professional {
      export const name = "professional";
      export const path = `${RouteNames.Register.path}/${RouteNames.Register.Professional.name}`;

      export namespace Start {
        export const name = "start";
        export const path = `${RouteNames.Register.Professional.path}/${RouteNames.Register.Professional.Start.name}`;
      }

      export namespace Verify {
        export const name = "verify";
        export const path = `${RouteNames.Register.Professional.path}/${RouteNames.Register.Professional.Verify.name}`;
      }

      export namespace Success {
        export const name = "success";
        export const path = `${RouteNames.Register.Professional.path}/${RouteNames.Register.Professional.Success.name}`;
      }
    }

    //================================
    //    HUMAN REGISTRATION
    //================================
    export namespace Human {
      export const name = "human";
      export const path = `${RouteNames.Register.path}/${RouteNames.Register.Human.name}`;

      export namespace Start {
        export const name = "start";
        export const path = `${RouteNames.Register.Human.path}/${RouteNames.Register.Human.Start.name}`;
      }

      export namespace SetPassword {
        export const name = "password";
        export const path = `${RouteNames.Register.Human.path}/${RouteNames.Register.Human.SetPassword.name}`;
      }

      export namespace GrantPermissions {
        export const name = "permissions";
        export const path = `${RouteNames.Register.Human.path}/${RouteNames.Register.Human.GrantPermissions.name}`;
      }

      export namespace Success {
        export const name = "success";
        export const path = `${RouteNames.Register.Human.path}/${RouteNames.Register.Human.Success.name}`;
      }
    }
  }

  //================================
  //    HOME
  //================================
  export namespace Home {
    export const name = "home";
    export const path = `${RouteNames.path}${RouteNames.Home.name}`;

    //================================
    //    PROFILE
    //================================
    export namespace Profile {
      export const name = "profile";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Profile.name}`;
    }

    //================================
    //    CONSULTATIONS
    //================================
    export namespace Consultations {
      export const name = "consultations";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Consultations.name}`;

      export namespace ShowConsultation {
        export const name = ":uuid";
        export const path = `${RouteNames.Home.Consultations.path}/${RouteNames.Home.Consultations.ShowConsultation.name}`;

        export namespace Confirm {
          export const name = "confirm";
          export const path = `${RouteNames.Home.Consultations.ShowConsultation.path}/${RouteNames.Home.Consultations.ShowConsultation.Confirm.name}`;
        }
      }

      export namespace AddConsultation {
        export const name = "add";
        export const path = `${RouteNames.Home.Consultations.path}/${RouteNames.Home.Consultations.AddConsultation.name}`;
      }
    }

    //================================
    //    CLIENTS
    //================================
    export namespace Clients {
      export const name = "clients";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Clients.name}`;

      export namespace ShowClient {
        export const name = ":id";
        export const path = `${RouteNames.Home.Clients.path}/${RouteNames.Home.Clients.ShowClient.name}`;
      }

      export namespace AddClient {
        export const name = "add";
        export const path = `${RouteNames.Home.Clients.path}/${RouteNames.Home.Clients.AddClient.name}`;
      }
    }

    //================================
    //    COLLEAGUES
    //================================
    export namespace Colleagues {
      export const name = "colleagues";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Colleagues.name}`;

      export namespace ShowColleague {
        export const name = ":id";
        export const path = `${RouteNames.Home.Colleagues.path}/${RouteNames.Home.Colleagues.ShowColleague.name}`;
      }
    }

    //================================
    //    PROFESSIONALS
    //================================
    export namespace Professionals {
      export const name = "professionals";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Professionals.name}`;

      export namespace ShowProfessional {
        export const name = ":id";
        export const path = `${RouteNames.Home.Professionals.path}/${RouteNames.Home.Professionals.ShowProfessional.name}`;
      }
    }

    //================================
    //    AVAILABILITY
    //================================
    export namespace Availability {
      export const name = "availability";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.Availability.name}`;
    }

    //================================
    //    COMMUNITY
    //================================
    export namespace Community {
      export const path = `https://chat.bloomup.org/professionals/channels/town-square`;
    }

    //================================
    //    ORGANIZATION USAGE
    //================================
    export namespace OrganizationUsage {
      export const name = "organization-usage";
      export const path = `${RouteNames.Home.path}/${RouteNames.Home.OrganizationUsage.name}`;
    }
  }
  //================================
  //    ONBOARDING
  //================================
  export namespace Onboarding {
    export const name = "onboarding";
    export const path = `${RouteNames.path}${RouteNames.Onboarding.name}`;

    export namespace Professional {
      export const name = "professional";
      export const path = `${RouteNames.Onboarding.path}/${RouteNames.Onboarding.Professional.name}`;

      export namespace Start {
        export const name = "start";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Start.name}`;
      }

      export namespace BirthDate {
        export const name = "birthdate";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.BirthDate.name}`;
      }

      export namespace Gender {
        export const name = "gender";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Gender.name}`;
      }

      export namespace Avatar {
        export const name = "avatar";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Avatar.name}`;
      }

      export namespace Topics {
        export const name = "topics";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Topics.name}`;
      }

      export namespace Therapy {
        export const name = "therapy";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Therapy.name}`;
      }

      export namespace Experience {
        export const name = "experience";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Experience.name}`;
      }

      export namespace Languages {
        export const name = "languages";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Languages.name}`;
      }

      export namespace Profile {
        export const name = "profile";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Profile.name}`;
      }

      export namespace VisumAndCM {
        export const name = "visumandcm";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.VisumAndCM.name}`;
      }

      export namespace PracticeAddress {
        export const name = "address";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.PracticeAddress.name}`;
      }

      export namespace Rate {
        export const name = "rate";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Rate.name}`;
      }

      export namespace Billing {
        export const name = "billing";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Billing.name}`;
      }

      export namespace Consent {
        export const name = "consent";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Consent.name}`;
      }

      export namespace Success {
        export const name = "success";
        export const path = `${RouteNames.Onboarding.Professional.path}/${RouteNames.Onboarding.Professional.Success.name}`;
      }
    }
  }

  //================================
  //    CALL
  //================================
  export namespace Call {
    export const name = "meet/:uuid";
    export const path = `${RouteNames.path}${RouteNames.Call.name}`;

    // TODO: IF you encounter this after the end of february 2022, remove the LEGACY namespace.
    export namespace LEGACY {
      export const path = `/consultation/:uuid`;
    }

    export namespace Enter {
      export const name = "";
      export const path = `${RouteNames.Call.path}/${RouteNames.Call.Enter.name}`;
    }

    export namespace Exit {
      export const name = "exit";
      export const path = `${RouteNames.Call.path}/${RouteNames.Call.Exit.name}`;
    }

    export namespace Survey {
      export const name = "survey";
      export const path = `${RouteNames.Call.path}/${RouteNames.Call.Survey.name}`;
    }

    export namespace WaitingRoom {
      export const name = "waiting";
      export const path = `${RouteNames.Call.path}/${RouteNames.Call.WaitingRoom.name}`;
    }
  }

  //================================
  //    INVITE
  //================================
  export namespace Invite {
    export const name = "invite";
    export const path = `${RouteNames.path}${RouteNames.Invite.name}`;

    export namespace Human {
      export const name = "human";
      export const path = `${RouteNames.Invite.path}/${RouteNames.Invite.Human.name}`;

      export namespace Accept {
        export const name = ":uuid";
        export const path = `${RouteNames.Invite.Human.path}/${RouteNames.Invite.Human.Accept.name}`;
      }
    }
  }

  //================================
  //    ADMINISTRATION
  //================================
  export namespace Admin {
    export const name = "admin";
    export const path = `${RouteNames.path}${RouteNames.Admin.name}`;

    export namespace Dashboard {
      export const name = "dashboard";
      export const path = `${RouteNames.Admin.path}/${RouteNames.Admin.Dashboard.name}`;
    }
  }

  /* Router underneath are legacy routes. Remove these after end of Feb 2022. It should be the routes underneath: Payment */
  export namespace Payments {
    export const name = "payments";
    export const path = `${RouteNames.path}${RouteNames.Payments.name}`;

    export namespace Consultation {
      export const name = "pay";
      export const path = `${RouteNames.Payments.path}/${RouteNames.Payments.Consultation.name}`;

      export namespace Pay {
        export const name = ":uuid";
        export const path = `${RouteNames.Payments.Consultation.path}/${RouteNames.Payments.Consultation.Pay.name}`;

        export namespace Reimbursement {
          export const name = "reimbursement";
          export const path = `${RouteNames.Payments.Consultation.Pay.path}/${RouteNames.Payments.Consultation.Pay.Reimbursement.name}`;
        }

        export namespace Success {
          export const name = "success";
          export const path = `${RouteNames.Payments.Consultation.Pay.path}/${RouteNames.Payments.Consultation.Pay.Success.name}`;
        }

        export namespace Failure {
          export const name = "failure";
          export const path = `${RouteNames.Payments.Consultation.Pay.path}/${RouteNames.Payments.Consultation.Pay.Failure.name}`;
        }

        export namespace Pending {
          export const name = "pending";
          export const path = `${RouteNames.Payments.Consultation.Pay.path}/${RouteNames.Payments.Consultation.Pay.Pending.name}`;
        }
      }
    }
  }

  //================================
  //    PAYMENT
  //================================
  export namespace Payment {
    export const name = "payment";
    export const path = `${RouteNames.path}${RouteNames.Payment.name}`;

    export namespace Consultation {
      export const name = "pay";
      export const path = `${RouteNames.Payment.path}/${RouteNames.Payment.Consultation.name}`;

      export namespace Pay {
        export const name = ":uuid";
        export const path = `${RouteNames.Payment.Consultation.path}/${RouteNames.Payment.Consultation.Pay.name}`;

        export namespace Reimbursement {
          export const name = "reimbursement";
          export const path = `${RouteNames.Payment.Consultation.Pay.path}/${RouteNames.Payment.Consultation.Pay.Reimbursement.name}`;
        }

        export namespace Success {
          export const name = "success";
          export const path = `${RouteNames.Payment.Consultation.Pay.path}/${RouteNames.Payment.Consultation.Pay.Success.name}`;
        }

        export namespace Failure {
          export const name = "failure";
          export const path = `${RouteNames.Payment.Consultation.Pay.path}/${RouteNames.Payment.Consultation.Pay.Failure.name}`;
        }

        export namespace Pending {
          export const name = "pending";
          export const path = `${RouteNames.Payment.Consultation.Pay.path}/${RouteNames.Payment.Consultation.Pay.Pending.name}`;
        }
      }
    }
  }

  //================================
  //    B2B LANDING PAGES
  //================================
  export namespace B2BLandingPages {
    export const name = "partner";
    export const path = `${RouteNames.path}${RouteNames.B2BLandingPages.name}`;

    export namespace Organization {
      export const name = ":slug";
      export const path = `${RouteNames.B2BLandingPages.path}/${RouteNames.B2BLandingPages.Organization.name}`;
    }

    export namespace MantelzorgLanding {
      export const name = "landing/mantelzorg";
      export const path = `${RouteNames.B2BLandingPages.path}/${RouteNames.B2BLandingPages.MantelzorgLanding.name}`;
    }

    export namespace MantelzorgGentLanding {
      export const name = "landing/mantelzorggent";
      export const path = `${RouteNames.B2BLandingPages.path}/${RouteNames.B2BLandingPages.MantelzorgGentLanding.name}`;
    }

    export namespace Unknown {
      export const name = "unknown";
      export const path = `${RouteNames.B2BLandingPages.path}/${RouteNames.B2BLandingPages.Unknown.name}`;
    }
  }

  //================================
  //    NEW B2B LANDING PAGES
  //================================

  export namespace NewB2BLandingPages {
    export const name = "organization";
    export const path = `${RouteNames.path}${RouteNames.NewB2BLandingPages.name}`;

    export namespace Organization {
      export const name = "b2b";
      export const path = `${RouteNames.path}${RouteNames.NewB2BLandingPages.Organization.name}`;
    }

    export namespace selfCare {
      export const name = "selfcare";
      export const path = `${RouteNames.path}${RouteNames.NewB2BLandingPages.selfCare.name}`;
    }
  }

  //================================
  //    QUALITY QUESTIONS
  //================================
  export namespace QualityQuestions {
    export const name = "quality";
    export const path = `${RouteNames.path}${RouteNames.QualityQuestions.name}`;

    export namespace Question {
      export const name = "question";
      export const path = `${RouteNames.QualityQuestions.path}/${RouteNames.QualityQuestions.Question.name}`;
      export namespace EntryPointOrId {
        export const name = ":entrypointOrId";
        export const path = `${RouteNames.QualityQuestions.Question.path}/${name}`;
      }
      export namespace ThankYou {
        export const name = "thankyou";
        export const path = `${RouteNames.QualityQuestions.Question.path}/${name}`;
      }
    }
  }
}
