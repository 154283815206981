import { Route, Routes } from "react-router";
import ProfessionalOnboardingContextProvider from "../pages/professional-onboard/context/ProfessionalOnboardingContextProvider";
import AddressStep from "../pages/professional-onboard/steps/AddressStep";
import AvatarStep from "../pages/professional-onboard/steps/AvatarStep";
import BillingStep from "../pages/professional-onboard/steps/BillingStep";
import BirthDateStep from "../pages/professional-onboard/steps/BirthDateStep";
import ConsentStep from "../pages/professional-onboard/steps/ConsentStep";
import ConsultationLanguagesStep from "../pages/professional-onboard/steps/ConsultationLanguagesStep";
import ExperienceStep from "../pages/professional-onboard/steps/ExperienceStep";
import PsyGenderStep from "../pages/professional-onboard/steps/GenderStep";
import ProfileStep from "../pages/professional-onboard/steps/ProfileStep";
import SuccessStep from "../pages/professional-onboard/steps/SuccessStep";
import TargetRateStep from "../pages/professional-onboard/steps/TargetRateStep";
import TherapyStep from "../pages/professional-onboard/steps/TherapyStep";
import TopicsStep from "../pages/professional-onboard/steps/TopicsStep";
import VisumAndCMNumberStep from "../pages/professional-onboard/steps/VisumAndCMNumberStep";
import WelcomeStep from "../pages/professional-onboard/steps/WelcomeStep";
import AuthRoute from "./AuthRoute";
import ProfessionalGuard from "./guards/ProfessionalGuard";
import { RouteNames } from "./routeNames";

export default function ProfessionalOnboardingRoutes() {
  return (
    <AuthRoute>
      <ProfessionalGuard>
        <ProfessionalOnboardingContextProvider>
          <Routes>
            <Route
              element={<WelcomeStep />}
              path={RouteNames.Onboarding.Professional.Start.name}
            />
            <Route
              element={<BirthDateStep />}
              path={RouteNames.Onboarding.Professional.BirthDate.name}
            />
            <Route
              element={<PsyGenderStep />}
              path={RouteNames.Onboarding.Professional.Gender.name}
            />
            <Route
              element={<AvatarStep />}
              path={RouteNames.Onboarding.Professional.Avatar.name}
            />
            <Route
              element={<TopicsStep />}
              path={RouteNames.Onboarding.Professional.Topics.name}
            />
            <Route
              element={<TherapyStep />}
              path={RouteNames.Onboarding.Professional.Therapy.name}
            />
            <Route
              element={<ConsultationLanguagesStep />}
              path={RouteNames.Onboarding.Professional.Languages.name}
            />
            <Route
              element={<ProfileStep />}
              path={RouteNames.Onboarding.Professional.Profile.name}
            />
            <Route
              element={<ExperienceStep />}
              path={RouteNames.Onboarding.Professional.Experience.name}
            />
            <Route
              element={<VisumAndCMNumberStep />}
              path={RouteNames.Onboarding.Professional.VisumAndCM.name}
            />
            <Route
              element={<AddressStep />}
              path={RouteNames.Onboarding.Professional.PracticeAddress.name}
            />
            <Route
              element={<TargetRateStep />}
              path={RouteNames.Onboarding.Professional.Rate.name}
            />
            <Route
              element={<BillingStep />}
              path={RouteNames.Onboarding.Professional.Billing.name}
            />
            <Route
              element={<ConsentStep />}
              path={RouteNames.Onboarding.Professional.Consent.name}
            />
            <Route
              element={<SuccessStep />}
              path={RouteNames.Onboarding.Professional.Success.name}
            />
            {/* catch all for professional onboarding, put other routes before this one*/}
            <Route element={<WelcomeStep />} path={""} />
          </Routes>
        </ProfessionalOnboardingContextProvider>
      </ProfessionalGuard>
    </AuthRoute>
  );
}
