import { useCallback, useState } from "react";
import Toggle from "../../ui/form/Toggle";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerWithOptionsProps } from "./answers";

export default function AnswerToggle({
  onAnswerChanged,
  options,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.Toggle>) {
  const [selected, setSelected] = useState<number>(0);

  const onChange = useCallback(
    (value) => {
      setSelected(value);
      onAnswerChanged([value]);
    },
    [onAnswerChanged],
  );

  return <Toggle onChange={onChange} options={options} value={selected} />;
}
