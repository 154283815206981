import type { TFunction } from "i18next";
import _ from "lodash";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { I18NexusLanguages } from "../../../components/language/languagesUtil";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import SelectionDataType from "../../SelectionDataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class ConsultationLanguages extends SelectionDataType<
  Array<I18NexusLanguages>,
  string,
  DataTypePaths.Professional.ConsultationLanguages
> {
  protected static path: DataTypePaths.Professional.ConsultationLanguages =
    "consultationLanguages";

  protected static defaultValue = [];
  protected static translationKey = "ui:languages";

  protected fromGraphQL = (value: string): Array<string> => JSON.parse(value);
  protected forGraphQL = (value: Array<string>): string =>
    JSON.stringify(value);

  protected type: any = ConsultationLanguages;

  constructor(value?: any, fromGQL = false) {
    super(value);
    this.setValue(value || ConsultationLanguages.defaultValue, fromGQL);
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "professional:consultationlanguages.mandatory",
          "Geef minstens één taal op.",
        ),
        path: this.getPath(),
        validate: (ConsulationLanguages: Array<string>): boolean =>
          ConsulationLanguages.length > 0,
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionally, an icon.
   *
   * @param  {Function} translate The i18next translate hook with the glossary namespace.
   */
  public getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<string>> {
    const options: Array<ISelectObject<string>> = Object.values(
      I18NexusLanguages,
    ).map((value) => {
      return {
        label: translate(`${ConsultationLanguages.translationKey}.${value}`),
        value,
      };
    });

    return _.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  public getOptions(): any {
    return I18NexusLanguages;
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This function only returns the values set to this object.
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   * @return {Array<Object>}
   */
  public getSelectedOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<string>> {
    const options: Array<ISelectObject<string>> = [];

    if (this.getValue() === null) return [];

    for (const value of this.getValue()) {
      options.push({
        label: translate(`${ConsultationLanguages.translationKey}.${value}`),
        value,
      });
    }

    return _.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Return an array of translated values.
   * This function only returns the values set to this object.
   *
   * @param  {Function} translate The i18next translate hook with the glossary namespace.
   * @return {Array<String>}
   */
  public getTranslatedValues(
    translate: TFunction<I18Namespaces>,
  ): Array<string> {
    const options: Array<string> = [];

    if (this.getValue() === null) return [];

    for (const value of this.getValue()) {
      options.push(
        translate(`${ConsultationLanguages.translationKey}.${value}`),
      );
    }

    return _.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Get the humanreadible string for this data type's value.
   * Ex: Burn-out, Angsstoornis
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   */
  public prettyPrint(translate: TFunction<I18Namespaces>): string {
    return this.getTranslatedValues(translate).filter(Boolean).join(", ");
  }

  getAsFormStateValue(
    translate: TFunction<I18Namespaces>,
  ): Partial<
    Record<
      DataTypePaths.Professional.ConsultationLanguages,
      string | ISelectObject<string> | ISelectObject<string>[]
    >
  > {
    const formStateValues = this.value.map((v) => {
      return {
        label: translate(`${ConsultationLanguages.translationKey}.${v}`),
        value: v,
      };
    });

    return { consultationLanguages: formStateValues };
  }
}
