/* eslint-disable react-hooks/exhaustive-deps */

import {
  DatesSetArg,
  EventChangeArg,
  EventContentArg,
} from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getMomentLocale } from "../../language/languagesUtil";
import LoadingPage from "../../layout/LoadingPage";
import TitleWithButton from "../../ui/text/TitleWithButton";
import CalendarEventRenderer from "./CalendarEventRenderer";
import FooterToolBarFullCalendar from "./FooterToolBarFullCalendar";
import HeaderToolBarFullCalendar from "./HeaderToolBarFullCalendar";
import { IProfessionalAvailabilityContext } from "./context/ProfessionalAvailability";
import ProfessionalAvailabilityContext from "./context/ProfessionalAvailabilityContext";

const ProfessionalAvailabilityPage = () => {
  const {
    calendarRef,
    createCalendarEvent,
    dateRangeOfCalendar,
    deleteCalendarEvent,
    events,
    setDateRangeOfCalendar,
    updateCalendarEvent,
  } = useContext<IProfessionalAvailabilityContext>(
    ProfessionalAvailabilityContext,
  );

  const xs = useMediaQuery("only screen and (max-width: 200px)");
  const sm = useMediaQuery("only screen and (max-width: 500px)");
  const md = useMediaQuery("only screen and (max-width: 700px)");
  const lg = useMediaQuery("only screen and (max-width: 1000px)");

  const { i18n, ready, t } = useTranslation();

  const daysToDisplay = useMemo(() => {
    let days = 7;

    //TODO: Deze logic kan in de media query gestopt worden.
    if (!xs && !sm && !md && !lg) {
      days = 7;
    } else if (!xs && !sm && !md && lg) {
      days = 5;
    } else if (!xs && !sm && md && lg) {
      days = 3;
    } else if (!xs && sm && md && lg) {
      days = 2;
    }

    return days;
  }, [xs, sm, md, lg]);

  const onEventChange = useCallback(
    (changeInfo: EventChangeArg) => {
      if (!updateCalendarEvent) return;

      if (
        changeInfo &&
        changeInfo.event &&
        changeInfo.event.start &&
        changeInfo.event.end
      ) {
        updateCalendarEvent({
          allDay: changeInfo.event.allDay,
          id: Number(changeInfo.event.id),
          scheduledFrom: changeInfo.event.start,
          scheduledTo: changeInfo.event.end,
        });
      }
    },
    [updateCalendarEvent],
  );

  const onDatesSetChange = useCallback(
    (info: DatesSetArg) =>
      setDateRangeOfCalendar({
        end: info.end,
        start: info.start,
      }),

    [],
  );

  const onDateSelect = useCallback(
    (selectionInfo) => {
      if (!createCalendarEvent) return;

      createCalendarEvent({
        allDay: selectionInfo.allDay,
        scheduledFrom: selectionInfo.start,
        scheduledTo: selectionInfo.end,
        type: "availability",
      });
    },
    [createCalendarEvent],
  );

  if (!ready) return <LoadingPage full />;

  return (
    <Container
      maxWidth="md"
      sx={{
        ".availability-event": {
          background: "none",
          border: "none",
        },
        ".consultation-event": {
          background: "none",
          border: "none",
        },
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginLeft: "10px",
        marginRight: 0,
        minHeight: "100vh",
        paddingBottom: "15px",

        paddingTop: "40px",

        position: "relative",
      }}
    >
      <TitleWithButton title={t("common:availability.label")} />
      <Typography
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.sizes.normal,
          marginBottom: "15px",
          marginTop: "-15px",
        })}
      >
        {t("professional:availability.info")}
      </Typography>
      <HeaderToolBarFullCalendar
        handleAgendaButton={() => {
          console.log("Agenda Button");
        }}
        handleDisplayOptionButton={() => {
          console.log("Display Option Button");
        }}
        handleNextButton={() => {
          calendarRef?.current?.getApi().next();
        }}
        handlePreviousButton={() => {
          calendarRef?.current?.getApi().prev();
        }}
        headerTitle={
          moment(dateRangeOfCalendar.start)
            .locale(getMomentLocale(i18n.language) ?? i18n.language)
            .format("D MMM") +
          " - " +
          moment(dateRangeOfCalendar.end)
            // Needed because the FullCalendar plugin used in this component
            // returns an end date that is exclusive to the range. Subtracting 1
            // second will make the date go back by 1 https://fullcalendar.io/docs/datesSet
            .subtract(1, "second")
            .locale(getMomentLocale(i18n.language) ?? i18n.language)
            .format("D MMM YYYY")
        }
      />
      <Box
        sx={(theme) => ({
          ".view": {
            "& td, th, .fc-scrollgrid": {
              borderColor: "#c7e1e5",
            },
          },

          ".view .fc-scroller": {
            scrollbarColor: `${theme.palette.bloom.main} #dcecee`,
          },

          ".view .fc-scroller::-webkit-scrollbar": {
            width: "15px",
          },

          ".view .fc-scroller::-webkit-scrollbar-thumb": {
            background: theme.palette.bloom.main,
            borderRadius: "10px",
          },

          ".view .fc-scroller::-webkit-scrollbar-track": {
            backgroundColor: "#dcecee",
          },

          flexGrow: 9,
        })}
      >
        <FullCalendar
          allDaySlot={false}
          datesSet={onDatesSetChange}
          duration={{ days: daysToDisplay }}
          editable={true}
          eventChange={onEventChange}
          eventContent={(eventInfo: EventContentArg) => (
            <CalendarEventRenderer
              deleteCalendarEvent={deleteCalendarEvent}
              eventInfo={eventInfo}
            />
          )}
          eventStartEditable={true}
          events={events}
          firstDay={1}
          headerToolbar={false}
          initialDate={moment().startOf("week").toDate()}
          initialView={"timeGrid"}
          nowIndicator={true}
          plugins={[timeGridPlugin, interactionPlugin]}
          ref={calendarRef}
          select={onDateSelect}
          selectable={true}
          viewClassNames={"view"}
        />
      </Box>
      <FooterToolBarFullCalendar />
    </Container>
  );
};

export default ProfessionalAvailabilityPage;
