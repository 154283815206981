/* eslint-disable react-hooks/exhaustive-deps */

import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
} from "@apollo/client";
import { useMemo } from "react";
import createApolloClient from "../../api/createApolloClient";
import { IWithChildren } from "../../baseInterfaces";

export default function ApolloClientProvider({ children }: IWithChildren) {
  const getApolloClient: ApolloClient<NormalizedCacheObject> = useMemo(
    () => createApolloClient(),
    [createApolloClient],
  );

  return <ApolloProvider client={getApolloClient}>{children}</ApolloProvider>;
}
