import { useQuery } from "@apollo/client";
import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";
import { useSearchParams } from "react-router";
import { getFragmentData, graphql } from "../../../api/__generated__";
import { ColleagueNodeFragment } from "../../../api/__generated__/graphql";
import LoadingPage from "../../layout/LoadingPage";
import ColleagueDetail from "./ColleagueDetail";
import ColleaguesList from "./ColleaguesList";

const colleagueNodeFragment = graphql(`
  fragment ColleagueNode on Professional {
    ...Colleague
    firstName
    id
    lastName
  }
`);

const queryColleagues = graphql(`
  query colleagues($input: ProfessionalsInput!) {
    professionals(input: $input) {
      edges {
        cursor
        node {
          ...ColleagueNode
        }
      }
      totalCount
    }
  }
`);

const ColleaguesPage = () => {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { data, loading } = useQuery(queryColleagues, {
    variables: {
      input: {
        adminsIncluded: false,
        verifiedOnly: true,
      },
    },
  });

  const sortedColleagues = useMemo(() => {
    return data?.professionals?.edges
      ?.reduce((acc, edge) => {
        if (edge?.node) {
          acc.push(getFragmentData(colleagueNodeFragment, edge.node));
        }

        return acc;
      }, [] as ColleagueNodeFragment[])
      .sort((a, b) => {
        return `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`,
        );
      });
  }, [data?.professionals?.edges]);

  const [searchParams, setSearchParams] = useSearchParams();

  const idParam = searchParams.get("id");
  const selectedColleagueId =
    idParam !== undefined && idParam !== null
      ? parseInt(idParam, 10)
      : undefined;

  const handleClick = (colleagueId: number) =>
    setSearchParams((previous) => ({ ...previous, id: colleagueId }));

  const closeDetailScreen = () =>
    setSearchParams((previous) => {
      previous.delete("id");

      return previous;
    });

  if (loading || !sortedColleagues) return <LoadingPage />;

  if (small) {
    return selectedColleagueId !== undefined ? (
      <ColleagueDetail
        closeDetailScreen={closeDetailScreen}
        professionalId={selectedColleagueId}
      />
    ) : (
      <ColleaguesList
        colleagues={sortedColleagues}
        handleColleagueClick={handleClick}
      />
    );
  }

  return (
    <>
      <ColleaguesList
        colleagues={sortedColleagues}
        handleColleagueClick={handleClick}
        selectedColleagueId={selectedColleagueId}
      />
      {selectedColleagueId !== undefined && (
        <ColleagueDetail
          closeDetailScreen={closeDetailScreen}
          professionalId={selectedColleagueId}
        />
      )}
    </>
  );
};

export default ColleaguesPage;
