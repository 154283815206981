import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useQueryParams, { getParamAsString } from "../hooks/useQueryParams";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import { RouteNames } from "../routes/routeNames";

export default function ThankYouPage() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
  ]);

  const navigate = useNavigate();

  const { redirectPath } = useQueryParams();

  return (
    <OnboardingContainer includeNavigation={false}>
      <OnboardingStepContainer
        description={translate(
          "quality_questions:thankyou.description",
          "Door je bijdrage kunnen we BloomUp steeds blijven verbeteren.",
        )}
        fullContent={true}
        fullCopyright={false}
        headline={translate("quality_questions:thankyou.headline", "Super 🎉!")}
        labelNext={translate("common:to.dashboard", "Naar dashboard")}
        onClickNext={() => {
          const redirectPathAsString = getParamAsString(redirectPath);

          navigate(
            redirectPathAsString ? redirectPathAsString : RouteNames.Home.path,
          );
        }}
        showLanguageSelector={false}
        showSpecialConditionsForTherapist={false}
        subhead={translate(
          "quality_questions:thankyou.subhead",
          "Bedankt voor je input!",
        )}
      />
    </OnboardingContainer>
  );
}
