import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import StyledTooltip from "../../ui/Tooltip";
import { IProfessionalAvailabilityContext } from "./context/ProfessionalAvailability";
import ProfessionalAvailabilityContext from "./context/ProfessionalAvailabilityContext";

const PREFIX = "FooterToolBarFullCalendar";

const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.white.main,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
  },

  [`& .${classes.button}`]: {
    "&:hover": {
      color: theme.palette.bloom.dark,
    },
    alignItems: "center",
    display: "flex",
    marginRight: 20,
  },

  [`& .${classes.icon}`]: {
    marginRight: 10,
  },
}));

const FooterToolBarFullCalendar = () => {
  const { t } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Professional,
  );

  const { copyAvailabilityToThisWeek, removeAvailabilityOfVisiblePeriod } =
    useContext<IProfessionalAvailabilityContext>(
      ProfessionalAvailabilityContext,
    );

  return (
    <StyledBox className={classes.root} width={"100%"}>
      <StyledTooltip
        placement="top-start"
        text={t("professional:availability.remove.all.of.this.week.tooltip")}
      >
        <Box
          className={classes.button}
          onClick={removeAvailabilityOfVisiblePeriod}
        >
          <RemoveCircleOutlineIcon className={classes.icon} />
          <Typography>
            {t("professional:availability.remove.all.of.this.week")}
          </Typography>
        </Box>
      </StyledTooltip>

      <StyledTooltip
        placement="top-start"
        text={t("professional:availability.copy.to.this.week.tooltip")}
      >
        <Box className={classes.button} onClick={copyAvailabilityToThisWeek}>
          <FileCopyOutlinedIcon className={classes.icon} />
          <Typography>
            {t("professional:availability.copy.to.this.week")}
          </Typography>
        </Box>
      </StyledTooltip>
    </StyledBox>
  );
};

export default FooterToolBarFullCalendar;
