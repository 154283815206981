import { useMutation } from "@apollo/client";
import { graphql } from "../../__generated__";
import { ProfessionalRegistrationInput } from "../../__generated__/graphql";

const mutation = graphql(`
  mutation RegisterProfessional($input: ProfessionalRegistrationInput!) {
    registerProfessional(input: $input)
  }
`);

export default function useRegisterProfessional() {
  const [registerProfessionalFunction, { data, error, loading }] =
    useMutation(mutation);

  const registerProfessional = (input: ProfessionalRegistrationInput) =>
    registerProfessionalFunction({ variables: { input } });

  return {
    data,
    error,
    loading,
    registerProfessional,
  };
}
