import { graphql } from "../__generated__";

export const QUERY_FETCH_FULL_PROFESSIONAL_PROFILE = graphql(`
  query fullProfessionalProfile {
    professional {
      ...Professional
      ...ProfessionalExtra
    }
  }
`);

export const QUERY_FETCH_PROFESSIONAL_CURRENT = graphql(`
  query professionalCurrent {
    professional {
      ...Professional
    }
  }
`);

export const QUERY_FETCH_PROFESSIONALS_FOR_HUMAN = graphql(`
  query professionalsForHuman($input: ProfessionalsForCurrentHumanInput) {
    professionalsForCurrentHuman(input: $input) {
      edges {
        node {
          ...ProfessionalInfoForHuman
        }
      }
    }
  }
`);

export const QUERY_PROFESSIONAL_EXISTS = graphql(`
  query professionalExists($email: String!) {
    professionalExists(email: $email)
  }
`);
