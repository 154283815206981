import CallIcon from "@mui/icons-material/Call";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import Consultation from "../../../data-model/types/consultation/Consultation";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import Button from "../../ui/buttons/Button";
import CashTransactionActions from "./CashTransactionActions";

const PREFIX = "consultationActionsMemo";

const classes = {
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.button}`]: {
    display: "inline-block",
    marginBottom: 15,
    marginRight: 15,
  },
}));

interface IConsultationActions {
  cancelConsultation: () => void;
  cancelling: boolean;
  confirmConsultation: () => void;
  confirming: boolean;
  consultation: Consultation;
  isEditing: boolean;
  isGoingToCancel: boolean;
  redirectToPayment: () => void;
  setIsEditing: (val: boolean) => void;
  setIsGoingToCancel: (val: boolean) => void;
  startCall: () => void;
  updateConsultation: () => void;
}

/**
 * Returns actions that can be taken for each consult.
 *
 * @param consultation {Consultation} The consultation for which the actions count.
 * @param toggleEditConsultation {}
 */
function ConsultationActions({
  cancelConsultation,
  cancelling,
  confirmConsultation,
  confirming,
  consultation,
  isEditing,
  isGoingToCancel,
  redirectToPayment,
  setIsEditing,
  setIsGoingToCancel,
  startCall,
  updateConsultation,
}: IConsultationActions) {
  const smallScreen = useMediaQuery("(max-width: 500px)");

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Payments,
  ]);

  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const consultationStatus = consultation.getStatus();
  const cashTransaction = consultation.getCashTransaction();
  const showStartConsultation =
    !isGoingToCancel &&
    !isEditing &&
    !consultation.isOver() &&
    !consultationStatus.isCancelled();

  const showConfirmConsultation =
    !isEditing &&
    !isGoingToCancel &&
    !consultation.isOver() &&
    ((consultationStatus.getValue() === "REQUESTED" &&
      (consultation.acceptedByHuman === true ||
        consultation.acceptedByHuman === undefined) &&
      (consultation.acceptedByProfessional === false ||
        consultation.acceptedByProfessional === undefined) &&
      currentUser instanceof Professional) ||
      (consultationStatus.getValue() === "REQUESTED" &&
        (consultation.acceptedByHuman === false ||
          consultation.acceptedByHuman === undefined) &&
        (consultation.acceptedByProfessional === true ||
          consultation.acceptedByProfessional === undefined) &&
        currentUser instanceof Human));

  const showConfirmCancellation = isGoingToCancel;
  const showCancelCancellation = isGoingToCancel;

  const showConfirmRescheduling = isEditing && !isGoingToCancel;
  const showCancelRescheduling = isEditing && !isGoingToCancel;

  const showReschedule =
    !isEditing &&
    !isGoingToCancel &&
    !consultation.isOver() &&
    !consultationStatus.isDone() &&
    !consultationStatus.isCancelled();

  const showCancel =
    !isEditing &&
    !isGoingToCancel &&
    !consultation.isOver() &&
    !consultationStatus.isDone() &&
    !consultationStatus.isCancelled();

  const showPaymentButton =
    !isEditing &&
    !isGoingToCancel &&
    currentUser &&
    !consultationStatus.isCancelled() &&
    (currentUser.isHuman() || currentUser.isOrganizationAdmin());

  return (
    <StyledBox mt={3}>
      {cashTransaction && showPaymentButton && redirectToPayment ? (
        <CashTransactionActions
          cashTransaction={cashTransaction}
          consultation={consultation}
          redirectToPayment={redirectToPayment}
        />
      ) : null}

      {showStartConsultation ? (
        <Box className={classes.button}>
          <Button
            icon={<CallIcon />}
            label={translate("common:startconversation", "Start consultatie")}
            onClick={startCall}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}

      {showConfirmConsultation ? (
        <Box className={classes.button}>
          <Button
            bloomColor="green"
            icon={<CheckCircleIcon />}
            label={translate("common:confirm", "Bevestig")}
            loading={confirming}
            onClick={confirmConsultation}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showConfirmCancellation ? (
        <Box className={classes.button}>
          <Button
            bloomColor="green"
            icon={<CheckCircleIcon />}
            label={translate(
              "common:confirm_cancellation",
              "Bevestig annulatie",
            )}
            loading={cancelling}
            onClick={cancelConsultation}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showCancelCancellation ? (
        <Box className={classes.button}>
          <Button
            bloomColor="red"
            icon={<CancelIcon />}
            label={translate("common:decline_cancellation", "Niet annuleren")}
            onClick={() => setIsGoingToCancel(false)}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showConfirmRescheduling ? (
        <Box className={classes.button}>
          <Button
            bloomColor="green"
            icon={<CheckIcon />}
            label={translate("common:confirm", "Bevestig")}
            onClick={updateConsultation}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showCancelRescheduling ? (
        <Box className={classes.button}>
          <Button
            bloomColor="red"
            icon={<CancelIcon />}
            label={translate(
              "common:cancel_adjustment",
              "Annuleer aanpassingen",
            )}
            onClick={() => setIsEditing(false)}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showReschedule ? (
        <Box className={classes.button}>
          <Button
            bloomColor="orange"
            icon={<SyncIcon />}
            label={translate("common:consultation.edit", "Verplaats")}
            onClick={() => setIsEditing(true)}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
      {showCancel ? (
        <Box className={classes.button}>
          <Button
            bloomColor="red"
            icon={<CancelIcon />}
            label={translate("common:cancel_consultation", "Annuleer afspraak")}
            onClick={() => setIsGoingToCancel(true)}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      ) : null}
    </StyledBox>
  );
}

//Otherwise IsolatedModules gets angry.²
const consultationActionsMemo = memo(ConsultationActions);

export default consultationActionsMemo;
