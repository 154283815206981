import { ApolloError, useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import Consultation from "../../../data-model/types/consultation/Consultation";
import { APIConsultation } from "../consultations";
import { QUERY_FETCH_CONSULTATION_WITH_CASH_TRANSACTION } from "../queries";

interface IFetchConsultationByUuidData {
  consultation: Consultation | undefined;
  error: ApolloError | undefined;
  fetchConsultation: (uuid: string) => void;
  loading: boolean;
}

export default function useLazyFetchConsultationByUuid(): IFetchConsultationByUuidData {
  const [executeQuery, { data, error, loading }] = useLazyQuery<
    APIConsultation.Fetch.ResultByUuid,
    APIConsultation.Fetch.InputByUuid
  >(QUERY_FETCH_CONSULTATION_WITH_CASH_TRANSACTION);

  const fetchConsultationByUuid = (uuid: string) => {
    executeQuery({ variables: { uuid } });
  };

  const consultationToReturn = useMemo(() => {
    if (!loading && data) {
      const { consultation } = data;

      return new Consultation(consultation);
    }
  }, [loading, data]);

  return {
    consultation: consultationToReturn,
    error,
    fetchConsultation: fetchConsultationByUuid,
    loading,
  };
}
