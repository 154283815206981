import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../../data-model/types/User";
import Human from "../../../data-model/types/human/Human";
import { I18Namespaces } from "../../language/I18Namespaces";
import UnavailableSection from "../../layout/UnavailableSection";
import ExpansionToggle from "../../ui/ExpansionToggle";
import Client from "./Client";

export interface IClientSubList {
  clients: Array<Human>;
  emptyText: string;
  isOpen: boolean;
  listName: string;
  onSelectClient?: (id: number) => void;
  pt?: number;
  roleType: UserRoles;
  selectedClient?: Human;
}

const ClientSubList = ({
  clients = [],
  emptyText,
  isOpen = true,
  listName,
  onSelectClient,
  pt = 0,
  roleType,
  selectedClient,
}: IClientSubList) => {
  const { t: translate } = useTranslation<I18Namespaces>("common");
  const [isExpanded, setIsExpanded] = useState(isOpen);

  const empty =
    emptyText ||
    translate("common:consultations.none.planned", "Je hebt geen cliënten");

  return (
    <Box pb={2} pt={pt}>
      <ExpansionToggle
        isOpen={isExpanded}
        label={`${listName} (${clients.length})`}
        mb={2}
        onClick={() => setIsExpanded(!isExpanded)}
        primary={false}
      />
      {isExpanded ? (
        clients.length > 0 ? (
          <List>
            {clients.map((client: Human) => (
              <Client
                client={client}
                handleClick={onSelectClient}
                isOpen={
                  selectedClient
                    ? client.getID() === selectedClient.getID()
                    : false
                }
                key={client.getID()}
                roleType={roleType}
                status={
                  client instanceof Human ? client.getStatus() : undefined
                }
              />
            ))}
          </List>
        ) : (
          <UnavailableSection text={empty} />
        )
      ) : null}
    </Box>
  );
};

export default ClientSubList;
