import type { TFunction } from "i18next";
import moment from "moment";
import { LicenseFile, APIUser } from "../../../api/api";
import { APIProfessional } from "../../../api/professionals/professionals";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import {
  databaseLanguageToi18NexusLanguageEquivalentsMap,
  I18NexusLanguages,
  parseDatabaseLanguagesFromAPI,
} from "../../../components/language/languagesUtil";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import User, { IUserFromStateValues, UserRoles } from "../User";
import BillingInformation from "../billing/BillingInformation";
import ConsultationDuration, {
  ConsultationDurationOptions,
} from "../consultation/ConsultationDuration";
import ConsultationRate from "../consultation/ConsultationRate";
import TimeSlot from "../consultation/TimeSlot";
import Human from "../human/Human";
import Bio from "./Bio";
import CMNumber from "./CMNumber";
import ConsultationLanguages from "./ConsultationLanguages";
import Description from "./Description";
import Experience from "./Experience";
import Practice from "./Practice";
import Therapies, { TherapyOptions } from "./Therapies";
import VisumNumber from "./VisumNumber";
import Website from "./Website";

interface IProfessionalFormStateValues extends IUserFromStateValues {
  availability: Array<TimeSlot>;
  cmNumber: string | undefined;
  consultationLanguages: Array<ISelectObject<I18NexusLanguages>>;
  experienceSince: Date;
  practice;
  profile: Description;
  standardDuration: ConsultationDurationOptions;
  standardRate: number;
  therapies: Array<ISelectObject<TherapyOptions>>;
  visumNumber: string | undefined;
}

export default class Professional extends User<DataTypePaths.ProfessionalPath> {
  protected type: any = Professional;

  protected bio: Bio = new Bio();
  protected experience = new Experience();
  protected consultationLanguages = new ConsultationLanguages();
  protected therapies = new Therapies();
  protected rate = new ConsultationRate();
  protected duration = new ConsultationDuration();
  protected supervision = false;
  protected firstline = false;
  protected verified: moment.Moment | null = null;
  protected professionalType: string | undefined;
  protected training: string | undefined;
  protected therapyTypes: string | undefined;
  protected website = new Website();
  protected clients: Array<Human> = [];
  protected licenseFiles: Array<LicenseFile> = [];
  protected practice = new Practice();
  protected billing = new BillingInformation();
  protected visum = new VisumNumber();
  protected cmNumber = new CMNumber();
  protected availability: Array<TimeSlot> = [];
  protected clientStop = false;
  protected forBgt = false;

  constructor(
    model?: APIProfessional.Result,
    fromGQL = false,
    isAdmin = false,
  ) {
    super(model, fromGQL);

    if (!model) return;

    this.setRole(isAdmin ? UserRoles.ADMIN : UserRoles.PROFESSIONAL);
    this.bio.parseFromJsonInput(model[Bio.getPath()]);
    this.experience.setValue(model[Experience.getPath()], fromGQL);

    //consultation languages are stored as json in the DB, and does not need to be parsed from JSON
    let consultationLanguages = model[ConsultationLanguages.getPath()];

    if (!Array.isArray(consultationLanguages)) consultationLanguages = [];

    this.consultationLanguages.setValue(
      parseDatabaseLanguagesFromAPI(consultationLanguages),
    );

    this.therapies.setValue(model[Therapies.getPath()], fromGQL);
    this.rate.setValue(model[ConsultationRate.getPath()], fromGQL);
    this.duration.setValue(model[ConsultationDuration.getPath()], fromGQL);
    this.supervision = model.supervision ?? false;
    this.firstline = model.isFirstLine ?? false;
    this.verified = model.verifiedAt ? moment(model.verifiedAt) : null;
    this.professionalType = model.type;
    this.training = model.training;
    this.therapyTypes = model.therapyTypes;
    this.website.setValue(model.website);
    this.clients = model.clients ?? [];
    this.licenseFiles = model.licenseFiles ?? [];
    this.cmNumber.setValue(model.cmNumber);
    this.visum.setValue(model.visumNumber);

    if (model.user) {
      this.preferredLanguage.setValue(
        databaseLanguageToi18NexusLanguageEquivalentsMap.get(
          model.user.preferredLanguage,
        ) || I18NexusLanguages.FLEMISH,
      );
    }

    if (model.practice) {
      this.addPractice(new Practice(model.practice));
    }

    if (model.billingInformation) {
      this.addBillingInformation(
        new BillingInformation(model.billingInformation),
      );
    }

    this.availability = model.availability
      ? model.availability.map(
          (slot: string) =>
            new TimeSlot({
              durationInMinutes: this.duration.getValue(),
              startTime: slot,
            }),
        )
      : [];
    this.clientStop = model.clientStop ?? false;
    this.forBgt = model.forBgt ?? false;
  }

  /**
   * Return an object containing the values to be used
   * as the values in the formState.
   */
  public getAsFormStateValues(
    translate: TFunction<I18Namespaces>,
  ): IProfessionalFormStateValues {
    return {
      ...super.getAsFormStateValues(translate),
      ...this.bio.getAsFormStateValue(),
      ...this.experience.getAsFormStateValue(),
      ...this.therapies.getAsFormStateValue(translate),
      ...this.rate.getAsFormStateValue(),
      ...this.duration.getAsFormStateValue(),
      ...this.consultationLanguages.getAsFormStateValue(translate),
      ...this.visum.getAsFormStateValue(),
      ...this.cmNumber.getAsFormStateValue(),
      ...this.practice.getAsFormStateValues(),
      ...this.billing.getAsFormStateValues(),
      isFirstLine: this.firstline,
      supervision: this.supervision,
    };
  }

  /**
   * Get the professional's bio as an object.
   * You can call .getValue from this to get it textual.
   */
  public getBio(): Bio {
    return this.bio;
  }

  /**
   * Set the set the bio object of this professional.
   * If you want to change the bio of an existing professional
   * call getBio() and setValue on the returning value.
   */
  public setBio(bio: Bio): Professional {
    this.bio = bio;

    return this;
  }

  /**
   * Get the professional's experience as an object.
   * You can call .getValue from this to get it textual.
   */
  public getExperience(): Experience {
    return this.experience;
  }

  /**
   * Set the set the experience object of this professional.
   * If you want to change the experience of an existing professional
   * call getExperience() and setValue on the returning value.
   */
  public setExperience(experience: Experience): Professional {
    this.experience = experience;

    return this;
  }

  /**
   * Get the professional's Consultation Languages as an object.
   * You can call .getTranslatedValues from this to get it textual.
   */
  public getConsultationLanguages(): ConsultationLanguages {
    return this.consultationLanguages;
  }

  /**
   * Set the set the ConsultationLanguages object of this professional.
   * If you want to change the ConsultationLanguages of an existing professional
   * call getConsultationLanguages() and setValue on the returning value.
   */
  public setConsultationLanguages(
    consultationLanguages: ConsultationLanguages,
  ): Professional {
    this.consultationLanguages = consultationLanguages;

    return this;
  }

  /**
   * Get the professional's therapies as an object.
   * You can call .getTranslatedValues from this to get it textual.
   */
  public getTherapies(): Therapies {
    return this.therapies;
  }

  /**
   * Set the set the therapies object of this professional.
   * If you want to change the therapies of an existing professional
   * call getTherapies() and setValue on the returning value.
   */
  public setTherapies(therapies: Therapies): Professional {
    this.therapies = therapies;

    return this;
  }

  /**
   * Get the professional's default consultation duration.
   * You can call .getValue from this to get it numerical.
   */
  public getConsultationDuration(): ConsultationDuration {
    return this.duration;
  }

  /**
   * Set the default consulation duration object of this professional.
   * If you want to change the consultation duration of an existing professional
   * call getConsultationDuration() and setValue on the returning value.
   */
  public setConsultationDuration(duration: ConsultationDuration): Professional {
    this.duration = duration;

    return this;
  }

  /**
   * Get the professional's default consultation rate.
   * You can call .getValue from this to get it numerical.
   */
  public getConsultationRate(): ConsultationRate {
    return this.rate;
  }

  /**
   * Set the default consulation rate object of this professional.
   * If you want to change the consultation rate of an existing professional
   * call getConsultationRate() and setValue on the returning value.
   */
  public setConsultationRate(rate: ConsultationRate): Professional {
    this.rate = rate;

    return this;
  }

  /**
   * Getter and setter for supervision.
   *
   * @param supervision Optional new value for supervision.
   */
  public doesSupervision(supervision?: boolean): boolean {
    if (typeof supervision === "boolean") {
      this.supervision = supervision;
    }

    return this.supervision;
  }

  /**
   * Getter and setter for first line psy.
   *
   * @param firstline
   */
  public isFirstLine(firstline?: boolean): boolean {
    if (typeof firstline === "boolean") {
      this.firstline = firstline;
    }

    return this.firstline;
  }

  /**
   * Getter and setter for whether or not the professional is onboarded.
   *
   * @param verified
   */
  public verifiedAt(verified?: string): moment.Moment | null {
    if (typeof verified === "string") {
      this.verified = moment(verified);
    }

    return this.verified;
  }

  public getProfessionalType(): string | undefined {
    return this.professionalType;
  }

  public getLicenseFiles(): LicenseFile[] {
    return this.licenseFiles;
  }

  public addPractice(practice: Practice): Professional {
    this.practice = practice;

    return this;
  }

  public getPractice(): Practice {
    return this.practice;
  }

  public addBillingInformation(billing: BillingInformation): Professional {
    this.billing = billing;

    return this;
  }

  public getBillingInformation(): BillingInformation {
    return this.billing;
  }

  public getVisumNumber(): VisumNumber {
    return this.visum;
  }

  public getCMNumber(): CMNumber {
    return this.cmNumber;
  }

  public getWebsite(): Website {
    return this.website;
  }

  public getAvailability(): Array<TimeSlot> {
    return this.availability;
  }

  public getClientStop(): boolean {
    return this.clientStop;
  }

  public getForBgt(): boolean {
    return this.forBgt;
  }

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, APIUser.GeneralizedResult>
  > {
    return { professional: this.value };
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }

  static isProfessional(
    toBeDetermined: Professional | any,
  ): toBeDetermined is Professional {
    if (toBeDetermined === null || toBeDetermined === undefined) return false;

    return (
      toBeDetermined.getRole() === UserRoles.PROFESSIONAL ||
      toBeDetermined.getRole() === UserRoles.ADMIN
    );
  }
}
