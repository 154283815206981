import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import App from "./components/App";
import LoadingPage from "./components/layout/LoadingPage";
import MaintenancePage from "./components/pages/maintenance/MaintenancePage";
import Providers from "./components/providers/Providers";
import { theme } from "./components/providers/theme/ThemeProvider";
import ErrorPage from "./components/ui/ErrorPage";
import environment from "./environment/environment";

if (environment.REACT_APP_SENTRY_DSN) {
  //TODO: extract to other script
  Sentry.init({
    dsn: environment.REACT_APP_SENTRY_DSN,
    environment: environment.REACT_APP_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType,
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: environment.REACT_APP_SENTRY_SAMPLE_RATE,
  });
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Sentry.ErrorBoundary
    beforeCapture={(scope, error) =>
      console.log(`Scope:${scope}, error=${error}`)
    }
    fallback={<ErrorPage />}
  >
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingPage logText="Suspense fallback" />}>
          <Providers>
            {environment.REACT_APP_MAINTENANCE === true ? (
              <MaintenancePage />
            ) : (
              <App />
            )}
          </Providers>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>,
);
