import moment from "moment";

interface ITimeSlot {
  durationInMinutes: number;
  isTaken?: boolean;
  startTime: moment.Moment | Date | string;
}

class TimeSlot {
  private isTaken: boolean;
  private readonly startTime: moment.Moment;
  private readonly endTime: moment.Moment;
  private readonly duration: number;

  constructor({ durationInMinutes, isTaken, startTime }: ITimeSlot) {
    this.startTime = moment(startTime);
    this.duration = durationInMinutes;
    this.endTime = this.startTime.clone().add(durationInMinutes, "minutes");
    this.isTaken = isTaken ?? false;
  }

  public toggleTaken(): boolean {
    return (this.isTaken = !this.isTaken);
  }

  public getIsTaken(): boolean {
    return this.isTaken;
  }

  public getStartTime(): moment.Moment {
    return this.startTime;
  }

  public getEndTime(): moment.Moment {
    return this.endTime;
  }

  public getDurationInMinutes(): number {
    return this.duration;
  }

  public calendar(): string {
    return this.startTime.format("dd DD MMM HH:mm");
  }

  static now() {
    return new this({
      durationInMinutes: 0,
      startTime: moment(),
    });
  }

  static isTimeSlot(
    toBeDetermined: TimeSlot | any,
  ): toBeDetermined is TimeSlot {
    if (toBeDetermined === null || toBeDetermined === undefined) return false;

    return Object.prototype.hasOwnProperty.call(toBeDetermined, "isTaken");
  }
}

export default TimeSlot;
