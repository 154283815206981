import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import Consultation from "../../../data-model/types/consultation/Consultation";
import { APINode } from "../../api";
import { APIConsultation, APIConsultations } from "../consultations";
import { QUERY_FETCH_CONSULTATIONS_FOR_PROFESSIONAL_AND_HUMAN } from "../queries";

const useFetchConsultationsForProfessionalAndHuman = (humanId: number) => {
  const { data, error, loading } = useQuery<
    APIConsultations.AllForProfessionalAndHuman.Output,
    APIConsultations.AllForProfessionalAndHuman.Input
  >(QUERY_FETCH_CONSULTATIONS_FOR_PROFESSIONAL_AND_HUMAN, {
    fetchPolicy: "network-only",
    variables: { humanId },
  });

  //TODO: use extract and connectionToCollecation
  const consultations = useMemo(
    () =>
      data?.consultationsForProfessionalAndHuman.edges.map(
        (node: APINode<APIConsultation.Result>) => new Consultation(node.node),
      ),
    [data],
  );

  return { consultations, error, loading };
};

export default useFetchConsultationsForProfessionalAndHuman;
