import { lighten, Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RequestedByType } from "../../../api/__generated__/graphql";
import { UUID } from "../../../api/api";
import { UserRoles } from "../../../data-model/types/User";
import Consultation from "../../../data-model/types/consultation/Consultation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { getMomentLocale } from "../../language/languagesUtil";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import { theme } from "../../providers/theme/ThemeProvider";
import DeprecatedAvatar from "../../ui/DeprecatedAvatar";
import ButtonWithStatus from "../../ui/buttons/ButtonWithStatus";
import ConsultationsOverviewContext from "../context/ConsultationsOverviewContext";
import { IConsultationsOverviewContext } from "../context/consultations";
import "moment/locale/nl-be";
import "moment/locale/fr";
import "moment/locale/en-gb";

const PREFIX = "ConsultationListItem";

const classes = {
  fullName: `${PREFIX}-fullName`,
  text: `${PREFIX}-text`,
  time: `${PREFIX}-time`,
};

const StyledButtonWithStatus = styled(ButtonWithStatus)(({ theme }) => ({
  [`& .${classes.text}`]: {
    paddingLeft: 15,
  },

  [`& .${classes.fullName}`]: {
    fontSize: theme.typography.sizes.xl,
  },

  [`& .${classes.time}`]: {
    fontSize: theme.typography.sizes.normal,
  },
}));

interface IConsultationListItem {
  consultation: Consultation;
  handleClick: (uuid: UUID) => void;
  roleType?: UserRoles;
}

export default function ConsultationListItem({
  consultation,
  handleClick,
  roleType,
}: IConsultationListItem) {
  const { i18n, t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
  ]);

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down(470));

  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { openConsultationWithUuid, selectedConsultation } =
    useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  const startDate = Moment(consultation.getStartDate());

  const clientFullName = consultation.getClient().getFullName();

  const clientEmail = consultation.getClient().getEmail().getValue();
  const clientName =
    clientFullName !== null
      ? clientFullName
      : t("professional:new.client", {
          clientmail: clientEmail,
          defaultValue: `Nieuwe cliënt ({{clientmail}})`,
        });

  const fullNameToDisplay =
    currentUser?.isProfessional() || currentUser?.isAdmin()
      ? clientName
      : consultation.getProfessional().getFullName();

  Moment.locale(getMomentLocale(i18n.language));

  return (
    <StyledButtonWithStatus
      isOpen={consultation.getID() === selectedConsultation?.getID()}
      onClick={() => {
        openConsultationWithUuid(consultation.getUUID());
        handleClick(consultation.getUUID());
      }}
      requestedBy={consultation.requestedBy as RequestedByType}
      roleType={roleType}
      status={consultation.getStatus().getValue()}
      style={
        consultation.getCashTransaction() &&
        (consultation.getStatus().isDone() ||
          consultation.getStatus().isNoShowHuman()) &&
        consultation.getCashTransaction()?.getStatus() !== "paid"
          ? { backgroundColor: lighten(theme.palette.red.main, 0.6) }
          : {}
      }
    >
      <Grid container>
        <Grid item md={2} sm={2} sx={{ margin: "auto" }} xs={3}>
          <DeprecatedAvatar
            size={small ? 30 : 40}
            user={
              roleType === UserRoles.HUMAN ||
              roleType === UserRoles.ORGANIZATION_ADMIN
                ? consultation.getProfessional()
                : consultation.getClient()
            }
          />
        </Grid>
        <Grid className={classes.text} item md={10} sm={10} xs={9}>
          <Typography align="left" className={classes.fullName} noWrap>
            {fullNameToDisplay}
          </Typography>
          <Typography align="left" className={classes.time} noWrap>
            {startDate.locale(i18n.language).format("dddd DD MMM ") +
              t("common:date.at") +
              startDate.locale(i18n.language).format(" HH:mm")}
          </Typography>
        </Grid>
      </Grid>
    </StyledButtonWithStatus>
  );
}
