import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import Professional from "../../../data-model/types/professional/Professional";
import { connectionToCollection } from "../../../utils/graphql";
import { APIProfessional, APIProfessionals } from "../professionals";
import { QUERY_FETCH_PROFESSIONALS_FOR_HUMAN } from "../queries";

interface UseFetchProfessionalsForHumanProps {
  withAvailability?: boolean;
}

export default function useFetchProfessionalsForHuman(
  props?: UseFetchProfessionalsForHumanProps,
) {
  const variables = useMemo(() => {
    if (
      props &&
      props.withAvailability !== null &&
      props.withAvailability !== undefined
    ) {
      return { input: { withAvailability: props.withAvailability } };
    }
  }, [props]);

  //TODO: this query is 99% the same one as 'useFetchCollegues" --> Consolidate into one query!
  const { data, error, loading } = useQuery<
    APIProfessionals.Fetch.ForMe.Result,
    APIProfessionals.Fetch.ForMe.APIInput
  >(QUERY_FETCH_PROFESSIONALS_FOR_HUMAN, {
    fetchPolicy: "no-cache",
    variables,
  });

  const extract = (dataObjects: any) => connectionToCollection(dataObjects);

  const createProfessionals = (
    professionals: Array<APIProfessional.Result>,
  ): Array<Professional> => {
    return professionals.map((p) => new Professional(p, true));
  };

  const professionalsFromData = useMemo(() => {
    if (data) {
      const extracted = extract(data.professionalsForCurrentHuman);

      return createProfessionals(extracted);
    }

    return [];
  }, [data]);

  return {
    error,
    loading,
    professionals: professionalsFromData,
  };
}
