import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function SearchableSelect({
  items,
  onChange,
  testId,
  value,
}: {
  items: { label: string; testId?: string; value: number | string }[];
  onChange: (value: number | string) => void;
  testId?: string;
  value: number | string;
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => items.filter((option) => containsText(option.label, searchText)),
    [items, searchText],
  );

  return (
    <Select
      // Disables auto focus on MenuItems and allows TextField to be in focus
      MenuProps={{
        autoFocus: false,
        sx: (theme) => ({
          "& .MuiPaper-root": {
            border: `3px solid ${theme.palette.bloom.dark}`,
          },
        }),
      }}
      data-testid={testId}
      onChange={(e) => onChange(e.target.value)}
      onClose={() => setSearchText("")}
      sx={{
        width: "100%",
      }}
      value={value}
    >
      <ListSubheader>
        <TextField
          autoFocus
          fullWidth
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
          placeholder={t("common:searchable.select.search.placeholder")}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
          sx={{ pb: "8px" }}
        />
      </ListSubheader>
      {displayedOptions.map((option, i) => (
        <MenuItem data-testid={option.testId} key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
