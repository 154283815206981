/**
 * The standard duration of a consultation.
 */
import type { TFunction } from "i18next";
import lodash from "lodash";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import environment from "../../../environment/environment";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export enum ConsultationDurationOptions {
  FIFTY = environment.REACT_APP_APPOINTMENT_DURATION,
  SIXTY = 60,
}

export default class ConsultationDuration extends DataType<
  ConsultationDurationOptions,
  DataTypePaths.Consultation.StandardDuration
> {
  protected static path: DataTypePaths.Consultation.StandardDuration =
    "standardDuration";

  public static timeToPath: DataTypePaths.Consultation.TimeTo = "timeTo";
  public static timeFromPath: DataTypePaths.Consultation.TimeFrom = "timeFrom";

  protected static defaultValue = ConsultationDurationOptions.FIFTY;

  protected type: any = ConsultationDuration;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {any} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:standardDuration.mandatory",
          "Selecteer de lengte van je consultaties.",
        ),
        path: this.getPath(),
        validate: (standardDuration: any): boolean => {
          if (isNaN(standardDuration)) return false;

          const duration = Number(standardDuration);

          return (
            duration === ConsultationDurationOptions.FIFTY ||
            duration === ConsultationDurationOptions.SIXTY
          );
        },
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionally, an icon.
   *
   * @param  {Object} translate The i18next translate hook with the common namespace.
   */
  static getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<Record<string, unknown>> {
    const options: Array<Record<string, unknown>> = [];

    Object.values(ConsultationDurationOptions).forEach((value) => {
      if (typeof value !== "number") return;

      if (value === environment.REACT_APP_INTRO_DURATION) return;

      options.push({
        label: translate(`common:consultation.duration.${value}`),
        value,
      });
    });

    return lodash.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  static getOptions(): any {
    return ConsultationDurationOptions;
  }

  getAsFormStateValue(): Record<
    DataTypePaths.Consultation.StandardDuration,
    ConsultationDurationOptions
  > {
    return { standardDuration: this.value };
  }
}
