import Box from "@mui/material/Box";
import MuiFormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { IWithArbitraryProps } from "../../../baseInterfaces";

const PREFIX = "FormLabel";

const classes = {
  formlabel: `${PREFIX}-formlabel`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.formlabel}`]: {
    color: theme.palette.controls.label,
    display: "block",
    fontSize: theme.controls.fontSize,
    lineHeight: "25px",
    marginTop: "15px",
    textAlign: "left",
  },
}));

interface IFormLabel extends IWithArbitraryProps {
  label: any;
}

const FormLabel = ({ label, ...other }: IFormLabel) => {
  return (
    <StyledBox pb={1}>
      <MuiFormLabel className={classes.formlabel} component="label" {...other}>
        {label}
      </MuiFormLabel>
    </StyledBox>
  );
};

export default FormLabel;
