import { createContext } from "react";
import { IPaymentContext } from "./payment";

const defaultPaymentValues: IPaymentContext = {
  checked: false,
  pay: () => {},
  setChecked: () => {},
};

const PaymentContext = createContext<IPaymentContext>(defaultPaymentValues);

export default PaymentContext;
