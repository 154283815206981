import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FragmentType } from "../__generated__";
import { UseSelectTranslationStringInputFragment } from "./fragments";

const i18nToGraphLanguageMap = {
  en: "en",
  "fr-BE": "fr",
  frBE: "fr",
  "nl-BE": "nl",
  nlBE: "nl",
} as const;

type SelectTranslationInput =
  | FragmentType<typeof UseSelectTranslationStringInputFragment>
  | null
  | undefined;

type SelectTranslationReturn<T extends SelectTranslationInput> = T extends
  | null
  | undefined
  ? null
  : T extends FragmentType<typeof UseSelectTranslationStringInputFragment>
    ? string | null | undefined
    : boolean;

const useSelectTranslation = () => {
  const { i18n } = useTranslation();
  const key =
    i18nToGraphLanguageMap[
      i18n.language as keyof typeof i18nToGraphLanguageMap
    ];

  return useCallback(
    <T extends SelectTranslationInput>(
      input: T,
    ): SelectTranslationReturn<T> => {
      if (input === undefined || input === null) {
        return null as SelectTranslationReturn<T>;
      }

      return input[key] as SelectTranslationReturn<T>;
    },
    [key],
  );
};

export default useSelectTranslation;
