import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import useUpdateProfessionalMutation from "../../../../api/professionals/hooks/useUpdateProfessionalMutation";
import CMNumber from "../../../../data-model/types/professional/CMNumber";
import ConsultationLanguages from "../../../../data-model/types/professional/ConsultationLanguages";
import { DESCRIPTION_PREFIX } from "../../../../data-model/types/professional/Description";
import Experience from "../../../../data-model/types/professional/Experience";
import Therapies from "../../../../data-model/types/professional/Therapies";
import VisumNumber from "../../../../data-model/types/professional/VisumNumber";
import Address from "../../../../data-model/types/profile/Address";
import Avatar from "../../../../data-model/types/profile/Avatar";
import BirthDate from "../../../../data-model/types/profile/BirthDate";
import Gender from "../../../../data-model/types/profile/Gender";
import { prepareOneLanguageForAPI } from "../../../language/languagesUtil";
import LoadingPage from "../../../layout/LoadingPage";
import AuthContext from "../../../providers/auth/AuthContext";
import { IAuthContext } from "../../../providers/auth/auth";
import { RouteNames } from "../../../routes/routeNames";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import useProfessionalOnboardFormState, {
  FORM_KEY_ACCEPTED_CONSENT,
  FORM_KEY_DOES_SUPERVISION,
  FORM_KEY_IS_FIRST_LINE,
} from "../useProfessionalOnboardFormState";
import ProfessionalOnboardingContext from "./ProfessionalOnboardingContext";
import {
  AVATAR_STEP,
  BILLING_STEP,
  BIRTH_DATE_STEP,
  CONSENT_STEP,
  CONSULTATION_LANGUAGES_STEP,
  EXPERIENCE_STEP,
  GENDER_STEP,
  PRACTICE_ADDRESS_STEP,
  PROFILE_STEP,
  RATE_STEP,
  TOPICS_STEP,
  START_STEP,
  SUCCESS_STEP,
  THERAPY_STEP,
  TOTAL_STEPS,
  VISUM_AND_CM_STEP,
} from "./professionalOnboardingSteps";

export default function ProfessionalOnboardingContextProvider({ children }) {
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>(undefined);
  const [disable, setDisable] = useState<boolean>(false);
  const formState = useProfessionalOnboardFormState();
  const { loading: updating, updateProfessional } =
    useUpdateProfessionalMutation();

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const handleCompletion = () => {
    if (currentUser) {
      currentUser.isOnboardedAt(moment());
      navigate(RouteNames.Home.path);
    }
  };

  const getProfileValues = () => {
    const descriptionsObject = {};

    formState.getValue(ConsultationLanguages.getPath()).forEach((language) => {
      const key = prepareOneLanguageForAPI(language.value);
      const descriptionInFormState = formState.getValue([
        `${DESCRIPTION_PREFIX}-${key}`,
      ]);

      if (descriptionInFormState) {
        descriptionsObject[key] = descriptionInFormState;
      }
    });

    return descriptionsObject;
  };

  const handleSubmit = async () => {
    setDisable(true);
    setError(undefined);

    const {
      accountNumber,
      address,
      avatar,
      billingAddress,
      birthDate,
      cmNumber,
      consultationLanguages,
      experienceSince,
      therapyTypes,
      visumNumber,
    } = formState.values;

    const input = {
      [FORM_KEY_ACCEPTED_CONSENT]: formState.values[FORM_KEY_ACCEPTED_CONSENT],
      [FORM_KEY_DOES_SUPERVISION]: formState.values[FORM_KEY_DOES_SUPERVISION],
      [FORM_KEY_IS_FIRST_LINE]: formState.values[FORM_KEY_IS_FIRST_LINE],
      avatar: "preview" in avatar ? avatar : undefined,
      billingInformation: {
        accountNumber,
        address: {
          country: "BE",
          postcode: billingAddress.postcode ?? "",
          street: billingAddress.street ?? "",
        },
      },
      birthDate,
      cmNumber,
      consultationLanguages: JSON.stringify(
        consultationLanguages.map((l: ISelectObject) =>
          prepareOneLanguageForAPI(l.value),
        ),
      ),
      experienceSince,
      practice: {
        address: {
          country: "BE",
          postcode: address.postcode,
          street: address.street,
        },
      },
      profile: JSON.stringify(getProfileValues()),
      therapyTypes: JSON.stringify(
        therapyTypes.map((t: ISelectObject) => t.value),
      ),
      visumNumber,
    };

    try {
      await Promise.all([updateProfessional(input)]);
      setDisable(false);

      return;
    } catch (e) {
      setDisable(false);
      setError(
        "There was an error updating your profile. Please check your answers and try again.",
      );
      console.log(e);
    }
  };

  const formIsValid = (path: string) => formState.validate(path);

  const next = () => {
    switch (step) {
      case START_STEP:
        setStep(BIRTH_DATE_STEP);
        navigate(RouteNames.Onboarding.Professional.BirthDate.path);

        break;

      case BIRTH_DATE_STEP:
        if (formIsValid(BirthDate.getPath())) {
          setStep(GENDER_STEP);
          navigate(RouteNames.Onboarding.Professional.Gender.path);
        }

        break;

      case GENDER_STEP:
        if (formIsValid(Gender.getPath())) {
          setStep(AVATAR_STEP);
          navigate(RouteNames.Onboarding.Professional.Avatar.path);
        }

        break;

      case AVATAR_STEP:
        if (formIsValid(Avatar.getPath())) {
          setStep(TOPICS_STEP);
          navigate(RouteNames.Onboarding.Professional.Topics.path);
        }

        break;

      case THERAPY_STEP:
        if (formIsValid(Therapies.getPath())) {
          setStep(EXPERIENCE_STEP);
          navigate(RouteNames.Onboarding.Professional.Experience.path);
        }

        break;

      case EXPERIENCE_STEP:
        if (formIsValid(Experience.getPath())) {
          setStep(CONSULTATION_LANGUAGES_STEP);
          navigate(RouteNames.Onboarding.Professional.Languages.path);
        }

        break;

      case CONSULTATION_LANGUAGES_STEP:
        if (formIsValid(ConsultationLanguages.getPath())) {
          setStep(PROFILE_STEP);
          navigate(RouteNames.Onboarding.Professional.Profile.path);
        }

        break;

      case PROFILE_STEP: {
        const formHasErrors = formState.hasError(DESCRIPTION_PREFIX);

        if (!formHasErrors) {
          setStep(VISUM_AND_CM_STEP);
          navigate(RouteNames.Onboarding.Professional.VisumAndCM.path);
        }

        break;
      }

      case VISUM_AND_CM_STEP:
        if (
          formIsValid(CMNumber.getPath()) &&
          formIsValid(VisumNumber.getPath())
        ) {
          setStep(PRACTICE_ADDRESS_STEP);
          navigate(RouteNames.Onboarding.Professional.PracticeAddress.path);
        }

        break;

      case PRACTICE_ADDRESS_STEP:
        if (formIsValid(Address.getPath())) {
          setStep(RATE_STEP);
          navigate(RouteNames.Onboarding.Professional.Rate.path);
        }

        break;

      case RATE_STEP:
        setStep(BILLING_STEP);
        navigate(RouteNames.Onboarding.Professional.Billing.path);

        break;

      case BILLING_STEP:
        if (formIsValid("billingAddress") && formIsValid("billingAccount")) {
          setStep(CONSENT_STEP);
          navigate(RouteNames.Onboarding.Professional.Consent.path);
        }

        break;

      case CONSENT_STEP:
        if (formIsValid(FORM_KEY_ACCEPTED_CONSENT)) {
          handleSubmit().then(() => {
            setStep(SUCCESS_STEP);
            navigate(RouteNames.Onboarding.Professional.Success.path);
          });
        }

        break;

      case SUCCESS_STEP:
        handleCompletion();
        break;

      default:
        throw Error("Onboarding route not implemented");
    }
  };

  const previous = () => {
    switch (step) {
      case START_STEP:
        break;

      case BIRTH_DATE_STEP:
        setStep(START_STEP);
        navigate(RouteNames.Onboarding.Professional.Start.path);

        break;

      case GENDER_STEP:
        setStep(BIRTH_DATE_STEP);
        navigate(RouteNames.Onboarding.Professional.BirthDate.path);

        break;

      case AVATAR_STEP:
        setStep(GENDER_STEP);
        navigate(RouteNames.Onboarding.Professional.Gender.path);

        break;

      case THERAPY_STEP:
        setStep(TOPICS_STEP);
        navigate(RouteNames.Onboarding.Professional.Topics.path);

        break;

      case EXPERIENCE_STEP:
        setStep(THERAPY_STEP);
        navigate(RouteNames.Onboarding.Professional.Therapy.path);

        break;

      case CONSULTATION_LANGUAGES_STEP:
        setStep(EXPERIENCE_STEP);
        navigate(RouteNames.Onboarding.Professional.Experience.path);

        break;

      case PROFILE_STEP:
        setStep(CONSULTATION_LANGUAGES_STEP);
        navigate(RouteNames.Onboarding.Professional.Languages.path);

        break;

      case VISUM_AND_CM_STEP:
        setStep(PROFILE_STEP);
        navigate(RouteNames.Onboarding.Professional.Profile.path);

        break;

      case PRACTICE_ADDRESS_STEP:
        setStep(VISUM_AND_CM_STEP);
        navigate(RouteNames.Onboarding.Professional.VisumAndCM.path);

        break;

      case RATE_STEP:
        setStep(PRACTICE_ADDRESS_STEP);
        navigate(RouteNames.Onboarding.Professional.VisumAndCM.path);

        break;

      case BILLING_STEP:
        setStep(RATE_STEP);
        navigate(RouteNames.Onboarding.Professional.Rate.path);

        break;

      case CONSENT_STEP:
        setStep(BILLING_STEP);
        navigate(RouteNames.Onboarding.Professional.Billing.path);

        break;

      case SUCCESS_STEP:
        setStep(CONSENT_STEP);
        navigate(RouteNames.Onboarding.Professional.Consent.path);

        break;

      default:
        throw Error("Onboarding route not implemented");
    }
  };

  if (updating /*|| loading*/) return <LoadingPage full={false} />;

  return (
    <ProfessionalOnboardingContext.Provider
      value={{
        disable,
        error,
        formState,
        next,
        previous,
        setStep,
        step,
        totalSteps: TOTAL_STEPS,
      }}
    >
      {children}
    </ProfessionalOnboardingContext.Provider>
  );
}
