import { I18nextProvider } from "react-i18next";
import { IWithChildren } from "../../baseInterfaces";
import i18n from "../utils/i18n";
import ApolloClientProvider from "./ApolloClientProvider";
import { defaultAuthProviderValues } from "./auth/AuthContext";
import AuthStateProvider from "./auth/AuthStateProvider";
import MatomoClientProvider from "./matomo/MatomoClientProvider";
import { defaultToastProviderValues } from "./toast/ToastContext";
import ToastProvider from "./toast/ToastProvider";

export default function Providers({ children }: IWithChildren) {
  return (
    <MatomoClientProvider>
      <ApolloClientProvider>
        <I18nextProvider i18n={i18n}>
          <AuthStateProvider {...defaultAuthProviderValues}>
            <ToastProvider {...defaultToastProviderValues}>
              {children}
            </ToastProvider>
          </AuthStateProvider>
        </I18nextProvider>
      </ApolloClientProvider>
    </MatomoClientProvider>
  );
}
