import { useMutation, useQuery } from "@apollo/client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { add, format, getTime } from "date-fns";
import { Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import { ConsultationTypes } from "../../data-model/types/consultation/Consultation";
import environment from "../../environment/environment";
import { getDateFnsLocale } from "../language/languagesUtil";
import ToastContext from "../providers/toast/ToastContext";
import { IToastContext } from "../providers/toast/toast";
import Button from "../ui/buttons/Button";
import { CustomDateTimePicker } from "../ui/form/CustomDateTimePicker";
import CustomTextField from "../ui/form/CustomTextField";
import SearchableSelect from "../ui/form/SearchableSelect";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";

const requestConsultationProfessionalQuery = graphql(`
  query RequestConsultationProfessional($id: Int!) {
    professional(id: $id) {
      coachingEnabled
      coachingRate
      id
      standardDuration
      standardRate
      therapyEnabled
    }
  }
`);

const requestConsultationClientsQuery = graphql(`
  query RequestConsultationClients($id: Int!) {
    professional(id: $id) {
      clients {
        edges {
          node {
            id
            ...RequestConsultationHumanInfo
            user {
              email
              firstName
              id
              lastName
            }
          }
        }
      }
      id
    }
  }
`);

const professionalRequestConsultationMutation = graphql(`
  mutation ProfessionalRequestConsultation($input: CreateConsultationInput!) {
    createConsultation(input: $input) {
      id
      uuid
    }
  }
`);

const requestConsultationHumanInfoFragment = graphql(`
  fragment RequestConsultationHumanInfo on Human {
    canPayCoachingWithCredits
    canPayConsultWithCredits
    creditsLeft
    id
    organization {
      coachingEnabled
      id
      videoEnabled
    }
  }
`);

const ProfessionalScheduleConsultation = ({
  humanData,
  onCancel,
  onSchedule,
  professionalId,
}: {
  humanData?: FragmentType<typeof requestConsultationHumanInfoFragment>; //When booking in call, we know the client.
  onCancel?: () => void;
  onSchedule: (uuid: string) => void;
  professionalId: number;
}) => {
  const { i18n, t } = useTranslation();
  const dateFnsLocale = getDateFnsLocale(i18n.language);
  const { setToast } = useContext<IToastContext>(ToastContext);
  const human = getFragmentData(
    requestConsultationHumanInfoFragment,
    humanData,
  );

  const { data: profData } = useQuery(requestConsultationProfessionalQuery, {
    variables: {
      id: professionalId,
    },
  });

  const { data: clientsData } = useQuery(requestConsultationClientsQuery, {
    skip: Boolean(humanData),
    variables: {
      id: professionalId,
    },
  });

  const [createConsultation, { loading: creating }] = useMutation(
    professionalRequestConsultationMutation,
    {
      onCompleted(data) {
        if (data.createConsultation?.uuid) {
          onSchedule(data.createConsultation.uuid);
        }
      },
      onError(error) {
        if (error.message === "create_consultation_unpaid_consultations") {
          setToast({
            message: t("errors:create_consultation_unpaid_consultations"),
            severity: "error",
          });

          return;
        }

        if (error.message === "OVERLAPPING_EVENTS") {
          setToast({
            message: t(
              "common:professional.reschedule.consultation.validtation.overlapping.events",
            ),
            severity: "error",
          });

          return;
        }

        setToast({
          message: "Sorry, something went wrong. Please try again later.",
          severity: "error",
        });
      },

      refetchQueries: ["ProfessionalConsultations"],
    },
  );

  const smallScreen = useMediaQuery("(max-width: 500px)");

  if (!profData?.professional) return null;

  return (
    <Formik
      initialValues={{
        clientId: human?.id ?? undefined,
        consultationType: !profData.professional.therapyEnabled
          ? ConsultationTypes.COACHING
          : ConsultationTypes.APPOINTMENT,
        duration: profData.professional.standardDuration || 0,
        price: !profData.professional.therapyEnabled
          ? profData.professional.coachingRate
          : profData.professional.standardRate,
        scheduleFrom: add(new Date().setSeconds(0, 0), {
          minutes: 15,
        }),
        scheduleTo: add(new Date().setSeconds(0, 0), {
          minutes:
            profData.professional.standardDuration ??
            environment.REACT_APP_APPOINTMENT_DURATION,
        }),
      }}
      onSubmit={(values) => {
        const clientId = human
          ? human.id
          : clientsData?.professional?.clients.edges.find(
              (edge) => edge.node.id === values.clientId,
            )?.node.id;

        if (clientId) {
          createConsultation({
            variables: {
              input: {
                fromMatching: false,
                otherPartyId: clientId,
                price: values.price,
                scheduledFrom: values.scheduleFrom,
                scheduledTo: add(values.scheduleFrom, {
                  minutes:
                    values.consultationType === "INTRO"
                      ? environment.REACT_APP_INTRO_DURATION
                      : values.consultationType === "COACHING"
                        ? environment.REACT_APP_COACHING_DURATION
                        : values.duration,
                }),
                status: human ? "ACCEPTED" : "REQUESTED", //The only place we pass a human is in-call.
                type: values.consultationType,
              },
            },
          });
        }
      }}
      validationSchema={Yup.object({
        clientId: Yup.string().required(
          t("common:professional.request.consultation.validation.client"),
        ),
        consultationType: Yup.string<ConsultationTypes>()
          .oneOf([
            ConsultationTypes.APPOINTMENT,
            ConsultationTypes.INTRO,
            ConsultationTypes.COACHING,
          ])
          .required(
            t(
              "common:professional.request.consultation.validation.consultation_type",
            ),
          ),
        duration: Yup.number().min(
          1,
          t("common:professional.request.consultation.validation.duration"),
        ),
        price: Yup.number().required(
          t("common:professional.request.consultation.validation.price"),
        ),
        scheduleFrom: Yup.date().test(
          "min",
          t(
            "common:professional.request.consultation.validation.schedule_from",
          ),
          function (value) {
            return value && value >= new Date();
          },
        ),
      })}
    >
      {(props) => {
        const selectedHuman =
          human ??
          getFragmentData(
            requestConsultationHumanInfoFragment,
            clientsData?.professional?.clients.edges.find(
              (edge) => edge.node.id === props.values.clientId,
            )?.node,
          );

        const canPayWithCredits =
          (props.values.consultationType === "APPOINTMENT" &&
            selectedHuman &&
            !!selectedHuman.canPayConsultWithCredits) ||
          (props.values.consultationType === "COACHING" &&
            selectedHuman &&
            !!selectedHuman.canPayCoachingWithCredits);

        const b2cUser = selectedHuman?.organization?.id === undefined;

        const bloomUpFee =
          props.values.consultationType === "COACHING"
            ? environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_COACHING
            : environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT;

        return (
          <form onSubmit={props.handleSubmit}>
            <Box mt={1}>
              {!human && (
                <Box>
                  {clientsData?.professional ? (
                    <Box mb={3}>
                      <Label value={t("common:client")} />
                      <SearchableSelect
                        items={clientsData.professional.clients.edges.map(
                          (edge, id) => {
                            return {
                              label:
                                edge.node.user.firstName !== null
                                  ? edge.node.user.firstName +
                                    " " +
                                    edge.node.user.lastName
                                  : edge.node.user.email,

                              testId: `client-menu-item-${id}`,
                              value: edge.node.id,
                            };
                          },
                        )}
                        onChange={(value) => {
                          props.setFieldValue("clientId", value);

                          if (!b2cUser) {
                            if (
                              !selectedHuman.organization?.coachingEnabled &&
                              props.values.consultationType ===
                                ConsultationTypes.COACHING
                            ) {
                              props.setFieldValue(
                                "consultationType",
                                ConsultationTypes.APPOINTMENT,
                              );
                            } else if (
                              !selectedHuman.organization?.videoEnabled &&
                              props.values.consultationType ===
                                ConsultationTypes.APPOINTMENT
                            ) {
                              props.setFieldValue(
                                "consultationType",
                                ConsultationTypes.COACHING,
                              );
                            }
                          }
                        }}
                        testId="client-select"
                        value={props.values.clientId ?? ""}
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
              {selectedHuman ? (
                <Box>
                  <Label value={t("professional:consultations.select.type")} />
                  <Select
                    data-testid="consultation-type-select"
                    onChange={(event) => {
                      props.setFieldValue(
                        "consultationType",
                        event.target.value,
                      );

                      if (
                        event.target.value === ConsultationTypes.APPOINTMENT
                      ) {
                        props.setFieldValue(
                          "price",
                          profData.professional?.standardRate,
                        );
                      } else if (
                        event.target.value === ConsultationTypes.COACHING
                      ) {
                        props.setFieldValue(
                          "price",
                          profData.professional?.coachingRate,
                        );
                      }
                    }}
                    sx={{
                      width: "100%",
                    }}
                    value={props.values.consultationType}
                  >
                    {profData.professional?.therapyEnabled &&
                    (b2cUser || selectedHuman.organization?.videoEnabled) ? (
                      <MenuItem
                        data-testid="consultation-type-appointment"
                        value={ConsultationTypes.APPOINTMENT}
                      >
                        {t(`common:consultation_type.APPOINTMENT`)}
                      </MenuItem>
                    ) : null}
                    {!human && (
                      <MenuItem value={ConsultationTypes.INTRO}>
                        {t(`common:consultation_type.INTRO`)}
                      </MenuItem>
                    )}
                    {profData.professional?.coachingEnabled &&
                    (b2cUser || selectedHuman.organization?.coachingEnabled) ? (
                      <MenuItem value={ConsultationTypes.COACHING}>
                        {t(`common:consultation_type.COACHING`)}
                      </MenuItem>
                    ) : null}
                  </Select>
                </Box>
              ) : null}
              {selectedHuman && props.values.consultationType ? (
                <Box>
                  <Box
                    alignItems={"justify"}
                    display="flex"
                    mt={3}
                    width="100%"
                  >
                    <Box mr={1} pr={1} width={"75%"}>
                      <Label value={t("common:from")} />
                      <CustomDateTimePicker
                        disablePast
                        error={props.errors.scheduleFrom as string}
                        fullWidth
                        onChange={(value) => {
                          if (value && !isNaN(getTime(value))) {
                            props.setFieldValue("scheduleFrom", value, true);
                          } else {
                            props.setFieldError(
                              "scheduleFrom",
                              t(
                                "common:professional.request.consultation.validation.invalid_date",
                              ),
                            );
                          }
                        }}
                        value={props.values.scheduleFrom}
                      />
                    </Box>
                    {props.values.consultationType ===
                      ConsultationTypes.APPOINTMENT && (
                      <Box mr={1} width={"25%"}>
                        <Label value={t("common:duration")} />
                        <CustomTextField
                          error={props.errors.duration}
                          onChange={(event) => {
                            props.setFieldValue(
                              "duration",
                              Number(event.target.value),
                            );
                          }}
                          value={props.values.duration}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box alignItems={"center"} mt={3}>
                    <Label value={t("common:consultation.time", "Tijd")} />
                    <Typography width={"100%"}>
                      {`${format(props.values.scheduleFrom, "p", {
                        locale: dateFnsLocale,
                      })} →
                    ${format(
                      add(props.values.scheduleFrom, {
                        minutes:
                          props.values.consultationType === "INTRO"
                            ? environment.REACT_APP_INTRO_DURATION
                            : props.values.consultationType === "COACHING"
                              ? environment.REACT_APP_COACHING_DURATION
                              : props.values.duration,
                      }),
                      "p",
                      {
                        locale: dateFnsLocale,
                      },
                    )}`}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexWrap={"nowrap"} py={2}>
                    {!canPayWithCredits &&
                      props.values.consultationType ===
                        ConsultationTypes.APPOINTMENT && (
                        <Box mr={2}>
                          <Label
                            tooltip={t(
                              "professional:consultation.pricing.tooltip",
                            )}
                            value={t("common:price.label")}
                          />
                          <Box>
                            <CustomTextField
                              error={props.errors.price}
                              onChange={(event) => {
                                props.setFieldValue(
                                  "price",
                                  Number(event.target.value),
                                );
                              }}
                              value={props.values.price}
                            />
                          </Box>
                        </Box>
                      )}
                    {props.values.consultationType !== "INTRO" && (
                      <Box mt={2}>
                        <SanitizedText
                          input={
                            canPayWithCredits
                              ? selectedHuman.creditsLeft
                                ? t(
                                    "professional:consultation.pricing.info.credits",
                                    {
                                      credits: selectedHuman.creditsLeft,
                                    },
                                  )
                                : t(
                                    "professional:consultation.pricing.info.credits.unlimited",
                                  )
                              : t("professional:consultation.pricing.info", {
                                  clientPrice: props.values.price,
                                  professionalNetto:
                                    (props.values.price
                                      ? props.values.price
                                      : 0) - bloomUpFee,
                                  serviceCost: bloomUpFee,
                                })
                          }
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "2rem",
                    }}
                  >
                    <Button
                      bloomColor="green"
                      icon={<CheckCircleIcon />}
                      label={t("common:book")}
                      loading={creating}
                      onClick={() => {
                        props.submitForm();
                      }}
                      size={smallScreen ? "medium" : "large"}
                      type="submit"
                    />
                    {onCancel ? (
                      <Button
                        bloomColor="red"
                        icon={<ClearIcon />}
                        noLabel
                        onClick={onCancel}
                        sx={{ alignSelf: "flex-end" }}
                      />
                    ) : null}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfessionalScheduleConsultation;
