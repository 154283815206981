import {
  Box,
  Grid,
  List,
  Theme,
  Typography,
  lighten,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useParams } from "react-router";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import StatusIcon from "../../components/ui/StatusIcon";
import { useDateLocale } from "../../custom-hooks/useDateLocal";
import Avatar from "../ui/Avatar";
import NewExpansionToggle from "../ui/NewExpansionToggle";

const ProfessionalConsultationListFragment = graphql(`
  fragment ProfessionalConsultationList on ConsultationsConnection {
    edges {
      node {
        cashTransaction {
          id
          status
        }
        human {
          id
          user {
            ...Avatar
            email
            firstName
            id
            lastName
          }
        }
        id
        requestedBy
        scheduledFrom
        status
        uuid
      }
    }
  }
`);

const OptionalExpansionWrapper = ({
  children,
  expansionLabel,
}: {
  children: React.ReactNode;
  expansionLabel?: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return expansionLabel ? (
    <NewExpansionToggle
      isOpen={isExpanded}
      label={expansionLabel}
      mb={2}
      onClick={() => setIsExpanded(!isExpanded)}
      primary={false}
    >
      {children}
    </NewExpansionToggle>
  ) : (
    children
  );
};

const ProfessionalConsultationList = ({
  consultations: consultationsRaw,
  expensionLabel,
  onOpenConsultation,
}: {
  consultations: FragmentType<typeof ProfessionalConsultationListFragment>;
  expensionLabel?: string;
  onOpenConsultation: (uuid: string) => void;
}) => {
  const consultations = getFragmentData(
    ProfessionalConsultationListFragment,
    consultationsRaw,
  );

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down(470));
  const locale = useDateLocale();

  const { uuid } = useParams();

  if (consultations.edges.length === 0) return null;

  return (
    <OptionalExpansionWrapper expansionLabel={expensionLabel}>
      <List sx={{ marginBottom: "2rem" }}>
        {consultations.edges.map((edge) => {
          if (!edge.node.human) return null;

          const isOpen = edge.node.uuid === uuid;

          return (
            <Box
              data-cy="consultation-list-item"
              key={edge.node.id}
              onClick={() => onOpenConsultation(edge.node.uuid)}
              sx={(theme) => ({
                "& .open": {
                  "& .MuiSvgIcon-root": {
                    color: `${theme.palette.white.main} !important`,
                  },
                },
                "&:focus": {
                  outline: 0,
                },
                "&:hover": {
                  backgroundColor: isOpen
                    ? lighten(theme.palette.green.main, 0.7)
                    : lighten(theme.palette.bloom.main, 0.5),
                },
                "&:last-child": {
                  marginBottom: 0,
                },
                alignItems: "center",
                background: isOpen
                  ? lighten(theme.palette.green.main, 0.7)
                  : theme.palette.white.main,
                border: "none",
                borderLeft: "solid 3px",
                borderLeftColor: isOpen
                  ? theme.palette.green.main
                  : theme.palette.bloom.main,
                cursor: "pointer",
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                marginBottom: theme.spacing(3),

                [theme.breakpoints.down("md")]: {
                  padding: theme.spacing(1),
                },

                padding: theme.spacing(3),

                position: "relative",

                transition: "background-color 0.15s ease-in-out",

                width: "100%",
              })}
            >
              <Grid item xs={3}>
                <Avatar size={small ? 30 : 40} user={edge.node.human.user} />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={edge.node.status ? (small ? 10 : 11) : 12}>
                  <Grid item md={10} sm={10} xs={9}>
                    <Typography align="left" noWrap>
                      {edge.node.human.user.firstName !== null
                        ? edge.node.human.user.firstName +
                          " " +
                          edge.node.human.user.lastName
                        : edge.node.human.user.email}
                    </Typography>
                    <Typography align="left" noWrap>
                      {format(new Date(edge.node.scheduledFrom), "PPp", {
                        locale,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {edge.node.status && edge.node.requestedBy ? (
                <Grid item xs={1}>
                  <StatusIcon
                    isProfessional={true}
                    requestedBy={edge.node.requestedBy}
                    status={edge.node.status}
                  />
                </Grid>
              ) : null}
            </Box>
          );
        })}
      </List>
    </OptionalExpansionWrapper>
  );
};

export default ProfessionalConsultationList;
