import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UpdateConsultationInput } from "../../__generated__/graphql";
import { MUTATION_CONSULTATION_UPDATE } from "../mutations";

export default function useUpdateConsultation() {
  const [updateConsultationFunction, { data, error, loading }] = useMutation(
    MUTATION_CONSULTATION_UPDATE,
  );

  const updateConsultation = useCallback(
    (input: UpdateConsultationInput) =>
      updateConsultationFunction({ variables: { input } }),
    [updateConsultationFunction],
  );

  return {
    data,
    error,
    loading,
    updateConsultation,
  };
}
