/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../api/__generated__";
import { DateFormat } from "../../data-model/Date";
import Consultation from "../../data-model/types/consultation/Consultation";
import TimeSlot from "../../data-model/types/consultation/TimeSlot";
import { IFormState } from "../hooks/useFormState";
import { getDateFnsLocale, getMomentLocale } from "../language/languagesUtil";
import TimeSlotBoxes from "../pages/human-request-consultation/TimeSlotBoxes";
import Label from "../ui/text/Label";
import ValueWithLabel from "../ui/text/ValueWithLabel";

const consultationDateSelectionAvailabilityQuery = graphql(`
  query ConsultationDateSelectionAvailability(
    $consultationType: ConsultationType!
    $professionalId: Int!
  ) {
    professional(id: $professionalId) {
      availabilityV2(
        amountOfDaysInTheFuture: 31
        consultationType: $consultationType
      )
      id
      user {
        email
        firstName
        id
      }
    }
  }
`);

interface ConsultationDateSelectionProps {
  consultation: Consultation;
  formState: IFormState;
  isEditing: boolean;
  narrow?: boolean;
}

const ConsultationDateSelection = ({
  consultation,
  formState,
  isEditing,
}: ConsultationDateSelectionProps) => {
  const { i18n, t } = useTranslation();
  const dateFnsLocale = getDateFnsLocale(i18n.language);

  const { data } = useQuery(consultationDateSelectionAvailabilityQuery, {
    variables: {
      consultationType: consultation.getConsultationType(),
      professionalId: consultation.getProfessional().getID(),
    },
  });

  const timeSlotOptions = useMemo(() => {
    if (!data?.professional?.availabilityV2) return [];

    return data.professional.availabilityV2.map((timestamp) => ({
      label: format(timestamp, "eeee d MMMM p", { locale: dateFnsLocale }),
      value: new TimeSlot({
        durationInMinutes: consultation.getDurationInMinutes(),
        startTime: timestamp,
      }),
    }));
  }, [i18n.language, data]);

  return (
    <Box>
      <Box>
        {!isEditing && (
          <Box>
            <ValueWithLabel label={t("common:consultation.date")}>
              {consultation
                .getStartDate(
                  DateFormat.ONLY_DATE,
                  getMomentLocale(i18n.language),
                )
                .toString() || "-"}
            </ValueWithLabel>
          </Box>
        )}
        {isEditing ? (
          <>
            <Label value={t("common:consultation.date")} />
            <Box mt={1}>
              <TimeSlotBoxes
                label={t("human:request.consultation.timeslot.placeholder")}
                onSelectTime={(timeSlot) => {
                  formState.setValue("timeFrom", timeSlot.getStartTime());
                  formState.setValue("timeTo", timeSlot.getEndTime());
                }}
                timeslotItems={timeSlotOptions}
              />
            </Box>
            <Typography mt={1}>
              {t("human:reschedule.consultation.timeslot.otherOptions", {
                professionalEmail: data?.professional?.user.email,
                professionalName: data?.professional?.user.firstName,
              })}
            </Typography>
          </>
        ) : null}
      </Box>
      <Box>
        {!isEditing && (
          <ValueWithLabel label={t("common:consultation.time")}>
            {t("common:consultation.time.format", {
              duration: consultation.getDurationInMinutes(),
              endTime: consultation.getEndDate(DateFormat.ONLY_TIME),
              startTime: consultation.getStartDate(DateFormat.ONLY_TIME),
            })}
          </ValueWithLabel>
        )}
      </Box>
    </Box>
  );
};

export default ConsultationDateSelection;
