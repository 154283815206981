import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled, lighten } from "@mui/material/styles";
import { useContext, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import LoadingPage from "../../layout/LoadingPage";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import FormLabel from "../../ui/form/FormLabel";
import SelectTraining from "../../ui/form/SelectTraining";
import TextField from "../../ui/form/TextField";
import SanitizedText from "../../ui/text/SanitizedText";
import UploadErrorAlert from "./UploadErrorAlert";
import UploadFileBox from "./UploadFileBox";
import ProfessionalRegistrationContext from "./context/ProfessionalRegistrationContext";
import {
  IProfessionalRegistrationContext,
  ProfessionalType,
} from "./context/professionalRegistration";

const PREFIX = "RegisterProfessionalVerifyPage";

const classes = {
  boxedBox: `${PREFIX}-boxedBox`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.boxedBox}`]: {
    "& H5": {
      marginBottom: 15,
    },
    backgroundColor: lighten(theme.palette.secondary.main, 0.5),
    margin: "65px auto 70px",
    maxWidth: "80%",
    padding: theme.spacing(3),

    width: "100%",
  },
}));

const RegisterProfessionalVerifyPage = () => {
  const { t } = useTranslation();
  const {
    hasUploadError,
    isRegistering,
    previous,
    registerProfessional,
    verifyFormState: formState,
  } = useContext<IProfessionalRegistrationContext>(
    ProfessionalRegistrationContext,
  );

  const professionalType: ProfessionalType = useMemo(
    () => formState?.values?.professionalType,
    [formState.values],
  );

  useEffect(() => {
    if (formState?.values && !formState?.values?.professionalType) {
      previous();
    }
  }, [formState?.values, previous]);

  const isPsychoTherapist: boolean = professionalType === "pt";
  const hasDonePsycho: boolean = professionalType
    ? ["pt", "cppt"].includes(professionalType)
    : false;

  // The drop area label differs per professional type as some need to upload
  // more documents than others.
  const dropLabel = (() => {
    switch (professionalType) {
      case "pt":
        return (
          <>
            <span>
              {t(
                "professional:register.proof.training.copyproof.psychotherapist",
              )}
            </span>
            <ul>
              <li>
                {t(
                  "professional:register.proof.training.copyproof.psychotherapist.diploma",
                )}
              </li>
              <li>
                {t(
                  "professional:register.proof.training.copyproof.psychotherapist.psycho",
                )}
              </li>
              <li>
                {t(
                  "professional:register.proof.training.copyproof.psychotherapist.cv",
                )}
              </li>
            </ul>
          </>
        );

      case "cppt":
        return (
          <SanitizedText
            input={t(
              "professional:register.proof.training.copyproof.psychotherapy",
            )}
          />
        );

      default:
        return (
          <SanitizedText
            input={t("professional:register.proof.training.copyproof")}
          />
        );
    }
  })();

  if (isRegistering) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <Grid item>
        {hasUploadError ? (
          <UploadErrorAlert />
        ) : (
          <OnboardingStepContainer
            disable={
              formState?.values?.licenseFiles?.length === 0 ||
              (professionalType !== "cp"
                ? formState.values.psychoTherapyTraining === ""
                : false)
            }
            fullCopyright
            headline={t("professional:register.title")}
            labelBack={t("common:previous")}
            labelNext={t("common:complete")}
            onClickBack={previous}
            onClickNext={registerProfessional}
            subhead={t("professional:register.proof.subtitle")}
            subheadicon={<DescriptionOutlinedIcon />}
          >
            <Grid container>
              {/* The title description */}
              <Grid item md={12} sm={12} xs={12}>
                <Typography paragraph variant="body2">
                  {!isPsychoTherapist ? (
                    t("professional:register.proof.description")
                  ) : (
                    <Trans
                      i18nKey="professional:register.proof.psychotherapist.description"
                      t={t}
                    >
                      {
                        "Om als professional gebruik te maken van BloomUp dien je een bewijs van "
                      }
                      <Link
                        href="https://www.health.belgium.be/nl/psychotherapeut"
                        rel="noopener"
                        target="_blank"
                        underline="hover"
                      >
                        wettelijke uitoefening
                      </Link>
                      {" van de psychotherapie te hebben."}
                    </Trans>
                  )}
                </Typography>
              </Grid>
              {hasDonePsycho ? (
                <Grid item md={12} sm={12} xs={12}>
                  <FormLabel
                    label={t("professional:register.proof.training.label")}
                  />
                  <SelectTraining
                    errorText={t("professional:selecttraining.mandatory")}
                    formState={formState}
                    hasError={formState.hasError("psychoTherapyTraining")}
                    placeholder={t(
                      "professional:register.proof.training.select.label",
                    )}
                    style={{
                      marginBottom: 50,
                    }}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <FormLabel label={dropLabel} />
            <UploadFileBox formState={formState} />
            <TextField
              label={t("professional:register.proof.website")}
              required={false}
              style={{
                marginTop: 50,
              }}
              {...formState.getInputProps("website")}
            />
            <StyledBox className={classes.boxedBox}>
              <Typography align="left" variant="h5">
                {t("professional:register.proof.noneyet.title")}
              </Typography>
              <Typography align="left" variant="body2">
                {t("professional:register.proof.noneyet.description")}
              </Typography>
            </StyledBox>
          </OnboardingStepContainer>
        )}
      </Grid>
    </OnboardingContainer>
  );
};

export default RegisterProfessionalVerifyPage;
