import { PresenceMessage } from "ably";
import { usePresence, usePresenceListener } from "ably/react";
import { useEffect } from "react";

interface PresenceTrackerProps {
  channel: string;
  onChange?: (data: PresenceMessage[]) => void;
}

const PresenceTracker = ({ channel, onChange }: PresenceTrackerProps) => {
  usePresence(channel, "enter");

  const { presenceData } = usePresenceListener(channel);

  useEffect(() => {
    if (onChange) onChange(presenceData);
  }, [presenceData, onChange]);

  return null;
};

export default PresenceTracker;
