import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import NavigationToggle from "../../layout/NavigationToggle";
import { ToggleStylingProperties } from "../../ui/form/Toggle";

const dashboardsQuery = graphql(`
  query Dashboards {
    currentUser {
      adminOfOrganization {
        contractDashboardUrl {
          ...useSelectTranslationStringInput
        }
        id
        interestDashboardUrl {
          ...useSelectTranslationStringInput
        }
        mentalWellbeingDashboardUrl {
          ...useSelectTranslationStringInput
        }
        usageDashboardUrl {
          ...useSelectTranslationStringInput
        }
        videoEnabled
      }
      id
    }
  }
`);

type dashboardTab = "contract" | "interests" | "usage" | "mentaalWelbeeing";

type urls = {
  [key in dashboardTab]: string | undefined | null;
};

const OrganizationUsagePage = () => {
  const selectTranslation = useSelectTranslation();
  const [currentTab, setCurrentTab] = useState<dashboardTab>("contract");

  const { data } = useQuery(dashboardsQuery);

  const urls: urls = {
    contract: selectTranslation(
      data?.currentUser?.adminOfOrganization?.contractDashboardUrl,
    ),
    interests: selectTranslation(
      data?.currentUser?.adminOfOrganization?.interestDashboardUrl,
    ),
    mentaalWelbeeing: selectTranslation(
      data?.currentUser?.adminOfOrganization?.mentalWellbeingDashboardUrl,
    ),
    usage: selectTranslation(
      data?.currentUser?.adminOfOrganization?.usageDashboardUrl,
    ),
  };

  const styleProps: ToggleStylingProperties = {
    fontWeight: 600,
    height: "50px",
    paddingBottom: "15px",
    paddingTop: "15px",
    width: "700px",
  };

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Dashboards,
  ]);

  return (
    <>
      <NavigationToggle<dashboardTab>
        onSelectChanged={(selectedValue) => {
          setCurrentTab(selectedValue);
        }}
        options={compact([
          {
            label: t("dashboards:contract"),
            value: "contract",
          },
          data?.currentUser?.adminOfOrganization?.videoEnabled && {
            label: t("dashboards:interests"),
            value: "interests",
          },
          data?.currentUser?.adminOfOrganization?.videoEnabled && {
            label: t("dashboards:usage"),
            value: "usage",
          },
          data?.currentUser?.adminOfOrganization?.videoEnabled && {
            label: t("dashboards:mentaal.welbeeing"),
            value: "mentaalWelbeeing",
          },
        ])}
        toggleStylingProperties={styleProps}
      />

      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        allowTransparency
        frameBorder={0}
        height={"100%"}
        src={urls[currentTab] ?? undefined}
        width={"100%"}
      />
    </>
  );
};

export default OrganizationUsagePage;
