import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { APIBillingInformation } from "../../../api/billingInformation/billingInformation";
import AccountNumber from "../../../data-model/types/billing/AccountNumber";
import Professional from "../../../data-model/types/professional/Professional";
import { IFormState } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import InlineEditableAddress from "../../ui/edit/InlineEditableAddress";
import InlineEditableField from "../../ui/edit/InlineEditableField";
import { IProfessionalFormState } from "./hooks/useProfessionalProfileFormState";

const PREFIX = "ProfessionalBillingProfile";

const classes = {
  description: `${PREFIX}-description`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.description}`]: {
    fontSize: theme.typography.sizes.normal,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
}));

interface IProfessionalProfile {
  formState: IFormState<IProfessionalFormState>;
  onSave: (bI: APIBillingInformation.Output) => Promise<void>;
  professional: Professional;
}

export default function ProfessionalBillingProfile({
  formState,
  onSave,
  professional,
}: IProfessionalProfile) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
  ]);

  if (!professional) return <LoadingPage logText="Professional Profile" />;

  const saveBillingInfo = () => {
    const { accountNumber, billingAddress } = formState.values;

    return onSave({
      accountNumber,
      address: {
        country: "BE",
        postcode: billingAddress.postcode ?? "",
        street: billingAddress.street ?? "",
      },
    });
  };

  return (
    <StyledBox pb={5}>
      <Box className={classes.description}>
        {translate(
          "professional:profile.billing.description",
          "Deze gegevens komen op je afrekening te staan.",
        )}
      </Box>
      <Box mt={3}>
        <InlineEditableAddress
          formState={formState}
          initialAddress={professional.getBillingInformation().getAddress()}
          label={translate("professional:profile.billing.address", "Adres")}
          onSave={saveBillingInfo}
        />
      </Box>

      <Box mt={3}>
        <InlineEditableField<AccountNumber>
          dataObject={professional.getBillingInformation().getAccountNumber()}
          formState={formState}
          label={translate("common:accountnumber", "IBAN ")}
          onSave={saveBillingInfo}
        />
      </Box>
    </StyledBox>
  );
}
