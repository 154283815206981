import TextFieldMUI, { BaseTextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { ChangeEventHandler } from "react";

const PREFIX = "FormikTextField";

const classes = {
  disabled: `${PREFIX}-disabled`,
  root: `${PREFIX}-root`,
};

const StyledTextFieldMUI = styled(TextFieldMUI)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& .MuiFormHelperText-root": {
      "& .Mui-error": {
        ...theme.controls.validation?.error,
      },

      fontSize: theme.controls.fontSize,
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        ...theme.controls.border?.normal,
      },

      "& INPUT": {
        ...theme.controls.input,
      },

      "& TEXTAREA": {
        fontSize: theme.controls.fontSize,
        lineHeight: 1.5,
      },

      "&.Mui-focused FIELDSET": {
        ...theme.controls.border?.hover,
      },

      "&:hover FIELDSET": {
        ...theme.controls.border?.hover,
      },

      background: theme.palette.white.main,
    },

    "& LABEL.MuiInputLabel-outlined": {
      backgroundColor: theme.palette.white.main,
      fontSize: theme.controls.fontSize,
    },
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: theme.palette.bloom.light,
  },
}));

interface IBloomUpTextField extends BaseTextFieldProps {
  autoUpperCase?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  hasError?: boolean;
  onChange: ChangeEventHandler;
  placeholder?: string;
  required?: boolean;
}

/**
 * Custom text field for BloomUp.
 *
 * @param {Boolean} [fullWidth=true]
 *        Should the text field take up the full width?
 * @param {Boolean} [required=true]
 *        Is the text field a required field? This will add an asterisk.
 * @param {Text} [variant=outlined]
 *        What is the variant of text area? By default we use outlined at BloomUp.
 * @param {Function} [onChange]
 *        Function that takes 1 parameter which represents the value.
 */

export default function FormikTextField({
  autoUpperCase = false,
  errorText,
  fullWidth = true,
  hasError,
  onChange,
  placeholder,
  required = false,
  variant = "outlined",
  ...other
}: IBloomUpTextField) {
  /* (event) =>
    onChange(
        autoUpperCase
            ? toUpper(event.target.value)
            : event.target.value
    )*/

  return (
    <StyledTextFieldMUI
      className={classes.root}
      error={hasError}
      fullWidth={fullWidth}
      helperText={errorText}
      onChange={onChange}
      placeholder={placeholder ?? ""}
      required={required}
      variant={variant}
      {...other}
      inputProps={{
        classes: {
          disabled: classes.disabled,
        },

        style: { textTransform: autoUpperCase ? "uppercase" : "none" },
      }}
    />
  );
}
